import {
  createUrlBundle,
  createCacheBundle,
  composeBundlesRaw,
  createDebugBundle,
  createReactorBundle,
} from "redux-bundler";
import actionBundle from "./actions-bundle";
import aigsTempBundle from "./aigs-temp-bundle";
import apiBundle from "./api-bundle";
import cache from "../utils/cache";
import locationBundle from "./location-bundle";
import locationNotesBundle from "./note-bundle";
import locationsummaryBundle from "./locationsummary-bundle";
import statesBundle from "./state-bundle";
import chartsBundle from "./chart-bundle";
import aliasBundle from "./alias-bundle";
import projectsBundle from "./project-bundle";
import streamsBundle from "./stream-bundle";
import routesBundle from "./routes-bundle";
import unitBundle from "./unit-bundle";
import sampleBundle from "./sample-bundle";
import resultBundle from "./result-bundle";
import taxaBundle from "./taxa-bundle";
import bioresultBundle from "./bioresult-bundle";
import biosampleBundle from "./biosample-bundle";
import testsBundle from "./test-bundle";
import sourceBundle from "./source-bundle";
import collectionBundle from "./collection-bundle";
import labBundle from "./lab-bundle";
import storetBundle from "./storet-bundle";
import methodBundle from "./testMethod-bundle";
import resultsummaryBundle from "./resultsummary-bundle";
import resultswlocBundle from "./resultswloc-bundle";
import editBundle from "./edit-bundle";
import mapsBundle from "./maps-bundle";
import profileBundle from "./profile-bundle";
import projectMapBundle from "./project-map-bundle";
import reportBundle from "./report-bundle";
import reportStationBundle from "./report-station-bundle";
import reportSummaryBundle from "./report-summary-bundle";
import reportExceedanceBundle from "./report-exceedance-bundle";
import reportHitlistBundle from "./report-hitlist-bundle";
import bulkUploadBundle from "./bulk-upload-bundle";
import documentBundle from "./document-bundle";
import minorBasinBundle from "./minor-basin-bundle";
import majorBasinBundle from "./major-basin-bundle";
import fieldTripBundle from "./fieldTrip-bundle";
import fieldVisitBundle from "./fieldVisit-bundle";
import cwmsSeqBundle from "./cwmsSeq-bundle";
import indicesBundle from "./indices-bundle";
import locationIndicesBundle from "./indicesLocation-bundle";
import trendsBundle from "./trends-bundle";
import samplesNewBundle from "./samples-new-bundle";
import modalBundle from "./modal-bundle";
import trendsConclusionBundle from "./trends-conclusion-bundle";
import officeBundle from "./office-bundle";
import filterBundle from "./filter-bundle";
import heatmapBundle from "./heatmap-bundle";
import chart2Bundle from "./chart2-bundle";
import lrnFormBundle from "./lrnForm-bundle";
import biologicsBundle from "./biologics-bundle";
import createAuthBundle from "./create-auth-bundle";
import shannonBundle from "./shannon-bundle";
import profileGateBundle from "./profile-gate-bundle";
import projectSummaryBundle from "./project-summary-bundle";
import projectLocationBundle from "./project-location-bundle";
import {
  appTimeBundle,
  asyncCountBundle,
} from "redux-bundler/dist/redux-bundler.umd";
import profileUploadBundle from "./profile-upload-bundle";
import locationTypeBundle from "./location-type-bundle";
import countiesBundle from "./counties-bundle";
import authBundle from "./auth-bundle";
import userBundle from "./user-bundle";
import accessRequestBundle from "./access-request-bundle";
import userRolesBundle from "./user-roles-bundle";
import dataFlagBundle from "./data-flag-bundle";
import lakeQualityBundle from "./lake-quality-bundle";
import analysisMethodsBundle from "./analysis-methods-bundle";

export default composeBundlesRaw(
  appTimeBundle,
  asyncCountBundle,
  actionBundle,
  aigsTempBundle,
  apiBundle,
  aliasBundle,
  biologicsBundle,
  chartsBundle,
  collectionBundle,
  createCacheBundle({
    cacheFn: cache.set,
  }),
  createUrlBundle(),
  // createAuthBundle({
  //   appId: "7eff4569-374d-46f8-ba4c-e5d02ed67b02",
  //   redirectOnLogout: "/",
  // }),
  authBundle,
  createDebugBundle({
    actionFilter: (action) => action.type !== "APP_IDLE",
  }),
  createReactorBundle({
    idleTimeout: null,
  }),
  labBundle,
  locationNotesBundle,
  locationBundle,
  locationsummaryBundle,
  methodBundle,
  projectsBundle,
  projectSummaryBundle,
  projectLocationBundle,
  resultBundle,
  taxaBundle,
  bioresultBundle,
  biosampleBundle,
  resultsummaryBundle,
  resultswlocBundle,
  routesBundle,
  sampleBundle,
  sourceBundle,
  statesBundle,
  storetBundle,
  streamsBundle,
  testsBundle,
  unitBundle,
  editBundle,
  mapsBundle,
  projectMapBundle,
  reportBundle,
  reportStationBundle,
  reportSummaryBundle,
  reportExceedanceBundle,
  reportHitlistBundle,
  bulkUploadBundle,
  documentBundle,
  minorBasinBundle,
  majorBasinBundle,
  projectMapBundle,
  reportBundle,
  fieldTripBundle,
  fieldVisitBundle,
  cwmsSeqBundle,
  indicesBundle,
  locationIndicesBundle,
  trendsBundle,
  samplesNewBundle,
  modalBundle,
  trendsConclusionBundle,
  officeBundle,
  heatmapBundle,
  filterBundle,
  chart2Bundle,
  lrnFormBundle,
  shannonBundle,
  profileBundle,
  profileGateBundle,
  profileUploadBundle,
  locationTypeBundle,
  countiesBundle,
  userBundle,
  accessRequestBundle,
  userRolesBundle,
  dataFlagBundle,
  lakeQualityBundle,
  analysisMethodsBundle
);
