import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import { TableContainer } from "../../app-components/page-content";
import { noteConfig } from "../dataExport/filterConfigs";
import FilterSidebar from "../dataExport/FilterSidebar";

import TableNote from "./TableNote";

const PageNote = ({ doNoteShouldFetch, routeParams, ...props }) => {
  useEffect(() => {
    doNoteShouldFetch();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      {" "}
      <FilterSidebar config={noteConfig} {...props} />
      <TableContainer>
        <TableNote />
      </TableContainer>
    </div>
  );
};
export default connect(
  "doNoteShouldFetch",
  "selectRouteParams",
  ...noteConfig.selectors,
  ...noteConfig.fetchFns,
  PageNote
);
