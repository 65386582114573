import React, { useReducer, useEffect } from "react";
import ReactSelect from "react-select";
import { connect } from "redux-bundler-react";
import {
  ModalFooter,
  ModalContent,
  ModalHeader,
} from "../../app-components/modal";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const CODES = [
  "location_code",
  "result_code",
  "site_code",
  "collect_mthd",
  "sample_code",
  "source_code",
  "collection_method_name",
  "sample_depth",
  "biosample_code",
  "bioresult_code",
  "loc_id",
];

const bioresultFields = [
  "analysis_method",
  "biomass",
  "bioresult_code",
  "biosample_code",
  "biovolume",
  "bm_units",
  "bv_units",
  "count",
  "count_units",
  "date_analyzed",
  "dcl_flag",
  "density",
  "density_units",
  "export_flag",
  "lab_id",
  "life_stage",
  "location_code",
  "reference",
  "rep_num",
  "sample_type",
  "storage_loc",
  "storage_meth",
  "taxacode",
  "taxonomist_code",
];

const _isNotCode = (key) => !CODES.includes(key);

const EditResultModal = ({
  data,
  edit,
  doUpdateResult,
  doDeleteResult,
  doAddResult,
  taxaData,
  bioresult_code,
  nested,
  doNestedModalClose,
  doModalClose,
  bioSamplesByLocation,
  routeParams,
  bioSampleTypeOptions,
  biosample_code,
  sample_type,
  bioResultError: error,
  bioResultIsSaving,
  bioResultIsDeleting,
}) => {
  const initialState = {
    ...data,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    if (edit) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: {
          ...data,
        },
      });
    } else {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: {
          bioresult_code,
          biosample_code,
          sample_type,
        },
      });
    }
  }, [edit, data]);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSelect = (field, payload) => {
    dispatch({
      type: "UPDATE_INPUT",
      field,
      payload: payload.value,
    });
  };

  const handleCheckboxChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.checked ? "T" : "X",
    });
  };
  const doSave = () => {
    let data = { ...state };
    data.date_analyzed = !state.date_analyzed
      ? null
      : state.date_analyzed.split(":").length === 3
      ? `${state.date_analyzed}`
      : `${state.date_analyzed}:00Z`;
    data.location_code = parseInt(routeParams.id);
    data.sample_type = bioSamplesByLocation.filter(
      (b) => b.biosample_code === data.biosample_code
    )[0].sample_type;
    if (edit) {
      doUpdateResult(data, nested ? doNestedModalClose : doModalClose);
    } else {
      doAddResult(data, nested ? doNestedModalClose : doModalClose);
    }
  };
  return (
    <ModalContent size="lg" error={error}>
      <ModalHeader
        title="Edit Result"
        onClose={nested ? doNestedModalClose : doModalClose}
      />
      <section className="modal-body">
        <div className="container-fluid">
          <div className="d-flex flex-column">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: 12,
              }}
            >
              <div className="mb-2">
                <label htmlFor={"biosample_code"}>BioSample</label>
                <ReactSelect
                  isDisabled={edit}
                  className="w-100"
                  id={"biosample_code"}
                  onChange={(obj) => handleSelect("biosample_code", obj)}
                  name={"biosample_code"}
                  value={{
                    value: state.biosample_code,
                    label:
                      bioSamplesByLocation &&
                      bioSamplesByLocation.filter(
                        (t) => t.biosample_code === state.biosample_code
                      ).length > 0 &&
                      bioSamplesByLocation.filter(
                        (t) => t.biosample_code === state.biosample_code
                      )[0].sample_num,
                  }}
                  menuPosition="fixed"
                  options={
                    bioSamplesByLocation &&
                    bioSamplesByLocation.map((t) => ({
                      label: t.sample_num,
                      value: t.biosample_code,
                    }))
                  }
                />
              </div>
              <div className="mb-2">
                <label htmlFor={"sample_type"}>Sample Type</label>
                <ReactSelect
                  id="sample_type"
                  onChange={(obj) => handleSelect("sample_type", obj)}
                  name="sample_type"
                  value={{
                    label: state["sample_type"],
                    value: state["sample_type"],
                  }}
                  menuPosition="fixed"
                  options={bioSampleTypeOptions}
                  className="w-100"
                />
              </div>
              {bioresultFields &&
                bioresultFields.length > 0 &&
                bioresultFields.map((value) => {
                  if (value === "taxacode") {
                    return (
                      <div className="mb-2">
                        <label htmlFor={value}>Taxacode</label>
                        <ReactSelect
                          className="w-100"
                          id={value}
                          onChange={(obj) => handleSelect("taxacode", obj)}
                          name={value}
                          value={{
                            value: state.taxacode,
                            label:
                              taxaData.filter(
                                (t) => t.taxacode === state.taxacode
                              ).length > 0 &&
                              taxaData.filter(
                                (t) => t.taxacode === state.taxacode
                              )[0].sci_name,
                          }}
                          menuPosition="fixed"
                          options={taxaData.map((t) => ({
                            label: t.sci_name,
                            value: t.taxacode,
                          }))}
                        />
                      </div>
                    );
                  } else if (value.includes("date")) {
                    return (
                      <div className="mb-2">
                        <label htmlFor={value}>
                          {value
                            .replaceAll("_", " ")
                            .replace(/(\b[a-z](?!\s))/g, (x) =>
                              x.toUpperCase()
                            )}
                        </label>
                        <input
                          className="form-control"
                          id={value}
                          onChange={handleChange}
                          name={value}
                          value={
                            state[value] && state[value].includes("Z")
                              ? state[value].split(":00Z")[0]
                              : state[value]
                          }
                          type="datetime-local"
                        />
                      </div>
                    );
                  } else if (value === "export_flag") {
                    return (
                      <div className="mb-2 d-flex align-items-center">
                        <input
                          id={value}
                          onChange={handleCheckboxChange}
                          name={value}
                          value={state[value] === "T" ? true : false}
                          checked={state[value] === "T" ? true : false}
                          type="checkbox"
                          className="mr-2"
                        />
                        <label htmlFor={value}>
                          {value
                            .replaceAll("_", " ")
                            .replace(/(\b[a-z](?!\s))/g, (x) =>
                              x.toUpperCase()
                            )}
                        </label>
                      </div>
                    );
                  } else if (_isNotCode(value)) {
                    return (
                      <div className="mb-2">
                        <label htmlFor={value}>
                          {value
                            .replaceAll("_", " ")
                            .replace(/(\b[a-z](?!\s))/g, (x) =>
                              x.toUpperCase()
                            )}
                        </label>
                        <input
                          className="form-control"
                          id={value}
                          onChange={handleChange}
                          name={value}
                          value={state[value]}
                          disabled={
                            value === "site_name" ||
                            value === "office_id" ||
                            value === "loc_id"
                          }
                        />
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </section>
      <ModalFooter
        showCancelButton
        onSave={doSave}
        onDelete={() =>
          doDeleteResult(state, nested ? doNestedModalClose : doModalClose)
        }
        customClosingLogic
        onCancel={nested ? doNestedModalClose : doModalClose}
        saveIsDisabled={bioResultIsSaving}
        deleteIsDisabled={bioResultIsDeleting}
        saveText={
          bioResultIsSaving ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Save"
          )
        }
        deleteText={
          bioResultIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "selectTaxaData",
  "doNestedModalClose",
  "doModalClose",
  "selectBioSamplesByLocation",
  "selectRouteParams",
  "selectBioSampleTypeOptions",
  "doUpdateResult",
  "doDeleteResult",
  "doAddResult",
  "selectBioResultIsSaving",
  "selectBioResultIsDeleting",
  "selectBioResultError",
  EditResultModal
);
