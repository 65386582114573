export default {
  name: "api",

  getReducer: () => {
    const initialData = {
      root: process.env.REACT_APP_API_ROOT,
      token: "",
      shouldFetch: false,
    };
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "AUTH_LOGGED_IN":
        case "AUTH_VERIFY_TOKEN":
          return Object.assign({}, state, {
            shouldFetch: true,
          });
        case "API_TOKEN_START":
        case "API_TOKEN_LOADED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  selectApiRoot: (state) => {
    return state.api.root;
  },

  getExtraArgs: (store) => {
    return {
      apiFetch: (url, options) => {
        const token = store.selectAccessToken();
        const root = store.selectApiRoot();
        if (!options) options = {};
        if (token) {
          options.headers = {
            ...options.headers,
            ...{ Authorization: `Bearer ${token.access_token}` },
          };
        }
        if (url.indexOf("http") !== 0) url = `${root}${url}`;
        // when posting, ords/cwms_wq is being appended twice here.. testing if this statement just fixes it for now.
        if (url.includes("cwms_wq/ords"))
          url = url.replace("/cwms_wq/ords", "");
        return fetch(url, options)
          .then((r) => r)
          .catch((e) => options.onError && options.onError(e));
      },
    };
  },
};
