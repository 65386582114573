import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import "./css/bootstrap/css/bootstrap.min.css";
import "./css/coreui/coreui.min.css";
import "./css/mdi/css/materialdesignicons.min.css";

import Nav from "./containers/nav";
import { getNavHelper } from "internal-nav-helper";
import BreadcrumbsComponent from "./app-components/breadcrumbs/Breadcrumbs";
import Footer from "./containers/bottomNav/BottomNav";
import Modal from "./app-components/modal";
import classNames from "classnames";
import "./app.css";
import DodSafeModal from "./containers/home/DodSafeModal";
import AuthProvider from "./containers/auth-provider";

function App({ route, project, doUpdateUrl, doModalOpen }) {
  const Route = route;
  let showNavs =
    window.location.pathname !== "/" &&
    window.location.pathname !== "/access-request" &&
    window.location.pathname !== "/st" &&
    window.location.pathname !== "/st/";

  const navClass = classNames({
    "d-none": !showNavs,
  });
  // Checks if there is a dodSafe token and if it has expired, if no token or is expired then render modal.
  const _getDodExpiry = () => {
    let itemStr = localStorage.getItem("dodSafe");
    if (!itemStr) {
      doModalOpen(DodSafeModal);
      return null;
    }
    let item = JSON.parse(itemStr);
    if (item && item.expiry) {
      if (new Date().getTime() > item.expiry) {
        localStorage.removeItem("dodSafe");
        doModalOpen(DodSafeModal);
        return null;
      } else return null;
    } else doModalOpen(DodSafeModal);
  };

  useEffect(_getDodExpiry, []);
  return (
    <div onClick={getNavHelper(doUpdateUrl)}>
      <AuthProvider>
        <Modal closeWithEscape />
        <Nav className={navClass} />
        <div className={`body-container ${showNavs ? "show-nav" : ""}`}>
          {project && <BreadcrumbsComponent />}
          <Route />
          <Footer className={navClass} />
        </div>
      </AuthProvider>
    </div>
  );
}

export default connect(
  "selectRoute",
  "selectProject",
  "doUpdateUrl",
  "doModalOpen",
  App
);
