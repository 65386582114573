import React from "react";
import { connect } from "redux-bundler-react";

const FilterButton = connect(
  "doProjectMapLocationFetch",
  "doProjectMapResetFilter",
  ({ doProjectMapLocationFetch, doProjectMapResetFilter }) => {
    return (
      <div className="d-flex align-items-center">
        <button
          className={`btn btn-success mr-1 w-100`}
          onClick={doProjectMapLocationFetch}
        >
          Apply Filters
        </button>
        <button
          className={`btn btn-outline-dark ml-1 w-100`}
          variant="contained"
          onClick={doProjectMapResetFilter}
        >
          Clear Filters
        </button>
      </div>
    );
  }
);

export default FilterButton;
