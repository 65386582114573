import React, { useState, useEffect } from "react";
// import { StackedBar } from "../../charting";
import { connect } from "redux-bundler-react";
import Link from "../../misc/link";
import "./customCard.css";
// import { TableChart, ArrowRightAlt } from "@material-ui/icons";

function CardBioResults(props) {
  const [info, setInfo] = useState(null);
  const {
    doBioResultShouldFetch,
    bioResultData,
    bioResultIsFetching,
    storetData,
  } = props;

  useEffect(() => {
    if (props.location == null) return;
    setInfo(0);
    doBioResultShouldFetch(props.location.location_code);
  }, [props.location.location_code]);

  useEffect(() => {
    if (!bioResultIsFetching) {
      setInfo(bioResultData[0]);
    }
  }, [bioResultIsFetching]);

  return !props.location || !props.project || bioResultIsFetching ? (
    <div className="custom">
      <div className="card-body">
        <div style={{ textAlign: "center" }}>
          <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
        </div>
      </div>
    </div>
  ) : (
    <div className="custom">
      <a
        style={{ textDecoration: "none", cursor: "pointer", height: "100%" }}
        href={`/projects/${props.project.site_code}/locations/${props.location.location_code}/bioresults`}
      >
        <div className="card-body h-100">
          <div className="custom-body">
            {/* <TableChart color="white" htmlColor="white" fontSize="large" /> */}
            <div>
              <h4
                className="card-title border-bottom text-black"
                style={{ color: "#3c4b64" }}
              >
                BioResults
              </h4>
              <h6 className="card-subtitle mb-2 text-muted">
                {`Recent Sample Type: `}
                {info && info.sample_type ? info.sample_type : "n/a"}
              </h6>
              <h6 className="card-subtitle mb-2 text-muted">
                {`Recent Taxacode: `}
                {info && info.taxacode ? info.taxacode : "n/a"}
              </h6>
              <h6 className="card-subtitle mb-2 text-muted">
                {`Recent Count: `}
                {info && info.count ? info.count : "n/a"}
              </h6>
              <Link
                style={{ fontWeight: "bold" }}
                to={`/projects/${props.project.site_code}/locations/${props.location.location_code}/bioresults`}
              >
                Go to BioResults
              </Link>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}

export default connect(
  "selectBioResultData",
  "selectBioResultIsFetching",
  "doBioResultShouldFetch",
  CardBioResults
);
