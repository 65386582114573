import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "labs",
  getReducer: () => {
    const initialData = {
      selectsLabs: [],
      selectsCodes: [],
      shouldFetch: false,
      shouldTableFetch: false,
      data: [],
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "AUTH_VERIFY_TOKEN":
        case "URL_UPDATED":
          return Object.assign({}, state, {
            shouldTableFetch: true,
          });
        case "LABS_FETCH_STARTED":
        case "LABS_FETCH_FINISHED":
        case "LABS_TABLE_FETCH_STARTED":
        case "LABS_SHOULD_FETCH":
        case "LAB_SAVE_STARTED":
        case "LAB_SAVE_FINISHED":
        case "LAB_SAVE_ERROR":
        case "LAB_DELETE_STARTED":
        case "LAB_DELETE_FINISHED":
        case "LAB_DELETE_ERROR":
          return Object.assign({}, state, payload);
        case "MODAL_CLOSED":
          return { ...state, error: null };
        default:
          return state;
      }
    };
  },

  doLabsFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "LABS_FETCH_STARTED",
        payload: {
          shouldFetch: false,
        },
      });
      const createData = (data) => {
        var list = [];
        var labNums = [];
        var i = 0;
        for (i = 0; i < data.length; i++) {
          list.push({ value: data[i].lab_id, label: data[i].lab_name });
          labNums.push({ value: data[i].lab_code, label: data[i].lab_code });
        }
        dispatch({
          type: "LABS_FETCH_FINISHED",
          payload: {
            selectsLabs: list,
            selectsCodes: labNums,
          },
        });
      };

      apiFetch(ordsifyUrlBuilder("/labs/", [], 20000))
        .then((response) => response.json())
        .then((r) => createData(r.items))
        .catch((e) => {
          console.log(e);
        });
    },
  doLabsTableFetch:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "LABS_TABLE_FETCH_STARTED",
        payload: {
          shouldFetch: false,
          shouldTableFetch: false,
          isFetching: true,
        },
      });

      let url = `/wq_labs/?limit=10000`;

      if (state) {
        url = ordsifyUrlBuilder(
          "/wq_labs/",
          [
            {
              keyword: "lab_id",
              items: state["lab_id"] ? state["lab_id"] : "",
            },
          ],
          20000
        );
      }

      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "LABS_FETCH_FINISHED",
            payload: {
              isFetching: false,
              data: j.items,
            },
          });
        });
    },

  doLabsShouldFetch:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "LABS_SHOULD_FETCH",
        payload: { shouldFetch: true },
      });
    },

  doLabsNewFetch:
    () =>
    ({ dispatch, apiFetch }) => {
      dispatch({
        type: "LABS_FETCH_STARTED",
        isFetching: true,
      });
      apiFetch("/wq_labs/")
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "LABS_FETCH_FINISHED",
            payload: {
              isFetching: false,
              labs: j.items,
            },
          });
        });
    },

  doLabPost:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPostData, doModalClose } = store;
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "LAB_SAVE_STARTED",
        payload: { isSaving: true },
      });
      let url = `${apiRoot}/wq_labs/`;
      doActionPostData(
        url,
        state,
        () => {
          dispatch({
            type: "LAB_SAVE_FINISHED",
            payload: { isSaving: false, shouldTableFetch: true, error: null },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "LAB_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },
  doLabPut:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPutData, doModalClose } = store;
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "LAB_SAVE_STARTED",
        payload: { isSaving: true },
      });
      let url = `${apiRoot}/wq_labs/${state.lab_code}`;
      doActionPutData(
        url,
        state,
        () => {
          dispatch({
            type: "LAB_SAVE_FINISHED",
            payload: { isSaving: false, shouldTableFetch: true, error: null },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "LAB_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doLabDelete:
    (state) =>
    ({ dispatch, store }) => {
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "LAB_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      store.doActionDeleteData(
        `${apiRoot}/wq_labs/${state.lab_code}`,
        {},
        () => {
          dispatch({
            type: "LAB_DELETE_FINISHED",
            payload: { isDeleting: false, shouldTableFetch: true, error: null },
          });
          store.doModalClose();
        },
        (e) =>
          dispatch({
            type: "LAB_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },

  selectLabIsDeleting: (state) => state.labs.isDeleting,
  selectLabIsSaving: (state) => state.labs.isSaving,
  selectLabError: (state) => state.labs.error,

  selectLabs: (state) => state.labs.data,
  selectLabsOptions: (state) =>
    state.labs.data.map((item) => ({
      label: item.lab_name,
      value: item.lab_id,
    })),
  selectLabsIsFetching: (store) => store.labs.isFetching,
  selectLabsShouldFetch: (store) => store.labs.shouldFetch,

  selectLabsSelectsLabs: (state) => {
    return state.labs.selectsLabs;
  },

  selectLabsSelectsCodes: (state) => {
    return state.labs.selectsCodes;
  },

  reactLabsShouldFetch: (state) => {
    if (state.labs.shouldFetch)
      return {
        actionCreator: "doLabsFetch",
      };
  },
  reactLabsTableShouldFetch: (state) => {
    if (state.labs.shouldTableFetch)
      return {
        actionCreator: "doLabsTableFetch",
      };
  },
};
