import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder, ordsifyUrlBuilderNoSlash } from "../utils/ordsify";
// import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
    name: "trends",
    getReducer: () => {
        const initialData = {
            data: [],
            sorted: {}
        };

        return (state = initialData, { type, payload }) => {
            switch (type) {
                case "TRENDS_FETCH_START":
                case "TRENDS_FETCH_FINISH":
                case "TRENDS_SHOULD_FETCH":
                    return Object.assign({}, state, payload);
                default:
                    return state;
            }
        };
    },
    doTrendsShouldFetch: () => ({ dispatch }) => {
        dispatch({ type: "TRENDS_SHOULD_FETCH", payload: { shouldFetch: true } });
    },

    doTrendsFetch: (dates) => ({ dispatch, store, apiFetch }) => {
        dispatch({
            type: "TRENDS_FETCH_START",
            payload: {
                shouldFetch: false,
                isFetching: true
            }
        });

        let url = ordsifyUrlBuilderNoSlash(`/trends/project_extremes/${store.selectRouteParams().project}`, [
            {
                keyword: "sample_year",
                items: dates ? dates : []
            }
        ], 1000);
        apiFetch(url)
            .then(r => r.json())
            .then(j =>
                dispatch({
                    type: "TRENDS_FETCH_FINISH",
                    payload: { data: j.items, isFetching: false }
                })
            )
            .catch(e => {
                console.log(e);
            });
    },
    selectTrendsIsFetching: state => state.trends.isFetching,
    selectTrendsShouldFetch: state => state.trends.shouldFetch,
    selectTrends: state => state.trends.data,

    reactTrendsShouldFetch: createSelector(
        "selectTrendsIsFetching",
        "selectTrendsShouldFetch",
        (isFetching, shouldFetch) => {
            // never fetch if anoter fetch already in progress
            if (isFetching) {
                return null;
            }
            if (shouldFetch) {
                return {
                    actionCreator: "doTrendsFetch"
                };
            }
        }
    ),
};
