// import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "locationsummary",
  getReducer: () => {
    const initialData = {
      data: [],
      shouldFetch: false,
      isFetching: false,
      summary: [],
      shouldFetchSummary: true,
      isFetchingSummary: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "AUTH_VERIFY_TOKEN":
          return Object.assign({}, state, {
            shouldFetch: true
          });
        case "LOCATIONSUMMARY_FETCH_START":
        case "LOCATIONSUMMARY_FETCH_UPDATE":
        case "LOCATIONSUMMARY_FETCH_FINISH":
        case "LOCATIONSUMMARY_SHOULD_FETCH":
        case "LOCATIONSUMMARY_LOCATION_FETCH_START":
        case "LOCATIONSUMMARY_LOCATION_FETCH_UPDATE":
        case "LOCATIONSUMMARY_LOCATION_FETCH_FINISH":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },
  doLocationsummaryShouldFetch: () => ({ dispatch }) => {
    dispatch({ type: "LOCATIONSUMMARY_SHOULD_FETCH", payload: { shouldFetch: true } })
  },

  doLocationsummaryFetch: (query, offset, limit) => ({ dispatch, store, apiFetch }) => {
    dispatch({
      type: "LOCATIONSUMMARY_FETCH_START",
      payload: {
        shouldFetch: false,
        isFetching: true
      }
    });
    let fetch_url;
    if (!offset || !limit) {
      limit = 10;
      offset = 0;
    }
    if (query) {
      fetch_url = `/location_sample_count/?q={"loc_desc":{"$like":"%25${query}%25"}}&offset=${offset}&limit=${limit}`;
    } else {
      fetch_url = `/location_sample_count/?offset=${offset}&limit=${limit}`
    }
    apiFetch(fetch_url)
      .then(r => r.json())
      .then(j => {
        const retrieved = (j.items);
        dispatch({
          type: "LOCATIONSUMMARY_FETCH_UPDATE",
          payload: { data: retrieved }
        });
        dispatch({
          type: "LOCATIONSUMMARY_FETCH_FINISH",
          payload: {
            isFetching: false
          }
        })
        // if (j.hasMore) {
        //   store.doLocationsummaryFetch(
        //     j.links.find(i => i.rel === "next").href.replace(/http:/, "https:")
        //   );
        //   dispatch({
        //     type: "LOCATIONSUMMARY_FETCH_FINISH",
        //     payload: {
        //       isFetching: false
        //     }
        //   });
        // } else {
        //   dispatch({
        //     type: "LOCATIONSUMMARY_FETCH_FINISH",
        //     payload: {
        //       isFetching: false
        //     }
        //   });
        // }
      });
  },

  doLocationsummaryFetchByLocation: (location_code) => ({ dispatch, store, apiFetch }) => {
    dispatch({
      type: "LOCATIONSUMMARY_LOCATION_FETCH_START",
      payload: {
        shouldFetchSummary: false,
        isFetchingSummary: true
      }
    });

    const fetch_url = `/location_sample_count/?q={"location_code":${location_code}}&limit=500`

    apiFetch(fetch_url)
      .then(r => r.json())
      .then(j => {
        const retrieved = (j.items);
        dispatch({
          type: "LOCATIONSUMMARY_LOCATION_FETCH_UPDATE",
          payload: { summary: retrieved }
        });
        dispatch({
          type: "LOCATIONSUMMARY_FETCH_FINISH",
          payload: {
            isFetchingSummary: false
          }
        })
      });
  },

  selectLocationsummarySummary: state => state.locationsummary.summary,
  selectLocationsummaryIsFetchingSummary: state => state.locationsummary.isFetchingSummary,
  selectLocationsummaryRaw: state => state.locationsummary,
  selectLocationsummaryData: state =>
    state.locationsummary.data.sort((a, b) => {
      const aCode = a.location_code;
      const bCode = b.location_code;
      if (aCode < bCode) return -1;
      if (aCode > bCode) return 1;
      return 0;
    }),
  selectLocationsummaryIsFetching: state => state.locationsummary.isFetching,
  selectLocationsummaryQuery: state => state.locationsummary.query,
  reactLocationsummaryShouldFetch: state => {
    if (state.locationsummary.shouldFetch) {
      return { actionCreator: "doLocationsummaryFetch" };
    }
  }
};
