import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import Dropdown from "./nav-dropdown";
import DropdownItem from "./nav-dropdown-item";
import { sortBy } from "lodash";
export default connect(
  "doAuthLogout",
  "selectAuthUsername",
  "selectAuthRoles",
  "selectProjectsUserAccess",
  "selectAuthIsLoggedIn",
  "selectUserIsSysAdmin",
  function Navbar(props) {
    const {
      doAuthLogout,
      authUsername,
      authRoles,
      projectsUserAccess: projectAll,
      className,
      authIsLoggedIn,
      userIsSysAdmin,
    } = props;
    var orgRoles = authRoles
      ? authRoles.map((r) => {
          return r.split(".")[1];
        })
      : null;

    const [projects, setProjects] = useState([]);
    useEffect(() => {
      let sortedProjects = sortBy(
        projectAll,
        [(proj) => proj.site_name.toLowerCase()],
        "site_name"
      );
      setProjects(sortedProjects);
    }, [projectAll]);
    const reports = [
      { name: "Statistics", url: "/statistics" },
      { name: "Summary Data", url: "/summary" },
      { name: "Station Summary", url: "/stations" },
      { name: "Hit List", url: "/hitlist" },
      { name: "Parameter Value Exceedance", url: "/parameter" },
    ];
    const charting = [
      { name: "Line Chart", url: "/charting" },
      { name: "Heatmap", url: "/charting/heatmap" },
      { name: "Vertical Profile", url: "/charting/profile" },
    ];

    const lookup = [
      { name: "Taxacode", url: "/taxacode" },
      { name: "Parameters", url: "/parameters" },
      { name: "Collection Method", url: "/collection" },
      { name: "Labs", url: "/labs" },
      { name: "Major Basins", url: "/majorbasins" },
      { name: "Minor Basins", url: "/minorbasins" },
      { name: "Counties", url: "/counties" },
      { name: "Location Types", url: "/location_types" },
      { name: "Streams", url: "/streams" },
      { name: "Data Qualifier Flags", url: "/data_flags" },
      { name: "Analysis Methods", url: "/analysis_methods" },
    ];

    const _filterProjects = (e) => {
      let result = projectAll.filter((item) =>
        item.site_name.toUpperCase().includes(e.target.value.toUpperCase())
      );
      let sortedProjects = sortBy(
        result,
        [(proj) => proj.site_name.toLowerCase()],
        "site_name"
      );
      setProjects(sortedProjects);
    };
    return (
      <nav
        className={`navbar navbar-expand-lg bg-white ${className}`}
        style={{
          boxShadow: `0 2px 8px 0 rgba(25, 118, 210, 0.2)`,
          zIndex: 999,
        }}>
        <a
          className="navbar-brand"
          href="/"
          style={{ color: " #1976d2", fontWeight: 500 }}>
          Water Quality
        </a>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className={`navbar-nav ${!authIsLoggedIn && "d-none"}`}>
            <a
              className="nav-item nav-link active"
              href="/"
              style={{ color: "#1976d2" }}>
              Home <span className="sr-only">(current)</span>
            </a>
            <Dropdown iconClass="ms ms-data-cube-o" label={"Projects"}>
              <DropdownItem key={"search"} onClick={(e) => e.preventDefault()}>
                <input
                  onChange={_filterProjects}
                  placeholder="Search..."
                  id="no-collapse"
                  className="form-control m-1"
                  style={{ width: 210 }}
                />
              </DropdownItem>
              {projects.map((project, index) => {
                if (index === 0) {
                  return (
                    <>
                      <DropdownItem href={`/projects`} key={"all"}>
                        All Projects
                      </DropdownItem>
                      <DropdownItem
                        href={`/projects/${project.site_code}`}
                        key={project.site_code}>
                        {project.site_name}
                      </DropdownItem>
                    </>
                  );
                }
                return (
                  <DropdownItem
                    href={`/projects/${project.site_code}`}
                    key={project.site_code}>
                    {project.site_name}
                  </DropdownItem>
                );
              })}
            </Dropdown>
            <Dropdown iconClass="ms ms-data-cube-o" label={"Charting"}>
              {charting.map((item) => {
                return (
                  <DropdownItem href={item.url} key={item.url}>
                    {item.name}
                  </DropdownItem>
                );
              })}
            </Dropdown>
            <a
              className="nav-item nav-link active"
              href="/mapping"
              style={{ color: "#1976d2" }}>
              Mapping<span className="sr-only">(current)</span>
            </a>
            {/* THIS NEEDS TO BE UPDATED TO LOOKUP_TABLES: STORETS TAXA ETC ETC 
            <Dropdown iconClass="ms ms-data-cube-o" label={"Tabular Reports"}>
              {list.map((item) => {
                return (
                  <DropdownItem href={item.url}>{item.name}
                  </DropdownItem>
                );
              })}
            </Dropdown> */}
            <Dropdown iconClass="ms ms-data-cube-o" label={"Lookup"}>
              {sortBy(lookup, "name").map((item) => {
                return (
                  <DropdownItem href={item.url} key={item.url}>
                    {item.name}
                  </DropdownItem>
                );
              })}
            </Dropdown>

            <Dropdown iconClass="ms ms-data-cube-o" label={"Reports"}>
              {reports.map((item) => {
                return (
                  <DropdownItem href={item.url} key={item.url}>
                    {item.name}
                  </DropdownItem>
                );
              })}
            </Dropdown>
            <Dropdown iconClass="ms ms-data-cube-o" label="Data In/Out">
              <DropdownItem
                className="nav-item nav-link active"
                href="/data-export">
                Export Results <span className="sr-only">(current)</span>
              </DropdownItem>
              <DropdownItem
                className="nav-item nav-link active"
                href="/bulk-import">
                Bulk Import <span className="sr-only">(current)</span>
              </DropdownItem>
            </Dropdown>
          </div>
        </div>
        <div
          className={`nav navbar-nav navbar-right ${
            !authIsLoggedIn && "d-none"
          }`}>
          <Dropdown iconClass="ms ms-data-cube-o" label={authUsername}>
            {userIsSysAdmin && (
              <DropdownItem href={"/admin"}>Admin</DropdownItem>
            )}
            <span className="dropdown-item" onClick={doAuthLogout}>
              Logout
            </span>
          </Dropdown>
        </div>
      </nav>
    );
  }
);
