import React from "react";
import { connect } from "redux-bundler-react";
import MaterialTable from "material-table";
import Link from "../misc/link";
import { find, sortBy } from "lodash";
import ProjectModal from "./ProjectModal";

function TableProject(props) {
  const {
    projectsList,
    projectsIsFetching,
    doProjectsShouldFetch,
    userIsSysAdmin,
    doModalOpen,
    userRoleData,
  } = props;
  let sorted = sortBy(projectsList, "site_name");
  sorted = userIsSysAdmin
    ? sorted
    : sorted.filter(
        (project) =>
          !!find(userRoleData, (obj) => obj.office_id === project.office_id)
      );
  return (
    <MaterialTable
      title=""
      isLoading={projectsIsFetching}
      columns={[
        {
          field: "link",
          title: "Actions",
          render: (rowData) => {
            let canEdit = !!find(userRoleData, (obj) =>
              [
                `${rowData.office_id}.MEMBER`,
                `${rowData.office_id}.ADMIN`,
              ].includes(obj.role)
            );
            if (canEdit || userIsSysAdmin) {
              return (
                <button
                  className="hover"
                  onClick={(e) => {
                    doModalOpen(ProjectModal, {
                      data: rowData,
                      edit: true,
                    });
                  }}
                >
                  <i className="mdi mdi-pencil-outline mdi-24px"></i>
                </button>
              );
            } else
              return (
                <i
                  className="mdi mdi-pencil-off-outline mdi-24px"
                  style={{ color: "lightslategray" }}
                  title="You do not have ADMIN / MEMBER access to this org. "
                ></i>
              );
          },
        },
        {
          field: "link",
          title: "View Project Page",
          render: (rowData) => {
            return (
              <Link to={`projects/${rowData.site_code}`}>
                Go to {rowData.site_name}
              </Link>
            );
          },
        },
        { title: "Project ID", field: "site_id" },
        { title: "Project Name", field: "site_name" },
        { title: "Cover Photo", field: "url_cover_photo" },
      ]}
      data={sorted}
      options={{
        loadingType: "overlay",
        exportButton: true,
        exportAllData: true,
        sorting: true,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [50, 500, 1000, 2000],
      }}
      actions={[
        {
          icon: "refresh",
          tooltip: "Refresh table",
          isFreeAction: true,
          onClick: (event) => doProjectsShouldFetch(),
        },
      ]}
    />
  );
}

export default connect(
  "selectProjectsList",
  "selectProjectsIsFetching",
  "doProjectsShouldFetch",
  "selectApiRoot",
  "selectUserIsSysAdmin",
  "doModalOpen",
  "selectUserRoleData",
  TableProject
);
