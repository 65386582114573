import React from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";
import LRHForm from "../location/modals/LRHForm";

function TableReports(props) {
  const {
    lakeQualityItems,
    lakeQualityIsFetching,
    doLakeQualityFetch,
    doModalOpen,
    userIsAnyMemberOrAdmin,
  } = props;

  return (
    <div className="custom-table mt-3">
      <div className="card-body">
        <div className="d-flex w-100 align-items-center justify-content-start mb-2">
          <h4 className="m-0">LRH Lake Quality</h4>
        </div>
        <MaterialTable
          title=""
          isLoading={lakeQualityIsFetching}
          columns={[
            {
              field: "link",
              title: "View Details",
              render: (rowData) => {
                if (userIsAnyMemberOrAdmin) {
                  return (
                    <button
                      className="hover"
                      onClick={(e) => {
                        doModalOpen(LRHForm, {
                          data: rowData,
                          readOnly: true,
                        });
                      }}>
                      <i className="mdi mdi-eye mdi-24px"></i>
                    </button>
                  );
                }
              },
            },
            { title: "Date", field: "sample_time" },
            { title: "Lake Elevation", field: "lake_elev" },
          ]}
          data={lakeQualityItems}
          options={{
            loadingType: "overlay",
            exportButton: true,
            exportAllData: true,
            search: false,
            sorting: true,
            pageSize: 50,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [50, 500, 1000, 2000],
          }}
          actions={[
            {
              icon: "refresh",
              tooltip: "Refresh table",
              isFreeAction: true,
              onClick: doLakeQualityFetch,
            },
          ]}
        />
      </div>
    </div>
  );
}

export default connect(
  "selectLakeQualityItems",
  "selectLakeQualityIsFetching",
  "selectUserIsAnyMemberOrAdmin",
  "doLakeQualityFetch",
  "doModalOpen",
  "doModalOpenUniqueCode",
  TableReports
);
