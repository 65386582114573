import React from "react";
import MaterialTable from "material-table";
import Link from "../misc/link";
import { connect } from "redux-bundler-react";

const TableTrends = ({
  storetData,
  trends,
  trendsIsFetching,
  routeParams,
  projectAll,
}) => {
  let activeProject = projectAll.find(
    (obj) => routeParams.project == obj.site_code
  );
  let data = trends.map((trend) => {
    trend.parameter = storetData.find(
      (o) => o.storet_num === trend.storet_num
    ).short_name;
    return { ...trend };
  });
  return (
    <div className="custom-table mt-4">
      <div className="card-body">
        <div style={{ textAlign: "center" }}>
          <MaterialTable
            title={`Trends at ${activeProject ? activeProject.site_name : ""}`}
            isLoading={trendsIsFetching}
            columns={[
              { title: "Sample Year", field: "sample_year" },
              { title: "Parameter", field: "parameter" },
              { title: "Units", field: "units" },
              { title: "Lowest Minimum", field: "lowest_minimum" },
              { title: "Highest Minimum", field: "highest_minimum" },
              { title: "Lowest 25th", field: "lowest_25th" },
              { title: "Highest 25th", field: "highest_25th" },
              { title: "Lowest Median", field: "lowest_median" },
              { title: "Highest Median", field: "highest_median" },
              { title: "Lowest 75th", field: "lowest_75th" },
              { title: "Highest 75th", field: "highest_75th" },
              { title: "Lowest Maximum", field: "lowest_maximum" },
              { title: "Highest Maximum", field: "highest_maximum" },
              { title: "Lowest Standard Dev", field: "lowest_stddev" },
              { title: "Highest Standard Dev", field: "highest_stddev" },
              { title: "Number of Records", field: "num_records" },
            ]}
            data={data}
            options={{
              loadingType: "overlay",
              exportButton: true,
              exportAllData: true,
              //filtering: true,
              sorting: true,
              search: false,
              pageSize: 50,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [50, 500, 1000, 2000],
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default connect(
  "selectTrends",
  "selectTrendsIsFetching",
  "selectStoretData",
  "selectProjectAll",
  "selectRouteParams",
  TableTrends
);
