import React, { useReducer, useEffect } from "react";
import ReactSelect from "react-select";
import { connect } from "redux-bundler-react";
import {
  ModalFooter,
  ModalContent,
  ModalHeader,
} from "../../../app-components/modal";
import { classnames } from "../../../utils/helpers";
import Table from "../../tables/Table";
import "./lrnform.css";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "ADD_SAMPLE":
      return {
        ...state,
        samples: [...state.samples, { ...action.payload }],
      };
    case "DELETE_SAMPLE":
      let updated = state.samples.filter(
        (smpl) => smpl.tableData.id !== action.payload.tableData.id
      );
      return {
        ...state,
        samples: updated,
      };
    case "UPDATE_SAMPLE":
      let edited = state.samples.map((smpl) =>
        smpl.tableData.id === action.payload.tableData.id
          ? { ...action.payload }
          : smpl
      );
      return {
        ...state,
        samples: edited,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const dateToDatetime = (now) => {
  var now = new Date();
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
  return now.toISOString().slice(0, 16);
};

const LRNForm = ({
  data,
  edit,
  doDelete,
  projectLocations,
  collectionsSelects,
  doCollectionsFetch,
  project,
  location,
  apiRoot,
  doActionPostData,
  doLrnFormParamsFetch,
  lrnFormParams,
  lrnFormIsFetching,
}) => {
  const initialState = {
    project: project.site_name,
    location: {
      value: location.location_code,
      label: location.loc_id,
    },
    sample_date: dateToDatetime(new Date()),
    samples: [],
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    if (edit) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: data,
      });
    }
  }, [edit, data]);

  useEffect(() => doLrnFormParamsFetch(), [doLrnFormParamsFetch]);

  useEffect(() => doCollectionsFetch(), [doCollectionsFetch]);

  let sampleColumns =
    lrnFormParams && lrnFormParams.length > 0
      ? lrnFormParams
          .map((field) => {
            if (field.form_item.includes("18")) {
              return {
                field: field.storet_num,
                title: field.param_label,
              };
            } else return;
          })
          .filter((item) => item !== undefined)
      : [];

  sampleColumns.unshift({
    field: "sample_depth",
    title: "Sample Depth",
  });

  const doSave = () => {
    let obj = {};
    obj.location_code = state.location.value;
    obj.date_added = new Date();
    obj.clob_sample = state.samples ? JSON.stringify(state.samples) : "";
    obj.remarks = state.remarks;
    obj.site_code = project.site_code;
    obj.participants = state.participants
      ? JSON.stringify(state.participants.split(", "))
      : "";
    obj.sample_date = new Date(state.sample_date);
    let surface_sample_clob = [];
    Object.keys(state).forEach((key) => {
      if (
        [
          "sample_date",
          "date_added",
          "project",
          "participants",
          "remarks",
        ].indexOf(key) === -1
      ) {
        surface_sample_clob.push({ [key]: state[key] });
      }
    });
    obj.surface_sample_clob = JSON.stringify(surface_sample_clob);
    doActionPostData(
      `${apiRoot}/bulk/celrn_form_561`,
      obj,
      () => {},
      (e) => console.error(e)
    );
    // if (edit) {
    //   doUpdate(state.result_code, state);
    // } else {
    //   doPost(state);
    // }
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };
  const handleSelect = (field, obj) => {
    dispatch({
      type: "UPDATE_INPUT",
      field,
      payload: obj.value,
    });
  };

  const inputWrapperClass = classnames({
    "d-flex": true,
    "flex-column": true,
    "w-100": true,
    "mb-2": true,
  });

  return (
    <ModalContent size="lg">
      <ModalHeader
        title="Water Quality Field Data"
        subtitle="The proponent agency is CELRN-EC-H"
      />
      <section className="modal-body">
        <div className="container-fluid">
          <div className="two-grid">
            <div className={inputWrapperClass}>
              <label className="d-flex">Project</label>
              <input
                className="custom-input disabled"
                value={state.project}
                onChange={handleInputChange}
                name="project"
                disabled
              />
            </div>
            {/*Eventually make this a select that has all the members of WQ, with ability to write in your own react-select/creatable https://react-select.com/creatable */}
            <div className={inputWrapperClass}>
              <label className="d-flex">
                Participants{" "}
                <p className="text-muted m-0 ml-1">{`(separate by commas)`}</p>
              </label>
              <input
                className="custom-input"
                value={state.participants}
                onChange={handleInputChange}
                name="participants"
              />
            </div>
          </div>
          <div className="two-grid">
            <div className={inputWrapperClass}>
              <label className="d-flex">Location</label>
              <ReactSelect
                value={state.location}
                name="location"
                options={projectLocations.map((loc) => ({
                  value: loc.location_code,
                  label: loc.loc_id,
                }))}
                onChange={(obj) => handleSelect("location", obj)}
                menuPosition="fixed"
              />
            </div>
            <div className={inputWrapperClass}>
              <label className="d-flex">Date</label>
              <input
                className="custom-input"
                value={state.sample_date}
                onChange={handleInputChange}
                name="sample_date"
                type="datetime-local"
              />
            </div>
          </div>
          <div className="one-two-grid">
            <div className={inputWrapperClass}>
              <label className="d-flex">Weather</label>
              <input
                className="custom-input"
                value={state.weather}
                onChange={handleInputChange}
                name="weather"
              />
            </div>
            <div className={inputWrapperClass}>
              <label className="d-flex">Wind Velocity (mph)</label>
              <input
                className="custom-input"
                value={state.wind}
                onChange={handleInputChange}
                name="00035"
              />
            </div>
            <div className={inputWrapperClass}>
              <label className="d-flex">Air Temperature</label>
              <input
                className="custom-input"
                value={state.airTemp}
                onChange={handleInputChange}
                name="00021"
              />
            </div>
          </div>
          <div className="two-grid">
            <div className={inputWrapperClass}>
              <label className="d-flex">Color / Appearance</label>
              <input
                value={state.color}
                name="00079"
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
            <div className={inputWrapperClass}>
              <label htmlFor="collect">Collection Method</label>
              <ReactSelect
                value={
                  collectionsSelects && state["collect_mthd"]
                    ? collectionsSelects.filter(
                        (obj) => obj.value === state["collect_mthd"]
                      )[0]
                    : null
                }
                name={"collect_mthd"}
                inputId="collect"
                options={collectionsSelects.map((item) => ({
                  value: item.value,
                  label: item.label,
                }))}
                onChange={(obj) => handleSelect("collect_mthd", obj)}
                menuPosition="fixed"
              />
            </div>
          </div>
          <div className={inputWrapperClass}>
            <label className="d-flex">Remarks</label>
            <textarea
              value={state.remarks}
              name="remarks"
              onChange={handleInputChange}
              className="custom-input pt-1 pb-1"
            />
          </div>
          <div className="six-grid">
            <div className={inputWrapperClass}>
              <label className="d-flex">Maximum Depth (ft)</label>
              <input
                value={state.maxDepth}
                name="04195"
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
            <div className={inputWrapperClass}>
              <label className="d-flex">Distance between banks (ft)</label>
              <input
                value={state.betweenBanks}
                name="00004"
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
            <div className={inputWrapperClass}>
              <label className="d-flex">Distance from left bank (ft)</label>
              <input
                value={state.fromLeft}
                name="00009"
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
            <div className={inputWrapperClass}>
              <label className="d-flex">Secchi Disk (m)</label>
              <input
                value={state.secchi}
                name="00078"
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
            <div className={inputWrapperClass}>
              <label className="d-flex">Water Surface Elevation (ft)</label>
              <input
                value={state.waterSurface}
                name="00062"
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
            <div className={inputWrapperClass}>
              <label className="d-flex">Inst. Discharge (CFS)</label>
              <input
                value={state.instDis}
                name="00061"
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
          </div>
          <div className={inputWrapperClass}>
            <Table
              title="Samples"
              isLoading={lrnFormIsFetching}
              columns={sampleColumns}
              data={state.samples}
              options={{
                loadingType: "overlay",
                exportButton: true,
                exportAllData: true,
                sorting: true,
                pageSize: 5,
              }}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    dispatch({
                      type: "ADD_SAMPLE",
                      payload: newData,
                    });
                    resolve();
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    dispatch({
                      type: "DELETE_SAMPLE",
                      payload: oldData,
                    });
                    resolve();
                  }),
                onRowUpdate: (oldData, newData) =>
                  new Promise((resolve, reject) => {
                    dispatch({
                      type: "UPDATE_SAMPLE",
                      payload: {
                        ...oldData,
                        tableData: { ...newData.tableData },
                      },
                    });
                    resolve();
                  }),
              }}
            />
          </div>
        </div>
      </section>
      <ModalFooter
        saveText={edit ? "Update Sample" : "Save and Submit"}
        showCancelButton
        onSave={doSave}
        onDelete={() => doDelete()}
        deleteText="Delete this item"
      />
    </ModalContent>
  );
};

export default connect(
  "selectProjectLocations",
  "selectCollectionsSelects",
  "doCollectionsFetch",
  "selectLrnFormIsFetching",
  "doLrnFormParamsFetch",
  "selectLrnFormParams",
  "selectProject",
  "selectLocation",
  "doActionPostData",
  "selectApiRoot",
  LRNForm
);
