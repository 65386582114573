import React, { useEffect, useReducer } from "react";
import { connect } from "redux-bundler-react";
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "../../../app-components/modal";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const AnalysisMethodsModal = (props) => {
  const {
    doModalClose,
    data,
    edit,
    doPostAnalysisMethods,
    doPutAnalysisMethods,
    doDeleteAnalysisMethods,
    analysisMethodsIsSaving,
    analysisMethodsIsDeleting,
    analysisMethodsError: error,
    method_code,
  } = props;

  let initialState = {};

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (edit) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: data,
      });
    } else {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: { method_code },
      });
    }
  }, [edit, data]);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSelect = (field, payload) => {
    dispatch({
      type: "UPDATE_INPUT",
      field,
      payload: payload.value,
    });
  };

  return (
    <ModalContent size="md" error={error}>
      <ModalHeader
        title={!edit ? "Add Analysis Method" : "Edit Analysis Method Info"}
      />
      <section className="modal-body">
        <div className="container-fluid">
          <div className="d-flex flex-column w-100">
            <label htmlFor="analysis_method_id">Analysis Method ID</label>
            <input
              type="text"
              className="form-control"
              name="analysis_method_id"
              onChange={handleChange}
              value={state.analysis_method_id}
            />
          </div>
          <div className="d-flex flex-column w-100 mt-2">
            <label htmlFor="revision_number">Revision Number</label>
            <input
              type="text"
              className="form-control"
              name="revision_number"
              onChange={handleChange}
              value={state.revision_number}
            />
          </div>
          <div className="d-flex flex-column w-100 mt-2">
            <label htmlFor="method_source">Method Source</label>
            <input
              type="text"
              className="form-control"
              name="method_source"
              onChange={handleChange}
              value={state.method_source}
            />
          </div>
          <div className="d-flex flex-column w-100 mt-2">
            <label htmlFor="description">Description</label>
            <textarea
              type="text"
              className="form-control"
              name="description"
              onChange={handleChange}
              value={state.description}
            />
          </div>
        </div>
      </section>
      <ModalFooter
        onSave={() =>
          edit ? doPutAnalysisMethods(state) : doPostAnalysisMethods(state)
        }
        customClosingLogic
        onDelete={
          edit ? () => doDeleteAnalysisMethods(state.method_code) : null
        }
        showCancelButton
        onCancel={doModalClose}
        saveIsDisabled={analysisMethodsIsSaving}
        deleteIsDisabled={analysisMethodsIsDeleting}
        saveText={
          analysisMethodsIsSaving ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Save"
          )
        }
        deleteText={
          analysisMethodsIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "doAnalysisMethodsTableFetch",
  "selectAnalysisMethodsItems",
  "selectOfficeData",
  "doPostAnalysisMethods",
  "doPutAnalysisMethods",
  "doDeleteAnalysisMethods",
  "selectAnalysisMethodsIsSaving",
  "selectAnalysisMethodsIsDeleting",
  "selectAnalysisMethodsError",
  "doModalClose",
  AnalysisMethodsModal
);
