export default {
  name: "unit",
  getReducer: () => {
    const initialData = {
      data: [],
      shouldFetch: false
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "UNIT_FETCH_START":
        case "UNIT_FETCH_FINISH":
        case "UNIT_SHOULD_FETCH":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },
  doUnitShouldFetch: () => ({ dispatch }) => {
    dispatch({ type: "UNIT_SHOULD_FETCH", payload: { shouldFetch: true } })
  },

  doUnitFetch: () => ({ dispatch, store, apiFetch }) => {
    dispatch({
      type: "UNIT_FETCH_START",
      payload: {
        shouldFetch: false
      }
    });

    apiFetch("/wq_length_uom_t/")
      .then(r => r.json())
      .then(j => {
        dispatch({ type: "UNIT_FETCH_FINISH", payload: { data: j.items } });
      })
      .catch(e => {
        console.log(e);
      });
  },

  selectUnitAll: state => {
    return state.unit.data;
  },

  reactUnitShouldFetch: state => {
    if (state.unit.shouldFetch)
      return {
        actionCreator: "doUnitFetch"
      };
  }
};
