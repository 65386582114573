import { createSelector } from "redux-bundler";
import { sortBy } from "lodash";
import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "storet",
  // reducers
  getReducer: () => {
    const initialData = {
      shouldFetch: false,
      data: [],
      selected: [],
      storetsActive: [],
      storetsArray: [],
      storetActive: null,
      isFetching: false,
      storetActiveName: "",
    };
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "AUTH_VERIFY_TOKEN":
        case "URL_UPDATED":
          return Object.assign({}, state, {
            shouldFetch: true,
          });
        case "STORET_FETCH_START":
        case "STORET_FETCH_TABLE":
        case "STORET_FETCH_TABLE_FINISH":
        case "STORET_FETCH_FINISH":
        case "STORET_SELECTED_APPEND":
        case "STORET_SELECTED_REMOVE":
        case "STORET_SELECTED_REMOVE_ALL":
        case "STORET_SHOULD_FETCH":
        case "STORET_ACTIVE_UPDATE":
        case "STORET_SAVE_STARTED":
        case "STORET_SAVE_FINISHED":
        case "STORET_SAVE_ERROR":
        case "STORET_DELETE_STARTED":
        case "STORET_DELETE_FINISHED":
        case "STORET_DELETE_ERROR":
          return Object.assign({}, state, payload);
        case "MODAL_CLOSED":
          return { ...state, error: null };
        default:
          return state;
      }
    };
  },
  // action creators
  doStoretShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({ type: "STORET_SHOULD_FETCH", payload: { shouldFetch: true } });
    },
  doStoretFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "STORET_FETCH_START",
        payload: { shouldFetch: false, isFetching: true },
      });

      apiFetch("/storetsummary/?limit=100000")
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "STORET_FETCH_FINISH",
            payload: { data: j.items, isFetching: false },
          })
        );
    },
  doStoretFetchTable:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "STORET_FETCH_TABLE",
        payload: { shouldFetch: false, isFetching: true },
      });

      let url = "/wq_storet_codes/?limit=10000";

      if (state) {
        url = ordsifyUrlBuilder(
          "/wq_storet_codes/",
          [
            {
              keyword: "full_name",
              items: state["full_name"] ? state["full_name"] : "", // if no location_codes passed, fetch all
            },
          ],
          100000
        );
      }

      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "STORET_FETCH_TABLE_FINISH",
            payload: { data: j.items, isFetching: false },
          })
        );
    },
  doStoretSelectedIdAppend:
    (storet_codes) =>
    ({ dispatch, store }) => {
      const new_selected = [
        ...new Set(store.selectStoretSelectedId().concat(storet_codes)),
      ];
      dispatch({
        type: "STORET_SELECTED_APPEND",
        payload: { selected: new_selected },
      });
      // Also trigger re-fetch of resultsummary endpoint
      dispatch({
        type: "RESULTSUMMARY_FETCH_START",
        payload: { shouldFetch: true },
      });
    },
  doStoretSelectedIdRemove:
    (storet_codes) =>
    ({ dispatch, store }) => {
      // return a new array with all values in "selected" that are not in the passed
      // array storet_codes
      const new_selected = store
        .selectStoretSelectedId()
        .filter((i) => !storet_codes.includes(i));
      dispatch({
        type: "STORET_SELECTED_REMOVE",
        payload: { selected: new_selected },
      });
    },
  doStoretSelectedIdRemoveAll:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "STORET_SELECTED_REMOVE_ALL",
        payload: { selected: [] },
      });
    },
  doStoretsActive:
    (storetCodes) =>
    ({ dispatch, store }) => {
      if (storetCodes && storetCodes.length) {
        const storetArray = [...new Set(storetCodes.map((item) => item.value))];
        dispatch({
          type: "STORET_ACTIVE_UPDATE",
          payload: {
            storetsActive: storetCodes,
            storetsArray: storetArray,
          },
        });
      } else {
        dispatch({
          type: "STORET_ACTIVE_UPDATE",
          payload: {
            storetsActive: [],
            storetsArray: [],
          },
        });
      }
    },
  doStoretActive:
    (code) =>
    ({ dispatch, store }) => {
      if (code && code.value) {
        const storetCode = code.value;
        const name = store
          .selectStoretData()
          .filter((x) => x.storet_num === code.value);
        dispatch({
          type: "STORET_ACTIVE_UPDATE",
          payload: {
            storetActive: storetCode,
            storetActiveName: name[0].short_name,
          },
        });
      } else {
        const name = store
          .selectStoretData()
          .filter((x) => x.storet_num === code);
        dispatch({
          type: "STORET_ACTIVE_UPDATE",
          payload: {
            storetActive: code,
            storetActiveName: name[0].short_name,
          },
        });
      }
    },

  doStoretPost:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPostData, doModalClose } = store;
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "STORET_SAVE_STARTED",
        payload: { isSaving: true },
      });
      let url = `${apiRoot}/wq_storet_codes/`;
      doActionPostData(
        url,
        state,
        () => {
          dispatch({
            type: "STORET_SAVE_FINISHED",
            payload: { isSaving: false, shouldFetch: true, error: null },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "STORET_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },
  doStoretPut:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPutData, doModalClose } = store;
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "STORET_SAVE_STARTED",
        payload: { isSaving: true },
      });
      let url = `${apiRoot}/wq_storet_codes/${state.storet_num}`;
      doActionPutData(
        url,
        state,
        () => {
          dispatch({
            type: "STORET_SAVE_FINISHED",
            payload: { isSaving: false, shouldFetch: true, error: null },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "STORET_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doStoretDelete:
    (state) =>
    ({ dispatch, store }) => {
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "STORET_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      store.doActionDeleteData(
        `${apiRoot}/wq_storet_codes/${state.storet_num}`,
        {},
        () => {
          dispatch({
            type: "STORET_DELETE_FINISHED",
            payload: { isDeleting: false, shouldFetch: true, error: null },
          });
          store.doModalClose();
        },
        (e) =>
          dispatch({
            type: "STORET_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },
  // selectors

  selectStoretIsDeleting: (state) => state.storet.isDeleting,
  selectStoretIsSaving: (state) => state.storet.isSaving,
  selectStoretError: (state) => state.storet.error,

  selectStoretIsFetching: (store) => store.storet.isFetching,
  selectStoretsActive: (store) => store.storet.storetsActive,
  selectStoretActive: (store) => store.storet.storetActive,
  selectStoretActiveName: (store) => store.storet.storetActiveName,
  selectStoretActiveArray: (store) => store.storet.storetsArray,
  selectStoretRaw: (state) => state.storet,
  selectStoretData: (state) =>
    state.storet.data.sort((a, b) => {
      const aName = a.storet_num.toUpperCase();
      const bName = b.storet_num.toUpperCase();
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    }),
  selectStoretSelectedId: (state) => state.storet.selected,
  selectStoretSelected: createSelector(
    "selectStoretData",
    "selectStoretSelectedId",
    (storetData, storetSelectedId) => {
      if (!storetSelectedId.length) {
        return [];
      } else {
        return storetData.filter((d) =>
          storetSelectedId.includes(d.storet_num)
        );
      }
    }
  ),

  selectStoretOptions: createSelector("selectStoretData", (storetData) => {
    let sorted = sortBy(storetData, ["short_name"]);
    return sorted.map((item) => ({
      value: item["storet_num"],
      label: item["full_name"],
    }));
  }),

  selectStoretOptionsByCode: createSelector(
    "selectStoretOptions",
    "selectResultsByCode",
    (storetOptions, results) => {
      return storetOptions.filter((obj) => {
        return results.map((pt) => pt.storet_num).includes(obj.value);
      });
    }
  ),
  // reactors
  reactStoretShouldFetch: (state) => {
    if (state.storet.shouldFetch) {
      return {
        actionCreator: "doStoretFetch",
      };
    }
  },
};
