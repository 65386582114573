import React from 'react';
import ReactCalendarHeatmap from "react-calendar-heatmap";
import ReactTooltip from "react-tooltip";
import Rainbow from 'rainbowvis.js';

import 'react-calendar-heatmap/dist/styles.css';
import { connect } from 'redux-bundler-react';

const CalendarHeatmap = props => {
  const { data, units, parameter, resultIsFetching } = props;

  let rainbow = new Rainbow();
  let dates = [];
  if(data.length) {
    rainbow.setSpectrum('#c9c9ea', 'blue');
    let values = data.map(item => item.count);
    dates = data.map(item => item.date);
    rainbow.setNumberRange(Math.min(...values), Math.max(...values)); 
  }
  if(resultIsFetching) return <i className="mdi mdi-loading mdi-spin mdi-48px"></i>;
  else if(data.length) {
    return (
      <div className='d-flex flex-column align-items-center justify-content-center p-3'>
        <h3>Results between {new Date(Math.min(...dates)).toDateString()} and {new Date(Math.max(...dates)).toDateString()}</h3>
        <h6 className='text-muted'>{parameter && parameter[0] && parameter[0].label}</h6>
        <ReactCalendarHeatmap
          startDate={Math.min(...dates)}
          endDate={Math.max(...dates)}
          values={data}
          classForValue={value => {
            if (!value) {
              return "color-empty";
            }
            return `color-#${rainbow.colourAt(value.count)}`;
          }}
          tooltipDataAttrs={(value) => {
            if(value && value.date) {
            return {
              "data-tip": `${value.date.toISOString().slice(0, 10)} has value: ${
                value.count
              } ${units}`
            };
          } else return {
            "data-tip": 'No data available'
          };
          }}
          showWeekdayLabels={true}
          onClick={value => alert(`Clicked on value with count: ${value.count}`)}
          transformDayElement={(element, value, index) =>
            React.cloneElement(element, { rx: 100, ry: 100, fill: value ? `#${rainbow.colourAt(value.count)}` : "", color: value ? `#${rainbow.colourAt(value.count)}` : "" })
          }
        />
        <ReactTooltip />
      </div>
    );
  } else return (
  <div className='p-4'>
    <h3>No Data, use the filter to generate a Calendar Heatmap!</h3>
    <p>Please limit date range filter to 1 year intervals.</p>
  </div>
  );
}

export default connect("selectResultIsFetching", CalendarHeatmap);