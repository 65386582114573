import React from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";
import CountiesModal from "./CountiesModal";

function TableCounties(props) {
  const {
    countiesItems,
    countiesIsFetching,
    doCountiesShouldFetch,
    doModalOpen,
    doModalOpenUniqueCode,
    userIsAnyMemberOrAdmin,
    stateAll,
  } = props;

  return (
    <div className="custom-table mt-3">
      <div className="card-body">
        <div className="d-flex w-100 align-items-center justify-content-between mb-2">
          <h4 className="m-0">Counties</h4>
          {userIsAnyMemberOrAdmin && (
            <button
              className="btn btn-outline-success"
              onClick={() => {
                doModalOpenUniqueCode(CountiesModal, {}, "county_code");
              }}
            >
              Add County
            </button>
          )}
        </div>
        <MaterialTable
          title=""
          isLoading={countiesIsFetching}
          columns={[
            {
              field: "link",
              title: "Actions",
              render: (rowData) => {
                if (userIsAnyMemberOrAdmin) {
                  return (
                    <button
                      className="hover"
                      onClick={(e) => {
                        doModalOpen(CountiesModal, {
                          data: rowData,
                          edit: true,
                        });
                      }}
                    >
                      <i className="mdi mdi-pencil-outline mdi-24px"></i>
                    </button>
                  );
                }
              },
            },
            { title: "County Code", field: "cnty_code" },
            { title: "County Name", field: "cnty_name" },
            {
              title: "State",
              field: "storet_state",
              render: (rowData) =>
                stateAll.filter(
                  (s) => s.storet_state == rowData.storet_state
                )[0] &&
                stateAll.filter(
                  (s) => s.storet_state == rowData.storet_state
                )[0].state_name,
            },
          ]}
          data={countiesItems}
          options={{
            loadingType: "overlay",
            exportButton: true,
            exportAllData: true,
            search: false,
            sorting: true,
            pageSize: 50,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [50, 500, 1000, 2000],
          }}
          actions={[
            {
              icon: "refresh",
              tooltip: "Refresh table",
              isFreeAction: true,
              onClick: doCountiesShouldFetch,
            },
          ]}
        />
      </div>
    </div>
  );
}

export default connect(
  "selectCountiesItems",
  "selectCountiesIsFetching",
  "selectUserIsAnyMemberOrAdmin",
  "doCountiesShouldFetch",
  "doModalOpen",
  "doModalOpenUniqueCode",
  "selectStateAll",
  TableCounties
);
