import React, { useReducer, useEffect } from "react";
import { connect } from "redux-bundler-react";
import Select from "react-select";
import {
  ModalFooter,
  ModalContent,
  ModalHeader,
} from "../../app-components/modal";
import BioResultModal from "../bioresults/BioResultModal";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const BioSampleModal = ({
  data,
  edit,
  doSave,
  doDelete,
  bioSampleTypeOptions,
  collectionsSelects,
  biosample_code,
  doNestedModalOpenUniqueCode,
  doModalClose,
  bioSampleIsSaving,
  bioSampleIsDeleting,
  bioSampleError: error,
}) => {
  const [state, dispatch] = useReducer(reducer, {});
  useEffect(() => {
    if (edit) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: { ...data },
      });
    } else {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: { biosample_code },
      });
    }
  }, [data]);

  useEffect(() => {
    //this generates the sample num when creating a new biosample
    if (state.sample_depth && state.sample_time && !edit) {
      let sampleDepthStr = parseFloat(state.sample_depth).toFixed(1).toString();
      if (sampleDepthStr.length === 3) {
        sampleDepthStr = `00${sampleDepthStr}`;
      } else if (sampleDepthStr.length === 4) {
        sampleDepthStr = `0${sampleDepthStr}`;
      } else sampleDepthStr = sampleDepthStr;
      let sampleNum = `${state.sample_time
        .replaceAll("-", "")
        .replaceAll("T", "")
        .replace(":", "")}${sampleDepthStr}`;
      dispatch({
        type: "UPDATE_INPUT",
        field: "sample_num",
        payload: sampleNum,
      });
    }
  }, [state.sample_depth, state.sample_time]);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSelect = (field, payload) => {
    dispatch({
      type: "UPDATE_INPUT",
      field,
      payload: payload.value,
    });
  };

  return (
    <ModalContent size="lg" error={error}>
      <ModalHeader title={edit ? "Edit Biosample" : "Add Biosample"} />
      <section className="modal-body">
        <div className="container-fluid">
          <div
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 12 }}
          >
            <div className="d-flex flex-column  w-100">
              <label htmlFor="sample_num">Sample Number</label>
              <input
                className={`custom-input disabled`}
                id="sample_num"
                onChange={handleChange}
                name="sample_num"
                value={state["sample_num"]}
                disabled
              />
              <p className="m-0">
                <small className="text-muted">
                  Auto Generated from Sample Date and Depth
                </small>
              </p>
            </div>
            <div className="d-flex flex-column  w-100">
              <label htmlFor="sample_type">Sample Type</label>
              <Select
                id="sample_type"
                onChange={(obj) => handleSelect("sample_type", obj)}
                name="sample_type"
                value={{
                  label: state["sample_type"],
                  value: state["sample_type"],
                }}
                menuPosition="fixed"
                options={bioSampleTypeOptions}
                className="w-100"
              />
            </div>
            <div className="d-flex flex-column  w-100">
              <label htmlFor="sample_time" className="d-flex">
                Sample Date
              </label>
              <input
                className="custom-input"
                value={state.sample_time && state.sample_time.split("Z")[0]}
                onChange={handleChange}
                name="sample_time"
                type="datetime-local"
              />
            </div>
            <div className="d-flex flex-column  w-100">
              <label htmlFor="sample_depth">Sample Depth</label>
              <input
                id="sample_depth"
                onChange={handleChange}
                name="sample_depth"
                value={state.sample_depth}
                className="custom-input w-100"
              />
            </div>
            <div className="d-flex flex-column  w-100">
              <label htmlFor="collect_mthd1">Collection Method 1</label>
              <Select
                id="collect_mthd1"
                onChange={(obj) => handleSelect("collect_mthd1", obj)}
                name="collect_mthd1"
                value={
                  collectionsSelects.filter(
                    (obj) => obj.value === state["collect_mthd1"]
                  )[0]
                }
                menuPosition="fixed"
                options={collectionsSelects}
                className="w-100"
              />
            </div>
            <div className="d-flex flex-column  w-100">
              <label htmlFor="collect_mthd2">Collection Method 2</label>
              <Select
                id="collect_mthd2"
                onChange={(obj) => handleSelect("collect_mthd2", obj)}
                name="collect_mthd2"
                value={
                  collectionsSelects.filter(
                    (obj) => obj.value === state["collect_mthd2"]
                  )[0]
                }
                menuPosition="fixed"
                options={collectionsSelects}
                className="w-100"
              />
            </div>
            <div className="d-flex flex-column  w-100">
              <label htmlFor="collect_mthd3">Collection Method 3</label>
              <Select
                id="collect_mth31"
                onChange={(obj) => handleSelect("collect_mthd3", obj)}
                name="collect_mthd3"
                value={
                  collectionsSelects.filter(
                    (obj) => obj.value === state["collect_mthd3"]
                  )[0]
                }
                menuPosition="fixed"
                options={collectionsSelects}
                className="w-100"
              />
            </div>
            <div className="d-flex flex-column  w-100">
              <label htmlFor="collect_mthd4">Collection Method 4</label>
              <Select
                id="collect_mthd4"
                onChange={(obj) => handleSelect("collect_mthd4", obj)}
                name="collect_mthd4"
                value={
                  collectionsSelects.filter(
                    (obj) => obj.value === state["collect_mthd4"]
                  )[0]
                }
                menuPosition="fixed"
                options={collectionsSelects}
                className="w-100"
              />
            </div>
            <div className="d-flex flex-column w-100">
              <label htmlFor="collect_mthd5">Collection Method 5</label>
              <Select
                id="collect_mthd5"
                onChange={(obj) => handleSelect("collect_mthd5", obj)}
                name="collect_mthd5"
                value={
                  collectionsSelects.filter(
                    (obj) => obj.value === state["collect_mthd5"]
                  )[0]
                }
                menuPosition="fixed"
                options={collectionsSelects}
                className="w-100"
              />
            </div>
            <div className="d-flex flex-column w-100 ">
              <label htmlFor="collect_by">Collected By</label>
              <input
                id="collect_by"
                onChange={handleChange}
                name="collect_by"
                value={state.collect_by}
                className="custom-input w-100"
              />
            </div>
            <div className="d-flex flex-column w-100 ">
              <label htmlFor="sample_area">Sampled Area</label>
              <input
                id="sample_area"
                onChange={handleChange}
                name="sample_area"
                value={state.sample_area}
                className="custom-input w-100"
              />
            </div>
            <div className="d-flex flex-column w-100 ">
              <label htmlFor="volume_sampled">Volume Sampled</label>
              <input
                id="volume_sampled"
                onChange={handleChange}
                name="volume_sampled"
                value={state.volume_sampled}
                className="custom-input w-100"
              />
            </div>
            <div className="d-flex flex-column w-100 ">
              <label htmlFor="chlorophyll_a">Chlorophyll A</label>
              <input
                id="chlorophyll_a"
                onChange={handleChange}
                name="chlorophyll_a"
                value={state.chlorophyll_a}
                className="custom-input w-100"
              />
            </div>
            <div className="d-flex flex-column w-100 ">
              <label htmlFor="preservative">Preservative</label>
              <input
                id="preservative"
                onChange={handleChange}
                name="preservative"
                value={state.preservative}
                className="custom-input w-100"
              />
            </div>
            <div className="d-flex flex-column w-100 ">
              <label htmlFor="disposition">Disposition</label>
              <input
                id="disposition"
                onChange={handleChange}
                name="disposition"
                value={state.disposition}
                className="custom-input w-100"
              />
            </div>
            <div className="d-flex flex-column w-100 ">
              <label htmlFor="num_reps">Num Reps</label>
              <input
                id="num_reps"
                onChange={handleChange}
                name="num_reps"
                value={state.num_reps}
                className="custom-input w-100"
              />
            </div>
            <div className="d-flex flex-column w-100 ">
              <label htmlFor="depth_uom">Depth Uom</label>
              <input
                id="depth_uom"
                onChange={handleChange}
                name="depth_uom"
                value={state.depth_uom}
                className="custom-input w-100"
              />
            </div>
            <div className="d-flex flex-column w-100 ">
              <label htmlFor="area_uom">Area Uom</label>
              <input
                id="area_uom"
                onChange={handleChange}
                name="area_uom"
                value={state.area_uom}
                className="custom-input w-100"
              />
            </div>
            <div className="d-flex flex-column w-100 ">
              <label htmlFor="volume_uom">Volume Uom</label>
              <input
                id="volume_uom"
                onChange={handleChange}
                name="volume_uom"
                value={state.volume_uom}
                className="custom-input w-100"
              />
            </div>
          </div>
          {edit && (
            <div className="d-flex w-100 justify-content-end mt-2">
              <button
                className="btn btn-info"
                onClick={() =>
                  doNestedModalOpenUniqueCode(
                    BioResultModal,
                    {
                      nested: true,
                      biosample_code: state.biosample_code,
                      sample_type: state.sample_type,
                    },
                    "bioresult_code"
                  )
                }
              >
                Add Result
              </button>
            </div>
          )}
        </div>
      </section>
      <ModalFooter
        showCancelButton
        customClosingLogic
        onCancel={doModalClose}
        onSave={() => doSave(state)}
        onDelete={() => doDelete(state)}
        saveIsDisabled={bioSampleIsSaving}
        deleteIsDisabled={bioSampleIsDeleting}
        saveText={
          bioSampleIsSaving ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : edit ? (
            "Update BioSample"
          ) : (
            "Save"
          )
        }
        deleteText={
          bioSampleIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "selectBioSampleTypeOptions",
  "selectCollectionsSelects",
  "doNestedModalOpenUniqueCode",
  "doModalClose",
  "selectBioSampleIsSaving",
  "selectBioSampleIsDeleting",
  "selectBioSampleError",
  BioSampleModal
);
