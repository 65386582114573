import React from "react";
import classnames from "../../utils/classnames";
import "./nav.css";

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.renderButton = this.renderButton.bind(this);
  }
  renderButton() {
    const { label, iconClass } = this.props;
    return (
      <div className="dropdown-toggle pt-2" style={{ color: "#1976d2" }}>
        {iconClass ? <i className={`${iconClass} pr-1`}></i> : null}
        {label}
      </div>
    );
  }

  render() {
    const { children } = this.props;
    const { show } = this.state;

    const itemClass = classnames({
      "nav-item": true,
      dropdown: true,
      "dropdown-custom": true,
      "px-2": true,
    });

    const dropdownMenuClass = classnames({
      "dropdown-menu": true,
      "dropdown-menu-right": true,
      "dropdown-menu-custom": true,
      show: show,
    });

    return (
      <li className={itemClass} style={{ cursor: "pointer" }}>
        {this.renderButton()}
        <div
          style={{
            display: show ? "block" : "none",
            position: "fixed",
            zIndex: 999,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        ></div>
        <div
          className={dropdownMenuClass}
          x-placement="bottom-start"
          style={{
            position: "absolute",
            willChange: "transform",
            top: "0px",
            right: "0px",
            transform: "translate3d(0px, 36px, 0px)",
            width: 250,
            borderRadius: 16,
            padding: 8,
            borderTopRightRadius: 0,
            zIndex: 1,
            margin: 0,
          }}
        >
          <div
            style={{
              height: "200px",
              overflowY: "auto",
            }}
          >
            {children}
          </div>
        </div>
      </li>
    );
  }
}

export default Dropdown;
