import MaterialTable from "material-table";
import React, { useEffect, useReducer, useState } from "react";
import ReactSelect from "react-select";
import { connect } from "redux-bundler-react";
import {
  ModalContent,
  ModalHeader,
  ModalFooter,
} from "../../app-components/modal";
import SimpleSampleModal from "./SimpleSampleModal";
import SimpleFieldDataModal from "./SimpleFieldDataModal";
import { Collapse } from "@mui/material";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const _convertToObject = (value, valueKey, labelKey, arr) => {
  if (!value || typeof value === "object") return value;
  let currentObj = arr.filter((item) => item[valueKey] == value)[0];
  if (currentObj)
    return { value: currentObj[valueKey], label: currentObj[labelKey] };
  else return null;
};

const TableFieldVisitModal = (props) => {
  const {
    projectLocations,
    fieldTripAll,
    data,
    fieldTripIsFetching,

    doSamplesFetchByFieldVisit,
    samplesByFieldVisit,
    routeParams,
    doLoadConfiguration,
    doUpdateUrl,
    doModalClose,
    doNestedModalOpenUniqueCode,
    doNestedModalOpen,
    userIsOrgMemberOrAdmin,

    edit,
    field_visit_code,
    participants,

    doFieldVisitPost,
    doFieldVisitPut,
    doFieldVisitDelete,
    fieldVisitError: error,
    fieldVisitIsSaving,
    fieldVisitIsDeleting,

    doLrnFormFetch,
    lrnForm,
    doUpdateModalProps,
  } = props;
  const [open, setOpen] = useState(false);
  const initialState = {
    ...data,
    field_visit_code: field_visit_code
      ? field_visit_code
      : data.field_visit_code,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    doLrnFormFetch(state.field_visit_code);
  }, []);
  useEffect(() => {
    doSamplesFetchByFieldVisit(state.field_visit_code);
  }, [state.field_visit_code]);

  useEffect(() => {
    /**
     * When data is initially pulled into the modal from the table, it needs to be formatted in a digestible way for the react-select and input components.
     */
    let initialData = {
      ...data,
      field_visit_code: field_visit_code
        ? field_visit_code
        : data.field_visit_code,
    };

    initialData.location_code = _convertToObject(
      data.location_code,
      "location_code",
      "loc_id",
      projectLocations
    );
    initialData.field_trip_code = _convertToObject(
      data.field_trip_code,
      "field_trip_code",
      "field_trip_code",
      fieldTripAll
    );
    initialData.participants =
      initialData.participants && typeof initialData.participants === "string"
        ? JSON.parse(initialData.participants).map((person) => ({
            label: person,
            value: person,
          }))
        : initialData.participants;
    initialData.field_visit_date =
      initialData.field_visit_date &&
      initialData.field_visit_date.split("Z")[0];
    initialData.field_visit_end_date =
      initialData.field_visit_end_date &&
      initialData.field_visit_end_date.split("Z")[0];
    dispatch({
      type: "INITIALIZE_MODAL",
      payload: initialData,
    });
  }, [fieldTripAll, data, edit]);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSelect = (field, payload) => {
    dispatch({
      type: "UPDATE_INPUT",
      field,
      payload,
    });
  };

  const _onSave = () => {
    let participants =
      state.participants && state.participants.length
        ? state.participants.map((p) => p.label)
        : [];
    let data = {
      field_visit_code: state.field_visit_code,
      field_trip_code: state.field_trip_code.value,
      participants: JSON.stringify(participants),
      field_visit_end_date:
        state.field_visit_end_date.split(":").length === 3
          ? `${state.field_visit_end_date}Z`
          : `${state.field_visit_end_date}:00Z`,
      field_visit_date:
        state.field_visit_date.split(":").length === 3
          ? `${state.field_visit_date}Z`
          : `${state.field_visit_date}:00Z`,
      location_code: state.location_code.value,
      loc_id: state.location_code.label,
      notes: state.notes,
    };
    if (edit) {
      doFieldVisitPut(data);
    } else {
      doFieldVisitPost(data);
    }
  };

  let participantsOptions = participants
    ? JSON.parse(participants).map((p) => ({ label: p, value: p }))
    : fieldTripAll.filter(
        (ft) => ft.field_trip_code === state.field_trip_code.value
      ) &&
      fieldTripAll.filter(
        (ft) => ft.field_trip_code === state.field_trip_code.value
      )[0] &&
      fieldTripAll.filter(
        (ft) => ft.field_trip_code === state.field_trip_code.value
      )[0].participants &&
      JSON.parse(
        fieldTripAll.filter(
          (ft) => ft.field_trip_code === state.field_trip_code.value
        )[0].participants
      ).length
    ? JSON.parse(
        fieldTripAll.filter(
          (ft) => ft.field_trip_code === state.field_trip_code.value
        )[0].participants
      ).map((p) => ({ label: p, value: p }))
    : [];

  let lrnFormData = lrnForm.length > 0 ? lrnForm[0] : {};

  return (
    <ModalContent size="lg" error={error}>
      <ModalHeader title="Field Visit Details" />
      <section className="modal-body">
        <div className="container-fluid">
          <form>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label id="field-visit-date-label" htmlFor="field_visit_date">
                  Start Date
                </label>
                <input
                  className="form-control"
                  type="datetime-local"
                  onChange={handleChange}
                  id="field_visit_date"
                  name="field_visit_date"
                  value={state["field_visit_date"]}
                />
              </div>
              <div className="form-group col-md-6">
                <label id="field-visit-end-date-label">End Date</label>
                <input
                  className="form-control"
                  type="datetime-local"
                  onChange={handleChange}
                  name="field_visit_end_date"
                  value={state["field_visit_end_date"]}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Location</label>
                <ReactSelect
                  id="location"
                  name="location_code"
                  placeholder="Location"
                  options={projectLocations.map((p) => ({
                    value: p.location_code,
                    label: p.loc_id,
                  }))}
                  onChange={(obj) => handleSelect("location_code", obj)}
                  value={state["location_code"]}
                  // isDisabled={edit}
                  maxMenuHeight={144}
                  menuPlacement="top"
                  menuPosition="fixed"
                />
              </div>
              <div className="form-group col-md-6">
                <label>Participants</label>
                <ReactSelect
                  isMulti
                  labelId="participants"
                  id="participants"
                  name="participants"
                  placeholder="Participants"
                  onChange={(obj) => handleSelect("participants", obj)}
                  value={state["participants"]}
                  noOptionsMessage={() => (
                    <p className="m-0">
                      To add more participants, please update the corresponding
                      Field Trip
                    </p>
                  )}
                  options={participantsOptions}
                  menuPosition="fixed"
                />
              </div>
            </div>
            <div className="form-group">
              <label id="notes-label" htmlFor="notes">
                Notes
              </label>
              <textarea
                type="text"
                className="form-control"
                id="notes"
                placeholder="Notes"
                name="notes"
                onChange={handleChange}
                value={state.notes}
              />
            </div>
            <button
              className={`btn btn-sm btn-outline-${
                lrnForm.length > 0 ? "primary" : "success"
              } w-100 mb-4`}
              onClick={(e) => {
                e.preventDefault();
                doUpdateModalProps(
                  {
                    data: state,
                    edit,
                  },
                  () =>
                    doNestedModalOpen(SimpleFieldDataModal, {
                      participantsOptions,
                      field_visit_code: state.field_visit_code,
                      location: state.location_code,
                      participants: state.participants,
                      lrnFormData,
                    })
                );
              }}
            >
              {lrnForm.length > 0 ? "View / Edit Field Data" : "Add Field Data"}
            </button>
            <div className="d-flex w-100 border-bottom mb-4">
              <button
                className="btn btn-ghost"
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(!open);
                }}
              >
                <i
                  className={`mdi mdi-chevron-${open ? "up" : "down"} mdi-18px`}
                />{" "}
                View Samples
              </button>
            </div>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              sx={{ width: "100%" }}
            >
              <div className="custom-table p-3">
                <div className="d-flex w-100 align-items-center justify-content-between mb-2">
                  <h4 className="m-0">Samples</h4>
                  {userIsOrgMemberOrAdmin && (
                    <button
                      className="btn btn-outline-success"
                      onClick={(e) => {
                        e.preventDefault();
                        doUpdateModalProps(
                          { data: state, edit },
                          doNestedModalOpenUniqueCode(
                            SimpleSampleModal,
                            {
                              field_visit_code: state.field_visit_code,
                              location_code: state.location_code.value,
                            },
                            "sample_code"
                          )
                        );
                      }}
                    >
                      Add Sample
                    </button>
                  )}
                </div>
                <MaterialTable
                  title={``}
                  columns={[
                    {
                      title: "Navigate to Sample",
                      field: "link",
                      render: (rowData) => {
                        return (
                          <a
                            className="hover"
                            onClick={(e) => {
                              e.preventDefault();
                              doModalClose(() => {
                                doUpdateUrl(
                                  `/projects/${routeParams.project}/locations/${rowData.location_code}/samples`
                                );
                                doLoadConfiguration({
                                  config: JSON.stringify({
                                    sample_num: rowData.sample_num,
                                  }),
                                });
                              });
                            }}
                          >
                            <i className="mdi mdi-eye mdi-24px"></i>
                          </a>
                        );
                      },
                    },
                    { title: "Sample Num", field: "sample_num" },
                    { title: "Sample Depth", field: "sample_depth" },
                    { title: "Sample Type", field: "sample_type" },
                    { title: "Sample Time", field: "sample_time" },
                  ]}
                  data={samplesByFieldVisit}
                  options={{
                    loadingType: "overlay",
                    exportButton: true,
                    exportAllData: true,
                    //filtering: true,
                    sorting: true,
                    search: false,
                    pageSize: 10,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [10, 100, 200],
                  }}
                  actions={[
                    {
                      icon: "refresh",
                      tooltip: "Refresh table",
                      isFreeAction: true,
                      onClick: () =>
                        doSamplesFetchByFieldVisit(state.field_visit_code),
                    },
                  ]}
                />
              </div>
            </Collapse>
          </form>
        </div>
      </section>
      <ModalFooter
        customClosingLogic
        showCancelButton
        onCancel={doModalClose}
        onSave={_onSave}
        onDelete={() => doFieldVisitDelete(state)}
        saveIsDisabled={fieldVisitIsSaving}
        deleteIsDisabled={fieldVisitIsDeleting}
        saveText={
          fieldVisitIsSaving ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Save"
          )
        }
        deleteText={
          fieldVisitIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "selectRouteParams",
  "doFieldVisitShouldFetchByLocation",
  "doFieldVisitShouldFetch",
  "selectProjectLocations",
  "doProjectLocationsShouldFetch",
  "selectApiRoot",
  "doActionPostData",
  "doActionPutData",
  "doActionDeleteData",
  "selectCwmsSeq",
  "doCwmsSeqFetch",
  "selectFieldTripAll",
  "selectFieldTripIsFetching",
  "doSamplesFetchByFieldVisit",
  "selectSamplesByFieldVisit",
  "selectUserIsOrgMemberOrAdmin",
  "doNestedModalOpenUniqueCode",
  "doNestedModalOpen",
  "doLoadConfiguration",
  "doUpdateUrl",
  "doModalClose",

  "doFieldVisitPost",
  "doFieldVisitPut",
  "doFieldVisitDelete",
  "selectFieldVisitError",
  "selectFieldVisitIsSaving",
  "selectFieldVisitIsDeleting",
  "doUpdateModalProps",
  "doLrnFormFetch",
  "selectLrnForm",
  TableFieldVisitModal
);
