import React from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";
import Modal from "./Modal";

const DocumentTable = ({
  items,
  isFetching,
  doDocumentDownload,
  doModalOpen,
}) => {
  const iconDict = {
    "text/csv": "equalizer",
    "image/jpeg": "image",
    "application/pdf": "picture_as_pdf",
  };
  const iconColor = {
    "text/csv": "success",
    "image/jpeg": "info",
    "application/pdf": "danger",
  };

  return (
    <div className="custom-table mt-3">
      <div className="card-body">
        <MaterialTable
          title=""
          isLoading={isFetching}
          columns={[
            {
              title: "Actions",
              render: (rowData) => {
                return (
                  <div className="d-flex">
                    <button
                      className="hover"
                      onClick={() => doDocumentDownload(rowData)}
                    >
                      <i className="mdi mdi-download-outline mdi-24px"></i>
                    </button>
                    <button
                      className="hover ml-1"
                      onClick={() => doModalOpen(Modal, { data: rowData })}
                    >
                      <i className="mdi mdi-pencil-outline mdi-24px"></i>
                    </button>
                  </div>
                );
              },
            },
            {
              title: "Type",
              render: (rowData) => (
                <i
                  title={rowData.mime_type}
                  className={`mdi mdi-${
                    iconDict[rowData.mime_type]
                  } mdi-24px text-${iconColor[rowData.mime_type]}`}
                />
              ),
            },
            { title: "Name", field: "name" },
            { title: "Subject", field: "subject" },
            { title: "Date Uploaded", field: "date_uploaded" },
            { title: "Document Type", field: "document_type" },
          ]}
          data={items}
          options={{
            loadingType: "overlay",
            exportButton: true,
            exportAllData: true,
            search: true,
            sorting: true,
            pageSize: 20,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [20, 50, 100],
          }}
        />
      </div>
    </div>
  );
};

export default connect("doDocumentDownload", "doModalOpen", DocumentTable);
