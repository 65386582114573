import React, { useEffect, useReducer } from "react";
import ReactSelect from "react-select";
import { connect } from "redux-bundler-react";
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "../../../app-components/modal";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const EditCollectionModal = (props) => {
  const {
    doModalClose,
    data,
    edit,
    officeData,
    doCollectionPost,
    doCollectionPut,
    doCollectionDelete,
    collectionIsSaving,
    collectionIsDeleting,
    collectionError: error,
    collection_method_code,
  } = props;

  let initialState = { meth_type: "" };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (edit) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: data,
      });
    } else {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: { collection_method_code },
      });
    }
  }, [edit, data]);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSelect = (field, payload) => {
    dispatch({
      type: "UPDATE_INPUT",
      field,
      payload: payload.value,
    });
  };

  return (
    <ModalContent size="md" error={error}>
      <ModalHeader
        title={!edit ? "Add Collection Method" : "Edit Collection Method Info"}
      />
      <section className="modal-body">
        <div className="container-fluid">
          <form>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="office_id">Office ID</label>
                <ReactSelect
                  id="office_id"
                  onChange={(obj) => handleSelect("office_id", obj)}
                  value={{ label: state.office_id, value: state.office_id }}
                  menuPosition="fixed"
                  options={officeData.map((s) => ({
                    label: s.office_id,
                    value: s.office_id,
                  }))}
                  className="w-100"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="collect_mthd">Collection Method</label>
                <input
                  type="text"
                  className="form-control"
                  name="collect_mthd"
                  onChange={handleChange}
                  placeholder="i.e. 16"
                  value={state.collect_mthd}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="collect_mthd">Method Type</label>
                <input
                  type="text"
                  className="form-control"
                  name="meth_type"
                  onChange={handleChange}
                  placeholder="i.e. C"
                  value={state.meth_type}
                  maxLength="4"
                />
                <p className="m-0 mt-1 text-muted">
                  <small>
                    {4 - state.meth_type.length} characters remaining
                  </small>
                </p>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="meth_name">Method Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="meth_name"
                  onChange={handleChange}
                  placeholder="i.e. closing net"
                  value={state.meth_name}
                />
              </div>
            </div>
          </form>
        </div>
      </section>
      <ModalFooter
        onSave={() => (edit ? doCollectionPut(state) : doCollectionPost(state))}
        customClosingLogic
        onDelete={edit ? () => doCollectionDelete(state) : null}
        showCancelButton
        onCancel={doModalClose}
        saveIsDisabled={collectionIsSaving}
        deleteIsDisabled={collectionIsDeleting}
        saveText={
          collectionIsSaving ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Save"
          )
        }
        deleteText={
          collectionIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "doCollectionTableFetch",
  "doCollectionsShouldFetch",
  "selectCollections",
  "selectOfficeData",
  "doCollectionPost",
  "doCollectionPut",
  "doCollectionDelete",
  "selectCollectionIsSaving",
  "selectCollectionIsDeleting",
  "selectCollectionError",
  "doModalClose",
  EditCollectionModal
);
