import React from "react";
import Report from "./Report";
import FilterSidebar from "../../dataExport/FilterSidebar";
import { TableContainer } from "../../../app-components/page-content";
import statisticsConfig from "./statistics.config.json";
import { connect } from "redux-bundler-react";

const Statistics = ({ reportData, ...props }) => {
  const generateCSV = () => {
    const csvString = [
      [
        "Location ID",
        "Storet Number",
        "Short Name",
        "Sample Depth",
        "Units",
        "Min Value",
        "Median Value",
        "Mean Value",
        "Max Value",
        "Standard Deviation",
        "Standard Error",
        "Number of Results",
        "Minimum Date",
        "Maximum Date",
      ],
      ...reportData.map((item) => [
        item.loc_id,
        "'" + item.storet_num,
        item.short_name,
        item.sample_depth,
        item.units,
        item.minval,
        item.medianval,
        item.meanval,
        item.maxval,
        item.std_dev,
        item.std_err,
        item.num_results,
        item.sample_min_date,
        item.sample_max_date,
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");
    const csvFile = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvFile);
    window.open(url);
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      <FilterSidebar
        config={statisticsConfig}
        generateText="Generate Report"
        {...props}
      />
      <TableContainer>
        <h3
          className="card-title border-bottom w-100 d-flex justify-content-between align-items-center"
          style={{ paddingTop: 24 }}
        >
          Statistics Report
          <button
            onClick={generateCSV}
            disabled={!reportData.length}
            className="btn btn-info"
          >
            Export to CSV
          </button>
        </h3>
        <Report />
      </TableContainer>
    </div>
  );
};
export default connect(
  ...statisticsConfig.selectors,
  ...statisticsConfig.fetchFns,
  "selectReportData",
  Statistics
);
