import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import { TableContainer } from "../../app-components/page-content";
import { resultsDataPointsConfig } from "../dataExport/filterConfigs";
import FilterSidebar from "../dataExport/FilterSidebar";
import TableResult from "./TableResultData";

const LocationResultsData = (props) => {
  const { doResultShouldFetch } = props;

  useEffect(() => {
    doResultShouldFetch();
  }, []);

  return (
    <div>
      <div
        className="d-flex"
        style={{
          width: "100%",
          minHeight: "calc(100vh - 106px)",
          borderTop: "1px solid lightgray",
        }}
      >
        <FilterSidebar config={resultsDataPointsConfig} {...props} />
        <TableContainer>
          <div className="card" style={{ paddingBottom: "30px" }}>
            <TableResult />
          </div>
        </TableContainer>
      </div>
    </div>
  );
};

export default connect(
  "doResultShouldFetch",
  ...resultsDataPointsConfig.selectors,
  ...resultsDataPointsConfig.fetchFns,
  LocationResultsData
);
