export default {
  name: "filter",
  getReducer: () => {
    const initialData = {
      configs: [],
      filters: [],
      isExpanded: true,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "APPLY_FILTERS":
        case "CONFIGURATIONS_SAVE_STARTED":
        case "CONFIGURATIONS_DELETE_STARTED":
        case "CONFIGURATIONS_FETCH_STARTED":
        case "CONFIGURATIONS_FETCH_FINISHED":
        case "CONFIGURATIONS_LOAD":
        case "FILTERS_EXPANDED_UPDATE":
          return Object.assign({}, state, payload);
        case "CLEAR_FILTERS":
          return { isExpanded: state.isExpanded };
        case "URL_UPDATED":
          return { ...state, isExpanded: true };
        default:
          return state;
      }
    };
  },

  doExpandUpdate:
    () =>
    ({ store, dispatch }) => {
      let isExpanded = store.selectFilterIsExpanded();
      dispatch({
        type: "FILTERS_EXPANDED_UPDATE",
        payload: { isExpanded: !isExpanded },
      });
    },

  doApplyFilters:
    (doX, state) =>
    ({ dispatch, store }) => {
      if (doX) store[doX](state);
      dispatch({
        type: "APPLY_FILTERS",
        payload: { filters: state },
      });
    },

  doClearFilters:
    (doX) =>
    ({ dispatch, store }) => {
      if (doX) store[doX]();
      dispatch({
        type: "CLEAR_FILTERS",
      });
    },
  doSaveConfiguration:
    (configObj) =>
    ({ dispatch, store }) => {
      let { state, config_code, page, name } = configObj;
      let apiRoot = store.selectApiRoot();
      let user_id = store.selectAuthTokenPayload().sub;
      let config = JSON.stringify(state);
      dispatch({
        type: "CONFIGURATIONS_SAVE_STARTED",
        payload: { isLoading: true },
      });

      store.doActionPostData(
        `${apiRoot}/configurations/`,
        {
          config,
          config_code,
          page,
          created_date: new Date(),
          name,
          keycloak_id: user_id,
        },
        store.doFetchConfigurations,
        (e) => console.log(e)
      );
    },
  doFetchConfigurations:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "CONFIGURATIONS_FETCH_STARTED",
        payload: { isLoading: true },
      });
      let user_id = store.selectAuthTokenPayload().sub;
      apiFetch(`/configurations/users/${user_id}`)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "CONFIGURATIONS_FETCH_FINISHED",
            payload: { configs: j.items, isLoading: false },
          });
        });
    },

  doLoadConfiguration:
    (config) =>
    ({ dispatch }) => {
      dispatch({
        type: "CONFIGURATIONS_LOAD",
        payload: {
          loadedConfig: JSON.parse(config.config),
        },
      });
    },

  doDeleteConfiguration:
    (config) =>
    ({ dispatch, store }) => {
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "CONFIGURATIONS_DELETE_STARTED",
        payload: { isLoading: true },
      });

      store.doActionDeleteData(
        `${apiRoot}/configurations/${config.value}`,
        {},
        store.doFetchConfigurations,
        (e) => console.log(e)
      );
    },
  doGenericFnCall:
    (fn, arg) =>
    ({ store }) => {
      store[fn](arg);
    },
  selectLoadedConfig: (state) => state.filter.loadedConfig,
  selectFilterConfigs: (state) => state.filter.configs,
  selectFilters: (state) => state.filter.filters,
  selectConfigIsFetching: (state) => state.filter.isLoading,

  selectFilterIsExpanded: (state) => state.filter.isExpanded,
};
