import React, { useState, useEffect } from 'react';
import { connect } from 'redux-bundler-react';
import Select from "react-select"

export default connect(
    "selectStoretData",
    "selectStoretActive",
    "doStoretActive",
    "selectStoretIsFetching",
    ({ storetData, storetActive, doStoretActive, storetIsFetching, required }) => {
        useEffect(() => {
            if (!storetActive) {
                setVal(null)
            } else {
                setVal({
                    value: storetData.find(x => x.storet_num === storetActive).storet_num,
                    label: storetData.find(x => x.storet_num === storetActive).short_name
                })
            }
        }, [storetActive])
        const [val, setVal] = useState(null)
        const handleChange = (e) => {
            if (e) {
                setVal(e)
                doStoretActive(e)
            } else {
                setVal(null)
            }
        }
        return (
            <div style={{ width: "200px", padding: "5px" }}>
                <Select
                    placeholder={required ? <div className='css-1wa3eu0-placeholder' style={{ color: 'red' }}>Parameters</div> : "Parameters"}
                    options={storetData.map((item) => ({
                        value: item['storet_num'],
                        label: item['short_name']
                    }))}
                    value={val}
                    onChange={(value) => handleChange(value)}
                    menuPosition="fixed"
                    isLoading={storetIsFetching}
                />
            </div>
        )
    }
)