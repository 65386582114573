import React from "react";
import { connect } from "redux-bundler-react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

function capitalizeFirstLetter(string) {
  return typeof string === "string"
    ? string.charAt(0).toUpperCase() + string.slice(1)
    : string;
}

const renderBreadcrumbs = (routes, pathArr, project, location, ft, indices) => {
  if (!routes.project) return null;
  let href = "";
  pathArr.unshift("home");
  return pathArr.map((item, index) => {
    if (index === 0) {
      href += "/";
      return _renderLink(index, pathArr, item, href);
    } else if (item === "projects") {
      href += "projects";
      return _renderLink(index, pathArr, item, href);
    } else if (pathArr[index - 1] === "indices") {
      href += `/${routes.location}`;
      return _renderLink(index, pathArr, indices, href);
    } else if (routes.project === item) {
      href += `/${project.site_code}`;
      return _renderLink(index, pathArr, project.site_name, href);
    } else if (routes.id === item) {
      href += `/${item}`;
      return _renderLink(index, pathArr, location, href);
    } else if (routes.fieldTrip === item) {
      href += `/${item}`;
      return _renderLink(index, pathArr, ft, href);
    } else if (routes.storet === item) {
      href += `/${item}`;
      return null;
    } else {
      href += `/${item}`;
      return _renderLink(index, pathArr, item, href);
    }
  });
};

const _renderLink = (index, pathArr, text, href) => {
  let textOrLink =
    text === "add" ? "Add Location" : capitalizeFirstLetter(text);
  if (index === pathArr.length - 1) {
    return (
      <Typography color="text.primary" key={index}>
        {textOrLink}
      </Typography>
    );
  } else {
    return (
      <Link underline="hover" color="inherit" href={href} key={index}>
        {textOrLink}
      </Link>
    );
  }
};

const BreadcrumbsComponent = ({
  project,
  routeParams,
  location: currentLocation,
  fieldTrip,
  currentIndices,
}) => {
  let location = currentLocation && currentLocation.loc_id;
  let fieldTripSelected =
    fieldTrip && fieldTrip[0] && fieldTrip[0].field_trip_id;
  let pathArr = window.location.pathname
    .split("/")
    .filter((item) => item !== "");
  let indices_id = currentIndices && currentIndices.loc_id;
  return (
    <div style={{ padding: "0 64px", width: "100%" }}>
      <div style={{ padding: "16px 0" }}>
        <Breadcrumbs aria-label="breadcrumb">
          {renderBreadcrumbs(
            routeParams,
            pathArr,
            project,
            location,
            fieldTripSelected,
            indices_id
          )}
        </Breadcrumbs>
      </div>
    </div>
  );
};

export default connect(
  "selectProject",
  "selectRouteParams",
  "selectFieldTrip",
  "selectLocation",
  "selectCurrentIndices",
  BreadcrumbsComponent
);
