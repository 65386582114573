import React, { useState } from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";
import Chart from "../charting/Chart";
import EditIcon from "@material-ui/icons/RemoveRedEye";
import Link from "../misc/link";
import GraphLinkDropdown from "./GraphLinkDropdown";

function TableResult(props) {
  const {
    projectIsFetching,
    resultsummaryData,
    resultsummaryIsFetching,
    doResultShouldFetch,
    doApplyFilters,
    projectLocations,
    projects,
  } = props;

  return (
    <MaterialTable
      title="Results Summary"
      isLoading={resultsummaryIsFetching || projectIsFetching}
      columns={[
        {
          title: "View Data Points",
          field: "link",
          render: (rowData) => {
            return !props.projectParam ? (
              <p>Loading</p>
            ) : (
              <Link
                to={`/projects/${props.projectParam}/locations/${rowData.location_code}/results/${rowData.storet_num}/${rowData.sample_depth}`}
              >
                <EditIcon />
              </Link>
            );
          },
        },
        {
          title: "Sample Depth",
          field: "sample_depth",
          render: (rowData) => {
            const {
              sample_depth,
              storet_num,
              storet_name,
              sample_date_early: min_sample_date,
              sample_date_most_recent: max_sample_date,
            } = rowData;
            const charts = [
              {
                name: "Line Chart",
                url: "/charting",
                icon: "chart-line-stacked",
                onClick: () => {
                  let project = projects.filter(
                    (item) => item.site_code === parseInt(props.projectParam)
                  )[0];
                  let location = projectLocations.filter(
                    (item) => item.location_code === rowData.location_code
                  )[0];
                  doApplyFilters("doChartHydrographFetch", {
                    projects: [
                      { value: project.site_code, label: project.site_name },
                    ],
                    [project.site_code]: [
                      { value: location.location_code, label: location.loc_id },
                    ],
                    sample_depth: [
                      { value: sample_depth, label: sample_depth },
                    ],
                    parameters: [{ value: storet_num, label: storet_name }],
                  });
                },
              },
              {
                name: "Heatmap",
                url: "/charting/heatmap",
                icon: "grid",
                onClick: () => {
                  let project = projects.filter(
                    (item) => item.site_code === parseInt(props.projectParam)
                  )[0];
                  let location = projectLocations.filter(
                    (item) => item.location_code === rowData.location_code
                  )[0];
                  doApplyFilters("doGenerateData", {
                    projects: [
                      { value: project.site_code, label: project.site_name },
                    ],
                    [project.site_code]: [
                      { value: location.location_code, label: location.loc_id },
                    ],
                    sample_depth: sample_depth.toString(),
                    parameters: [{ value: storet_num, label: storet_name }],
                    min_sample_date: min_sample_date.split("T")[0],
                    max_sample_date: max_sample_date.split("T")[0],
                  });
                },
              },
            ];

            if (
              ["00011", "00301", "00299", "00300", "00295", "00010"].includes(
                storet_num
              )
            )
              charts.push({
                name: "Vertical Profile",
                url: "/charting/profile",
                icon: "chart-line",
                onClick: () => {
                  let project = projects.filter(
                    (item) => item.site_code === parseInt(props.projectParam)
                  )[0];
                  let location = projectLocations.filter(
                    (item) => item.location_code === rowData.location_code
                  )[0];
                  doApplyFilters("doProfileFetch", {
                    projects: [
                      { value: project.site_code, label: project.site_name },
                    ],
                    [project.site_code]: [
                      { value: location.location_code, label: location.loc_id },
                    ],
                    min_sample_depth: 0,
                    max_sample_depth: 100,
                    parameters: [{ value: storet_num, label: storet_name }],
                    date: rowData.sample_date_most_recent,
                  });
                },
              });
            return <GraphLinkDropdown charts={charts} rowData={rowData} />;
          },
        },
        { title: "Depth Unit", field: "depth_uom" },
        { title: "Storet Name", field: "storet_name" },
        { title: "Units", field: "units" },
        {
          title: "Boxplot",
          field: "boxplot",
          render: (rowData) => {
            return (
              <div
                className="d-flex flex-column border bg-white rounded mb-2"
                style={{
                  width: "250px",
                  height: "80px",
                  position: "relative",
                }}
              >
                <Chart
                  options={{
                    chart: {
                      type: "boxplot",
                      inverted: true,
                    },
                    title: {
                      text: "",
                    },
                    legend: {
                      enabled: false,
                    },
                    xAxis: {
                      categories: [""],
                      title: {
                        text: "",
                      },
                    },
                    yAxis: {
                      title: {
                        text: "",
                      },
                    },
                    series: [
                      {
                        name: "Observations",
                        data: [
                          [
                            rowData.minval,
                            rowData.pct_25,
                            rowData.medianval,
                            rowData.pct_75,
                            rowData.maxval,
                          ],
                        ],
                      },
                      {
                        name: "Most Recent Observation",
                        color: "green",
                        type: "scatter",
                        data: [
                          // x, y positions where 0 is the first category
                          [0, rowData.mostrecentval],
                        ],
                        marker: {
                          fillColor: "green",
                          lineWidth: 1,
                          lineColor: "green",
                        },
                        tooltip: {
                          pointFormat: "Most Recent Observation: {point.y}",
                        },
                      },
                    ],
                  }}
                />
              </div>
            );
          },
        },
        { title: "Number of Results", field: "num_results" },
        { title: "First Sample Date", field: "sample_date_early" },
        { title: "Most Recent Sample Date", field: "sample_date_most_recent" },
        { title: "Most Recent Value", field: "mostrecentval" },
      ]}
      data={resultsummaryData}
      options={{
        loadingType: "overlay",
        exportButton: true,
        exportAllData: true,
        sorting: true,
        search: true,
        pageSize: 20,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [20, 500, 1000, 2000],
      }}
      actions={[
        {
          icon: "refresh",
          tooltip: "Refresh table",
          isFreeAction: true,
          onClick: (event) => doResultShouldFetch(),
        },
      ]}
    />
  );
}

export default connect(
  "doActionPostData",
  "doActionPutData",
  "doActionDeleteData",
  "selectResultData",
  "selectResultsummaryData",
  "selectResultsummaryIsFetching",
  "doResultShouldFetch",
  "doProjectActive",
  "doLocationActive",
  "doSampleDepthActive",
  "doStoretActive",
  "doProjectShouldFetch",
  "doStoretFetch",
  "selectProjectAll",
  "selectStoretOptions",
  "selectSampleByProjectUnique",
  "doChartHydrographFetch",
  "selectProjectIsFetching",
  "selectProjectLocations",
  "selectProjects",
  "doApplyFilters",
  TableResult
);
