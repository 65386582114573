import React from 'react';
import { Icon } from '@material-ui/core';
import "../../location/cards/customCard.css"
import Modal from "./Modal"

const DocumentCard = props => {
    return (
        <div>
            <div className='custom m-2 p-2'>
                <div className='card-body' style={{ padding: "0px 20px 0px 0px" }}>
                    <div className='row'>
                        <div className="col-1">
                            <div style={{ float: "left", borderRight: "1px solid rgb(213 212 212)", padding: "5px", height: "100%" }}>
                                <Icon style={{ color: "blue", fontSize: 35 }} onClick={props.onClick}>download</Icon>
                                <Modal icon={"edit"} color={"blue"} type={"Edit"} info={props} />
                                <Modal icon={"delete"} color={"red"} type={"Delete"} info={props} />
                            </div>
                        </div>
                        <div className="col" style={{ width: "50%", padding: "20px" }}>
                            <h4 className="card-title">{props.name}</h4>
                            <h6 className="card-subtitle m-0">{props.subject}</h6>
                        </div>
                        <div className="col-4" style={{ padding: "20px" }}>
                            <h6 className="card-title">Document Type: <b>{props.document_type}</b></h6>
                            <h6 className="card-title">Date Uploaded: <b>{props.date}</b></h6>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}
export default DocumentCard