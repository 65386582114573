import React, { useEffect } from 'react';
import { connect } from 'redux-bundler-react';

import { ImageList, ImageListItem, ImageListItemBar, Icon } from "@material-ui/core";
// import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';

const Images = connect(
    "selectDocumentPhotos",
    "doDocumentDownload",
    ({ documentPhotos, doDocumentDownload }) => {
        return (
            <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={250}>
                {documentPhotos.map((item) => {
                    let source = "data:" + item.mime_type + ";base64," + item.blob_content
                    return (
                        <ImageListItem key={item.id}>
                            <img
                                src={source}
                                // srcSet={`${source}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt={item.name}
                                loading="lazy"
                            />
                            <ImageListItemBar
                                title={item.subject}
                                subtitle={item.date_uploaded}
                                // subtitle={<IconButton color="secondary" onClick={() => handleDownload(source)}><CloudDownloadOutlinedIcon /></IconButton>}
                                position="bottom"
                                actionIcon={
                                    <Icon style={{ color: 'rgba(255, 255, 255, 0.8)', paddingRight: '30px', paddingTop: '5px' }} onClick={() => doDocumentDownload(item)}>
                                        download
                                    </Icon>
                                }
                            />
                        </ImageListItem>
                    )
                })}
            </ImageList >
        )
    }
)

export default Images;