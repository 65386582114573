import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import CollapsedTable from "../../app-components/tables/collapsedTable";
import BioResultModal from "../bioresults/BioResultModal";
import BioSampleModal from "./BioSampleModal";

const TableBiosample = (props) => {
  const {
    doActionPostData,
    doActionPutData,
    doActionDeleteData,
    apiRoot,
    userIsOrgMemberOrAdmin,
    routeParams,
    loadedConfig,
    doBioSampleFetch,
    doSortBioresults,
    bioResultDataByCode,
    bioResultSortedData,
    doModalOpen,
    doModalClose,
    doModalOpenUniqueCode,
    bioSampleData,
    doBioResultFetchByCode,
    doBioSamplePost,
    doBioSamplePut,
    doBioSampleDelete,
    doUpdateModalProps,
  } = props;
  useEffect(() => {
    if (!loadedConfig) doBioSampleFetch();
  }, []);

  const _fetchSamples = () => {
    doBioSampleFetch();
    doBioResultFetchByCode();
  };
  const doUpdate = (payload) => {
    doBioSamplePut(payload, () => {
      doUpdateModalProps({ data: payload, edit: true });
      doModalClose();
    });
  };

  const doSave = (payload) => {
    let data = { ...payload };
    data.sample_time = !payload.sample_time
      ? null
      : payload.sample_time.split(":").length === 3
      ? `${payload.sample_time}Z`
      : `${payload.sample_time}:00Z`;
    data.location_code = parseInt(routeParams.id);
    doBioSamplePost(data, () => {
      doUpdateModalProps({ data, edit: true });
    });
  };
  const doDelete = (data) => {
    doBioSampleDelete(data);
  };

  let sortedData = [];
  bioSampleData.forEach((smpl) => {
    sortedData.push({
      sample: smpl,
      results: bioResultSortedData[smpl.biosample_code],
    });
  });

  return (
    <CollapsedTable
      isFetching={false}
      userIsOrgMemberOrAdmin={userIsOrgMemberOrAdmin}
      routeParams={routeParams}
      doFetch={_fetchSamples}
      doSort={doSortBioresults}
      data={bioResultDataByCode}
      title="Biosamples"
      actions={["EDIT"]}
      doEditSample={(data) =>
        doModalOpen(BioSampleModal, {
          data,
          edit: true,
          doSave: doUpdate,
          doDelete,
        })
      }
      doEditResult={(data) =>
        doModalOpen(BioResultModal, {
          data,
          edit: true,
        })
      }
      onAdd={() =>
        doModalOpenUniqueCode(BioSampleModal, { doSave }, "biosample_code")
      }
      addText={"Add Sample"}
      addButtonDisabled={false}
      sortedData={sortedData}
      sampleCodeKey="biosample_code"
    />
  );
};

export default connect(
  "doActionPostData",
  "doActionPutData",
  "doActionDeleteData",
  "selectApiRoot",
  "selectRouteParams",
  "selectBioResultData",
  "doBioSampleFetch",
  "doBioResultFetchByCode",
  "doSortBioresults",
  "selectBioResultDataByCode",
  "selectBioResultSortedData",
  "doModalOpen",
  "doModalClose",
  "doModalOpenUniqueCode",
  "selectBioSampleData",
  "doBioSamplePost",
  "doBioSamplePut",
  "doBioSampleDelete",
  "doUpdateModalProps",
  TableBiosample
);
