import React, { useState, useEffect, useReducer } from "react";
import { connect } from "redux-bundler-react";
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "../../../app-components/modal";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const EditLabsModal = (props) => {
  const {
    data,
    edit,
    lab_code,
    doLabPut,
    doLabPost,
    doLabDelete,
    labIsSaving,
    labIsDeleting,
    labError: error,
    doModalClose,
  } = props;

  let initialState = { lab_code, state: "" };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (edit) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: {
          ...data,
        },
      });
    }
  }, [edit, data]);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  return (
    <ModalContent size="md" error={error}>
      <ModalHeader title={edit ? "Edit Labs Info" : "Add Lab"} />
      <section className="modal-body">
        <div className="container-fluid">
          <form>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="lab_id">Lab ID</label>
                <input
                  type="text"
                  className="form-control"
                  name="lab_id"
                  onChange={handleChange}
                  placeholder="i.e. ARDL"
                  value={state.lab_id}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="lab_name">Lab Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="lab_name"
                  onChange={handleChange}
                  placeholder="i.e. NUS Laboratory"
                  value={state.lab_name}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  className="form-control"
                  name="city"
                  onChange={handleChange}
                  placeholder="i.e. Charleston"
                  value={state.city}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  className="form-control"
                  name="state"
                  onChange={handleChange}
                  placeholder="i.e. PA"
                  value={state.state}
                  maxLength="2"
                />
                <p className="m-0 mt-1 text-muted">
                  <small>{2 - state.state.length} characters remaining</small>
                </p>
              </div>
            </div>
          </form>
        </div>
      </section>
      <ModalFooter
        onSave={() => (edit ? doLabPut(state) : doLabPost(state))}
        customClosingLogic
        onDelete={edit ? () => doLabDelete(state) : null}
        showCancelButton
        onCancel={doModalClose}
        saveIsDisabled={labIsSaving}
        deleteIsDisabled={labIsDeleting}
        saveText={
          labIsSaving ? <i className="mdi mdi-loading mdi-spin" /> : "Save"
        }
        deleteText={
          labIsDeleting ? <i className="mdi mdi-loading mdi-spin" /> : "Delete"
        }
      />
    </ModalContent>
  );
};

export default connect(
  "selectLabsIsFetching",
  "selectLabs",
  "doLabPut",
  "doLabPost",
  "doLabDelete",
  "selectLabIsSaving",
  "selectLabIsDeleting",
  "selectLabError",
  "doModalClose",
  EditLabsModal
);
