import React, { useState, useEffect } from "react";
import { connect } from "redux-bundler-react";
import Link from "../../misc/link";
import "./customCard.css";
import { TableChart, ArrowRightAlt } from "@material-ui/icons";

function CardBioSamples(props) {
  const [info, setInfo] = useState(null);
  const { doBioSampleShouldFetch, bioSampleData, bioSampleIsFetching } = props;

  useEffect(() => {
    if (props.location == null) return;
    setInfo(0);
    doBioSampleShouldFetch(props.location.location_code);
  }, [props.location.location_code]);

  useEffect(() => {
    if (!bioSampleIsFetching) {
      setInfo(bioSampleData[0]);
    }
  }, [bioSampleIsFetching, bioSampleData]);

  return !props.location || !props.project || bioSampleIsFetching ? (
    <div className="custom">
      <div className="card-body">
        <div style={{ textAlign: "center" }}>
          <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
        </div>
      </div>
    </div>
  ) : (
    <div className="custom">
      <a
        style={{ textDecoration: "none", cursor: "pointer", height: "100%" }}
        href={`/projects/${props.project.site_code}/locations/${props.location.location_code}/biosamples`}
      >
        <div className="card-body h-100">
          <div className="custom-body">
            {/* <TableChart color="white" htmlColor="white" fontSize='large' /> */}
            <div>
              <h4
                className="card-title border-bottom text-black"
                style={{ color: "#3c4b64" }}
              >
                BioSamples
              </h4>
              <h6 className="card-subtitle mb-2 text-muted">
                {`Recent Sample Type: `}
                {info && info.sample_type ? info.sample_type : "n/a"}
              </h6>
              <h6 className="card-subtitle mb-2 text-muted">
                {`Recent Sample Num: `}
                {info && info.sample_num ? info.sample_num : "n/a"}
              </h6>
              <h6 className="card-subtitle mb-2 text-muted">
                {`Recent Date: `}
                {info && info.sample_time
                  ? info.sample_time.split("T")[0]
                  : "n/a"}
              </h6>
              <Link
                style={{ fontWeight: "bold" }}
                to={`/projects/${props.project.site_code}/locations/${props.location.location_code}/biosamples`}
              >
                Go to BioSamples
              </Link>
            </div>
            {/* <div style={{ position: 'absolute', right: 8, bottom: 0 }}>
          <ArrowRightAlt color='white' htmlColor='white' fontSize='large' /> */}
          </div>
        </div>
      </a>
    </div>
  );
}

export default connect(
  "selectBioSampleData",
  "selectBioSampleIsFetching",
  "doBioSampleShouldFetch",
  CardBioSamples
);
