import { createSelector } from "redux-bundler";
// import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "cwmsSeq",
  getReducer: () => {
    const initialData = {
      data: [],
      isFetching: false,
      shouldFetch: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "CWMS_SEQ_FETCH_START":
        case "CWMS_SEQ_FETCH_FINISH":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },
  doCwmsSeqShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "CWMS_SEQ_SHOULD_FETCH",
        payload: { shouldFetch: true },
      });
    },
  doCwmsSeqFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "CWMS_SEQ_FETCH_START",
        payload: {
          isFetching: true,
          shouldFetch: false,
        },
      });
      let url = "/wq_seq_v/";
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "CWMS_SEQ_FETCH_FINISH",
            payload: { data: j.items, isFetching: false },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },
  doCwmsSeqNewFetch:
    () =>
    ({ dispatch, apiFetch }) => {
      dispatch({
        type: "CWMS_SEQ_FETCH_START",
        payload: {
          isFetching: true,
        },
      });
      let url = "/wq_seq_v/";
      return apiFetch(url)
        .then((r) => {
          dispatch({
            type: "CWMS_SEQ_FETCH_FINISH",
            payload: { isFetching: false },
          });
          let res = r && r.json();
          return res;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  selectCwmsSeq: (state) => state.cwmsSeq.data,
  selectCwmsSeqIsFetching: (state) => state.cwmsSeq.isFetching,
  selectCwmsSeqShouldFetch: (state) => state.cwmsSeq.shouldFetch,

  reactCwmsSeqShouldFetch: createSelector(
    "selectCwmsSeqIsFetching",
    "selectCwmsSeqShouldFetch",
    (cwmsSeqIsFetching, cwmsSeqShouldFetch) => {
      // never fetch if another fetch already in progress
      if (cwmsSeqIsFetching) {
        return null;
      }
      if (cwmsSeqShouldFetch) {
        return {
          actionCreator: "doCwmsSeqFetch",
        };
      }
    }
  ),
};
