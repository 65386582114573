import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";
import { ordsifyUrlBuilder } from "../../utils/ordsify";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Link from "../misc/link";
import CustomAvatar from "./CustomAvatar";
import { stringAvatar } from "./helpers";
import FieldTripModal from "./FieldTripModal";

function TableFieldTrip(props) {
  const {
    fieldTrip,
    fieldTripIsFetching,
    doFieldTripShouldFetch,
    doActionPostData,
    doActionDeleteData,
    apiRoot,
    doCwmsSeqFetch,
    cwmsSeq,
    cwmsSeqIsFetching,
    isPosting,
    routeParams,
    userIsOrgMemberOrAdmin,
    doModalOpen,
    doModalOpenUniqueCode,
  } = props;
  const [info, setInfo] = useState({
    start_date: new Date(),
    end_date: new Date(),
  });

  useEffect(() => {
    if (cwmsSeq.length > 0) {
      setInfo((state) => ({ ...state, field_trip_code: cwmsSeq[0].seq }));
      doActionPostData(
        `${apiRoot}/field_trip/`,
        { ...info, field_trip_code: cwmsSeq[0].seq },
        doFieldTripShouldFetch,
        // (e) => props.setError(e, "Invalid input type. Field Trip Code can only be integers.")
        (e) => console.error(e)
      );
    }
  }, [cwmsSeq]);
  const start_dateOnChange = (date) =>
    setInfo((state) => ({ ...state, start_date: date }));
  const end_dateOnChange = (date) =>
    setInfo((state) => ({ ...state, end_date: date }));

  return fieldTripIsFetching ? (
    <div className="custom-table" style={{ width: "300px" }}>
      <div className="card-body">
        <div style={{ textAlign: "center" }}>
          <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
        </div>
      </div>
    </div>
  ) : (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="custom-table">
        <div className="card-body">
          <div className="d-flex w-100 align-items-center justify-content-between mb-2">
            <h4 className="m-0">Field Trips</h4>
            {userIsOrgMemberOrAdmin && (
              <button
                className="btn btn-outline-success"
                onClick={() => {
                  doModalOpenUniqueCode(FieldTripModal, {}, "field_trip_code");
                }}
              >
                Add Field Trip
              </button>
            )}
          </div>
          <div style={{ textAlign: "center" }}>
            <MaterialTable
              title={""}
              isLoading={fieldTripIsFetching || cwmsSeqIsFetching || isPosting}
              columns={[
                {
                  field: "link",
                  title: "Actions",
                  render: (rowData) => {
                    if (userIsOrgMemberOrAdmin) {
                      return (
                        <button
                          className="hover"
                          onClick={(e) => {
                            doModalOpen(FieldTripModal, {
                              data: rowData,
                              edit: true,
                            });
                          }}
                        >
                          <i className="mdi mdi-pencil-outline mdi-24px"></i>
                        </button>
                      );
                    }
                  },
                },
                {
                  field: "link",
                  title: "View Field Visits",
                  render: (rowData) => {
                    return (
                      <Link
                        to={`/projects/${rowData.site_code}/fieldtrips/${rowData.field_trip_code}`}
                      >
                        <i className="mdi mdi-eye mdi-24px"></i>
                      </Link>
                    );
                  },
                  editComponent: () => null,
                },
                { title: "Field Trip ID", field: "field_trip_id" },
                {
                  title: "Participants",
                  field: "participants",
                  render: (rowData) => {
                    return (
                      <div className="d-flex">
                        {rowData.participants &&
                          JSON.parse(rowData.participants).length > 0 &&
                          JSON.parse(rowData.participants).map((name) => (
                            <div className="mr-1" key={name}>
                              <CustomAvatar
                                key={name}
                                size={30}
                                disabled
                                {...stringAvatar(name, 30)}
                              />
                            </div>
                          ))}
                      </div>
                    );
                  },
                },
                {
                  title: "Start Date",
                  field: "start_date",
                  editComponent: (props) => (
                    <DateTimePicker
                      onChange={start_dateOnChange}
                      value={info.start_date}
                      format="MM/dd/yyyy"
                      disableFuture
                    />
                  ),
                },
                {
                  title: "End Date",
                  field: "end_date",
                  editComponent: (props) => (
                    <DateTimePicker
                      onChange={end_dateOnChange}
                      value={info.end_date}
                      format="MM/dd/yyyy"
                      disableFuture
                    />
                  ),
                },
                { title: "Notes", field: "notes" },
              ]}
              data={fieldTrip}
              options={{
                loadingType: "overlay",
                exportButton: true,
                exportAllData: true,
                search: false,
                sorting: true,
                pageSize: 50,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [50, 500, 1000, 2000],
              }}
              actions={[
                {
                  icon: "refresh",
                  tooltip: "Refresh table",
                  isFreeAction: true,
                  onClick: (event) => doFieldTripShouldFetch(),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </MuiPickersUtilsProvider>
  );
}

export default connect(
  "doActionPostData",
  "doActionDeleteData",
  "selectFieldTrip",
  "selectFieldTripIsFetching",
  "doFieldTripShouldFetch",
  "selectApiRoot",
  "selectRouteParams",
  "selectCwmsSeq",
  "selectCwmsSeqIsFetching",
  "doCwmsSeqFetch",
  "selectIsPosting",
  "selectUserIsOrgMemberOrAdmin",
  "doModalOpen",
  "doModalOpenUniqueCode",
  TableFieldTrip
);
