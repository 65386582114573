import React from "react";
import { connect } from "redux-bundler-react";
import MaterialTable from "material-table";

import "../Report.css";

export default connect(
  "selectReportData",
  "selectReportDone",
  ({ reportData, reportDone }) => {
    return (
      <div>
        <div
          style={{
            marginTop: "10px",
            overflow: "scroll",
            height: "calc(100vh - 200px)",
          }}
        >
          {reportData.length ? (
            <MaterialTable
              title={""}
              columns={[
                { title: "Location ID", field: "loc_id" }, // this is the unique ID
                { title: "Storet Number", field: "storet_num" },
                { title: "Sample Depth", field: "sample_depth" },
                { title: "Units", field: "units" },
                { title: "Min Value", field: "minval" },
                { title: "Median Value", field: "medianval" },
                { title: "Mean Value", field: "meanval" },
                { title: "Max Value", field: "maxval" },
                { title: "Standard Deviation", field: "std_dev" },
                { title: "Standard Error", field: "std_err" },
                { title: "Number of Results", field: "num_results" },
                { title: "Minimum Date", field: "sample_min_date" },
                { title: "Maximum Date", field: "sample_max_date" },
              ]}
              data={reportData}
            />
          ) : reportDone ? (
            <div>
              <h3>
                No Data at these parameters. Please adjust the parameters and
                generate a new report!
              </h3>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);
