import { createSelector } from "redux-bundler";
import { stringifyDate, ordsifyUrlBuilderNoSlash } from "../utils/ordsify";

export default {
  name: "exceedance",
  // reducers
  getReducer: () => {
    const initialData = {
      data: [],
      type: null,
      min: null,
      max: null,
      config: [],
      shouldFetch: false,
      done: false,
    };
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "REPORT_EXCEEDANCE_STARTED":
        case "REPORT_EXCEEDANCE_FINISHED":
        case "REPORT_EXCEEDANCE_TYPE":
        case "REPORT_EXCEEDANCE_MIN":
        case "REPORT_EXCEEDANCE_MAX":
        case "REPORT_EXCEEDANCE_CONFIG_STARTED":
        case "REPORT_EXCEEDANCE_CONFIG_FINISHED":
        case "REPORT_EXCEEDANCE_CONFIG_SHOULD_FETCH":
        case "REPORT_EXCEEDANCE_CONFIG_FETCH_STARTED":
        case "REPORT_EXCEEDANCE_CONFIG_FETCH_FINISHED":
        case "REPORT_EXCEEDANCE_LOAD_STARTED":
        case "REPORT_EXCEEDANCE_LOAD_FINISHED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },
  // action creators
  doReportExceedanceThreshold:
    (type) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "REPORT_EXCEEDANCE_TYPE",
        payload: {
          type: type,
          done: false,
        },
      });
    },
  doReportExceedanceMin:
    (min) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "REPORT_EXCEEDANCE_MIN",
        payload: {
          min: min,
        },
      });
    },
  doReportExceedanceMax:
    (max) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "REPORT_EXCEEDANCE_MAX",
        payload: {
          max: max,
        },
      });
    },
  doReportExceedanceGenerate:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      const {
        min_sample_date: min_date,
        max_sample_date: max_date,
        min_value: min,
        max_value: max,
      } = state;
      dispatch({ type: "REPORT_EXCEEDANCE_STARTED" });
      let parameter =
        state.parameters && state.parameters[0] && state.parameters[0].value;
      let locationArray = Object.keys(state).map((key) => {
        if (parseInt(key) && state[key]) {
          /** key is site_code containing arrays of locations */
          return state[key].map((location) => location.value);
        }
      });
      locationArray = locationArray
        .flat(1)
        .filter((item) => item !== undefined);
      const url = ordsifyUrlBuilderNoSlash(
        `/reports/exceedance/${parameter}/${min}/${max}/${stringifyDate(
          min_date
        )}/${stringifyDate(max_date)}`,
        [
          {
            keyword: "location_code",
            items: locationArray ? locationArray : "", // if no location_codes passed, fetch all
          },
        ],
        500
      );

      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "REPORT_EXCEEDANCE_FINISHED",
            payload: {
              data: j.items,
              done: true,
            },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },
  doReportExceedancePostConfig:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({ type: "REPORT_EXCEEDANCE_CONFIG_STARTED" });
      const id = Math.floor(Math.random() * 100000);
      const cacUid = parseInt(store.selectAuthTokenPayload().sub);
      const locations = store.selectLocationsActive();
      let locationNames = [];
      if (locations[0].hasOwnProperty("loc_id")) {
        locationNames = [
          ...new Set(locations.map((item) => item.loc_id)),
        ].toString();
      } else {
        locationNames = [
          ...new Set(locations.map((item) => item.label)),
        ].toString();
      }
      const data = {
        project_code: store.selectProjectActive(),
        location_codes: store.selectLocationsActiveArray().toString(),
        location_ids: locationNames,
        min_date: stringifyDate(store.selectSampleDateActiveMin()),
        max_date: stringifyDate(store.selectSampleDateActiveMax()),
        storet_num: store.selectStoretActive(),
        minimum: store.selectReportExceedanceMin(),
        maximum: store.selectReportExceedanceMax(),
        cac_uid: cacUid,
        id: id,
      };
      apiFetch("/reports/config", {
        method: "POST",
        headers: {
          Cac_uid: cacUid,
          Id: id,
          Report_type: "parameter",
        },
        body: JSON.stringify(data),
      }).then((r) => {
        dispatch({ type: "REPORT_EXCEEDANCE_CONFIG_FINISHED" });
      });
    },
  doReportExceedanceShouldFetchConfig:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "REPORT_EXCEEDANCE_CONFIG_SHOULD_FETCH",
        payload: {
          shouldFetch: true,
        },
      });
    },
  doReportExceedanceGetConfig:
    () =>
    ({ dispatch, store, apiFetch }) => {
      const cacUid = parseInt(store.selectAuthTokenPayload().sub);
      dispatch({
        type: "REPORT_EXCEEDANCE_CONFIG_FETCH_STARTED",
        payload: { shouldFetch: false },
      });
      apiFetch(`/reports/config/${cacUid}?q={"report_type":"parameter"}`)
        .then((response) => response.json())
        .then((r) =>
          dispatch({
            type: "REPORT_EXCEEDANCE_CONFIG_FETCH_FINISHED",
            payload: {
              config: r.items,
            },
          })
        );
      store.doReportSetFinished(false);
    },
  doReportExceedanceLoadConfig:
    (rowData) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "REPORT_EXCEEDANCE_LOAD_STARTED",
      });
      store.doReportSetFinished(true);
      // get project data
      // @TODO I think I need a project lookup for project code
      // store.doLocationFetchByProject(rowData.project_code)
      // Need to wait for locations to get back to get activeLocationCode
      const locationCodes = rowData.location_codes.split(",");
      const storetCode = rowData.storet_num;
      // FETCH SAMPLE
      // I dont even know if we need this anymroe
      // store.doSampleFetch(locationCodes)
      // PROJECT ACTIVE
      store.doProjectActive(rowData.project_code);
      // STORET ACTIVE
      store.doStoretActive(storetCode);
      // SAMPLES ACTIVE
      store.doSampleDateActiveMin(rowData.min_date);
      store.doSampleDateActiveMax(rowData.max_date);
      store.doReportExceedanceMin(rowData.minimum);
      store.doReportExceedanceMax(rowData.maximum);
      // FETCH LOCATIONS BY LOCATION CODE
      // back to statistics bundle?
      store.doReportLocationFetch(locationCodes);
      dispatch({
        type: "REPORT_EXCEEDANCE_LOAD_FINISHED",
      });
    },

  selectReportExceedanceData: (state) => state.exceedance.data,
  selectReportExceedanceType: (state) => state.exceedance.type,
  selectReportExceedanceMin: (state) => state.exceedance.min,
  selectReportExceedanceMax: (state) => state.exceedance.max,
  selectReportExceedanceDone: (state) => state.exceedance.done,
  selectReportExceedanceConfig: (state) => state.exceedance.config,
  selectReportExceedanceConfigShouldFetch: (state) =>
    state.exceedance.shouldFetch,
  selectReportExceedanceConfigJson: createSelector(
    "selectReportExceedanceConfig",
    "selectProjectAll",
    "selectStoretData",
    (reportExceedanceConfig, projectAll, storetData) => {
      let data = [];
      if (reportExceedanceConfig.length) {
        reportExceedanceConfig.forEach((x) => {
          data.push(JSON.parse(x.configuration));
        });
        data.map((x) => {
          x.project_name = projectAll.find(
            (y) => y.site_code === x.project_code
          ).site_name;
          x.storet_name = storetData.find(
            (y) => y.storet_num === x.storet_num
          ).short_name;
        });
      }

      return data;
    }
  ),
  reactReportExceedanceConfigFetch: createSelector(
    "selectReportExceedanceConfigShouldFetch",
    (shouldFetch) => {
      if (shouldFetch)
        return {
          actionCreator: "doReportExceedanceGetConfig",
        };
    }
  ),
};
