import React, { useState, useEffect } from "react";
import { connect } from "redux-bundler-react";
import Link from "../../misc/link";
import "./customCard.css";
import FileCard from "./FileCard";
import { sortBy } from "lodash";

function CardTasks(props) {
  const [fileCount, setFileCount] = useState(0);
  const [recent, setRecent] = useState([]);
  const {
    doDocumentFetch,
    documentItems,
    documentIsFetching,
    doDocumentDownload,
  } = props;

  useEffect(() => {
    if (props.location == null) return;
    setFileCount(0);
    doDocumentFetch(props.location.location_code);
  }, [props.location]);

  useEffect(() => {
    if (!documentIsFetching) {
      setFileCount(documentItems.length);
    }
  }, [documentIsFetching, props.location]);

  useEffect(() => {
    let sorted = sortBy(documentItems, "date_uploaded").slice(0, 3);
    setRecent(sorted);
  }, [documentItems]);

  const iconDict = {
    "text/csv": "equalizer",
    "image/jpeg": "image",
    "application/pdf": "pdf",
  };

  const _getType = (type) => iconDict[type];

  return !props.location || !props.project || documentIsFetching ? (
    <div className="custom">
      <div className="card-body">
        <div style={{ textAlign: "center" }}>
          <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
        </div>
      </div>
    </div>
  ) : (
    <div className="custom">
      <a
        style={{
          textDecoration: "none",
          cursor: "pointer",
          height: "100%",
        }}
        href={`/projects/${props.project.site_code}/locations/${props.location.location_code}/documents`}>
        <div className="card-body h-100">
          <div className="custom-body">
            <div>
              <h4
                className="card-title border-bottom"
                style={{ color: "#3c4b64" }}>
                Documents
              </h4>
              <h6 className="card-subtitle mb-4 text-muted">{`Recent Uploads`}</h6>
              {/* <p className="card-text">
              <strong>Files Uploaded: </strong>
              {!fileCount ? "0" : fileCount}
            </p> */}
              <div style={{ display: "flex", flexDirection: "column" }}>
                {recent &&
                  recent.map((doc) => {
                    return (
                      <div className="mb-2" key={doc.name}>
                        <FileCard
                          type={_getType(doc.mime_type)}
                          name={doc.name}
                          onClick={() => doDocumentDownload(doc)}
                        />
                      </div>
                    );
                  })}
              </div>
              {/* <Link style={{ fontWeight: 'bold', paddingBottom: 8 }}
              to={`/projects/${props.project.site_code}/locations/${props.location.location_code}/photos`}
            >
              <p className="card-text">Go To Photos</p>
            </Link> */}
              {/* <Link style={{ fontWeight: 'bold', paddingBottom: 8 }}
              to={`/projects/${props.project.site_code}/locations/${props.location.location_code}/upload`}
            >
              <p className="card-text">Go To Bulk Data Upload</p>
            </Link> */}
            </div>
            <Link
              style={{ fontWeight: "bold" }}
              to={`/projects/${props.project.site_code}/locations/${props.location.location_code}/documents`}>
              <p className="card-text">Go To Documents</p>
            </Link>
          </div>
        </div>
      </a>
    </div>
  );
}

export default connect(
  "doDocumentFetch",
  "selectDocumentItems",
  "selectDocumentIsFetching",
  "doDocumentDownload",
  CardTasks
);
