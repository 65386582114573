import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import { CircularProgress } from "@material-ui/core";

import Images from "./ImageList";

const Index = connect(
  "selectRouteParams",
  "doDocumentFetch",
  "selectDocumentIsFetching",
  ({ routeParams, doDocumentFetch, documentIsFetching }) => {
    useEffect(() => {
      if (routeParams) {
        doDocumentFetch(routeParams.id);
      }
    }, [routeParams]);

    return (
      <div>
        <div
          style={{
            padding: "0 64px 32px",
            height: "100%",
            width: "100%",
            minHeight: "calc(100vh - 106px)",
          }}
        >
          <div>
            <h1>Photo Album</h1>
            {documentIsFetching ? <CircularProgress /> : null}
            <Images />
          </div>
        </div>
      </div>
    );
  }
);
export default Index;
// InputFileReader.defaultProps = {
//   accept: "image/*",
//   capture: true,
//   multiple: false,
// };
// InputFileReader.propTypes = {
//   accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
//   capture: PropTypes.bool,
//   multiple: PropTypes.bool,
// };
// export default connect("selectRouteParams", "selectLocation", "doTaskUpload", PhotoUpload);
