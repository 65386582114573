import { useEffect, useReducer } from "react";
import { connect } from "redux-bundler-react";
import ReactSelect from "react-select";
import {
  ModalContent,
  ModalHeader,
  ModalFooter,
} from "../../../app-components/modal";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const EditMinorBasinsModal = (props) => {
  const {
    data,
    edit,
    minor_code,
    majorBasinAll,
    doMinorBasinPut,
    doMinorBasinPost,
    doMinorBasinDelete,
    minorBasinIsSaving,
    minorBasinIsDeleting,
    minorBasinError: error,
    doModalClose,
  } = props;
  let initialState = {};

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (edit) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: {
          ...data,
        },
      });
    } else {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: {
          minor_code,
        },
      });
    }
  }, [edit, data]);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSelect = (field, payload) => {
    dispatch({
      type: "UPDATE_INPUT",
      field,
      payload: payload.value,
    });
  };

  let currentMajorObj = majorBasinAll.filter(
    (mb) => mb.major_code === state.major_code
  );

  return (
    <ModalContent size="md" error={error}>
      <ModalHeader
        title={edit ? "Edit Minor Basins Info" : "Add Minor Basin"}
      />
      <section className="modal-body">
        <div className="container-fluid">
          <form>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="major_code">Major Basin</label>
                <ReactSelect
                  name="major_code"
                  value={{
                    label:
                      currentMajorObj &&
                      currentMajorObj.length > 0 &&
                      currentMajorObj[0].major_name,
                    value:
                      currentMajorObj &&
                      currentMajorObj.length > 0 &&
                      currentMajorObj[0].major_code,
                  }}
                  options={majorBasinAll.map((m) => ({
                    value: m.major_code,
                    label: m.major_name,
                  }))}
                  onChange={(obj) => handleSelect("major_code", obj)}
                  menuPosition="fixed"
                />
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="minor_name">Minor Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="minor_name"
                  onChange={handleChange}
                  placeholder="i.e. DF"
                  value={state.minor_name}
                />
              </div>
            </div>
          </form>
        </div>
      </section>
      <ModalFooter
        onSave={() => (edit ? doMinorBasinPut(state) : doMinorBasinPost(state))}
        customClosingLogic
        onDelete={edit ? () => doMinorBasinDelete(state) : null}
        showCancelButton
        onCancel={doModalClose}
        saveIsDisabled={minorBasinIsSaving}
        deleteIsDisabled={minorBasinIsDeleting}
        saveText={
          minorBasinIsSaving ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Save"
          )
        }
        deleteText={
          minorBasinIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "doMinorBasinPost",
  "doMinorBasinPut",
  "doMinorBasinDelete",
  "selectMinorBasinIsSaving",
  "selectMinorBasinIsDeleting",
  "selectMinorBasinError",
  "doModalClose",
  "selectMajorBasinAll",
  EditMinorBasinsModal
);
