import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import withError from "../../utils/catchError";
import TrueLineChart from "../charting/TrueLineChart";
import "../location/cards/customCard.css";

const IndicesPage = (props) => {
  const {
    sortedIndices,
    doLocationIndicesFetch,
    locationIndices,
    doSetGraphData,
    ctsiData: data,
    uglData: uglArr,
  } = props;

  useEffect(() => {
    doLocationIndicesFetch();
    doSetGraphData();
  }, []);

  const _getElement = (i) => {
    switch (i) {
      case 0:
        return { element: "Nitrogen", bounds: [0.75, 1.9] };
      case 2:
        return { element: "Phosphorous", bounds: [27, 84] };
      case 1:
      default:
        return { element: "Chlorophyll", bounds: [4.7, 14] };
    }
  };

  return !sortedIndices || !locationIndices.length ? (
    <div style={{ textAlign: "center" }}>
      <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
    </div>
  ) : (
    <div
      style={{
        padding: "0 64px 32px",
        height: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      <h3
        className="card-title bold"
        style={{ display: "inline-flex", alignItems: "center" }}
      >
        {" "}
        Indices at {locationIndices[0].loc_id}
      </h3>
      <div className="custom mb-4">
        <div className="card-body">
          <div className="">
            <TrueLineChart data={data} title="Carlson TSI" />
          </div>
        </div>
      </div>
      <div>
        {uglArr &&
          uglArr.length &&
          uglArr.map((arr, i) => {
            if (arr && arr.length) {
              return (
                <div className="custom mb-4" key={i}>
                  <div className="card-body">
                    <TrueLineChart
                      data={arr}
                      value={_getElement(i)}
                      title={`Vollenweider ${_getElement(i).element}`}
                    />
                  </div>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};

export default connect(
  "selectRouteParams",
  "selectSortedIndices",
  "doLocationIndicesFetch",
  "selectLocationIndices",
  "doSetGraphData",
  "selectCtsiData",
  "selectUglData",
  withError(IndicesPage)
);
