import { createSelector } from "redux-bundler";

export default {
  name: "projectLocations",
  getReducer: () => {
    const initialData = {
      locations: [],
      locationsByProject: [],
      shouldFetch: false,
      isFetching: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "PROJECT_LOCATION_SHOULD_FETCH":
        case "PROJECT_LOCATION_FETCH_START":
        case "PROJECT_LOCATION_FETCH_FINISH":
          return Object.assign({}, state, payload);
        case "LOCATION_SAVE_FINISHED":
        case "LOCATION_DELETE_FINISHED":
          return { ...state, shouldFetch: true };
        default:
          return state;
      }
    };
  },
  doProjectLocationsShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "PROJECT_LOCATION_SHOULD_FETCH",
        payload: { shouldFetch: true },
      });
    },
  doProjectLocationsFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      let id = store.selectRouteParams();
      if (id.project) {
        dispatch({
          type: "PROJECT_LOCATION_FETCH_START",
          payload: {
            shouldFetch: false,
            isFetching: true,
          },
        });
        apiFetch(`/locations/projects/${id.project}?q={}&limit=10000`)
          .then((r) => r.json())
          .then((j) =>
            dispatch({
              type: "PROJECT_LOCATION_FETCH_FINISH",
              payload: {
                locations: j.items,
                isFetching: false,
                shouldFetch: false,
              },
            })
          )
          .catch((e) => {
            console.log(e);
          });
      }
    },

  /** This is for the dropdown in the project modal, separate from doProjectLocationFetch */
  doFetchLocationByProject:
    (siteCode) =>
    ({ dispatch, apiFetch }) => {
      dispatch({
        type: "PROJECT_LOCATION_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });
      apiFetch(`/locations/projects/${siteCode}?q={}&limit=10000`)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "PROJECT_LOCATION_FETCH_FINISH",
            payload: {
              locationsByProject: j.items,
              isFetching: false,
              shouldFetch: false,
            },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },

  selectLocationsByProject: (state) =>
    state.projectLocations.locationsByProject,
  selectProjectLocations: (store) => store.projectLocations.locations,
  selectProjectLocationsIsFetching: (store) =>
    store.projectLocations.isFetching,
  selectProjectLocationsShouldFetch: (store) =>
    store.projectLocations.shouldFetch,

  reactProjectLocationShouldFetch: createSelector(
    "selectProjectLocationsIsFetching",
    "selectProjectLocationsShouldFetch",
    "selectRouteParams",
    "selectProjectLocations",
    (isFetching, shouldFetch, routeParams, projectLocations) => {
      // never fetch if another fetch already in progres
      if (isFetching) return null;
      if (
        shouldFetch ||
        (projectLocations &&
          routeParams.project &&
          projectLocations.length > 0 &&
          routeParams.project != projectLocations[0].site_code)
      ) {
        return {
          actionCreator: "doProjectLocationsFetch",
        };
      }
    }
  ),
};
