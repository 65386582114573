import React, { useReducer, useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import {
  ModalFooter,
  ModalContent,
  ModalHeader,
} from "../../app-components/modal";
import CustomAvatar from "./CustomAvatar";
import { stringAvatar } from "./helpers";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "UPDATE_PARTICIPANTS":
      return { ...state, [action.field]: action.payload, name: "" };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const FieldTripModal = ({
  data,
  edit,
  doModalClose,
  field_trip_code,
  doFieldTripPost,
  doFieldTripPut,
  doFieldTripDelete,
  fieldTripIsDeleting,
  fieldTripIsSaving,
  fieldTripError: error,
  routeParams,
}) => {
  const initialState = {
    ...data,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (edit) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: {
          ...data,
          participants: data.participants ? JSON.parse(data.participants) : [],
        },
      });
    } else {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: {
          field_trip_code,
          participants: [],
        },
      });
    }
  }, [edit, data]);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const _formatDate = (date) =>
    date.includes("Z")
      ? date
      : date.split(":").length === 3
      ? `${date}Z`
      : `${date}:00Z`;

  const _doSave = () => {
    state.start_date = _formatDate(state.start_date);
    state.end_date = _formatDate(state.end_date);
    state.participants = JSON.stringify(state.participants);
    state.site_code = parseInt(routeParams.project);
    if (edit) {
      doFieldTripPut(state);
    } else {
      doFieldTripPost(state);
    }
  };

  const _doDelete = () => {
    doFieldTripDelete(state);
  };
  return (
    <ModalContent size="md" error={error}>
      <ModalHeader
        title={edit ? "Edit Field Trip" : "Add Field Trip"}
        onClose={doModalClose}
      />
      <section className="modal-body">
        <div className="container-fluid">
          <div className="d-flex flex-column mb-2">
            <label htmlFor="field_trip_id">Field Trip ID</label>
            <input
              type="text"
              className="form-control"
              id="field_trip_id"
              name="field_trip_id"
              onChange={handleChange}
              value={state.field_trip_id}
            />
          </div>
          <div className="d-flex flex-column mb-2">
            <label htmlFor="start_date">Start Date</label>
            <input
              className="custom-input"
              type="datetime-local"
              placeholder="Start Date"
              value={
                state["start_date"] && state["start_date"].includes("Z")
                  ? state["start_date"].split("Z")[0]
                  : state["start_date"]
              }
              name={"start_date"}
              id={"start_date"}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex flex-column mb-2">
            <label htmlFor="end_date">End Date</label>
            <input
              className="custom-input"
              type="datetime-local"
              placeholder="Sample Date"
              value={
                state["end_date"] && state["end_date"].includes("Z")
                  ? state["end_date"].split("Z")[0]
                  : state["end_date"]
              }
              name={"end_date"}
              id={"end_date"}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex flex-column mb-2">
            <label htmlFor="event_type">Event Type</label>
            <input
              type="text"
              className="form-control"
              id="event_type"
              name="event_type"
              onChange={handleChange}
              value={state.event_type}
            />
          </div>
          <div style={{ display: "flex", width: "100%", marginTop: 16 }}>
            <FormControl
              sx={{ m: 0, width: "25ch" }}
              style={{ width: "100%" }}
              variant="outlined"
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{ fontSize: 14 }}
              >
                Participants
              </InputLabel>
              <OutlinedInput
                value={state.name}
                onChange={handleChange}
                name="name"
                sx={{ p: 1 }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        dispatch({
                          type: "UPDATE_PARTICIPANTS",
                          field: "participants",
                          payload: [
                            ...state.participants,
                            state.name.toUpperCase(),
                          ],
                        });
                      }}
                      edge="end"
                    >
                      <i className="mdi mdi-checkbox-marked-circle mdi-18px text-success" />
                    </IconButton>
                  </InputAdornment>
                }
                label="Participants"
              />
              <small className="text-muted my-1">
                Please check the green button after entering each participant!
              </small>
              <Stack
                className={
                  state.participants &&
                  typeof state.participants !== "string" &&
                  state.participants.length > 0 &&
                  "m-2"
                }
                direction={"row"}
                spacing={1}
              >
                {state.participants &&
                  typeof state.participants !== "string" &&
                  state.participants.length > 0 &&
                  state.participants.map((usr, index) => (
                    <CustomAvatar
                      size={30}
                      key={usr}
                      {...stringAvatar(usr, 30)}
                      onDelete={(e) => {
                        let arr = state.participants;
                        arr.splice(index, 1);
                        dispatch({
                          type: "UPDATE_PARTICIPANTS",
                          field: "participants",
                          payload: [...arr],
                        });
                      }}
                    />
                  ))}
              </Stack>
            </FormControl>
          </div>
          <div className="d-flex flex-column mb-2">
            <label htmlFor="notes">Notes</label>
            <textarea
              className="custom-input p-2"
              value={state.notes}
              name={"notes"}
              id={"notes"}
              onChange={handleChange}
            />
          </div>
        </div>
      </section>
      <ModalFooter
        onSave={_doSave}
        customClosingLogic
        onDelete={edit ? _doDelete : null}
        showCancelButton
        onCancel={doModalClose}
        saveIsDisabled={fieldTripIsSaving}
        deleteIsDisabled={fieldTripIsDeleting}
        saveText={
          fieldTripIsSaving ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Save"
          )
        }
        deleteText={
          fieldTripIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "doFieldTripPost",
  "doFieldTripPut",
  "doFieldTripDelete",
  "doModalClose",
  "selectRouteParams",
  "selectFieldTripIsDeleting",
  "selectFieldTripIsSaving",
  "selectFieldTripError",
  FieldTripModal
);
