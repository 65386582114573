import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "state",
  getReducer: () => {
    const initialData = {
      data: [],
      shouldFetch: false,
      isFetching: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "AUTH_VERIFY_TOKEN":
          return { ...state, shouldFetch: true };
        case "STATE_FETCH_START":
        case "STATE_FETCH_FINISH":
        case "STATE_SHOULD_FETCH":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doStateShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "STATE_SHOULD_FETCH",
        payload: {
          shouldFetch: true,
        },
      });
    },

  doStateFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "STATE_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      const url = ordsifyUrlBuilder("/states/", [], 70);
      //const url = "https://wq.sec.usace.army.mil/ords/cwms_wq/states/?limit=70";
      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "STATE_FETCH_FINISH",
            payload: { isFetching: false, data: j.items },
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

  selectStateRaw: (store) => store.state,
  selectStateAll: (store) => store.state.data,

  reactStateShouldFetch: (store) => {
    if (store.state.shouldFetch)
      return {
        actionCreator: "doStateFetch",
      };
  },
};
