const bioresultsCsv = [
  [
    "LOCATION_ID",
    "SAMPLE_TYPE",
    "TAXACODE",
    "COUNT",
    "BIOVOLUME",
    "DENSITY",
    "LIFE_STAGE",
    "STORAGE_LOC",
    "STORAGE_METH",
    "LAB_ID",
    "DATE_ANALYZED",
    "TAXONOMIST_CODE",
    "DCL_FLAG",
    "EXPORT_FLAG",
    "COUNT_UNITS",
    "DENSITY_UNITS",
    "BV_UNITS",
    "BIOMASS",
    "BM_UNITS",
    "REP_NUM",
    "REFERENCE",
    "ANALYSIS_METHOD",
  ],
  [
    "1ACS0001",
    "BQL",
    "ACARI",
    "3",
    "0",
    "0",
    "1",
    "",
    "",
    "ORH",
    "",
    "",
    "",
    "",
    "count",
    "",
    "",
    "",
    "0",
    "",
    "",
    "",
    "",
  ],
];

export default bioresultsCsv;
