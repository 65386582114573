import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import { TableContainer } from "../../../app-components/page-content";
import FilterSidebar from "../../dataExport/FilterSidebar";
import TableDataFlag from "./TableDataFlag";
import dataFlagConfig from "./dataflag.config.json";

const PageDataFlag = ({ routeParams, ...props }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      {" "}
      <FilterSidebar config={dataFlagConfig} {...props} />
      <TableContainer>
        <TableDataFlag />
      </TableContainer>
    </div>
  );
};
export default connect(
  "selectRouteParams",
  ...dataFlagConfig.selectors,
  ...dataFlagConfig.fetchFns,
  PageDataFlag
);
