import { createSelector } from "redux-bundler";
import { stringifyDate, ordsifyUrlBuilderNoSlash } from "../utils/ordsify";

export default {
  name: "summary",
  // reducers
  getReducer: () => {
    const initialData = {
      data: [],
      config: [],
      shouldFetch: false,
      done: false,
    };
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "REPORT_SUMMARY_STARTED":
        case "REPORT_SUMMARY_FINISHED":
        case "REPORT_SUMMARY_CONFIG_STARTED":
        case "REPORT_SUMMARY_CONFIG_FINISHED":
        case "REPORT_SUMMARY_CONFIG_SHOULD_FETCH":
        case "REPORT_SUMMARY_CONFIG_FETCH_STARTED":
        case "REPORT_SUMMARY_CONFIG_FETCH_FINISHED":
        case "REPORT_SUMMARY_LOAD_STARTED":
        case "REPORT_SUMMARY_LOAD_FINISHED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },
  // action creators
  doReportSummaryGenerate:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      const { date } = state;
      let parameters = state.parameters && state.parameters.map((p) => p.value);
      let locations = Object.keys(state).map((key) => {
        if (parseInt(key) && state[key]) {
          /** key is site_code containing arrays of locations */
          return state[key].map((location) => location.value);
        }
      });
      locations = locations.flat(1).filter((item) => item !== undefined);
      dispatch({ type: "REPORT_SUMMARY_STARTED", payload: { done: false } });
      // const locationArray = store.selectLocationsActiveArray()
      // const date = store.selectSampleDateActiveMin()
      // const storetArray = store.selectStoretActiveArray()
      const url = ordsifyUrlBuilderNoSlash(
        `/reports/summary/${stringifyDate(date)}`,
        [
          {
            keyword: "location_code",
            items: locations ? locations : "", // if no location_codes passed, fetch all
          },
          {
            keyword: "storet_num",
            items: parameters ? parameters : "", // if no location_codes passed, fetch all
          },
        ],
        500
      );

      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "REPORT_SUMMARY_FINISHED",
            payload: {
              data: j.items,
              done: true,
            },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },
  doReportSummaryPostConfig:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({ type: "REPORT_SUMMARY_CONFIG_STARTED" });
      const id = Math.floor(Math.random() * 100000);
      const cacUid = parseInt(store.selectAuthTokenPayload().sub);
      const locations = store.selectLocationsActive();
      const storets = store.selectStoretsActive();
      let storetNames = [];
      let locationNames = [];
      if (storets[0].hasOwnProperty("short_name")) {
        storetNames = [
          ...new Set(storets.map((item) => item.short_name)),
        ].toString();
      } else {
        storetNames = [
          ...new Set(storets.map((item) => item.label)),
        ].toString();
      }
      if (locations[0].hasOwnProperty("loc_id")) {
        locationNames = [
          ...new Set(locations.map((item) => item.loc_id)),
        ].toString();
      } else {
        locationNames = [
          ...new Set(locations.map((item) => item.label)),
        ].toString();
      }
      const data = {
        project_code: store.selectProjectActive(),
        location_codes: store.selectLocationsActiveArray().toString(),
        location_ids: locationNames,
        min_date: stringifyDate(store.selectSampleDateActiveMin()),
        storet_nums: store.selectStoretActiveArray().toString(),
        storet_names: storetNames,
        cac_uid: cacUid,
        id: id,
      };
      apiFetch("/reports/config", {
        method: "POST",
        headers: {
          Cac_uid: cacUid,
          Id: id,
          Report_type: "summary",
        },
        body: JSON.stringify(data),
      }).then((r) => {
        dispatch({ type: "REPORT_SUMMARY_CONFIG_FINISHED" });
      });
    },
  doReportSummaryShouldFetchConfig:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "REPORT_SUMMARY_CONFIG_SHOULD_FETCH",
        payload: {
          shouldFetch: true,
        },
      });
    },
  doReportSummaryGetConfig:
    () =>
    ({ dispatch, store, apiFetch }) => {
      const cacUid = parseInt(store.selectAuthTokenPayload().sub);
      dispatch({
        type: "REPORT_SUMMARY_CONFIG_FETCH_STARTED",
        payload: { shouldFetch: false },
      });
      apiFetch(`/reports/config/${cacUid}?q={"report_type":"summary"}`)
        .then((response) => response.json())
        .then((r) =>
          dispatch({
            type: "REPORT_SUMMARY_CONFIG_FETCH_FINISHED",
            payload: {
              config: r.items,
            },
          })
        );
      store.doReportSetFinished(false);
    },
  doReportSummaryLoadConfig:
    (rowData) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "REPORT_SUMMARY_LOAD_STARTED",
      });
      store.doReportSetFinished(true);
      // get project data
      // @TODO I think I need a project lookup for project code
      // store.doLocationFetchByProject(rowData.project_code)
      // Need to wait for locations to get back to get activeLocationCode
      const locationCodes = rowData.location_codes.split(",");
      const storetData = store.selectStoretData();
      const storetCodes = rowData.storet_nums.split(",");
      let activeStoret = [];
      storetCodes.forEach((x) => {
        activeStoret.push(storetData.find((y) => x === y.storet_num));
      });
      // FETCH SAMPLE
      // I dont even know if we need this anymroe
      // store.doSampleFetch(locationCodes)
      // PROJECT ACTIVE
      store.doProjectActive(rowData.project_code);
      // STORET ACTIVE
      store.doStoretsActive(activeStoret);
      // SAMPLES ACTIVE
      store.doSampleDateActiveMin(rowData.min_date);
      // FETCH LOCATIONS BY LOCATION CODE
      // back to statistics bundle?
      store.doReportLocationFetch(locationCodes);
      dispatch({
        type: "REPORT_SUMMARY_LOAD_FINISHED",
      });
    },
  selectReportSummaryData: (state) => state.summary.data,
  selectReportSummaryDone: (state) => state.summary.done,
  selectReportSummaryConfig: (state) => state.summary.config,
  selectReportSummaryConfigShouldFetch: (state) => state.summary.shouldFetch,
  selectReportSummaryConfigJson: createSelector(
    "selectReportSummaryConfig",
    "selectProjectAll",
    "selectStoretData",
    (reportSummaryConfig, projectAll, storetData) => {
      let data = [];
      if (reportSummaryConfig.length) {
        reportSummaryConfig.forEach((x) => {
          data.push(JSON.parse(x.configuration));
        });
        data.map((x) => {
          x.project_name = projectAll.find(
            (y) => y.site_code === x.project_code
          ).site_name;
        });
      }

      return data;
    }
  ),
  reactReportSummaryConfigFetch: createSelector(
    "selectReportSummaryConfigShouldFetch",
    (shouldFetch) => {
      if (shouldFetch)
        return {
          actionCreator: "doReportSummaryGetConfig",
        };
    }
  ),
};
