import classNames from "classnames";
import React, { useState } from "react";
import { connect } from "redux-bundler-react";

const NewCard = ({
  doUpdateUrl,
  site_code,
  header,
  title,
  subtitle,
  image,
  profileLoc,
}) => {
  const [openMenu, setOpenMenu] = useState(false);

  const dropdownMenuClass = classNames({
    "dropdown-menu": true,
    show: openMenu,
    rounded: true,
  });
  return (
    <div
      className="card text-dark card-has-bg click-col border-0"
      style={{
        backgroundImage: `url('${image}')`,
        position: "relative",
      }}>
      {profileLoc && (
        <div style={{ position: "absolute", top: 12, right: 12, zIndex: 999 }}>
          <div className="dropdown">
            <button
              className="btn btn-ghost-dark"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded={openMenu}
              onClick={(e) => setOpenMenu(!openMenu)}>
              <i className="mdi mdi-dots-vertical mdi-18px" />
            </button>
            <div
              className={dropdownMenuClass}
              aria-labelledby="dropdownMenuButton"
              style={{ position: "absolute", left: -116 }}>
              <button
                className="dropdown-item"
                onClick={(e) => {
                  e.preventDefault();
                  doUpdateUrl(
                    `/projects/${site_code}/locations/${profileLoc}#upload`
                  );
                }}>
                LRH Lake Quality
              </button>
            </div>
          </div>
        </div>
      )}
      <a
        onClick={() => doUpdateUrl(`/projects/${site_code}`)}
        style={{ textDecoration: "none" }}>
        <div className="card-img-overlay d-flex flex-column">
          <div className="card-body">
            <small className="card-meta mb-2">
              <b>{header}</b>
            </small>
            <h4 className="card-title mt-0 mb-2">{title}</h4>
            <h5 className="m-0">
              <small>{subtitle}</small>
            </h5>
          </div>
          <div className="w-100">
            <div className="media">
              <div className="d-flex w-100 justify-content-end">
                <h6 className="my-0 text-white d-block">
                  <i className="mdi mdi-chevron-right mdi-36px " />
                </h6>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};
export default connect("doUpdateUrl", "doModalOpen", NewCard);
