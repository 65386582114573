import React, { useEffect, useState } from "react";
import "./newcards.css";
import NewCard from "./NewCard";
import { find, sortBy } from "lodash";
import { connect } from "redux-bundler-react";
const NewCards = ({
  projectAll: projects,
  officeData,
  userOfficesOptions,
  userPrimaryOffice,
  userData,
}) => {
  const [flag, setFlag] = useState([]);
  const [page, setPage] = useState(1);
  const [pageArray, setPageArray] = useState([]);
  const [officeList, setOfficeList] = useState([]);

  const handleClick = (i) => {
    setPage(1);
    let items = [...flag];
    items[i] = !!items[i] ? 0 : 1;
    setFlag(items);
    _updateOfficeList(items);
  };

  const _createSubArrays = (arr, subArrSize) => {
    let subArray = [];
    let finalArray = [];
    arr.forEach((item, idx) => {
      if (subArray.length < subArrSize && arr.length - 1 !== idx)
        subArray.push(item);
      else {
        if (arr.length - 1 === idx) {
          if (subArray.length > 0) {
            if (subArray.length === subArrSize) finalArray.push(subArray);
            else finalArray.push([...subArray, item]);
          } else finalArray.push([item]);
        } else {
          finalArray.push(subArray);
          subArray = [item];
        }
      }
    });
    return finalArray;
  };

  const _updateOfficeList = (flag) => {
    let temp = [],
      activeList = [];
    temp = officeData.map((x) => x.office_id);
    let buttonList = [...new Set(temp)].sort();
    buttonList.map((b, i) => {
      if (flag[i]) activeList.push(b);
    });
    if (activeList.length === 0) activeList = buttonList;
    buttonList.sort();
    setOfficeList(buttonList);
    _updatePageArray(activeList);
  };

  const _updatePageArray = (activeList) => {
    let sortedProjects = sortBy(projects, (obj) => obj.site_name.toUpperCase());
    let projectsArray = activeList
      ? sortedProjects.filter((p) => activeList.indexOf(p.office_id) !== -1)
      : sortedProjects;
    projectsArray = sortBy(projectsArray, (obj) =>
      obj["site_name"].toUpperCase()
    );
    let rowArray = _createSubArrays(projectsArray, 4); //creates array of rows (depth 1)
    let totalArray = _createSubArrays(rowArray, 5); // creates array of pages (depth 2)
    setPageArray(totalArray);
  };
  useEffect(() => {
    // let tempFlag = [];
    let ofcIds = officeData.map((x) => x.office_id);
    let tempArr = ofcIds.map((o) =>
      userPrimaryOffice
        ? o === userPrimaryOffice
          ? 1
          : 0
        : isUserOffice(o)
        ? 1
        : 0
    );
    setFlag(tempArr);
    _updateOfficeList(tempArr);
  }, [officeData, userData]);

  const isUserOffice = (office) =>
    find(userOfficesOptions, (o) => o.value === office);

  return (
    <div className="cards-container">
      <div className="row">
        <div className="col text-center mb-4">
          <h2 className="display-5 font-weight-bolder text-white">
            All Projects
          </h2>
          <p className="lead text-white">
            Sort projects by office using the buttons below.
          </p>
        </div>
      </div>
      <div className="btn-group w-100 mb-4" role="group">
        {officeList.map((btn, i) => {
          return (
            <button
              type="button"
              className={
                !isUserOffice(btn)
                  ? "btn btn-outline-secondary w-100 pe-none"
                  : flag[i]
                  ? "btn btn-light w-100"
                  : "btn btn-outline-light w-100"
              }
              onClick={() => isUserOffice(btn) && handleClick(i)}
              key={btn}
              disabled={!isUserOffice(btn)}>
              {userPrimaryOffice && userPrimaryOffice === btn ? (
                <>
                  <i className="mdi mdi-star mdi-14px" /> {btn}
                </>
              ) : (
                btn
              )}
            </button>
          );
        })}
      </div>
      {pageArray.length > 0 &&
        pageArray[page - 1].map((row, idx) => {
          return (
            <div className="row" key={idx}>
              {row.map((card) => {
                return (
                  <div
                    className="col-sm-12 col-md-6 col-lg-3 mb-4"
                    key={card.site_code}>
                    <NewCard
                      header={card.office_id}
                      title={card.site_name}
                      subtitle={card.site_id}
                      image={card.url_cover_photo}
                      site_code={card.site_code}
                      profileLoc={card.profile_loc}
                      disabled={
                        !find(
                          userOfficesOptions,
                          (obj) => obj.value === card.office_id
                        )
                      }
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      {pageArray.length > 1 && (
        <div className="d-flex w-100 pb-4 align-items-center justify-content-center">
          <button
            onClick={() => page !== 1 && setPage(page - 1)}
            className={`btn btn-ghost px-0 mx-3`}
            tabIndex={page === 1 ? -1 : 0}
            style={{
              boxShadow: page === 1 && "none",
              cursor: page === 1 && "default",
            }}>
            {page !== 1 ? (
              <i className="mdi mdi-arrow-left mdi-24px" />
            ) : (
              <div style={{ width: 24 }} />
            )}
          </button>
          <h4 style={{ fontWeight: 800, margin: 0 }}>
            {page}/{pageArray.length}
          </h4>
          <button
            onClick={() => page !== pageArray.length && setPage(page + 1)}
            className={`btn btn-ghost  px-0 mx-3`}
            tabIndex={page === pageArray.length ? -1 : 0}
            style={{
              boxShadow: page === pageArray.length && "none",
              cursor: page === pageArray.length && "default",
            }}>
            {page !== pageArray.length ? (
              <i className="mdi mdi-arrow-right mdi-24px" />
            ) : (
              <div style={{ width: 24 }} />
            )}
          </button>
        </div>
      )}
    </div>
  );
};
export default connect("selectUserData", "selectUserPrimaryOffice", NewCards);
