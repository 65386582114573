import { sortBy } from "lodash";
import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "dataFlag",
  getReducer: () => {
    const initialState = {
      data: [],
      isFetching: false,
      shouldFetch: false,
      isPosting: false,
      isDeleting: false,
    };

    return (state = initialState, { payload, type }) => {
      switch (type) {
        case "DATA_FLAG_FETCH_STARTED":
        case "DATA_FLAG_FETCH_FINISHED":
        case "DATA_FLAG_FETCH_ERROR":
        case "DATA_FLAG_SHOULD_FETCH":
        case "DATA_FLAG_DELETE_STARTED":
        case "DATA_FLAG_DELETE_FINISHED":
        case "DATA_FLAG_DELETE_ERROR":
        case "DATA_FLAG_SAVE_STARTED":
        case "DATA_FLAG_SAVE_FINISHED":
        case "DATA_FLAG_SAVE_ERROR":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doDataFlagFetch:
    (state) =>
    ({ dispatch, apiFetch }) => {
      dispatch({
        type: "DATA_FLAG_FETCH_STARTED",
        payload: { isFetching: true },
      });
      let url = "/data_flags/?limit=10000";
      if (state) {
        let office =
          state && state.office && state.office.map((off) => off.value);
        url = ordsifyUrlBuilder(
          "/data_flags/",
          [
            {
              keyword: "data_qualifier_flag",
              items: state.data_qualifier_flag
                ? [state.data_qualifier_flag]
                : [],
            },
            {
              keyword: "associated_lab",
              items: state.associated_lab ? [state.associated_lab] : [],
            },
            {
              keyword: "office_id",
              items: office ? office : [],
            },
          ],
          10000
        );
      }
      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "DATA_FLAG_FETCH_FINISHED",
            payload: { isFetching: false, data: j.items },
          });
        })
        .catch((err) => {
          dispatch({
            type: "DATA_FLAG_FETCH_ERROR",
            payload: { isFetching: false, error: err },
          });
        });
    },

  doPostDataFlag:
    (data, cb) =>
    ({ dispatch, store }) => {
      let { doActionPostData, doDataFlagFetch, selectApiRoot, doModalClose } =
        store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "DATA_FLAG_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPostData(
        `${apiRoot}/data_flags/`,
        data,
        () => {
          dispatch({
            type: "DATA_FLAG_SAVE_FINISHED",
            payload: { isSaving: false },
          });
          cb && cb();
          doDataFlagFetch();
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "DATA_FLAG_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },
  doPutDataFlag:
    (data, cb) =>
    ({ dispatch, store }) => {
      let { doActionPutData, doDataFlagFetch, selectApiRoot, doModalClose } =
        store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "DATA_FLAG_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPutData(
        `${apiRoot}/data_flags/${data.flag_code}`,
        data,
        () => {
          dispatch({
            type: "DATA_FLAG_SAVE_FINISHED",
            payload: { isSaving: false, shouldFetch: true },
          });
          cb && cb();
          doDataFlagFetch();
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "DATA_FLAG_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },
  doDeleteDataFlag:
    (code, cb) =>
    ({ dispatch, store }) => {
      let { doActionDeleteData, doDataFlagFetch, selectApiRoot, doModalClose } =
        store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "DATA_FLAG_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      doActionDeleteData(
        `${apiRoot}/data_flags/${code}`,
        {},
        () => {
          dispatch({
            type: "DATA_FLAG_DELETE_FINISHED",
            payload: { isDeleting: false, shouldFetch: true },
          });
          cb && cb();
          doDataFlagFetch();
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "DATA_FLAG_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },
  selectDataFlagIsFetching: (state) => state.dataFlag.isFetching,
  selectDataFlagIsDeleting: (state) => state.dataFlag.isDeleting,
  selectDataFlagIsSaving: (state) => state.dataFlag.isSaving,
  selectDataFlagError: (state) => state.dataFlag.error,
  selectDataFlagItems: (state) => state.dataFlag.data,
};
