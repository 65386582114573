import React from "react";
import "./pageContent.css";
import { connect } from "redux-bundler-react";

const TableContainer = ({
  children,
  style,
  isLoading,
  fullWidth,
  filterIsExpanded,
}) => {
  return (
    <div
      className={
        !filterIsExpanded
          ? "tableContainer expanded"
          : fullWidth
          ? "tableContainer mw-100"
          : "tableContainer"
      }
      style={style}
    >
      {isLoading ? (
        <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
      ) : (
        children
      )}
    </div>
  );
};

export default connect("selectFilterIsExpanded", TableContainer);
