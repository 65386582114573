import MaterialTable from "material-table";
import React from "react";
import { connect } from "redux-bundler-react";
import { ModalContent, ModalHeader } from "../../app-components/modal";

const AigsParamsModal = (props) => {
  const { aigsParams, doAigsParamsShouldFetch, aigsParamsIsFetching } = props;

  return (
    <ModalContent size="lg">
      <ModalHeader title="AIGS Parameters" />
      <section className="modal-body">
        <div className="container-fluid">
          <MaterialTable
            title=""
            isLoading={aigsParamsIsFetching}
            columns={[
              { title: "Observed Property ID", field: "observed_property_id" },
              {
                title: "Medium",
                field: "medium",
              },
              {
                title: "Result Unit",
                field: "result_unit",
              },
              {
                title: "Storet Num",
                field: "storet_num",
              },
              {
                title: "Taxacode",
                field: "taxacode",
              },
              {
                title: "Office ID",
                field: "office_id",
              },
              {
                title: "CAS Number",
                field: "cas_number",
              },
            ]}
            data={aigsParams}
            options={{
              loadingType: "overlay",
              exportButton: true,
              exportAllData: true,
              sorting: true,
              pageSize: 50,
            }}
            actions={[
              {
                icon: "refresh",
                tooltip: "Refresh table",
                isFreeAction: true,
                onClick: doAigsParamsShouldFetch,
              },
            ]}
          />
        </div>
      </section>
    </ModalContent>
  );
};

export default connect(
  "doAigsParamsShouldFetch",
  "selectAigsParamsIsFetching",
  "selectAigsParams",
  AigsParamsModal
);
