import { useEffect, useReducer, useState } from "react";
import { connect } from "redux-bundler-react";
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "../../../app-components/modal";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const LocationTypeModal = (props) => {
  const {
    type_code,
    edit,
    data,
    doModalClose,
    doLocationTypePost,
    doLocationTypePut,
    doLocationTypeDelete,
    locationTypeIsSaving,
    locationTypeIsDeleting,
    locationTypeError: error,
  } = props;

  const [state, dispatch] = useReducer(reducer, {});

  useEffect(() => {
    if (edit) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: {
          ...data,
        },
      });
    } else {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: {
          type_code,
        },
      });
    }
  }, [edit, data]);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  return (
    <ModalContent size="md" error={error}>
      <ModalHeader title={edit ? "Edit Location Type" : "Add Location Type"} />
      <section className="modal-body">
        <div className="container-fluid">
          <form>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="major_name">Type Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="type_name"
                  onChange={handleChange}
                  placeholder="i.e. Spring"
                  value={state.type_name}
                />
              </div>
            </div>
          </form>
        </div>
      </section>
      <ModalFooter
        onSave={() =>
          edit ? doLocationTypePut(state) : doLocationTypePost(state)
        }
        customClosingLogic
        onDelete={edit ? () => doLocationTypeDelete(state) : null}
        showCancelButton
        onCancel={doModalClose}
        saveIsDisabled={locationTypeIsSaving}
        deleteIsDisabled={locationTypeIsDeleting}
        saveText={
          locationTypeIsSaving ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Save"
          )
        }
        deleteText={
          locationTypeIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "doLocationTypePost",
  "doLocationTypePut",
  "doLocationTypeDelete",
  "selectLocationTypeIsSaving",
  "selectLocationTypeIsDeleting",
  "selectLocationTypeError",
  "doModalClose",
  LocationTypeModal
);
