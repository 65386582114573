import { FormControl } from "@mui/material";
import { sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import Plot from "../../app-components/chart/Plot";
import "./contour.css";
import ReactSelect from "react-select";
import { connect } from "redux-bundler-react";

const ContourChart = ({
  series,
  doHeatmapFetch,
  storetOptionsByCode: storetOptions,
  heatmapIsFetching,
  resultIsFetching,
  projectLocations
}) => {
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [location, setLocation] = useState();
  const [parameter, setParameter] = useState({
    value: "00010",
    label: "WATER    TEMP    CENT",
  });
  const { data } = series;
  const monthNames = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  let sorted = sortBy(data, "x");

  let x = sorted.map(
    (a) => `${monthNames[a.x.getMonth()]}-${a.x.getFullYear()}`
  );
  let y = sorted.map((b) => b.y);
  let z = sorted.map((c) => c.z);
  
    return (
      <div className="d-flex flex-column">
        <div className="custom-grid-wrapper">
          <FormControl className="mt-2">
            <label className="d-flex">Date Range</label>
            <div className="input-grid">
              <input
                className="custom-input w-100"
                type="date"
                placeholder="Min Date"
                value={minDate}
                name="minDate"
                onChange={(e) => setMinDate(e.target.value)}
              />
              <input
                className="custom-input w-100"
                type="date"
                placeholder="Max Date"
                value={maxDate}
                name="maxDate"
                onChange={(e) => setMaxDate(e.target.value)}
              />
            </div>
          </FormControl>
          <FormControl className="mt-2">
            <label className="d-flex align-items-end">Parameter <small className="text-muted ml-2">Required</small></label>
            <ReactSelect
              value={parameter}
              name="parameter"
              options={storetOptions}
              onChange={(obj, type) => setParameter(obj)}
              isLoading={resultIsFetching || heatmapIsFetching}
            />
          </FormControl>
          <FormControl className="mt-2">
            <label className="d-flex">Location</label>
            <ReactSelect
              value={location}
              name="location"
              options={projectLocations.map(obj => ({ value: obj.location_code, label: obj.loc_id }))}
              onChange={(obj, type) => setLocation(obj)}
              isLoading={resultIsFetching || heatmapIsFetching}
            />
          </FormControl>
          <div className="d-flex h-100 align-items-end">
            <button
              className={`btn btn-${heatmapIsFetching || !parameter.value ? "secondary" : "success"
                } go-button`}
              disabled={heatmapIsFetching || !parameter.value}
              onClick={() =>
                doHeatmapFetch([minDate, maxDate], parameter.value, location && location.value ? location.value : null)
              }
            >
              Generate Graph
            </button>
            <button
              className={`ml-2 btn btn-outline-dark go-button`}
              onClick={e => { setParameter({value: "", label: ""}); setLocation({value: "", label: ""});}}
            >
              Clear Filters
            </button>
          </div>
        </div>
        {heatmapIsFetching ? 
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
      <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
    </div> :
        <Plot
          data={[
            {
              z,
              x,
              y,
              type: "contour",
            },
          ]}
          layout={{
            title: "Cross Section over Time",
            yaxis: {
              autorange: "reversed",
              title: {
                text: "Sample Depth",
              },
            },
            xaxis: {
              title: {
                text: "Date",
              },
            },
          }}
        />}
      </div>
    );
};

export default connect(
  "selectStoretOptionsByCode",
  "selectProjectLocations",
  "selectHeatmapIsFetching",
  "selectResultIsFetching",
  ContourChart
);
