import React from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";
import BioResultModal from "./BioResultModal";

function TableBioResult(props) {
  const {
    bioResultData,
    bioResultIsFetching,
    userIsOrgMemberOrAdmin,
    doBioResultShouldFetch,
    doModalOpen,
    doModalOpenUniqueCode,
    taxaData,
  } = props;

  return (
    <div className="custom-table mt-3">
      <div className="card-body">
        <div className="d-flex w-100 align-items-center justify-content-between mb-2">
          <h4 className="m-0">BioResults</h4>
          {userIsOrgMemberOrAdmin && (
            <button
              className="btn btn-outline-success"
              onClick={() => {
                doModalOpenUniqueCode(BioResultModal, {}, "bioresult_code");
              }}
            >
              Add BioResult
            </button>
          )}
        </div>
        <MaterialTable
          title=""
          isLoading={bioResultIsFetching}
          columns={[
            {
              field: "link",
              title: "Actions",
              render: (rowData) => {
                if (userIsOrgMemberOrAdmin) {
                  return (
                    <button
                      className="hover"
                      onClick={(e) => {
                        doModalOpen(BioResultModal, {
                          data: rowData,
                          edit: true,
                        });
                      }}
                    >
                      <i className="mdi mdi-pencil-outline mdi-24px"></i>
                    </button>
                  );
                }
              },
            },
            { title: "Sample Type", field: "sample_type" },
            {
              title: "Taxacode",
              field: "taxacode",
              render: (rowData) =>
                taxaData.filter((t) => t.taxacode === rowData.taxacode).length >
                  0 &&
                taxaData.filter((t) => t.taxacode === rowData.taxacode)[0]
                  .sci_name,
            },
            { title: "Count", field: "count" },
            { title: "Biovolume", field: "biovolume" },
            { title: "Density", field: "density" },
            { title: "Life Stage", field: "life_stage" },
            { title: "Storage Location", field: "storage_loc" },
            { title: "Storage Method", field: "storage_meth" },
            { title: "Lab ID", field: "lab_id" },
            { title: "Data Analyzed", field: "date_analyzed" },
            { title: "Taxonomist Code", field: "taxonomist_code" },
            { title: "DCL Flag", field: "dcl_flag" },
            { title: "Export Flag", field: "export_flag" },
            { title: "Count Units", field: "count_units" },
            { title: "Density Units", field: "density_units" },
            { title: "BV Units", field: "bv_units" },
            { title: "BM Units", field: "bm_units" },
            { title: "Rep Num", field: "rep_num" },
            { title: "Reference", field: "reference" },
            { title: "Analysis Method", field: "analysis_method" },
          ]}
          data={bioResultData}
          options={{
            loadingType: "overlay",
            exportButton: true,
            exportAllData: true,
            search: false,
            sorting: true,
            pageSize: 50,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [50, 500, 1000, 2000],
          }}
          actions={[
            {
              icon: "refresh",
              tooltip: "Refresh table",
              isFreeAction: true,
              onClick: doBioResultShouldFetch,
            },
          ]}
        />
      </div>
    </div>
  );
}

export default connect(
  "selectBioResultData",
  "selectBioResultIsFetching",
  "selectUserIsOrgMemberOrAdmin",
  "doBioResultShouldFetch",
  "selectRouteParams",
  "doBioResultFetchByCode",
  "doModalOpen",
  "doModalOpenUniqueCode",
  "selectTaxaData",
  TableBioResult
);
