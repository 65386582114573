import { createSelector } from "redux-bundler";

export default {
  name: "document",
  // reducers
  getReducer: () => {
    const initialData = {
      status: null,
      documents: [],
      shouldFetch: false,
      documentLocation: null,
      isFetching: false,
      documentTypes: [],
      shouldTypeFetch: false,
      searchItems: [],
      searchTerm: null,
      isUpdating: false,
    };
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "DOCUMENT_UPLOAD_STARTED":
        case "DOCUMENT_UPLOAD_FINISHED":
        case "DOCUMENT_UPLOAD_ERROR":
        case "DOCUMENT_STATUS_CLEAR":
        case "DOCUMENT_FETCH_STARTED":
        case "DOCUMENT_FETCH_FINISHED":
        case "DOCUMENT_DOWNLOAD_STARTED":
        case "DOCUMENT_DOWNLOAD_FINISHED":
        case "DOCUMENT_TYPE_FETCH_STARTED":
        case "DOCUMENT_TYPE_FETCH_FINISHED":
        case "DOCUMENT_TYPE_POST_STARTED":
        case "DOCUMENT_TYPE_POST_FINISHED":
        case "DOCUMENT_TYPE_POST_ERROR":
        case "DOCUMENT_SEARCH_STARTED":
        case "DOCUMENT_SEARCH_FINISHED":
        case "DOCUMENT_DELETE_STARTED":
        case "DOCUMENT_DELETE_FINISHED":
        case "DOCUMENT_EDIT_STARTED":
        case "DOCUMENT_EDIT_FINISHED":
        case "DOCUMENT_EDIT_ERROR":
        case "DOCUMENT_DELETE_ERROR":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },
  doDocumentUpload:
    (file, location, subject, type) =>
    ({ dispatch, store, apiFetch }) => {
      const mime_type = file.type;
      const name = file.name;
      dispatch({
        type: "DOCUMENT_UPLOAD_STARTED",
        payload: { documentLocation: location },
      });
      apiFetch(`/documents/upload/${location}/${name}`, {
        method: "post",
        headers: {
          mime_type: mime_type,
          subject: subject,
          type: type,
        },
        body: file,
      })
        .then((r) => {
          if (r.ok) {
            dispatch({
              type: "DOCUMENT_UPLOAD_FINISHED",
              payload: {
                status: r.status,
                shouldFetch: true,
              },
            });
          }
        })

        .catch((e) => {
          console.log(e);
          dispatch({
            type: "DOCUMENT_UPLOAD_ERROR",
            payload: {
              status: 404,
            },
          });
        });
    },
  doDocumentClearStatus:
    () =>
    ({ dispatch, store }) => {
      dispatch({ type: "DOCUMENT_STATUS_CLEAR", payload: { status: null } });
    },
  doDocumentFetch:
    (location_code) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "DOCUMENT_FETCH_STARTED",
        payload: { shouldFetch: false, isFetching: true, status: null },
      });
      apiFetch(
        `/documents/location/${
          location_code ? location_code : store.selectRouteParams().id
        }`
      )
        .then((r) => r.json())
        .then((res) => {
          // console.log(res)
          dispatch({
            type: "DOCUMENT_FETCH_FINISHED",
            payload: {
              documents: res.items,
              isFetching: false,
            },
          });
        });
    },
  doDocumentDownload:
    (doc) =>
    ({ dispatch, store, apiFetch }) => {
      const type = doc.mime_type;
      dispatch({
        type: "DOCUMENT_DOWNLOAD_STARTED",
        payload: { download: null },
      });
      apiFetch(`/documents/download/${doc.id}`)
        .then((r) => {
          console.log(r, "res");
          return r.blob();
        })
        .then((blob) => {
          console.log(blob, "docblob");
          const newBlob = new Blob([blob], { type: type });
          const blobUrl = window.URL.createObjectURL(newBlob);
          window.open(blobUrl);
          URL.revokeObjectURL(newBlob);
        });
    },
  doDocumentTypeFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "DOCUMENT_TYPE_FETCH_STARTED",
        payload: {
          shouldTypeFetch: false,
        },
      });
      apiFetch(`/documents/type`)
        .then((r) => r.json())
        .then((res) => {
          // console.log(res)
          dispatch({
            type: "DOCUMENT_TYPE_FETCH_FINISHED",
            payload: {
              documentTypes: res.items,
              isFetching: false,
            },
          });
        });
    },
  doDocumentTypePost:
    (newType, cb) =>
    ({ dispatch, store, apiFetch }) => {
      const data = {
        type: newType,
      };
      dispatch({ type: "DOCUMENT_TYPE_POST_STARTED" });
      apiFetch(`/documents/type`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((r) => {
          if (r.ok) {
            dispatch({
              type: "DOCUMENT_TYPE_POST_FINISHED",
              payload: {
                status: r.status,
                shouldTypeFetch: true,
              },
            });
            cb && cb();
          }
        })

        .catch((e) => {
          console.log(e);
          dispatch({
            type: "DOCUMENT_TYPE_POST_ERROR",
            payload: {
              status: 404,
            },
          });
        });
    },
  doDocumentSearch:
    (search, location_code) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "DOCUMENT_SEARCH_STARTED",
        payload: {
          searchTerm: search,
          isFetching: true,
          searchItems: [],
        },
      });
      if (search && search.length) {
        apiFetch(`/documents/search/${location_code}`, {
          method: "GET",
          headers: {
            search: search,
          },
        })
          .then((r) => r.json())
          .then((res) => {
            dispatch({
              type: "DOCUMENT_SEARCH_FINISHED",
              payload: {
                searchItems: res.items,
                isFetching: false,
              },
            });
          });
      } else {
        store.doDocumentFetch();
      }
    },
  doDocumentEdit:
    (data) =>
    ({ dispatch, store, apiFetch }) => {
      console.log(data);
      dispatch({
        type: "DOCUMENT_EDIT_STARTED",
        payload: { isUpdating: true },
      });
      apiFetch(`/documents/edit/${data.id}`, {
        method: "PUT",
        headers: {
          name: data.name,
          subject: data.subject,
          document_type: data.document_type,
        },
      })
        .then((r) => {
          dispatch({
            type: "DOCUMENT_EDIT_FINISHED",
            payload: { status: r.status, isUpdating: false },
          });
          store.doModalClose();
        })
        .catch((err) => {
          dispatch({
            type: "DOCUMENT_EDIT_ERROR",
            payload: { error: err, isUpdating: false },
          });
        });
    },
  doDocumentDelete:
    (id) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "DOCUMENT_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      apiFetch(`/documents/edit/${id}`, {
        method: "DELETE",
      })
        .then((r) => {
          dispatch({
            type: "DOCUMENT_DELETE_FINISHED",
            payload: { status: r.status, isDeleting: false },
          });
          store.doModalClose();
        })
        .catch((err) => {
          dispatch({
            type: "DOCUMENT_DELETE_ERROR",
            payload: { error: err, isDeleting: false },
          });
        });
    },

  selectDocumentStatus: (state) => state.document.status,
  selectDocumentItems: (state) => state.document.documents,
  selectDocumentShouldFetch: (state) => state.document.shouldFetch,
  selectDocumentTypeShouldFetch: (state) => state.document.shouldTypeFetch,
  selectDocumentLocation: (state) => state.document.documentLocation,
  selectDocumentIsFetching: (state) => state.document.isFetching,
  selectDocumentTypes: (state) => state.document.documentTypes,
  selectDocumentPhotos: createSelector(
    "selectDocumentItems",
    (documentItems) => {
      if (documentItems) {
        return documentItems.filter(
          (x) => x.mime_type === "image/jpeg" || x.mime_type === "image/png"
        );
      }
    }
  ),
  selectDocumentSearchItems: (state) => state.document.searchItems,
  selectDocumentSearchTerm: (state) => state.document.searchTerm,
  selectDocumentIsUpdating: (state) => state.document.isUpdating,
  selectDocumentIsDeleting: (state) => state.document.isDeleting,
  selectDocumentError: (state) => state.document.error,

  reactDocumentShouldFetch: createSelector(
    "selectDocumentShouldFetch",
    "selectDocumentLocation",
    (documentShouldFetch, documentLocation) => {
      if (documentShouldFetch)
        return {
          actionCreator: "doDocumentFetch",
          args: [documentLocation],
        };
    }
  ),
  reactDocumentTypeFetch: createSelector(
    "selectDocumentTypeShouldFetch",
    (documentTypeShouldFetch) => {
      if (documentTypeShouldFetch) {
        return {
          actionCreator: "doDocumentTypeFetch",
        };
      }
    }
  ),
  reactDocumentFetchSuccess: createSelector(
    "selectDocumentStatus",
    (documentStatus) => {
      if (documentStatus === 200) {
        return {
          actionCreator: "doDocumentFetch",
        };
      }
    }
  ),
};
