import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const BarGraph = connect(
  "doProjectMapsBarGraph",
  "selectProjectMapShowBar",
  ({ doProjectMapsBarGraph, projectMapShowBar }) => {
    useEffect(() => {
      if (projectMapShowBar === false) {
        setShowBar(false);
      }
    }, [projectMapShowBar]);
    const [showBar, setShowBar] = useState(false);
    const handleBar = (e) => {
      setShowBar(!showBar);
      doProjectMapsBarGraph(!showBar);
    };
    return (
      <div>
        <div style={{ padding: "3px 0px 0px" }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showBar}
                  onChange={(e) => handleBar(e.target.value)}
                />
              }
              label="Show Bar Graph"
            />
          </FormGroup>
        </div>
      </div>
    );
  }
);
export default BarGraph;
