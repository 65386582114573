import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder, ordsifyUrlBuilderNoSlash } from "../utils/ordsify";
// import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "trendsConclusion",
  getReducer: () => {
    const initialData = {
      data: [],
      selected: [],
      shouldFetch: false,
      isFetching: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "TRENDS_CONCLUSION_FETCH_START":
        case "TRENDS_CONCLUSION_FETCH_FINISH":
        case "TRENDS_CONCLUSION_SHOULD_FETCH":
        case "TRENDS_CONCLUSION_SELECTED_FETCH_START":
        case "TRENDS_CONCLUSION_SELECTED_FETCH_FINISH":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doTrendsConclusionSelectedFetch:
    (site_code, storet_num) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "TRENDS_CONCLUSION_SELECTED_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      let url = `/trends/project_extremes_conclusion/${site_code}/${storet_num}`;
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "TRENDS_CONCLUSION_SELECTED_FETCH_FINISH",
            payload: { selected: j.items, isFetching: false },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },
  doTrendsConclusionShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "TRENDS_CONCLUSION_SHOULD_FETCH",
        payload: { shouldFetch: true },
      });
    },

  doTrendsConclusionFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "TRENDS_CONCLUSION_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      let url = `/trends/project_extremes_conclusion/${
        store.selectRouteParams().project
      }`;
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "TRENDS_CONCLUSION_FETCH_FINISH",
            payload: { data: j.items, isFetching: false },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },
  selectTrendsConclusionIsFetching: (state) =>
    state.trendsConclusion.isFetching,
  selectTrendsConclusionShouldFetch: (state) =>
    state.trendsConclusion.shouldFetch,
  selectTrendsConclusion: (state) => state.trendsConclusion.data,
  selectTrendsConclusionSelected: (state) => state.trendsConclusion.selected,

  reactTrendsConclusionShouldFetch: createSelector(
    "selectTrendsConclusionIsFetching",
    "selectTrendsConclusionShouldFetch",
    (isFetching, shouldFetch) => {
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doTrendsConclusionFetch",
        };
      }
    }
  ),
};
