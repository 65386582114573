import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "note",
  getReducer: () => {
    const initialData = {
      data: [],
      shouldFetch: false,
      isFetching: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "NOTE_FETCH_START":
        case "NOTE_FETCH_FINISH":
        case "NOTE_SHOULD_FETCH":
        case "NOTE_SAVE_STARTED":
        case "NOTE_SAVE_FINISHED":
        case "NOTE_SAVE_ERROR":
        case "NOTE_DELETE_STARTED":
        case "NOTE_DELETE_FINISHED":
        case "NOTE_DELETE_ERROR":
          return Object.assign({}, state, payload);
        case "MODAL_CLOSED":
          return { ...state, ...payload, error: null };
        default:
          return state;
      }
    };
  },

  doNoteShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "NOTE_SHOULD_FETCH",
        payload: {
          shouldFetch: true,
        },
      });
    },

  doNoteFetch:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "NOTE_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });
      let locationCode = store.selectRouteParams().id;
      let url = ordsifyUrlBuilder(
        "/wq_location_notes/",
        [
          {
            keyword: "location_code",
            items: locationCode ? [locationCode] : [],
          },
        ],
        500
      );
      if (state) {
        url = ordsifyUrlBuilder(
          "/wq_location_notes/",
          [
            {
              keyword: "location_code",
              items: locationCode ? [locationCode] : [],
            },
            {
              keyword: "date_created",
              items:
                state.min_date && state.max_date
                  ? [`${state.min_date}`, `${state.max_date}`]
                  : !state.min_date && state.max_date
                  ? ["1900-01-01T00:00:00Z", `${state.max_date}`]
                  : state.min_date && !state.max_date
                  ? [`${state.min_date}`, "2100-01-01T00:00:00Z"]
                  : [],
            },
          ],
          500
        );
      }
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "NOTE_FETCH_FINISH",
            payload: {
              isFetching: false,
              data: j.items,
            },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },

  doNotePost:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPostData, doModalClose } = store;
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "NOTE_SAVE_STARTED",
        payload: { isSaving: true },
      });
      let url = `${apiRoot}/wq_location_notes/`;
      doActionPostData(
        url,
        state,
        () => {
          dispatch({
            type: "NOTE_SAVE_FINISHED",
            payload: { isSaving: false, shouldFetch: true, error: null },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "NOTE_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },
  doNotePut:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPutData, doModalClose } = store;
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "NOTE_SAVE_STARTED",
        payload: { isSaving: true },
      });
      let url = `${apiRoot}/wq_location_notes/${state.id}`;
      doActionPutData(
        url,
        state,
        () => {
          dispatch({
            type: "NOTE_SAVE_FINISHED",
            payload: { isSaving: false, shouldFetch: true, error: null },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "NOTE_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doNoteDelete:
    (state) =>
    ({ dispatch, store }) => {
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "NOTE_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      store.doActionDeleteData(
        `${apiRoot}/wq_location_notes/${state.id}`,
        {},
        () => {
          dispatch({
            type: "NOTE_DELETE_FINISHED",
            payload: { isDeleting: false, shouldFetch: true, error: null },
          });
          store.doModalClose();
        },
        (e) =>
          dispatch({
            type: "NOTE_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },
  selectNoteRaw: (store) => store.note,

  selectNoteData: (state) => state.note.data,
  selectNoteIsFetching: (store) => store.note.isFetching,
  selectNoteShouldFetch: (store) => store.note.shouldFetch,

  selectNoteIsSaving: (state) => state.note.isSaving,
  selectNoteIsDeleting: (state) => state.note.isDeleting,
  selectNoteError: (state) => state.note.error,

  reactNoteShouldFetch: createSelector(
    "selectNoteIsFetching",
    "selectNoteShouldFetch",
    (isFetching, shouldFetch) => {
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doNoteFetch",
        };
      }
    }
  ),
};
