export default {
  name: "tests",
  getReducer: () => {
    const initialData = {
      test: [],
      shouldTestFetch: false
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "TEST_FETCH_STARTED":
        case "TEST_FETCH_FINISHED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doTestsFetch: () => ({ dispatch, store, apiFetch }) => {
    dispatch({
      type: "TEST_FETCH_STARTED",
      payload: {
        shouldTestFetch: false
      }
    });
    const createData = data => {
      var list = [];
      var i = 0;
      for (i = 0; i < data.length; i++) {
        list.push({
          storet_state: data[i].storet_state,
          postal_state: data[i].postal_state,
          state_name: data[i].state_name,
          state_initial: data[i].state_initial
        });
      }
      dispatch({
        type: "TEST_FETCH_FINISHED",
        payload: {
          test: list
        }
      });
    };

    apiFetch("/states/?limit=500")
      .then(response => response.json())
      .then(r => createData(r.items))
      .catch(e => {
        console.log(e);
      });
  },

  selectTestsItems: state => {
    return state.tests.test;
  },

  reactTestsShouldFetch: state => {
    if (state.tests.shouldTestFetch)
      return {
        actionCreator: "doTestsFetch"
      };
  }
};
