import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const BulkUpload = connect(
  "doDocumentClearStatus",
  ({ doDocumentClearStatus, onImport, states, doParse, doClear, csvFile }) => {
    const [file, setFile] = useState("");
    const [error, setError] = useState();

    const csvToArray = (str, delimiter = ",") => {
      const headers = str
        .slice(0, str.indexOf("\n"))
        .replace(/(\r\n|\n|\r)/gm, "");
      const newHeaders = headers.split(delimiter);
      const rows = str.slice(str.indexOf("\n") + 1).split("\n");
      const arr = rows.map(function (row) {
        row = row.replace(/(\r\n|\n|\r)/gm, "");
        const values = row.split(delimiter);
        const el = newHeaders.reduce(function (object, header, index) {
          object[header] = values[index];
          return object;
        }, {});
        return el;
      });
      return arr;
    };

    const _checkCsvType = (event, cb) => {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        let result = e.target.result;
        const bomBytes = new Uint8Array(reader.result.slice(0, 3));
        if (
          bomBytes[0] === 0xef &&
          bomBytes[1] === 0xbb &&
          bomBytes[2] === 0xbf
        ) {
          cb("UTF-8");
        } else {
          cb("Standard");
        }
      };
    };
    const handleChange = (event) => {
      setFile(event.target.files[0]);
      const reader = new FileReader();
      let baseType = event.target.files[0].type;
      if (baseType !== "text/csv") {
        setError({
          title: "Invalid File Type",
          body: "The file you attached is not a CSV. Please open the file in Excel, Go to'Save as', then select the file type dropdown and choose CSV (Not CSV UTF-8) and reupload.",
        });
        return;
      }
      reader.onload = function (e) {
        let text = e.target.result;
        _checkCsvType(event, (fileType) => {
          if (fileType === "UTF-8") {
            setError({
              title: "Invalid File Type",
              body: "The file you attached is a CSV with UTF-8 encoding. Please open the file in Excel, 'Save as', then select the file type dropdown and choose CSV (Not CSV UTF-8) and reupload.",
            });
          } else setError(null);
          const array = csvToArray(text);
          doParse(array);
        });
      };
      reader.readAsText(event.target.files[0]);
    };

    const handleClose = () => {
      states.setSuccess(false);
      states.setErrorMsg("");
      doDocumentClearStatus(null);
    };
    const handleImport = () => {
      // doDocumentUpload(file, location_code, subject, 2)
      onImport(file);
    };
    const handleClear = () => {
      document.getElementById("files").value = "";
      doClear();
      setFile(null);
    };

    let csv = "data:text/csv;charset=utf-8,";

    csvFile.forEach((rowArr) => (csv += rowArr.join(",") + "\r\n"));
    let href = encodeURI(csv);
    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={states.success || states.errorMsg}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          {states.errorMsg ? (
            <Alert onClose={handleClose} severity={"error"}>
              {states.errorMsg}
            </Alert>
          ) : (
            <Alert onClose={handleClose} severity="success">
              Successful Bulk Import!
            </Alert>
          )}
        </Snackbar>
        <div className="border-0" style={{ width: "100%" }}>
          <input
            // accept=".csv"
            type="file"
            id="files"
            onChange={(e) => handleChange(e)}
          ></input>
          <div className="d-flex flex-column mt-4">
            <button
              className={`btn btn-outline-${
                !!error || !file ? "secondary" : "success"
              } mb-2`}
              onClick={handleImport}
              disabled={!!error || !file}
            >
              Import Data
            </button>
            <button
              className={`btn btn-outline-${!file ? "secondary" : "dark"} mb-2`}
              onClick={handleClear}
              disabled={!file}
            >
              Clear File
            </button>
            <a
              href={href}
              download="DOWNLOAD_TEMPLATE.csv"
              className="btn btn-outline-info"
            >
              Download Template
            </a>
          </div>
        </div>
        {error && (
          <div
            className={`alert alert-danger w-100`}
            role="alert"
            key={error.title}
          >
            <h5 className="alert-heading">{error.title}</h5>
            <p>{error.body}</p>
          </div>
        )}
      </div>
    );
  }
);
export default BulkUpload;
