import { createSelector } from "redux-bundler";

import { ordsifyUrlBuilder } from "../utils/ordsify";
import { uniqueObjects } from "../utils/uniqueObjects";

// Lot of inspiration and code examples from redux-bundler-example
// https://github.com/HenrikJoreteg/redux-bundler-example/blob/master/src/bundles/base-data.js

export default {
  name: "location",
  getReducer: () => {
    const initialData = {
      data: [],
      selected: [],
      isFetching: false,
      shouldFetch: false,
      lastFetch: null,
      fetchedCodes: [],
      locationProjects: [],
      totalLocations: [],
      locationsActive: [],
      locationArray: [],
      locationActive: null,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "LOCATION_FETCH_START":
        case "LOCATION_FETCH_FINISH":
        case "LOCATION_SELECTED_ID_APPEND":
        case "LOCATION_SELECTED_ID_REMOVE":
        case "LOCATION_SELECTED_ID_REMOVE_ALL":
        case "LOCATION_ACTIVE_UPDATE":
        case "LOCATION_FETCH_SITE_START":
        case "LOCATION_FETCH_SITE_FINISH":
        case "LOCATION_SAVE_STARTED":
        case "LOCATION_SAVE_FINISHED":
        case "LOCATION_SAVE_ERROR":
        case "LOCATION_DELETE_STARTED":
        case "LOCATION_DELETE_FINISHED":
        case "LOCATION_DELETE_ERROR":
          return Object.assign({}, state, payload);
        case "LOCATION_FETCHEDCODES_APPEND":
          return Object.assign({}, state, {
            fetchedCodes: [
              ...new Set(state.fetchedCodes.concat(payload.fetchedCodes)),
            ],
          });
        case "MODAL_CLOSED":
          return { ...state, error: null };
        default:
          return state;
      }
    };
  },

  doLocationFetch:
    (location_codes) =>
    ({ dispatch, store, apiFetch }) => {
      // If location_code is passed, fetch data for only that location_code
      // otherwise, fetch data for "selected" locations.
      // set false to avoid infinite fetching attempts
      dispatch({
        type: "LOCATION_FETCH_START",
        payload: {
          isFetching: true,
          shouldFetch: false,
        },
      });
      const url = ordsifyUrlBuilder(
        "/locations/",
        [
          {
            keyword: "location_code",
            items: location_codes ? location_codes : "", // if no location_codes passed, fetch all
          },
        ],
        500
      );

      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "LOCATION_FETCH_FINISH",
            payload: {
              // Always add new fetched objects to the front end of the array
              // so uniqueObjects() will return most recently fetched data
              data: j.items,
              lastFetch: Date.now(),
              isFetching: false,
            },
          });
        });
    },

  doLocationPost:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPostData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "LOCATION_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPostData(
        `${apiRoot}/wq_locations/`,
        state,
        () => {
          dispatch({
            type: "LOCATION_SAVE_FINISHED",
            payload: { isSaving: false, shouldFetch: true },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "LOCATION_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doLocationPut:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPutData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "LOCATION_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPutData(
        `${apiRoot}/wq_locations/${state.location_code}`,
        state,
        () => {
          dispatch({
            type: "LOCATION_SAVE_FINISHED",
            payload: { isSaving: false, shouldFetch: true },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "LOCATION_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doLocationDelete:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionDeleteData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "LOCATION_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      doActionDeleteData(
        `${apiRoot}/wq_locations/${state.location_code}`,
        {},
        () => {
          dispatch({
            type: "LOCATION_DELETE_FINISHED",
            payload: { isDeleting: false, shouldFetch: true },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "LOCATION_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },

  doLocationSelectedIdAppend:
    (location_codes) =>
    ({ dispatch, store }) => {
      const new_selected = [
        ...new Set(store.selectLocationSelectedId().concat(location_codes)),
      ];

      dispatch({
        type: "LOCATION_SELECTED_ID_APPEND",
        payload: { selected: new_selected },
      });
      // Trigger fetches based on new filter querystring
      const update = ["RESULTSUMMARY"];
      // Fetch locations with new filter querystring
      update.map((t) =>
        dispatch({
          type: `${t}_FETCH_START`,
          payload: { shouldFetch: true },
        })
      );
    },

  doLocationSelectedIdRemove:
    (location_codes) =>
    ({ dispatch, store }) => {
      const new_selected = store
        .selectLocationSelectedId()
        .filter((i) => !location_codes.includes(i));

      dispatch({
        type: "LOCATION_SELECTED_ID_REMOVE",
        payload: { selected: new_selected },
      });
    },

  doLocationSelectedIdRemoveAll:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "LOCATION_SELECTED_ID_REMOVE_ALL",
        payload: { selected: [] },
      });
    },

  //fetch location by project
  doLocationFetchByProject:
    (project_code) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "LOCATION_FETCH_SITE_START",
        payload: {
          isFetching: true,
          shouldFetch: false,
        },
      });
      const url = `/wq_locations/?q={"site_code":${project_code}}`;
      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "LOCATION_FETCH_SITE_FINISH",
            payload: {
              // Always add new fetched objects to the front end of the array
              // so uniqueObjects() will return most recently fetched data
              locationProjects: j.items,
              isFetching: false,
            },
          });
        });
    },

  doLocationsActive:
    (locations) =>
    ({ dispatch, store }) => {
      if (locations && locations.length) {
        let locationArray = [];
        if (locations[0].hasOwnProperty("location_code")) {
          locationArray = [
            ...new Set(locations.map((item) => item.location_code)),
          ];
        } else {
          locationArray = [...new Set(locations.map((item) => item.value))];
        }
        dispatch({
          type: "LOCATION_ACTIVE_UPDATE",
          payload: {
            locationsActive: locations,
            locationsArray: locationArray,
          },
        });
        store.doSampleFetch(locationArray);
      } else {
        dispatch({
          type: "LOCATION_ACTIVE_UPDATE",
          payload: {
            locationsActive: [],
            locationsArray: [],
          },
        });
      }
    },
  doLocationActive:
    (location) =>
    ({ dispatch, store }) => {
      if (location && location.hasOwnProperty("value")) {
        const code = location.value;
        dispatch({
          type: "LOCATION_ACTIVE_UPDATE",
          payload: {
            locationActive: code,
          },
        });
        // check
        store.doSampleFetch([code]);
      } else if (location) {
        dispatch({
          type: "LOCATION_ACTIVE_UPDATE",
          payload: {
            locationActive: location,
          },
        });
        // check
        store.doSampleFetch([location]);
      } else {
        dispatch({
          type: "LOCATION_ACTIVE_UPDATE",
          payload: {
            locationActive: "",
          },
        });
      }
    },
  // selectLocationTest: state => state.location.totalLocations,
  // // locations by project id
  selectLocationByProject: (state) => state.location.locationProjects,
  selectLocationsActive: (state) => state.location.locationsActive,
  selectLocationsActiveArray: (state) => state.location.locationsArray,
  // entire location state
  selectLocationRaw: (state) => state.location,
  selectLocationActive: (state) => state.location.locationActive,

  selectLocation: createSelector(
    "selectRouteParams",
    "selectLocationRaw",
    (routeParams, locationRaw) => {
      // If the page has location_code in the URL, return array of one location
      if (routeParams.id) {
        return locationRaw.data.find(
          (d) => String(d.location_code) === routeParams.id
        );
      } else {
        return null;
      }
    }
  ),

  selectLocationIsSaving: (state) => state.location.isSaving,
  selectLocationIsDeleting: (state) => state.location.isDeleting,
  selectLocationError: (state) => state.location.error,

  selectLocationSelectedId: (state) => state.location.selected,

  selectLocationSelected: createSelector(
    "selectLocationSelectedId",
    "selectLocationRaw",
    (selectedId, locationRaw) => {
      if (!selectedId.length) {
        return [];
      } else {
        return locationRaw.data.filter((d) =>
          selectedId.includes(String(d.location_code))
        );
      }
    }
  ),
  selectLocationShouldFetch: (state) => state.location.shouldFetch,
  selectLocationIsFetching: (state) => state.location.isFetching,
  selectLocationLastFetch: (state) => state.location.lastFetch,
  // selectLocationFetchedCodes: state => state.location.fetchedCodes,

  reactLocationShouldFetch: createSelector(
    "selectRouteParams",
    "selectLocationRaw",
    "selectLocationShouldFetch",
    (routeParams, location, shouldFetch) => {
      // never fetch if another fetch already in progress
      if (location.isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doLocationFetch",
          args: [[routeParams.id]],
        };
      }
      if (routeParams.id) {
        // if location data array is empty (we know what we're looking for is not in there) or
        // if location we need is not available in memory
        if (
          !location.data.length ||
          !location.data.find((d) => String(d.location_code) === routeParams.id)
        ) {
          return {
            actionCreator: "doLocationFetch",
            args: [[routeParams.id]],
          };
        }
      }
    }
  ),

  reactLocationShouldAppendSelected: createSelector(
    "selectRouteParams",
    "selectLocationRaw",
    (routeParams, location) => {
      // if the URL contains a location_code AND
      // if the URL location_code is not in selected_locations,
      // append URL location_code to the selected locations
      if (
        routeParams.id &&
        !location.selected.find((i) => i === routeParams.id)
      ) {
        return {
          actionCreator: "doLocationSelectedIdAppend",
          args: [[routeParams.id]],
        };
      }
    }
  ),
};
