import { createSelector } from "redux-bundler";
// import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "fieldTrip",
  getReducer: () => {
    const initialData = {
      data: [],
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "FIELD_TRIP_FETCH_START":
        case "FIELD_TRIP_FETCH_FINISH":
        case "FIELD_TRIP_SHOULD_FETCH":
        case "FIELD_TRIP_SAVE_ERROR":
        case "FIELD_TRIP_DELETE_ERROR":
        case "FIELD_TRIP_SAVE_FINISHED":
        case "FIELD_TRIP_DELETE_FINISHED":
          return Object.assign({}, state, payload);
        case "MODAL_CLOSED":
        case "FIELD_TRIP_SAVE_STARTED":
        case "FIELD_TRIP_DELETE_STARTED":
          return { ...state, ...payload, error: null };
        default:
          return state;
      }
    };
  },

  doFieldTripShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "FIELD_TRIP_SHOULD_FETCH",
        payload: { shouldFetch: true },
      });
    },

  doFieldTripFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      let site_code = store.selectRouteParams().project;
      if (!site_code) return;
      dispatch({
        type: "FIELD_TRIP_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      let url = `/field_trip/?q={"site_code":${site_code}}&limit=20000`;
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "FIELD_TRIP_FETCH_FINISH",
            payload: { data: j.items, isFetching: false },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },

  doFieldTripPost:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPostData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "FIELD_TRIP_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPostData(
        `${apiRoot}/field_trip/`,
        state,
        () => {
          dispatch({
            type: "FIELD_TRIP_SAVE_FINISHED",
            payload: {
              isSaving: false,
              shouldFetch: true,
            },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "FIELD_TRIP_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doFieldTripPut:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPutData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "FIELD_TRIP_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPutData(
        `${apiRoot}/field_trip/${state.field_trip_code}`,
        state,
        () => {
          dispatch({
            type: "FIELD_TRIP_SAVE_FINISHED",
            payload: {
              isSaving: false,
              shouldFetch: true,
            },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "FIELD_TRIP_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doFieldTripDelete:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionDeleteData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "FIELD_TRIP_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      doActionDeleteData(
        `${apiRoot}/field_trip/${state.field_trip_code}`,
        {},
        () => {
          dispatch({
            type: "FIELD_TRIP_DELETE_FINISHED",
            payload: {
              isDeleting: false,
              shouldFetch: true,
            },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "FIELD_TRIP_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },
  selectFieldTripIsSaving: (state) => state.fieldTrip.isSaving,
  selectFieldTripIsDeleting: (state) => state.fieldTrip.isDeleting,
  selectFieldTripError: (state) => state.fieldTrip.error,
  selectFieldTripIsFetching: (state) => state.fieldTrip.isFetching,
  selectFieldTripShouldFetch: (state) => state.fieldTrip.shouldFetch,
  selectFieldTripAll: (state) => state.fieldTrip.data,
  selectFieldTrip: createSelector(
    "selectRouteParams",
    "selectFieldTripAll",
    (routeParams, fieldTripAll) => {
      if (routeParams.project && !routeParams.fieldTrip) {
        return fieldTripAll.filter(
          (d) => String(d.site_code) === routeParams.project
        );
      } else if (routeParams.project && routeParams.fieldTrip) {
        return fieldTripAll.filter(
          (d) =>
            String(d.site_code) === routeParams.project &&
            String(d.field_trip_code) === routeParams.fieldTrip
        );
      } else {
        return null;
      }
    }
  ),

  reactFieldTripShouldFetch: createSelector(
    "selectFieldTripIsFetching",
    "selectFieldTripShouldFetch",
    (isFetching, shouldFetch) => {
      // never fetch if anoter fetch already in progress
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doFieldTripFetch",
        };
      }
    }
  ),
};
