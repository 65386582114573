import React, { useState, useEffect } from "react";
import { connect } from "redux-bundler-react";
import Link from "../../misc/link";
import "./customCard.css";

function CardFieldVisits(props) {
  const [visitDate, setVisitDate] = useState(null);
  const { doFieldVisitFetchByLocation, fieldVisitByLocation, fieldVisitIsFetching, location } = props;

  useEffect(() => {
    if (location.location_code == null) return;
    setVisitDate(0);
    doFieldVisitFetchByLocation(location.loc_id);
  }, [location.loc_id]);

  useEffect(() => {
    if (!fieldVisitIsFetching) {
      setVisitDate(fieldVisitByLocation[0]);
    }
  }, [fieldVisitIsFetching]);

  return !props.location || !props.project ? (
    <div className="custom">
      <div className="card-body">
        <div style={{ textAlign: "center" }}>
          <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
        </div>
      </div>
    </div>
  ) : (
    <div className="custom">
      <a
        style={{
          textDecoration: "none",
          cursor: "pointer",
          height: "100%",
        }}
        href={`/projects/${props.project.site_code}/locations/${props.location.location_code}/fieldvisits`}
      >
        <div className="card-body h-100">
          <div className="custom-body">
            <div>
              <h4
                className="card-title border-bottom text-black"
                style={{ color: "#3c4b64" }}
              >
                Field Visits
              </h4>
              <h6 className="card-subtitle mb-2 text-muted">
              {`Recent Start Date: `}
                {visitDate && visitDate.field_visit_date
                  ? visitDate.field_visit_date.split("T")[0]
                  : "n/a"}
              </h6>
              <h6 className="card-subtitle mb-2 text-muted">
              {`Recent End Date: `}
                {visitDate && visitDate.field_visit_end_date ? visitDate.field_visit_end_date.split("T")[0] : "n/a"}
              </h6>
              <Link
                style={{ fontWeight: "bold" }}
                to={`/projects/${props.project.site_code}/locations/${props.location.location_code}/fieldvisits`}
              >
                Go to Field Visits
              </Link>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}

export default connect(
  "selectFieldVisitByLocation",
  "doFieldVisitFetchByLocation",
  "selectFieldVisitIsFetching",
  CardFieldVisits);
