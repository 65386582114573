import { createSelector } from "redux-bundler";

export default {
  name: "locationType",
  getReducer: () => {
    const initialData = {
      data: [],
      isFetching: false,
      shouldFetch: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "LOCATIONTYPE_FETCH_STARTED":
        case "LOCATIONTYPE_FETCH_FINISHED":
        case "LOCATIONTYPE_FETCH_ERROR":
        case "LOCATIONTYPE_SHOULD_FETCH":
        case "LOCATION_TYPE_SAVE_STARTED":
        case "LOCATION_TYPE_SAVE_FINISHED":
        case "LOCATION_TYPE_SAVE_ERROR":
        case "LOCATION_TYPE_DELETE_STARTED":
        case "LOCATION_TYPE_DELETE_FINISHED":
        case "LOCATION_TYPE_DELETE_ERROR":
          return Object.assign({}, state, payload);
        case "PROJECT_LOCATION_FETCH_FINISH":
          return { ...state, shouldFetch: true };
        default:
          return state;
      }
    };
  },

  doLocationTypeShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "LOCATIONTYPE_SHOULD_FETCH",
        payload: { shouldFetch: true },
      });
    },

  doLocationTypeFetch:
    () =>
    ({ dispatch, apiFetch }) => {
      dispatch({
        type: "LOCATIONTYPE_FETCH_STARTED",
        payload: { shouldFetch: false, isFetching: true },
      });
      apiFetch("/location_type/?limit=10000")
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "LOCATIONTYPE_FETCH_FINISHED",
            payload: { isFetching: false, data: j.items },
          })
        )
        .catch((e) =>
          dispatch({
            type: "LOCATIONTYPE_FETCH_ERROR",
            payload: { isFetching: false, error: e },
          })
        );
    },

  doLocationTypePost:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPostData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "LOCATION_TYPE_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPostData(
        `${apiRoot}/location_type/`,
        state,
        () => {
          dispatch({
            type: "LOCATION_TYPE_SAVE_FINISHED",
            payload: { isSaving: false, shouldFetch: true },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "LOCATION_TYPE_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doLocationTypePut:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPutData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "LOCATION_TYPE_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPutData(
        `${apiRoot}/location_type/${state.type_code}`,
        state,
        () => {
          dispatch({
            type: "LOCATION_TYPE_SAVE_FINISHED",
            payload: { isSaving: false, shouldFetch: true },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "LOCATION_TYPE_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doLocationTypeDelete:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionDeleteData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "LOCATION_TYPE_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      doActionDeleteData(
        `${apiRoot}/location_type/${state.type_code}`,
        {},
        () => {
          dispatch({
            type: "LOCATION_TYPE_DELETE_FINISHED",
            payload: { isDeleting: false, shouldFetch: true },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "LOCATION_TYPE_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },
  selectLocationTypeShouldFetch: (state) => state.locationType.shouldFetch,
  selectLocationTypeIsFetching: (state) => state.locationType.isFetching,
  selectLocationTypeItems: (state) => state.locationType.data,

  selectLocationTypeIsDeleting: (state) => state.locationType.isDeleting,
  selectLocationTypeIsSaving: (state) => state.locationType.isSaving,
  selectLocationTypeError: (state) => state.locationType.error,

  reactLocationTypeShouldFetch: createSelector(
    "selectLocationTypeShouldFetch",
    "selectLocationTypeIsFetching",
    (shouldFetch, isFetching) => {
      if (isFetching) return null;
      if (shouldFetch) {
        return {
          actionCreator: "doLocationTypeFetch",
        };
      }
    }
  ),
};
