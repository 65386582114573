import { createSelector } from "redux-bundler";

export default {
  name: "profileUpload",
  getReducer: () => {
    const initialData = {
      data: [],
      isUploading: false,
      isFetching: false,
      shouldFetch: false,
      shouldBlobUpload: false,
      blob: null,
      documentId: null,
    };
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "PROFILE_UPLOAD_STARTED":
        case "PROFILE_UPLOAD_FINISHED":
        case "PROFILE_UPLOAD_ERROR":
        case "BLOB_UPLOAD_STARTED":
        case "BLOB_UPLOAD_FINISHED":
        case "BLOB_UPLOAD_ERROR":
        case "PROFILEUPLOAD_FETCH_STARTED":
        case "PROFILEUPLOAD_FETCH_FINISHED":
        case "PROFILEUPLOAD_FETCH_ERROR":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },
  doDocumentPivotUpload:
    (file, site, subject, type, body, id) =>
    ({ dispatch, store, apiFetch }) => {
      const mime_type = file.type;
      dispatch({
        type: "PROFILE_UPLOAD_STARTED",
        payload: { isUploading: true },
      });
      let currentProject = store
        .selectProjectData()
        .filter(
          (p) => p.site_code === parseInt(store.selectRouteParams().project)
        );
      currentProject =
        currentProject && currentProject.length && currentProject[0];
      let officeId = currentProject.office_id;

      store.doActionPostData(
        `/wq_documents/`,
        {
          blob_content: file,
          mime_type: mime_type,
          subject: subject,
          document_type_code: 11,
          clob_content: JSON.stringify(body),
          last_updated: new Date(),
          id,
          site_code: site,
          office_id: officeId,
          name: file.name,
        },
        (res) =>
          dispatch({
            type: "PROFILE_UPLOAD_FINISHED",
            payload: {
              status: res.status,
              shouldUploadBlob: true,
              blob: file,
              documentId: id,
              isUploading: false,
              // shouldFetch: true,
            },
          }),
        (e) =>
          dispatch({
            type: "PROFILE_UPLOAD_ERROR",
            payload: {
              status: 404,
              err: e,
              isUploading: false,
            },
          })
      );
    },
  // action creators
  doDocumentBlobUpload:
    (blob, docId) =>
    ({ dispatch, store }) => {
      const { doActionPutData } = store;
      dispatch({
        type: "BLOB_UPLOAD_STARTED",
        payload: { shouldUploadBlob: false, isUploading: true },
      });
      doActionPutData(
        `/documents/upload/${docId}`,
        blob,
        () => {
          dispatch({
            type: "BLOB_UPLOAD_FINISHED",
            payload: { isUploading: false, shouldFetch: true },
          });
        },
        (e) =>
          dispatch({
            type: "BLOB_UPLOAD_ERROR",
            payload: { error: e, isUploading: false },
          })
      );
    },

  doProfileUploadFetch:
    () =>
    ({ dispatch, apiFetch }) => {
      dispatch({
        type: "PROFILEUPLOAD_FETCH_STARTED",
        payload: { isFetching: true, shouldFetch: false },
      });
      apiFetch("/profile_uploads/")
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "PROFILEUPLOAD_FETCH_FINISHED",
            payload: { data: j.items, isFetching: false },
          });
        })
        .catch((err) =>
          dispatch({
            type: "PROFILEUPLOAD_FETCH_ERROR",
            payload: { error: err, isFetching: false },
          })
        );
    },

  doDocumentProfileDownload:
    (doc) =>
    ({ apiFetch }) => {
      const type = doc.mime_type;
      apiFetch(`/documents/download/profile/${doc.id}`)
        .then((r) => {
          return r.json();
        })
        .then((blob) => {
          const newBlob = new Blob([blob.items[0].blob_content], {
            type: type,
          });
          const blobUrl = window.URL.createObjectURL(newBlob);
          window.open(blobUrl);
          URL.revokeObjectURL(newBlob);
        });
    },
  selectCurrentBlob: (state) => state.profileUpload.blob,
  selectBlobShouldUpload: (state) => state.profileUpload.shouldUploadBlob,
  selectCurrentDocumentId: (state) => state.profileUpload.documentId,
  selectProfileUploadShouldFetch: (state) => state.profileUpload.shouldFetch,
  selectProfileUploadData: (state) => state.profileUpload.data,
  selectProfileUploadIsFetching: (state) => state.profileUpload.isFetching,

  reactBlobShouldUpload: createSelector(
    "selectBlobShouldUpload",
    "selectCurrentBlob",
    "selectCurrentDocumentId",
    (blobShouldUpload, currentBlob, currentDocumentId) => {
      if (blobShouldUpload) {
        return {
          actionCreator: "doDocumentBlobUpload",
          args: [currentBlob, currentDocumentId],
        };
      }
    }
  ),

  reactProfileUploadsShouldFetch: createSelector(
    "selectProfileUploadShouldFetch",
    (profileUploadShouldFetch) => {
      if (profileUploadShouldFetch) {
        return {
          actionCreator: "doProfileUploadFetch",
          args: [],
        };
      }
    }
  ),
};
