import React, { useState, useEffect } from "react";
import { connect } from "redux-bundler-react";
import Link from "../../misc/link";
import "./customCard.css";

function CardReports(props) {
  const {} = props;

  return (
    <div className="custom">
      <a
        style={{
          textDecoration: "none",
          cursor: "pointer",
          height: "100%",
        }}
        href={`/projects/${props.project.site_code}/locations/${props.location.location_code}/reports`}>
        <div className="card-body h-100">
          <div className="custom-body">
            <div>
              <h4
                className="card-title border-bottom"
                style={{ color: "#3c4b64" }}>
                Reports
              </h4>
            </div>
            <Link
              style={{ fontWeight: "bold" }}
              to={`/projects/${props.project.site_code}/locations/${props.location.location_code}/reports`}>
              Go to Reports
            </Link>
          </div>
        </div>
      </a>
    </div>
  );
}

export default connect(CardReports);
