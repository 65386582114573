import React, { useReducer, useEffect } from "react";
import { connect } from "redux-bundler-react";
import {
  ModalFooter,
  ModalContent,
  ModalHeader,
} from "../../app-components/modal";
import ReactSelect from "react-select";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "UPDATE_PARTICIPANTS":
      return { ...state, [action.field]: action.payload, name: "" };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const SimpleSampleModal = ({
  doNestedModalClose,
  collectionsSelects,
  field_visit_code,
  location_code,
  sample_code,
  doSamplePost,
  doCollectionsFetch,
  sampleError: error,
  sampleIsSaving,
}) => {
  const initialState = { field_visit_code, sample_code, location_code };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(doCollectionsFetch, []);

  const handleSelect = (field, payload) => {
    dispatch({
      type: "UPDATE_INPUT",
      field,
      payload,
    });
  };

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const _renderSampleForm = () => {
    return (
      <>
        <div className="d-flex mb-2">
          <div className="d-flex flex-column w-100 mr-1">
            <label htmlFor="sample_time">Sample Date</label>
            <input
              className="custom-input"
              type="datetime-local"
              placeholder="Sample Date"
              value={
                state["sample_time"] && state["sample_time"].includes("Z")
                  ? state["sample_time"].split("Z")[0]
                  : state["sample_time"]
              }
              name={"sample_time"}
              id={"sample_time"}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex flex-column w-100 ml-1">
            <label htmlFor="sample_num">Sample Number</label>
            <input
              className="custom-input"
              type="text"
              value={state["sample_num"]}
              name={"sample_num"}
              id={"sample_num"}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="two-grid">
          <div className="d-flex flex-column w-100 mb-2">
            <label htmlFor="sample_depth">Sample Depth</label>
            <input
              className="custom-input"
              type="number"
              placeholder="Sample Depth"
              value={state["sample_depth"]}
              name={"sample_depth"}
              id={"sample_depth"}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex flex-column w-100 mb-2">
            <label htmlFor="sample_type">Sample Type</label>
            <input
              className="custom-input"
              placeholder="Sample Type"
              value={state["sample_type"]}
              name={"sample_type"}
              id={"sample_type"}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-2">
          <label htmlFor="collect">Collection Method</label>
          <ReactSelect
            value={
              collectionsSelects && state["collect_mthd"]
                ? collectionsSelects.filter(
                    (obj) => obj.value === state["collect_mthd"]
                  )[0]
                : null
            }
            name={"collect_mthd"}
            inputId="collect"
            options={collectionsSelects.map((item) => ({
              value: item.value,
              label: item.label,
            }))}
            onChange={(obj) => handleSelect("collect_mthd", obj.value)}
            menuPosition="fixed"
          />
        </div>
      </>
    );
  };
  return (
    <ModalContent size="md" error={error}>
      <ModalHeader title={"Add Sample"} onClose={doNestedModalClose} />
      <section className="modal-body">
        <div className="container-fluid">{_renderSampleForm()}</div>
      </section>
      <ModalFooter
        onSave={() => {
          state.sample_time =
            state.sample_time && !state.sample_time.includes("Z")
              ? `${state.sample_time}:00Z`
              : state.sample_time;
          doSamplePost(state, doNestedModalClose);
        }}
        showCancelButton
        onCancel={doNestedModalClose}
        customClosingLogic
        saveText={
          sampleIsSaving ? <i className="mdi mdi-loading mdi-spin" /> : "Save"
        }
      />
    </ModalContent>
  );
};

export default connect(
  "doNestedModalClose",
  "selectCollectionsSelects",
  "doSamplePost",
  "doCollectionsFetch",
  "selectSampleError",
  "selectSampleIsSaving",
  SimpleSampleModal
);
