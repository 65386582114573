import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "aigsTemp",
  getReducer: () => {
    const initialData = {
      data: [],
      shouldFetch: false,
      isFetching: false,

      parameters: [],
      paramsShouldFetch: false,
      paramsIsFetching: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "AIGS_TEMP_FETCH_START":
        case "AIGS_TEMP_FETCH_FINISH":
        case "AIGS_TEMP_SHOULD_FETCH":
        case "AIGS_PARAMS_FETCH_START":
        case "AIGS_PARAMS_FETCH_FINISH":
        case "AIGS_PARAMS_SHOULD_FETCH":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doAigsParamsShouldFetch:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "AIGS_PARAMS_SHOULD_FETCH",
        payload: {
          paramsShouldFetch: true,
        },
      });
    },

  doAigsParamsFetch:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "AIGS_PARAMS_FETCH_START",
        payload: {
          paramsShouldFetch: false,
          paramsIsFetching: true,
        },
      });
      const url = ordsifyUrlBuilder("/aigs_imported_params/", [], 100000);

      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "AIGS_PARAMS_FETCH_FINISH",
            payload: {
              paramsIsFetching: false,
              parameters: j.items,
            },
          });
        });
    },

  doAigsTempShouldFetch:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "AIGS_TEMP_SHOULD_FETCH",
        payload: {
          shouldFetch: true,
        },
      });
    },

  doAigsTempFetch:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "AIGS_TEMP_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });
      const url = ordsifyUrlBuilder(
        "/aigs_temp_observations_t_new/",
        [],
        100000
      );

      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "AIGS_TEMP_FETCH_FINISH",
            payload: {
              isFetching: false,
              data: j.items,
            },
          });
        });
    },
  selectAigsTempRaw: (store) => store.aigsTemp,
  selectAigsTempData: (state) => state.aigsTemp.data,
  selectAigsTempIsFetching: (store) => store.aigsTemp.isFetching,
  selectAigsTempShouldFetch: (store) => store.aigsTemp.shouldFetch,

  selectAigsParams: (state) => state.aigsTemp.parameters,
  selectAigsParamsIsFetching: (store) => store.aigsTemp.paramsIsFetching,
  selectAigsParamsShouldFetch: (state) => state.aigsTemp.paramsShouldFetch,

  reactAigsTempShouldFetch: createSelector(
    "selectAigsTempIsFetching",
    "selectAigsTempShouldFetch",
    (isFetching, shouldFetch) => {
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doAigsTempFetch",
          args: [{}],
        };
      }
    }
  ),

  reactAigsParamsShouldFetch: createSelector(
    "selectAigsParamsIsFetching",
    "selectAigsParamsShouldFetch",
    (isFetching, shouldFetch) => {
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doAigsParamsFetch",
          args: [{}],
        };
      }
    }
  ),
};
