import React from "react";

const Anchor = ({ children, href, onClick }) => (
  <a
    href={href}
    onClick={onClick}
    className="dropdown-item dropdown-item-styled"
    style={{ whiteSpace: "pre-wrap" }}
  >
    {children}
  </a>
);
const Div = ({ children, onClick }) => (
  <div
    className="d-flex align-items-center justify-content-center"
    onClick={onClick}
    id="no-collapse"
  >
    {children}
  </div>
);
export default ({ onClick, children, href }) => {
  let Item = href ? Anchor : Div;
  return (
    <Item onClick={onClick} href={href}>
      {children}
    </Item>
  );
};
