import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilderNoSlash } from "../utils/ordsify";

export default {
  name: "collections",
  getReducer: () => {
    const initialData = {
      selects: [],
      shouldFetch: false,
      data: [],
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "COLLECTIONS_FETCH_STARTED":
        case "COLLECTIONS_FETCH_FINISHED":
        case "COLLECTIONS_FETCH_SHOULD":
        case "COLLECTIONS_FETCH_DONE":
        case "COLLECTIONS_FETCH_BEG":
        case "COLLECTION_SAVE_STARTED":
        case "COLLECTION_SAVE_FINISHED":
        case "COLLECTION_SAVE_ERROR":
        case "COLLECTION_DELETE_STARTED":
        case "COLLECTION_DELETE_FINISHED":
        case "COLLECTION_DELETE_ERROR":
          return Object.assign({}, state, payload);
        case "MODAL_CLOSED":
          return { ...state, error: null };
        case "BIOSAMPLE_FETCH_FINISH":
          return { ...state, ...payload, shouldFetch: true };
        default:
          return state;
      }
    };
  },

  doCollectionsShouldFetch:
    (type) =>
    ({ dispatch }) => {
      dispatch({
        type: "COLLECTIONS_FETCH_SHOULD",
        payload: { shouldFetch: true },
      });
    },

  doCollectionsFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "COLLECTIONS_FETCH_STARTED",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      const createData = (data) => {
        var list = [];
        var i = 0;
        for (i = 0; i < data.length; i++) {
          list.push({
            value: data[i].collection_method_code,
            label: data[i].meth_name,
          });
        }
        dispatch({
          type: "COLLECTIONS_FETCH_FINISHED",
          payload: {
            selects: list,
          },
        });
      };

      apiFetch("/collect_methods/?q={}&limit=500")
        .then((response) => response.json())
        .then((r) => createData(r.items))
        .catch((e) => {
          console.log(e);
        });
    },

  doCollectionTableFetch:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "COLLECTIONS_FETCH_BEG",
        payload: {
          shouldFetch: false,
          shouldTableFetch: false,
          isFetching: true,
        },
      });
      const url = "/collect_methods/?q={}&limit=500";

      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "COLLECTIONS_FETCH_DONE",
            payload: {
              data: j.items,
              isFetching: false,
            },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },

  doCollectionPost:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPostData, doModalClose } = store;
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "COLLECTION_SAVE_STARTED",
        payload: { isSaving: true },
      });
      let url = `${apiRoot}/wq_collection_methods/`;
      doActionPostData(
        url,
        state,
        () => {
          dispatch({
            type: "COLLECTION_SAVE_FINISHED",
            payload: { isSaving: false, shouldTableFetch: true, error: null },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "COLLECTION_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },
  doCollectionPut:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPutData, doModalClose } = store;
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "COLLECTION_SAVE_STARTED",
        payload: { isSaving: true },
      });
      let url = `${apiRoot}/wq_collection_methods/${state.collection_method_code}`;
      doActionPutData(
        url,
        state,
        () => {
          dispatch({
            type: "COLLECTION_SAVE_FINISHED",
            payload: { isSaving: false, shouldTableFetch: true, error: null },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "COLLECTION_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doCollectionDelete:
    (state) =>
    ({ dispatch, store }) => {
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "COLLECTION_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      store.doActionDeleteData(
        `${apiRoot}/wq_collection_methods/${state.collection_method_code}`,
        {},
        () => {
          dispatch({
            type: "COLLECTION_DELETE_FINISHED",
            payload: { isDeleting: false, shouldTableFetch: true, error: null },
          });
          store.doModalClose();
        },
        (e) =>
          dispatch({
            type: "COLLECTION_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },
  // selectors

  selectCollectionIsDeleting: (state) => state.collections.isDeleting,
  selectCollectionIsSaving: (state) => state.collections.isSaving,
  selectCollectionError: (state) => state.collections.error,

  selectCollectionsSelects: (state) => {
    return state.collections.selects;
  },

  selectCollectionIsFetching: (state) => state.collections.isFetching,
  selectCollectionShouldFetch: (state) => state.collections.shouldFetch,
  selectCollections: (state) => state.collections.data,

  reactCollectionsShouldFetch: (state) => {
    if (state.collections.shouldFetch)
      return {
        actionCreator: "doCollectionsFetch",
      };
  },
  reactCollectionsTableShouldFetch: (state) => {
    if (state.collections.shouldTableFetch)
      return {
        actionCreator: "doCollectionTableFetch",
      };
  },
};
