import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import CardIndices from "./cards/CardIndices";
import CardSamples from "./cards/CardSamples";
import CardNotes from "./cards/CardNotes";
import CardResults from "./cards/CardResults";
import CardAliases from "./cards/CardAliases";
import CardTasks from "./cards/CardTasks";
import CardBioSamples from "./cards/CardBioSamples";
import CardBioResults from "./cards/CardBioResults";
import CardFieldVisits from "./cards/CardFieldVisits";
import "./locationHome.css";
import classnames from "classnames";
import LRNForm from "./modals/LRNForm";
import AddLocationModal from "../project/AddLocationModal";
import { useEffect } from "react";
import LRHForm from "./modals/LRHForm";
import CardReports from "./cards/CardReports";

export default connect(
  "doNoteShouldFetch",
  "doResultsummaryShouldFetch",
  "doSampleShouldFetch",
  "selectRouteParams",
  "selectLocation",
  "selectStream",
  "selectProject",
  "selectUserIsOrgMemberOrAdmin",
  "doModalOpen",
  "doModalOpenUniqueCode",
  "doLocationFetch",
  "doStreamFetch",
  "doStateShouldFetch",
  "doMajorBasinShouldFetch",
  "doMinorBasinShouldFetch",
  "doLocationTypeShouldFetch",
  ({
    routeParams,
    location,
    stream,
    project,
    userIsOrgMemberOrAdmin,
    doModalOpen,
    doModalOpenUniqueCode,
    doLocationFetch,
    doStreamFetch,
    doStateShouldFetch,
    doMajorBasinShouldFetch,
    doMinorBasinShouldFetch,
    doLocationTypeShouldFetch,
  }) => {
    const [fieldDataOpen, setFieldDataOpen] = useState(false);
    useEffect(() => {
      doLocationFetch([routeParams.id]);
      doStreamFetch();
      doStateShouldFetch();
      doMajorBasinShouldFetch();
      doMinorBasinShouldFetch();
      doLocationTypeShouldFetch();

      if (window.location.hash === "#upload") {
        doModalOpenUniqueCode(LRHForm, {}, "form_code"); // open new form for uploading lake intake and outflow for LRH
      }
    }, []);

    const dropdownMenuClass = classnames({
      "dropdown-menu": true,
      show: fieldDataOpen,
      rounded: true,
    });

    return (
      <div style={{ padding: "0 64px 32px" }}>
        {!location || !project ? (
          <div style={{ textAlign: "center" }}>
            <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
          </div>
        ) : (
          <>
            <div
              className="border-bottom mb-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: 8,
              }}>
              <div style={{ display: "inline-flex" }}>
                <div className="card-body p-0">
                  <h3 className="card-title bold m-0">
                    {`${location.loc_id}`}
                    <small className="text-muted ml-2">
                      {!project.office_id ? "" : project.office_id}
                    </small>
                  </h3>
                  <p className="card-subtitle m-0">{location.loc_desc}</p>
                </div>
              </div>
              <div style={{ display: "inline-flex" }}>
                {userIsOrgMemberOrAdmin && (
                  <div className="dropdown">
                    <button
                      className="btn btn-outline-primary dropdown-toggle mr-2"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded={fieldDataOpen}
                      onClick={() => setFieldDataOpen(!fieldDataOpen)}>
                      Input Field Data
                    </button>
                    <div
                      className={dropdownMenuClass}
                      aria-labelledby="dropdownMenuButton">
                      <a
                        className="dropdown-item cursor-pointer"
                        onClick={() => {
                          setFieldDataOpen(false);
                          doModalOpen(LRNForm);
                        }}>
                        CELRN Form 561
                      </a>
                      {project.profile_loc === location.location_code && (
                        <a
                          className="dropdown-item cursor-pointer"
                          onClick={() => {
                            doModalOpenUniqueCode(LRHForm, {}, "form_code");
                          }}>
                          LRH Lake Quality
                        </a>
                      )}
                    </div>
                  </div>
                )}
                {userIsOrgMemberOrAdmin && (
                  <button
                    className="btn btn-primary"
                    style={{ maxWidth: 250, maxHeight: 35 }}
                    onClick={() => {
                      doModalOpen(AddLocationModal, { edit: true });
                    }}>
                    View / Edit Location Details
                  </button>
                )}
              </div>
            </div>
            <div className="half-grid-container">
              <div
                style={{
                  display: "grid",
                  gridTemplateRows: "0.5fr 1fr 0.5fr 0.25fr",
                  gap: 20,
                }}>
                <CardResults location={location} project={project} />
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: 20,
                  }}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr",
                      gridTemplateRows:
                        project.profile_loc === location.location_code
                          ? "1fr 1fr"
                          : "1fr",
                      gap: 20,
                    }}>
                    <CardSamples location={location} project={project} />
                    {project.profile_loc === location.location_code && (
                      <CardReports location={location} project={project} />
                    )}
                  </div>
                  <CardTasks location={location} project={project} />
                </div>

                <CardFieldVisits location={location} project={project} />

                <CardAliases location={location} project={project} />
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateRows: "1fr 0.5fr 0.5fr 0.25fr",
                  gap: 20,
                }}>
                <CardIndices location={location} project={project} />

                <CardBioResults
                  location={location}
                  project={project}
                  routeParams={routeParams}
                />
                <CardBioSamples location={location} project={project} />
                <CardNotes location={location} project={project} />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);
