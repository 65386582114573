import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "minorBasin",
  getReducer: () => {
    const initialData = {
      data: [],
      shouldFetch: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "AUTH_VERIFY_TOKEN":
          return { ...state, shouldFetch: true };
        case "MINOR_BASIN_FETCH_START":
        case "MINOR_BASIN_TABLE_FETCH_START":
        case "MINOR_BASIN_FETCH_FINISH":
        case "MINOR_BASIN_SHOULD_FETCH":
        case "MINOR_BASIN_SAVE_STARTED":
        case "MINOR_BASIN_SAVE_FINISHED":
        case "MINOR_BASIN_SAVE_ERROR":
        case "MINOR_BASIN_DELETE_STARTED":
        case "MINOR_BASIN_DELETE_FINISHED":
        case "MINOR_BASIN_DELETE_ERROR":
          return Object.assign({}, state, payload);
        case "MODAL_CLOSED":
          return { ...state, error: null };
        default:
          return state;
      }
    };
  },

  doMinorBasinShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "MINOR_BASIN_SHOULD_FETCH",
        payload: { shouldFetch: true },
      });
    },

  doMinorBasinFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "MINOR_BASIN_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      let url = "/minor_basins/";
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "MINOR_BASIN_FETCH_FINISH",
            payload: { data: j.items, isFetching: false },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },
  doMinorBasinTableFetch:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "MINOR_BASIN_TABLE_FETCH_START",
        payload: {
          shouldTableFetch: false,
          isFetching: true,
        },
      });

      let url = "/wq_v_minor_basins/";
      if (state) {
        url = ordsifyUrlBuilder(
          "/wq_v_minor_basins/",
          [
            {
              keyword: "minor_code",
              items: state["minor_code"] ? state["minor_code"] : "", // if no location_codes passed, fetch all
            },
          ],
          20000
        );
      }
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "MINOR_BASIN_FETCH_FINISH",
            payload: { data: j.items, isFetching: false },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },

  doMinorBasinPost:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPostData, doModalClose } = store;
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "MINOR_BASIN_SAVE_STARTED",
        payload: { isSaving: true },
      });
      let url = `${apiRoot}/minor_basins/`;
      doActionPostData(
        url,
        state,
        () => {
          dispatch({
            type: "MINOR_BASIN_SAVE_FINISHED",
            payload: { isSaving: false, shouldTableFetch: true, error: null },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "MINOR_BASIN_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doMinorBasinPut:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPutData, doModalClose } = store;
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "MINOR_BASIN_SAVE_STARTED",
        payload: { isSaving: true },
      });
      let url = `${apiRoot}/minor_basins/${state.minor_code}`;
      doActionPutData(
        url,
        state,
        () => {
          dispatch({
            type: "MINOR_BASIN_SAVE_FINISHED",
            payload: { isSaving: false, shouldTableFetch: true, error: null },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "MINOR_BASIN_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },
  doMinorBasinDelete:
    (state) =>
    ({ dispatch, store }) => {
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "MINOR_BASIN_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      store.doActionDeleteData(
        `${apiRoot}/minor_basins/${state.minor_code}`,
        {},
        () => {
          dispatch({
            type: "MINOR_BASIN_DELETE_FINISHED",
            payload: { isDeleting: false, shouldTableFetch: true, error: null },
          });
          store.doModalClose();
        },
        (e) =>
          dispatch({
            type: "MINOR_BASIN_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },

  selectMinorBasinIsFetching: (state) => state.minorBasin.isFetching,
  selectMinorBasinShouldFetch: (state) => state.minorBasin.shouldFetch,
  selectMinorBasinTableShouldFetch: (state) =>
    state.minorBasin.shouldTableFetch,
  selectMinorBasinAll: (state) => state.minorBasin.data,

  selectMinorBasinIsDeleting: (state) => state.minorBasin.isDeleting,
  selectMinorBasinIsSaving: (state) => state.minorBasin.isSaving,
  selectMinorBasinError: (state) => state.minorBasin.error,

  reactMinorBasinShouldFetch: createSelector(
    "selectMinorBasinIsFetching",
    "selectMinorBasinShouldFetch",
    (isFetching, shouldFetch) => {
      // never fetch if anoter fetch already in progress
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doMinorBasinFetch",
        };
      }
    }
  ),

  reactMinorBasinTableShouldFetch: createSelector(
    "selectMinorBasinIsFetching",
    "selectMinorBasinTableShouldFetch",
    (isFetching, shouldFetch) => {
      // never fetch if anoter fetch already in progress
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doMinorBasinTableFetch",
        };
      }
    }
  ),
};
