import { createSelector } from "redux-bundler";
// import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "indices",
  getReducer: () => {
    const initialData = {
      data: [],
      sorted: {},
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "INDICES_FETCH_START":
        case "INDICES_FETCH_FINISH":
        case "INDICES_SHOULD_FETCH":
        case "UPDATE_SORTED_OBJECT":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },
  doSetSortedIndicesObject:
    () =>
    ({ dispatch, store }) => {
      let sortedObjectArray = {};
      store.selectIndices().forEach((sample) => {
        const { location_code } = sample;
        if (sortedObjectArray[location_code]) {
          sortedObjectArray[location_code].push(sample);
        } else {
          sortedObjectArray[location_code] = [sample];
        }
      });
      dispatch({
        type: "UPDATE_SORTED_OBJECT",
        payload: { sorted: sortedObjectArray },
      });
    },
  doIndicesShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "INDICES_SHOULD_FETCH",
        payload: { shouldFetch: true },
      });
    },

  doIndicesFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "INDICES_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      let url = `/indicies/site_data/${store.selectRouteParams().project}`;
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "INDICES_FETCH_FINISH",
            payload: { data: j.items, isFetching: false },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },
  selectIndicesIsFetching: (state) => state.indices.isFetching,
  selectIndicesShouldFetch: (state) => state.indices.shouldFetch,
  selectIndices: (state) => state.indices.data,
  selectSortedIndices: (state) => state.indices.sorted,

  reactIndicesShouldFetch: createSelector(
    "selectIndicesIsFetching",
    "selectIndicesShouldFetch",
    (isFetching, shouldFetch) => {
      // never fetch if anoter fetch already in progress
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doIndicesFetch",
        };
      }
    }
  ),
};
