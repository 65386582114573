import React, { useState } from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";
import NoteModal from "./NoteModal";
// import { postData, putData, deleteData } from "../../utils/fetch";

function TableNote(props) {
  const {
    noteData,
    noteIsFetching,
    doNoteShouldFetch,
    userIsOrgMemberOrAdmin,
    doModalOpen,
    doModalOpenUniqueCode,
  } = props;

  return (
    <div className="custom-table">
      <div className="card-body">
        <div className="d-flex flex-column w-100">
          {userIsOrgMemberOrAdmin && (
            <div className="d-flex justify-content-between align-items-center w-100">
              <h4>Notes</h4>
              <button
                className={`btn btn-outline-${"success"} m-2`}
                onClick={() => {
                  doModalOpenUniqueCode(NoteModal, {}, "id");
                }}
              >
                {"Add Note"}
              </button>
            </div>
          )}
          <MaterialTable
            title=""
            isLoading={noteIsFetching}
            columns={[
              {
                field: "link",
                title: "Actions",
                render: (rowData) => {
                  if (userIsOrgMemberOrAdmin) {
                    return (
                      <button
                        className="hover"
                        onClick={(e) => {
                          doModalOpen(NoteModal, {
                            data: rowData,
                            edit: true,
                          });
                        }}
                      >
                        <i className="mdi mdi-pencil-outline mdi-24px"></i>
                      </button>
                    );
                  }
                },
              },
              {
                title: "Date Created",
                field: "date_created",
                editable: false,
              },
              {
                title: "Date Effective",
                field: "date_effective",
                editComponent: (props) => (
                  <input
                    type="text"
                    value={new Date()}
                    onChange={(e) => props.onChange(e.target.value)}
                  />
                ),
              },
              { title: "Note", field: "note" },
            ]}
            data={noteData}
            options={{
              loadingType: "overlay",
              exportButton: true,
              exportAllData: true,
              sorting: true,
              pageSize: 10,
            }}
            actions={[
              {
                icon: "refresh",
                tooltip: "Refresh table",
                isFreeAction: true,
                onClick: (event) => doNoteShouldFetch(),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default connect(
  "doActionPostData",
  "doActionPutData",
  "doActionDeleteData",
  "selectNoteData",
  "selectNoteIsFetching",
  "selectUserIsOrgMemberOrAdmin",
  "doNoteShouldFetch",
  "selectApiRoot",
  "doModalOpen",
  "doCwmsSeqNewFetch",
  "doModalOpenUniqueCode",
  TableNote
);
