import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import ReactSelect from "react-select";
import { useEffect } from "react";
import { find } from "lodash";
import pkg from "../../../package.json";
import cm from "./img/cm.png";

const AccessRequestForm = ({
  officeData,
  tokenKeyCloakId,
  doPostAccessRequest,
  accessRequestByUser,
  accessRequestIsFetching,
  accessRequestIsSaving,
  doAccessRequestFetchByUser,
  userOfficesOptions,
  authIsLoggedIn,
  doOfficeFetch,
}) => {
  const [office, setOffice] = useState();
  const [success, showSuccess] = useState(false);
  useEffect(() => {
    doAccessRequestFetchByUser();
    doOfficeFetch();
  }, []);

  let officeOptions = officeData.filter(
    (o) =>
      !find(userOfficesOptions, (obj) => o.office_id === obj.value) &&
      !find(accessRequestByUser, (a) => o.office_id === a.office_id)
  );

  return (
    <div className="background">
      <div className="header-card" style={{ position: "relative" }}>
        <div className="d-flex flex-column justify-content-around h-100 align-items-center">
          <div
            className="d-flex align-items-end pr-3 pl-3"
            style={{ paddingBottom: authIsLoggedIn ? 24 : 108 }}
          >
            <div className="d-flex align-items-start">
              <img className="cm" src={cm} loading="lazy" />
              <div className="d-flex flex-column align-items-start">
                <div className="h1 text-black m-0">USACE Civil Works</div>
                <div
                  className="h2 d-flex flex-column align-items-start"
                  style={{ color: "#1976d2" }}
                >
                  Water Quality Enterprise Database
                  <h2 className="text-muted">{pkg.version}</h2>
                </div>
              </div>
            </div>
          </div>
          {accessRequestIsFetching ? (
            <i className="mdi mdi-loading mdi-spin mdi-48px" />
          ) : (
            <div className="d-flex flex-column w-100">
              <h5>Office Access Request Form</h5>
              <p className="text-muted">
                Please select an office below to gain access to, and an admin
                will be notified to grant your access.
              </p>
              <div style={{ width: 350, margin: "0 auto" }}>
                <div className="d-flex justify-content-start">
                  <label>Select Office</label>
                </div>
                <div
                  style={{
                    display: "grid",
                    gap: 8,
                    gridTemplateColumns: "2fr 2fr",
                  }}
                >
                  <ReactSelect
                    name="office"
                    onChange={(obj) => setOffice(obj)}
                    options={officeOptions.map((o) => ({
                      label: o.office_id,
                      value: o.office_id,
                    }))}
                    value={office}
                    noOptionsMessage={() => (
                      <p className="m-0">
                        You have access to all organizations, or are a system
                        administrator.
                      </p>
                    )}
                    placeholder="TBD"
                  />
                  <button
                    className="btn btn-primary w-100"
                    onClick={() =>
                      doPostAccessRequest(
                        {
                          office_id: office.value,
                          keycloak_id: tokenKeyCloakId,
                        },
                        () => {
                          setOffice(null);
                          showSuccess(true);
                          setTimeout(() => showSuccess(false), 5000);
                        }
                      )
                    }
                  >
                    {accessRequestIsFetching || accessRequestIsSaving ? (
                      <i className="mdi mdi-loading mdi-spin mdi-18px" />
                    ) : (
                      `Send Request`
                    )}
                  </button>
                </div>
              </div>
              <div className="w-100 d-flex justify-content-center">
                <a
                  className=" p-0 mt-2 d-inline-flex justify-content-center"
                  href="/"
                  style={{ width: 200 }}
                >
                  <b className="link">Return to Home.</b>
                </a>
              </div>
              {success && (
                <div className="mr-3 ml-3 mt-2 d-flex justify-content-center">
                  <div className={`alert alert-success w-75`} role="alert">
                    <h6 className="alert-heading">
                      Request sent successfully!
                    </h6>
                    <p>An administrator will review your request shortly.</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(
  "selectOfficeData",
  "selectTokenKeyCloakId",
  "doPostAccessRequest",
  "selectAccessRequestByUser",
  "selectAccessRequestIsFetching",
  "selectAccessRequestIsSaving",
  "doAccessRequestFetchByUser",
  "selectUserOfficesOptions",
  "selectAuthIsLoggedIn",
  "doOfficeFetch",
  AccessRequestForm
);
