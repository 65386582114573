import React from "react";
import { connect } from "redux-bundler-react";
import { TableContainer } from "../../../app-components/page-content";
import FilterSidebar from "../../dataExport/FilterSidebar";
import TableCounties from "./TableCounties";
import countiesConfig from "./counties.config.json";

const PageCounties = ({ routeParams, ...props }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "calc(100vh - 106px)",
      }}>
      {" "}
      <FilterSidebar config={countiesConfig} {...props} />
      <TableContainer>
        <TableCounties />
      </TableContainer>
    </div>
  );
};
export default connect(
  "selectRouteParams",
  ...countiesConfig.selectors,
  ...countiesConfig.fetchFns,
  PageCounties
);
