import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import { sortBy } from "lodash";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import "../location/locationHome.css";
import "./trends.css";

HighchartsMore(Highcharts);

const DATE_OPTIONS = [
  { value: "All", label: "All" },
  { value: JSON.stringify([1971, 1980]), label: "1971-1980" },
  { value: JSON.stringify([1981, 1990]), label: "1981-1990" },
  { value: JSON.stringify([1991, 2000]), label: "1991-2000" },
  { value: JSON.stringify([2001, 2010]), label: "2001-2010" },
  { value: JSON.stringify([2011, 2020]), label: "2011-2020" },
];
const TrendsPlot = ({
  doTrendsFetch,
  trends,
  trendsIsFetching,
  storetData,
  doStoretFetch,
  routeParams,
  projectAll,
}) => {
  const [date, setDate] = useState("All");
  const [currentData, setCurrentData] = useState([]);
  const [series, setSeries] = useState([]);
  /** need to clean these up , switch to useReducer instad...  */

  /* This is the storet number selected based on the dropdown */
  const [currentStoret, setCurrentStoret] = useState();

  /* This is all of the storetValues that exist for the project */
  const [storetVals, setStoretVals] = useState([]);

  /* This is all the units / labels that exist for the project */
  const [yAxisLabels, setYAxisLabels] = useState([]);

  /* This is the current units based on currentStoret */
  const [currentLabel, setCurrentLabel] = useState();

  /* This is the entire object of the storet code, to access the full_name of it... maybe add this to the endpoint so i dont need a separate fetch */
  const [storetObject, setStoretObject] = useState();

  /** Temporary states for new Select, need to refactor page  */
  const [storetOption, setStoretOption] = useState({});
  const [dateOption, setDateOption] = useState({ value: "All", label: "All" });

  useEffect(() => doTrendsFetch(), []);

  useEffect(() => {
    let sortedTrends = sortBy(trends, "sample_year");
    let storetCodes = [],
      unitLabels = [];
    /* Create array of storet codes within the current trends data, as items in the dropdown */
    sortedTrends.forEach((obj) => {
      if (
        !storetCodes.includes(obj.storet_short_name) &&
        obj.num_records !== 0
      ) {
        storetCodes.push(obj.storet_short_name);
        unitLabels.push(obj.units);
      }
    });
    /* set these values to a local state to be available in the component */
    setStoretVals(storetCodes);
    setYAxisLabels(unitLabels);
    /* only update the current storet value if the new array doesn't include the current value */
    if (!currentStoret || !storetCodes.includes(currentStoret)) {
      setCurrentStoret(storetCodes[0]);
      setStoretOption({ value: storetCodes[0], label: storetCodes[0] });
    }
  }, [trends]);

  useEffect(() => {
    let sortedTrends = sortBy(trends, "sample_year");
    doStoretFetch();
    setStoretObject(_getStoretObject());
    let boxplotData = [];
    /* Creating arrays of statistical data formatted for highcharts boxplot */
    sortedTrends.forEach((obj) => {
      if (obj.storet_short_name === currentStoret) {
        boxplotData.push([
          obj["sample_year"],
          obj["highest_minimum"],
          obj["highest_25th"],
          obj["highest_median"],
          obj["highest_75th"],
          obj["highest_maximum"],
        ]);
        boxplotData.push([
          obj["sample_year"],
          obj["lowest_minimum"],
          obj["lowest_25th"],
          obj["lowest_median"],
          obj["lowest_75th"],
          obj["lowest_maximum"],
        ]);
      }
    });

    /* Get current label */
    setCurrentLabel(yAxisLabels[storetVals.indexOf(currentStoret)]);
    setCurrentData(boxplotData);
  }, [storetVals, currentStoret]);

  const _getStoretObject = () =>
    storetData.filter((item) => item.storet_short_name === currentStoret)[0];

  const handleDateChange = (obj) => {
    if (obj.value === "All") {
      doTrendsFetch();
    } else {
      let arr = JSON.parse(obj.value);
      doTrendsFetch(arr);
    }
    setDate(obj.value);
    setDateOption(obj);
  };

  const handleStoretChange = (obj) => {
    setCurrentStoret(obj.value);
    setStoretOption(obj);
  };
  let activeProject = projectAll.find(
    (obj) => routeParams.project == obj.site_code
  );

  useEffect(() => {
    let sample_year_data = [];
    currentData &&
      currentData.forEach((arr, i) => {
        if (i > 0 && currentData[i - 1][0] === arr[0]) {
          sample_year_data.push({
            type: "boxplot",
            name: "Observations",
            data: [arr],
            lineColor: "blue",
            lineWidth: 2,
            whiskerColor: "blue",
            whiskerWidth: 2,
            stemColor: "blue",
            stemWidth: 2,
            medianColor: "blue",
            medianWidth: 2,
            pointWidth: date === "All" ? 10 : 20,
          });
        } else {
          sample_year_data.push({
            type: "boxplot",
            name: "Observations",
            data: [arr],
            lineColor: "red",
            lineWidth: 2,
            whiskerColor: "red",
            whiskerWidth: 2,
            stemColor: "red",
            stemWidth: 2,
            medianColor: "red",
            medianWidth: 2,
            pointWidth: date === "All" ? 10 : 20,
          });
        }
      });
    if (currentData.length) {
      let options = {
        chart: {
          type: "boxplot",
        },

        title: {
          text: `Highest and Lowest Trends at  ${activeProject.site_name}`,
        },

        legend: {
          enabled: false,
        },

        xAxis: {
          title: {
            text: "Sample Year",
          },
          min: date === "All" ? currentData[0][0] - 2 : JSON.parse(date)[0],
          max:
            date === "All"
              ? currentData[currentData.length - 1][0] + 2
              : JSON.parse(date)[1],
        },

        yAxis: {
          title: {
            text: currentLabel,
          },
        },

        series: sample_year_data,
      };
      setSeries(options);
    }
  }, [currentData]);

  return (
    <div className="custom-table">
      <div className="card-body">
        {trendsIsFetching || !currentStoret ? (
          <div className="text-center">
            <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <FormControl className="width-200px m-2">
                  <label>Date Range</label>
                  <Select
                    value={dateOption}
                    name="date"
                    onChange={handleDateChange}
                    options={DATE_OPTIONS}
                    defaultValue={{ value: "All", label: "All" }}
                  />
                </FormControl>
                <FormControl className="width-200px m-2">
                  <label>Parameter</label>
                  <Select
                    value={storetOption}
                    name="storet"
                    onChange={handleStoretChange}
                    options={storetVals.map((val) => ({
                      value: val,
                      label: val,
                    }))}
                  />
                </FormControl>
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <div className="legend legend-red" />
                  <p className="m-0"> Highest Trends</p>
                </div>
                <div className="d-flex align-items-center">
                  <div className="legend legend-blue" />
                  <p className="m-0"> Lowest Trends</p>
                </div>
              </div>
            </div>
            <HighchartsReact highcharts={Highcharts} options={series} />
          </>
        )}
      </div>
    </div>
  );
};

export default connect(
  "selectRouteParams",
  "selectProjectAll",
  "selectTrends",
  "doTrendsFetch",
  "selectTrendsIsFetching",
  "selectStoretSelected",
  "doStoretFetch",
  "selectStoretData",
  TrendsPlot
);
