import React, { useReducer, useEffect, useState } from "react";
import Select from "react-select";
import FormControl from "@mui/material/FormControl";
import { connect } from "redux-bundler-react";
import "../home/hometest.css";
import "react-datepicker/dist/react-datepicker.css";
import LoadConfigModal from "./LoadConfigModal";
import SaveConfigModal from "./SaveConfigModal";
import ReactDatePicker from "react-datepicker";
import { find } from "lodash";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "CLEAR_LOCATIONS":
    case "CLEAR_FILTERS":
    case "RENDER_FORM":
    case "LOAD_CONFIG":
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
const FilterSidebar = ({
  doApplyFilters,
  doClearFilters,
  doSaveConfiguration,
  doLoadConfiguration,
  doFetchConfigurations,
  doDeleteConfiguration,
  doProjectRemoveLocations,
  doProjectClearLocations,
  doProjectLocationAccumulateFetch,
  projectLocationsArray,
  projectLocationsIsFetching,
  config,
  filters,
  generateText,
  doCwmsSeqNewFetch,
  doModalOpen,
  doModalOpenUniqueCode,
  loadedConfig,
  includeDates,
  filterConfigs,
  configIsFetching,
  doGenericFnCall,
  initialState: initialStateProp,
  customInputs,
  doExpandUpdate,
  filterIsExpanded,
  ...props
}) => {
  let initialState = { ...filters, ...initialStateProp };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [locationsObject, setLocationsObject] = useState({});

  useEffect(() => {
    //clears redux store filters, which are used only to pass results params to heatmap. Once on heatmap page, all filters are stored in local reducer
    // probably a better way to do it all in one spot but this works for now
    if (Object.keys(initialState).length > 0) doClearFilters();
    if (state.projects && state.projects.length > 0) {
      doProjectLocationAccumulateFetch(state.projects[0].value);
    }
    config.fetchFns.map((doX) => {
      props[doX]();
    });
  }, []);

  useEffect(() => {
    if (config.fetchOnChange) {
      doApplyFilters(config.fetchOnChange, state);
    }
  }, [state]);

  useEffect(() => {
    let obj = {};
    projectLocationsArray.forEach((item) => {
      obj[item.site_code]
        ? obj[item.site_code].push(item)
        : (obj[item.site_code] = [item]);
    });
    setLocationsObject(obj);
  }, [projectLocationsArray]);

  useEffect(() => {
    if (loadedConfig) {
      let obj = {};
      config.inputs.forEach((input) => {
        if (input.name && input.type === "input") {
          obj[input.name] = "";
        } else if (input.type === "range") {
          obj[input.minValueName] = "";
          obj[input.maxValueName] = "";
        } else if (input.name && input.type === "select" && input.isMulti) {
          obj[input.name] = [];
        } else if (input.name && input.type === "select" && !input.isMulti) {
          obj[input.name] = {};
        }
      });
      dispatch({
        type: "LOAD_CONFIG",
        payload: {
          ...obj,
          ...loadedConfig,
        },
      });

      if (loadedConfig && loadedConfig.projects) {
        doProjectLocationAccumulateFetch(loadedConfig.projects[0].value);
      }

      doApplyFilters(config.doFetch, loadedConfig);
      // if fetch on CHange need to execute as well to limit dates/dropdowns
      if (config.fetchOnChange)
        doApplyFilters(config.fetchOnChange, loadedConfig);
    }
  }, [loadedConfig]);

  const _doClearFilters = (doX) => {
    if (doX) doClearFilters(doX);
    let empty = {};
    Object.keys(state).forEach((key) => {
      if (key.includes("date")) empty[key] = null;
      else if (typeof state[key] === "object") {
        empty[key] = [];
      } else {
        empty[key] = "";
      }
    });

    dispatch({
      type: "CLEAR_FILTERS",
      payload: empty,
    });
    doApplyFilters(config.doFetch, empty);
    // need to clear projectlocations, otherwise they will concatenate... could also just make it a set but thats hacky
    doProjectClearLocations();
  };

  const handleSelect = (payload, field, callback) => {
    dispatch({
      type: "UPDATE_INPUT",
      field,
      payload: payload ? (Array.isArray(payload) ? payload : [payload]) : [],
    });

    // Make sure the callback you create expects the correct data structure of payload or it will not work
    callback && doGenericFnCall(callback, payload);
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleDateChange = (date, name) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: name,
      payload: date,
    });
  };
  const handleProjectSelect = (obj, type, callback, isMulti) => {
    switch (type.action) {
      case "select-option":
        // If this select is not a multi-select, we do not want to concatenate locations, this finds any key that is an INT (which is a site_code), and clears the locations from the state
        if (!isMulti) {
          let s = state;
          let locKey = find(Object.keys(state), (key) => !!parseInt(key));
          if (locKey) delete s[locKey];
          dispatch({
            type: "CLEAR_LOCATIONS",
            payload: s,
          });
        }
        doProjectLocationAccumulateFetch(
          type.option ? type.option.value : obj.value,
          isMulti
        );
        break;
      case "remove-value":
        doProjectRemoveLocations(
          type.removedValue ? type.removedValue.value : obj.value
        );
        break;
      case "clear":
        doProjectClearLocations();
        /** need to clear location fields here too */
        break;
    }
    handleSelect(obj, "projects", callback);
  };
  const _renderOptions = (input) => {
    // only show projects that exist within chosen offices
    if (
      !state["office"] ||
      state["office"].length === 0 ||
      input.name !== "projects"
    )
      return props[input.options];
    let officeValues =
      state["office"] && state["office"].length > 0
        ? state["office"].map((of) => of.value)
        : [];
    return props[input.options].filter((p) =>
      officeValues.includes(p.office_id)
    );
  };

  const _nextDate = (input, name) => {
    let d = new Date(state[name ? name : input.name]);
    let idx = includeDates.map(Number).indexOf(+d);
    dispatch({
      type: "UPDATE_INPUT",
      field: name ? name : input.name,
      payload: includeDates[idx + 1] ? includeDates[idx + 1] : includeDates[0],
    });
  };

  const _prevDate = (input, name) => {
    let d = new Date(state[name ? name : input.name]);
    let idx = includeDates.map(Number).indexOf(+d);
    dispatch({
      type: "UPDATE_INPUT",
      field: name ? name : input.name,
      payload: includeDates[idx - 1]
        ? includeDates[idx - 1]
        : includeDates[includeDates.length - 1],
    });
  };

  const _renderFormInputs = () => {
    return config.inputs.map((input) => {
      switch (input.type) {
        case "select":
          return (
            <>
              <FormControl
                className="w-100 mt-2"
                key={input.name}
                required={input.required}
              >
                <label className="d-flex align-items-center">
                  {input.label}{" "}
                  <p className="m-0 ml-2 text-muted">
                    {input.required ? "Required" : ""}
                  </p>
                </label>
                <Select
                  value={state[input.name]}
                  name={input.name}
                  isMulti={input.isMulti}
                  options={_renderOptions(input)?.map((item) => ({
                    value: item[input.valueKey],
                    label: item[input.labelKey],
                  }))}
                  onChange={(obj, type) => {
                    if (input.name === "projects") {
                      handleProjectSelect(
                        obj,
                        type,
                        input.callback,
                        input.isMulti
                      );
                    } else handleSelect(obj, input.name, input.callback);
                  }}
                />
                {input.helperText && (
                  <div>
                    <p className="m-0 text-muted">
                      <small>{input.helperText}</small>
                    </p>
                  </div>
                )}
              </FormControl>
              {input.name === "projects" &&
                !input.hideLocations &&
                state.projects &&
                state.projects.map((project) => (
                  <FormControl className="w-100 mt-2 ml-2" key={project.value}>
                    <label className="d-flex">
                      Locations at {project.label}
                    </label>
                    <Select
                      value={state[project.value]}
                      name={project.value}
                      onChange={(arr, type) =>
                        handleSelect(arr, project.value, input.locationCallback)
                      }
                      options={locationsObject[project.value]?.map((proj) => ({
                        value: proj.location_code,
                        label: proj.loc_id,
                      }))}
                      isMulti={input.isMulti}
                      // isLoading={projectIsLocationFetching}
                    />
                    {input.helperText && (
                      <div>
                        <p className="m-0 text-muted">
                          <small>{input.helperText}</small>
                        </p>
                      </div>
                    )}
                  </FormControl>
                ))}
            </>
          );
        case "input":
          return (
            <FormControl
              className="w-100 mt-2"
              key={input.name}
              required={input.required}
            >
              <label className="d-flex align-items-center">
                {input.label}{" "}
                <p className="m-0 ml-2 text-muted">
                  {input.required ? "Required" : ""}
                </p>
              </label>
              <input
                className="custom-input"
                type={input.inputType}
                value={state[input.name]}
                onChange={handleInputChange}
                name={input.name}
              />
              {input.helperText && (
                <div>
                  <p className="m-0 text-muted">
                    <small>{input.helperText}</small>
                  </p>
                </div>
              )}
            </FormControl>
          );
        case "single_date":
          let disabledDatePicker =
            !state.projects ||
            !state.parameters ||
            state.projects.length === 0 ||
            state.parameters.length === 0;
          return (
            <FormControl
              className="w-100 mt-2"
              key={input.name}
              required={input.required}
            >
              <label className="d-flex align-items-center">
                {input.label}{" "}
                <p className="m-0 ml-2 text-muted">
                  {input.required ? "Required" : ""}
                </p>
              </label>
              <ReactDatePicker
                className={`custom-input w-100 ${
                  disabledDatePicker ? "disabled" : ""
                }`}
                type={input.inputType}
                value={
                  typeof state[input.name] === "string"
                    ? new Date(state[input.name])
                    : state[input.name]
                }
                selected={
                  typeof state[input.name] === "string"
                    ? new Date(state[input.name])
                    : state[input.name]
                }
                onChange={(date) => handleDateChange(date, input.name)}
                name={input.name}
                placeholderText="MM/DD/YYYY"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                includeDates={includeDates}
                disabled={disabledDatePicker}
                maxDate={
                  includeDates
                    ? includeDates[includeDates.length - 1]
                    : new Date()
                }
                minDate={includeDates ? includeDates[0] : null}
              />
              {includeDates && (
                <div className="d-flex w-100 mt-1">
                  <button
                    className={`btn btn-sm btn-outline-${
                      disabledDatePicker ? "disabled" : "primary"
                    } w-100 mr-1`}
                    disabled={disabledDatePicker}
                    onClick={() => _prevDate(input)}
                  >
                    <i className="mdi mdi-arrow-left mr-1" />
                    Prev Date
                  </button>
                  <button
                    className={`btn btn-sm btn-outline-${
                      disabledDatePicker ? "disabled" : "primary"
                    } w-100 ml-1`}
                    disabled={disabledDatePicker}
                    onClick={() => _nextDate(input)}
                  >
                    Next Date
                    <i className="mdi mdi-arrow-right ml-1" />
                  </button>
                </div>
              )}
              {input.helperText && (
                <div>
                  <p className="m-0 text-muted">
                    <small>{input.helperText}</small>
                  </p>
                </div>
              )}
            </FormControl>
          );
        case "range":
        default:
          return (
            <FormControl
              className="w-100 mt-2"
              key={input.name}
              required={input.required}
            >
              <label className="d-flex align-items-center">
                {input.label}{" "}
                <p className="m-0 ml-2 text-muted">
                  {input.required ? "Required" : ""}
                </p>
              </label>
              {input.inputType === "date" ? (
                <div className="input-grid">
                  <div className="d-flex flex-column w-100">
                    <ReactDatePicker
                      className={`custom-input w-100`}
                      type={input.inputType}
                      value={
                        typeof state[input.minValueName] === "string" &&
                        !!state[input.minValueName]
                          ? new Date(state[input.minValueName])
                          : state[input.minValueName]
                      }
                      selected={
                        typeof state[input.minValueName] === "string" &&
                        !!state[input.minValueName]
                          ? new Date(state[input.minValueName])
                          : state[input.minValueName]
                      }
                      onChange={(date) =>
                        handleDateChange(date, input.minValueName)
                      }
                      name={input.minValueName}
                      placeholderText="MM/DD/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      includeDates={includeDates}
                      maxDate={
                        includeDates
                          ? includeDates[includeDates.length - 1]
                          : new Date()
                      }
                      minDate={
                        includeDates ? includeDates[0] : new Date("1/1/1970")
                      }
                    />

                    {includeDates && input.minValueName === "min_date" && (
                      <div className="d-flex w-100 mt-1">
                        <button
                          className={`btn btn-sm btn-outline-${"primary"} w-100 mr-1`}
                          onClick={() => _prevDate(input, input.minValueName)}
                        >
                          <i className="mdi mdi-arrow-left mr-1" />
                        </button>
                        <button
                          className={`btn btn-sm btn-outline-${"primary"} w-100 ml-1`}
                          onClick={() => _nextDate(input, input.minValueName)}
                        >
                          <i className="mdi mdi-arrow-right ml-1" />
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-column w-100">
                    <ReactDatePicker
                      className={`custom-input w-100 `}
                      type={input.inputType}
                      value={
                        typeof state[input.maxValueName] === "string" &&
                        !!state[input.maxValueName]
                          ? new Date(state[input.maxValueName])
                          : state[input.maxValueName]
                      }
                      selected={
                        typeof state[input.maxValueName] === "string" &&
                        !!state[input.maxValueName]
                          ? new Date(state[input.maxValueName])
                          : state[input.maxValueName]
                      }
                      onChange={(date) =>
                        handleDateChange(date, input.maxValueName)
                      }
                      name={input.maxValueName}
                      placeholderText="MM/DD/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      includeDates={includeDates}
                      maxDate={
                        includeDates
                          ? includeDates[includeDates.length - 1]
                          : new Date()
                      }
                      minDate={includeDates ? includeDates[0] : null}
                    />
                    {includeDates && input.maxValueName === "max_date" && (
                      <div className="d-flex w-100 mt-1">
                        <button
                          className={`btn btn-sm btn-outline-${"primary"} w-100 mr-1`}
                          onClick={() => _prevDate(input, input.maxValueName)}
                        >
                          <i className="mdi mdi-arrow-left mr-1" />
                        </button>
                        <button
                          className={`btn btn-sm btn-outline-${"primary"} w-100 ml-1`}
                          onClick={() => _nextDate(input, input.maxValueName)}
                        >
                          <i className="mdi mdi-arrow-right ml-1" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="input-grid">
                  <input
                    className="custom-input"
                    type={input.inputType}
                    placeholder={input.minValueLabel}
                    value={state[input.minValueName]}
                    name={input.minValueName}
                    onChange={handleInputChange}
                  />
                  <input
                    className="custom-input"
                    type={input.inputType}
                    placeholder={input.maxValueLabel}
                    value={state[input.maxValueName]}
                    name={input.maxValueName}
                    onChange={handleInputChange}
                  />
                </div>
              )}
              {input.helperText && (
                <div>
                  <p className="m-0 text-muted">
                    <small>{input.helperText}</small>
                  </p>
                </div>
              )}
            </FormControl>
          );
      }
    });
  };

  let disabled =
    config.inputs.filter((input) => {
      if (input.type === "range") {
        return (
          input.required &&
          !state[input.minValueName] &&
          !state[input.maxValueName]
        );
      } else
        return (
          input.required &&
          (!state[input.name] ||
            (state[input.name] && state[input.name].length === 0))
        );
    }).length > 0;
  return (
    <div
      className="sidenav border-right"
      style={{ width: !filterIsExpanded && 75 }}
    >
      {filterIsExpanded && (
        <>
          <h3 className="card-title border-bottom">Filters</h3>
          {_renderFormInputs()}
          {customInputs && customInputs()}
          <button
            className={`btn btn-${
              disabled ? "secondary" : "success"
            } mt-4 w-100`}
            disabled={disabled}
            onClick={() => {
              //handling dates.. double check all bundles
              let tempState = state;
              Object.keys(state).forEach((key) => {
                if (key.includes("date") && !!state[key]) {
                  tempState[key] =
                    typeof state[key] === "string"
                      ? state[key].includes("T")
                        ? state[key]
                        : `${state[key]}T00:00:00Z`
                      : state[key].toISOString();
                }
              });
              doApplyFilters(config.doFetch, tempState);
            }}
          >
            {generateText ? generateText : "Apply Filters"}
          </button>
          <button
            className={`btn btn-outline-dark mt-2 w-100`}
            onClick={() => _doClearFilters(config.doClear)}
          >
            Clear Filters
          </button>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 12,
              width: "100%",
            }}
            className="mt-2"
          >
            <button
              className={`btn btn-outline-info`}
              onClick={() => {
                doModalOpenUniqueCode(
                  SaveConfigModal,
                  {
                    doSave: doSaveConfiguration,
                    config: { state, page: config.table },
                  },
                  "config_code"
                );
              }}
            >
              {"Save Config"}
            </button>
            <button
              className="btn btn-outline-primary"
              onClick={() =>
                doModalOpen(LoadConfigModal, {
                  doLoad: doLoadConfiguration,
                  doFetch: doFetchConfigurations,
                  doDelete: doDeleteConfiguration,
                  page: config.table,
                })
              }
            >
              Load Config
            </button>
          </div>
        </>
      )}
      <button
        className="btn  btn-ghost-dark p-0"
        onClick={doExpandUpdate}
        style={{
          position: "absolute",
          top: 28,
          left: filterIsExpanded ? 288 : 25,
        }}
      >
        <i
          className={
            filterIsExpanded
              ? "mdi mdi-arrow-collapse-left mdi-14x"
              : "mdi mdi-arrow-collapse-right mdi-14px"
          }
        />
      </button>
    </div>
  );
};

export default connect(
  "selectProjectAll",
  "doGenerateData",
  "doProjectShouldFetch",
  "doProjectRemoveLocations",
  "doProjectClearLocations",
  "selectStoretOptions",
  "doProjectLocationAccumulateFetch",
  "selectProjectLocationsArray",
  "selectOfficeData",
  "doOfficeShouldFetch",
  "doCollectionsFetch",
  "selectCollectionsSelects",
  "selectOfficeIsFetching",
  "selectProjectLocationsIsFetching",
  "doApplyFilters",
  "doClearFilters",
  "selectFilters",
  "doSaveConfiguration",
  "doLoadConfiguration",
  "doFetchConfigurations",
  "doDeleteConfiguration",
  "selectFilterConfigs",
  "selectConfigIsFetching",
  "doCwmsSeqNewFetch",
  "doModalOpen",
  "doModalOpenUniqueCode",
  "selectLoadedConfig",
  "doGenericFnCall",
  "doExpandUpdate",
  "selectFilterIsExpanded",
  FilterSidebar
);
