export default {
  name: "action",

  getReducer: () => {
    const initialData = {
      fetch: "",
      isPosting: false,
      isPutting: false,
      isDeleting: false,
    };
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "POST_START":
        case "PUT_START":
        case "PUT_FINISH":
        case "POST_FINISH":
        case "POST_ERROR":
        case "DELETE_START":
        case "DELETE_FINISH":
        case "DELETE_ERROR":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doActionPostData:
    (url, data, onSuccess, onError) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "POST_START",
        payload: {
          fetch: true,
          isPosting: true,
        },
      });
      apiFetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        onError: onError,
      })
        .then((r) => {
          if (r.ok) {
            onSuccess && typeof onSuccess === "function" && onSuccess(r);
            dispatch({
              type: "POST_FINISH",
              payload: {
                fetch: false,
                isPosting: false,
              },
            });
          } else {
            onError({ status: r.status, message: r.statusText });
            dispatch({
              type: "POST_ERROR",
              payload: {
                fetch: false,
                isPosting: false,
              },
            });
          }
        })
        .catch((err) => {
          if (onError && typeof onError === "function") {
            onError(err);
            dispatch({
              type: "POST_ERROR",
              payload: {
                fetch: false,
                isPosting: false,
              },
            });
          }
        });
    },
  doActionPutData:
    (url, data, onSuccess, onError) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "PUT_START",
        payload: {
          fetch: true,
          isPutting: true,
        },
      });
      apiFetch(url, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        onError: onError,
      })
        .then((r) => {
          if (r.ok) {
            onSuccess && typeof onSuccess === "function" && onSuccess(r);
            dispatch({
              type: "PUT_FINISH",
              payload: {
                fetch: false,
                isPutting: false,
              },
            });
          } else {
            dispatch({
              type: "PUT_ERROR",
              payload: {
                fetch: false,
                isPutting: false,
              },
            });
            onError && onError({ status: r.status, message: r.statusText });
          }
        })
        .catch((e) => onError(e));
    },
  doActionDeleteData:
    (url, data, onSuccess, onError) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "DELETE_START",
        payload: {
          fetch: true,
          isDeleting: true,
        },
      });
      apiFetch(url, {
        method: "DELETE",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((r) => {
          if (r.ok) {
            dispatch({
              type: "DELETE_FINISH",
              payload: {
                fetch: false,
                isDeleting: false,
              },
            });
            if (onSuccess && typeof onSuccess === "function") onSuccess(r);
          } else {
            dispatch({
              type: "DELETE_ERROR",
              payload: {
                fetch: false,
                isDeleting: false,
              },
            });
            if (onError && typeof onError === "function")
              onError({ status: r.status, message: r.statusText });
          }
        })
        .catch((e) => {
          dispatch({
            type: "DELETE_ERROR",
            payload: {
              fetch: false,
              isDeleting: false,
            },
          });
          if (onError && typeof onError === "function") onError(e);
        });
    },
  selectIsPosting: (state) => state.action.isPosting || state.action.isPutting,
  selectIsDeleting: (state) => state.action.isDeleting,
};
