import { createSelector } from "redux-bundler";

export default {
  name: "shannon",
  getReducer: () => {
    const initialData = {
      siteData: [],
      locationData: [],
      siteShouldFetch: false,
      locationShouldFetch: false,
      isFetching: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "SHANNON_FETCH_START":
        case "SHANNON_FETCH_FINISH":
        case "SHANNON_SHOULD_FETCH":
        case "SHANNON_FETCH_ABORT":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doShannonLocationShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "SHANNON_SHOULD_FETCH",
        payload: { locationShouldFetch: true },
      });
    },

  doShannonSiteShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "SHANNON_SHOULD_FETCH",
        payload: { siteShouldFetch: true },
      });
    },
  doShannonSiteFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "SHANNON_FETCH_START",
        payload: {
          siteShouldFetch: false,
          isFetching: true,
        },
      });
      let site_code = store.selectRouteParams().project;
      if (!site_code) {
        dispatch({
          type: "SHANNON_FETCH_ABORT",
          payload: {
            siteShouldFetch: false,
            isFetching: false,
          },
        });
        return;
      }

      let url = `/indicies/site_shannon/${site_code}`;

      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "SHANNON_FETCH_FINISH",
            payload: { siteData: j.items, isFetching: false },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },

  doShannonLocationFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "SHANNON_FETCH_START",
        payload: {
          locationShouldFetch: false,
          isFetching: true,
        },
      });
      let location_code = store.selectRouteParams().id;
      if (!location_code) {
        dispatch({
          type: "SHANNON_FETCH_ABORT",
          payload: {
            locationShouldFetch: false,
            isFetching: false,
          },
        });
        return;
      }

      let url = `/indicies/location_shannon/${location_code}`;

      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "SHANNON_FETCH_FINISH",
            payload: { locationData: j.items, isFetching: false },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },
  // selectors
  selectShannonLocationData: (store) => store.shannon.locationData,
  selectShannonLocationShouldFetch: (store) =>
    store.shannon.locationShouldFetch,
  selectShannonSiteData: (store) => store.shannon.siteData,
  selectShannonSiteShouldFetch: (store) => store.shannon.siteShouldFetch,
  selectShannonIsFetching: (store) => store.shannon.isFetching,

  reactShannonLocationShouldFetch: createSelector(
    "selectShannonIsFetching",
    "selectShannonLocationShouldFetch",
    (isFetching, shannonLocationShouldFetch) => {
      if (isFetching) {
        return null;
      }
      if (shannonLocationShouldFetch)
        return {
          actionCreator: "doShannonLocationFetch",
        };
    }
  ),

  reactShannonSiteShouldFetch: createSelector(
    "selectShannonIsFetching",
    "selectShannonSiteShouldFetch",
    (isFetching, shannonSiteShouldFetch) => {
      if (isFetching) {
        return null;
      }
      if (shannonSiteShouldFetch) {
        return {
          actionCreator: "doShannonSiteFetch",
        };
      }
    }
  ),
};
