import React from "react";
export default () => {
  return (
    <div
      style={{
        padding: "0 64px 32px",
        height: "100%",
        width: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      <h2>404</h2>
      <p>We couldn't find the page you were looking for...</p>
      <a href="/">Go Home</a>
    </div>
  );
};
