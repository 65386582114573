import React from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";

const TableBiologics = ({
  biologicsData,
  biologicsIsFetching,
  routeParams,
  projectAll,
}) => {
  let activeProject = projectAll.find(
    (obj) => routeParams.project == obj.site_code
  );
  return (
    <div className="custom mt-4">
      <div className="card-body">
        <div style={{ textAlign: "center" }}>
          <MaterialTable
            title={`Biologic Diversity at ${
              activeProject ? activeProject.site_name : ""
            }`}
            isLoading={biologicsIsFetching}
            columns={[
              { title: "Class Name", field: "class_name" },
              { title: "Site Name", field: "site_name" },
              { title: "Sum Biocount", field: "sum_biocount" },
              { title: "Sample Year", field: "sample_year" },
            ]}
            data={biologicsData}
            options={{
              loadingType: "overlay",
              exportButton: true,
              exportAllData: true,
              //filtering: true,
              sorting: true,
              search: false,
              pageSize: 50,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [50, 500, 1000, 2000],
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default connect(
  "selectBiologicsData",
  "selectBiologicsIsFetching",
  "selectProjectAll",
  "selectRouteParams",
  TableBiologics
);
