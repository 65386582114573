import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";
import { TableContainer } from "../../../app-components/page-content";
import AnalysisMethodsModal from "./AnalysisMethodsModal";

const TableAnalysisMethods = (props) => {
  const {
    analysisMethodsItems,
    doAnalysisMethodsFetch,
    analysisMethodIsFetching,
    doModalOpen,
    doModalOpenUniqueCode,
    userIsAnyMemberOrAdmin,
  } = props;

  useEffect(doAnalysisMethodsFetch, []);

  return (
    <div className="custom-table mt-3">
      <div className="card-body">
        <div className="d-flex w-100 align-items-center justify-content-between mb-2">
          <h4 className="m-0">Analysis Methods</h4>
          {userIsAnyMemberOrAdmin && (
            <button
              className="btn btn-outline-success"
              style={{ maxWidth: 250, maxHeight: 35 }}
              onClick={() => {
                doModalOpenUniqueCode(AnalysisMethodsModal, {}, "method_code");
              }}>
              Add Analysis Method
            </button>
          )}
        </div>
        <MaterialTable
          title=""
          isLoading={analysisMethodIsFetching}
          columns={[
            {
              field: "link",
              title: "Actions",
              render: (rowData) => {
                if (userIsAnyMemberOrAdmin)
                  return (
                    <button
                      className="hover"
                      onClick={(e) => {
                        doModalOpen(AnalysisMethodsModal, {
                          data: rowData,
                          edit: true,
                        });
                      }}>
                      <i className="mdi mdi-pencil-outline mdi-24px"></i>
                    </button>
                  );
              },
            },
            { title: "Analysis Method ID", field: "analysis_method_id" },
            { title: "Revison Number", field: "revision_number" },
            { title: "Description", field: "description" },
            { title: "Method Source", field: "method_source" },
          ]}
          data={analysisMethodsItems}
          options={{
            loadingType: "overlay",
            exportButton: true,
            exportAllData: true,
            sorting: true,
            search: false,
            pageSize: 20,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [20, 500, 1000, 2000],
          }}
        />
      </div>
    </div>
  );
};
export default connect(
  "doAnalysisMethodsFetch",
  "selectAnalysisMethodsItems",
  "doModalOpen",
  "selectAnalysisMethodsIsFetching",
  "selectUserIsAnyMemberOrAdmin",
  "doModalOpenUniqueCode",
  TableAnalysisMethods
);
