import React from "react";
import { connect } from "redux-bundler-react";
import MaterialTable from "material-table";
import "../Report.css";

export default connect(
  "selectReportStationData",
  "selectReportStationDone",
  ({ reportStationData, reportStationDone }) => {
    return (
      <div className="custom-table">
        <div
          style={{
            marginTop: "10px",
            overflow: "scroll",
            height: "calc(100vh - 200px)",
          }}
        >
          {reportStationData.length ? (
            <MaterialTable
              title={"Report"}
              columns={[
                {
                  title: "Location Code",
                  field: "location_code",
                  hidden: true,
                },
                { title: "Location ID", field: "loc_id" },
                { title: "Location Type", field: "location_type_id" },
                { title: "County", field: "cnty_code" },
                { title: "State", field: "state_name" },
                { title: "Stream Name", field: "stream_name" },
                { title: "Stream Mile", field: "stream_mile" },
                { title: "Latitude", field: "latitude" },
                { title: "Longitude", field: "longitude" },
                { title: "USGS Quadrangle", field: "quad_code" },
              ]}
              data={reportStationData}
              options={{
                loadingType: "overlay",
                exportButton: true,
                exportAllData: true,
                search: false,
                sorting: true,
                pageSize: 50,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [50, 500, 1000, 2000],
              }}
            />
          ) : reportStationDone ? (
            <div>
              <h3>No Data</h3>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);
