import * as React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_exporting from "highcharts/modules/exporting";
HC_exporting(Highcharts);

function dateString(today) {

    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return mm + '/' + dd + '/' + yyyy;
}
function LineChart(props) {
    let chartOptions = {};
    if (!props.chartData.length) return null;
    if (props.title === 'Carlson TSI') {
        chartOptions = {
            title: {
                text: props.title
            },
            xAxis: {
                tickInterval: 7 * 24 * 3600,
                tickWidth: 1,
                type: "datetime",
                dateTimeLabelFormats: {
                    year: '%Y'
                },
            },
            yAxis: {
                min: 0, max: 80, tickInterval: 10,
                plotBands: [{
                    label: {
                        text: "Oligotrophic", style: { color: 'gray', fontSize: 20, opacity: 0.8 }, align: "center"
                    },
                    color: '#fff6f6',
                    from: 0,
                    to: 29
                },
                {
                    color: { linearGradient: { x1: 1, y1: 1, x2: 1, y2: 0 }, stops: [[0, '#fff6f6'], [1, '#fff1d8']] },
                    from: 31,
                    to: 39
                },
                {
                    color: '#fff1d8',
                    from: 41,
                    to: 49,
                    label: {
                        text: "Mesotrophic", style: { color: 'gray', fontSize: 20, opacity: 0.8 }, align: "center"
                    },
                },
                {
                    color: { linearGradient: { x1: 1, y1: 1, x2: 1, y2: 0 }, stops: [[0, '#fff1d8'], [1, '#ffffb1']] },
                    from: 51,
                    to: 69
                },
                {
                    color: '#ffffb1',
                    from: 71,
                    to: 80,
                    label: {
                        text: "Hypereutrophic", style: { color: 'gray', fontSize: 20, opacity: 0.8 }, align: "center"
                    },
                }
                ],
                plotLines: [{
                    color: 'red', // Color value
                    dashStyle: 'longdashdot', // Style of the plot line. Default to solid
                    value: 30, // Value of where the line will appear
                    width: 2 // Width of the line    
                },
                {
                    color: 'orange', // Color value
                    dashStyle: 'longdashdot', // Style of the plot line. Default to solid
                    value: 40, // Value of where the line will appear
                    width: 2 // Width of the line    
                },
                {
                    color: 'green', // Color value
                    dashStyle: 'longdashdot', // Style of the plot line. Default to solid
                    value: 50, // Value of where the line will appear
                    width: 2 // Width of the line    
                },
                {
                    color: 'purple', // Color value
                    dashStyle: 'longdashdot', // Style of the plot line. Default to solid
                    value: 70, // Value of where the line will appear
                    width: 2 // Width of the line    
                }]
            },
            tooltip: {
                shared: true,
                useHTML: true,
                formatter: function () {
                    let self = this;
                    let formattedString = "<small></small><table>";
                    self.points.forEach(elem => {
                        formattedString +=
                            '<tr><td style="color: {series.color}">' +
                            elem.series.name +
                            ": </td>";
                        formattedString +=
                            '<td style="text-align: right"><b>' + elem.y + "</b></td></tr>";
                        formattedString +=
                            '<tr><td style="color: {series.color}">' +
                            'Date' +
                            ": </td>";
                        formattedString +=
                            '<td style="text-align: right"><b>' + dateString(new Date(elem.x)) + "</b></td></tr>";
                    });
                    return formattedString;
                }
            },

            series: [
                {
                    name: "SECCHI_CTSI",
                    marker: { enabled: true },
                    data: props.chartData[0]
                },
                {
                    name: "CTSI",
                    marker: { enabled: true },
                    data: props.chartData[1]
                },
                {
                    name: "P_CTSI",
                    marker: { enabled: true },
                    data: props.chartData[2]
                },
                {
                    name: "CHL_CTSI",
                    marker: { enabled: true },
                    data: props.chartData[3]
                }
            ]
        };
    } else {
        let max = Math.max.apply(Math, props.chartData.map(pair => pair[1]));
        let tickInterval = (props.value.bounds[1] / 5);
        chartOptions = {
            title: {
                text: props.title ?? "Location CTSI Values"
            },
            xAxis: {
                tickInterval: 7 * 24 * 3600,
                tickWidth: 1,
                type: "datetime",
                dateTimeLabelFormats: {
                    year: '%Y'
                },
            },
            yAxis: {
                min: 0, max, tickInterval,
                plotBands: [{
                    label: {
                        text: "Oligotrophic", style: { color: 'gray', fontSize: 20, opacity: 0.8 }, align: "center"
                    },
                    color: '#fff6f6',
                    from: 0,
                    to: props.value.bounds[0]
                },
                {
                    color: '#fff1d8',
                    from: props.value.bounds[0],
                    to: props.value.bounds[1],
                    label: {
                        text: "Mesotrophic", style: { color: 'gray', fontSize: 20, opacity: 0.8 }, align: "center"
                    },
                },
                {
                    color: '#ffffb1',
                    from: props.value.bounds[1],
                    to: 1000,
                    label: {
                        text: "Hypereutrophic", style: { color: 'gray', fontSize: 20, opacity: 0.8 }, align: "center"
                    },
                }
                ]
            },
            tooltip: {
                shared: true,
                useHTML: true,
                formatter: function () {
                    let self = this;
                    let formattedString = "<small></small><table>";
                    self.points.forEach(elem => {
                        formattedString +=
                            '<tr><td style="color: {series.color}">' +
                            elem.series.name +
                            ": </td>";
                        formattedString +=
                            '<td style="text-align: right"><b>' + elem.y + "</b></td></tr>";
                        formattedString +=
                            '<tr><td style="color: {series.color}">' +
                            'Date' +
                            ": </td>";
                        formattedString +=
                            '<td style="text-align: right"><b>' + dateString(new Date(elem.x)) + "</b></td></tr>";
                    });
                    return formattedString;
                }
            },

            series: [

                {
                    name: props.value.element,
                    marker: { enabled: true },
                    data: props.chartData
                }
            ]
        };
    }
    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;

}

export default LineChart;
