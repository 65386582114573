import React from "react";
import { connect } from "redux-bundler-react";

import BarGraph from "./BarGraph";
import ProjectSelect from "../report-tools/selectors/Projects";
import StoretSelect from "../report-tools/selectors/Storet";
import Type from "./Type";
import Time from "./Time";
import FilterButton from "./FilterButton";
import asyncComponent from "./AsyncComponent";

const Print = asyncComponent(() => import("./Print"));

const Options = connect("selectProjectMapShowBar", ({ projectMapShowBar }) => {
  return (
    <div className="p-3" style={{ width: 330 }}>
      <ProjectSelect />
      <Type />
      <hr />
      <h4>Query By Parameter</h4>
      <BarGraph />
      <StoretSelect required={projectMapShowBar} />
      <Time />
      <hr />
      <FilterButton />
      {/* @TODO Need to fix this */}
      <div style={{ margin: "10px" }}>
        <Print />
      </div>
    </div>
  );
});
export default Options;
