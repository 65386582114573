import { sortBy } from "lodash";
import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "counties",
  getReducer: () => {
    const initialState = {
      data: [],
      isFetching: false,
      shouldFetch: false,
      isPosting: false,
      isDeleting: false,
    };

    return (state = initialState, { payload, type }) => {
      switch (type) {
        case "COUNTIES_FETCH_STARTED":
        case "COUNTIES_FETCH_FINISHED":
        case "COUNTIES_FETCH_ERROR":
        case "COUNTIES_SHOULD_FETCH":
        case "COUNTIES_DELETE_STARTED":
        case "COUNTIES_DELETE_FINISHED":
        case "COUNTIES_DELETE_ERROR":
        case "COUNTIES_SAVE_STARTED":
        case "COUNTIES_SAVE_FINISHED":
        case "COUNTIES_SAVE_ERROR":
          return Object.assign({}, state, payload);
        case "STATE_FETCH_FINISH":
          return { ...state, shouldFetch: true };
        default:
          return state;
      }
    };
  },

  doCountiesShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "COUNTIES_SHOULD_FETCH",
        payload: { shouldFetch: true },
      });
    },

  doCountiesFetch:
    (state) =>
    ({ dispatch, apiFetch }) => {
      dispatch({
        type: "COUNTIES_FETCH_STARTED",
        payload: { isFetching: true, shouldFetch: false },
      });
      let url = "/wq_counties/?limit=10000";
      if (state)
        url = ordsifyUrlBuilder(
          "/wq_counties/",
          [
            {
              keyword: "storet_state",
              items: state.state.map((s) => s.value),
            },
          ],
          10000
        );
      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "COUNTIES_FETCH_FINISHED",
            payload: { isFetching: false, data: j.items },
          });
        })
        .catch((err) => {
          dispatch({
            type: "COUNTIES_FETCH_ERROR",
            payload: { isFetching: false, error: err },
          });
        });
    },

  doPostCounty:
    (data, cb) =>
    ({ dispatch, store }) => {
      let apiRoot = store.selectApiRoot();
      let { doActionPostData } = store;
      dispatch({
        type: "COUNTIES_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPostData(
        `${apiRoot}/wq_counties/`,
        data,
        () => {
          dispatch({
            type: "COUNTIES_SAVE_FINISHED",
            payload: { isSaving: false, shouldFetch: true },
          });
          cb && cb();
        },
        (e) =>
          dispatch({
            type: "COUNTIES_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },
  doPutCounty:
    (data, cb) =>
    ({ dispatch, store }) => {
      let apiRoot = store.selectApiRoot();
      let { doActionPutData } = store;
      dispatch({
        type: "COUNTIES_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPutData(
        `${apiRoot}/wq_counties/${data.county_code}`,
        data,
        () => {
          dispatch({
            type: "COUNTIES_SAVE_FINISHED",
            payload: { isSaving: false, shouldFetch: true },
          });
          cb && cb();
        },
        (e) =>
          dispatch({
            type: "COUNTIES_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },
  doDeleteCounty:
    (code, cb) =>
    ({ dispatch, store }) => {
      let apiRoot = store.selectApiRoot();
      let { doActionDeleteData } = store;
      dispatch({
        type: "COUNTIES_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      doActionDeleteData(
        `${apiRoot}/wq_counties/${code}`,
        {},
        () => {
          dispatch({
            type: "COUNTIES_DELETE_FINISHED",
            payload: { isDeleting: false, shouldFetch: true },
          });
          cb && cb();
        },
        (e) =>
          dispatch({
            type: "COUNTIES_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },
  selectCountiesShouldFetch: (state) => state.counties.shouldFetch,
  selectCountiesIsFetching: (state) => state.counties.isFetching,
  selectCountiesItems: (state) => sortBy(state.counties.data, "cnty_name"),
  selectCountiesIsDeleting: (state) => state.counties.isDeleting,
  selectCountiesIsSaving: (state) => state.counties.isSaving,
  selectCountiesError: (state) => state.counties.error,

  reactCountiesShouldFetch: createSelector(
    "selectCountiesShouldFetch",
    "selectCountiesIsFetching",
    (shouldFetch, isFetching) => {
      if (isFetching) return null;
      if (shouldFetch) {
        return {
          actionCreator: "doCountiesFetch",
        };
      }
    }
  ),
};
