import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import React from "react";

function withError(Component) {
  return (props) => {
    const [alertParams, setAlertParams] = React.useState({});
    const [error, setError] = React.useState(false);
    const errorCallback = React.useCallback((errorEvent, errorMsg) => {
      if (errorEvent === "warning") {
        setAlertParams({ status: "Warning", errorMessage: errorMsg });
        return;
      }
      if (!errorEvent) {
        setError(false);
        return;
      }
      if (errorEvent.toString().split("TypeError")[1])
        setAlertParams({ status: 403, errorMessage: errorMsg });
      else
        setAlertParams({ status: errorEvent.status, errorMessage: errorMsg });
    }, []);

    // run effect every time there's fetch error
    React.useEffect(() => {
      if (alertParams.status && alertParams.status !== 200) setError(true);
    }, [alertParams]);
    return (
      <div>
        {error && (
          <Alert
            variant="filled"
            severity={alertParams.status === "Warning" ? "warning" : "error"}
            onClose={(e) => setError(false)}
          >
            <AlertTitle sx={{ fontWeight: "bold" }}>
              {alertParams.status === "Warning"
                ? "Warning"
                : `${alertParams.status} Error`}
            </AlertTitle>
            {alertParams.errorMessage}
          </Alert>
        )}
        <div className={error ? "mt-4" : ""}>
          <Component setError={errorCallback} {...props} />
        </div>
      </div>
    );
  };
}

export default withError;
