export default {
  name: "bulk",
  // reducers
  getReducer: () => {
    const initialData = {
      status: null,
      bulkData: [],
      shouldFetch: false,
      isFetching: false,
      parsedData: null,
      parsedFields: null,
      data: [],
    };
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "BULK_PARSED":
        case "BULK_IMPORT_STARTED":
        case "BULK_IMPORT_FINISHED":
        case "BULK_IMPORT_ERROR":
        case "AIGS_FETCH_FINISHED":
        case "AIGS_FETCH_STARTED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },
  // action creators
  doBulkParse:
    (parsed) =>
    ({ dispatch, store }) => {
      let fields = Object.keys(parsed[0]);
      dispatch({
        type: "BULK_PARSED",
        payload: {
          parsedData: parsed,
          parsedFields: fields,
        },
      });
    },
  doBulkParseClear:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "BULK_PARSED",
        payload: {
          parsedData: null,
          parsedFields: null,
        },
      });
    },
  doAigsObservationsFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "AIGS_FETCH_STARTED",
        payload: { isFetching: true },
      });
      apiFetch("/aigs_temp_observations_t_new/")
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "AIGS_FETCH_FINISHED",
            payload: { data: j.items, isFetching: false },
          })
        )
        .catch((e) => {});
    },
  //temporary.. gonna have to design a proper importing functionality, need it to be generic
  // probalby similar to the doAction fncs
  // doBulkImport(csv, url); ie /results
  doBulkImport:
    (csv, onSuccess, onError) =>
    ({ dispatch, store, apiFetch }) => {
      //NEEDS TO BE ANSI ENCODED for now
      dispatch({ type: "BULK_IMPORT_STARTED", payload: { isImporting: true } });
      apiFetch("/aigs_temp_observations_t_new/batchload", {
        method: "POST",
        body: csv,
        // headers: {
        // 		"Content-Type": "text/csv",
        // },
      })
        .then((r) => {
          if (r.ok) {
            dispatch({
              type: "BULK_IMPORT_FINISHED",
              payload: {
                status: r.status,
                isImporting: false,
              },
            });
          }
          onSuccess && onSuccess();
        })
        .catch((e) => {
          onError && onError(e);
          dispatch({
            type: "BULK_IMPORT_ERROR",
            payload: {
              status: 404,
              isImporting: false,
            },
          });
        });
    },
  selectBulkParsedData: (state) => state.bulk.parsedData,
  selectBulkParsedDataFields: (state) => state.bulk.parsedFields,
  selectAigsObservationData: (state) => state.bulk.data,
  selectAigsObservationIsFetching: (state) => state.bulk.isFetching,
  selectIsImporting: (state) => state.bulk.isImporting,
};
