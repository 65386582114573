import React from 'react';
import { connect } from "redux-bundler-react";

class Map extends React.Component {
    componentDidMount() {
        const { mapKey, doMapsInitialize, options } = this.props;
        doMapsInitialize(mapKey, this.el, options)
    }
    render() {
        const { height, width, position } = this.props;
        return (
            <div>
                <div
                    style={{ height: height, width: width, position: position }}
                    ref={(el) => {
                        this.el = el;
                    }}
                />
            </div>
        )
    }
}
export default connect("doMapsInitialize", Map)