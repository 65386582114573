import { find } from "lodash";
import { createSelector } from "redux-bundler";

export default {
  name: "userRole",
  getReducer: () => {
    const initialData = {
      data: [],
      allRoles: [],
      shouldFetch: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "USERROLE_SAVE_STARTED":
        case "USERROLE_SAVE_FINISHED":
        case "USERROLE_SAVE_ERROR":
        case "USERROLE_DELETE_STARTED":
        case "USERROLE_DELETE_FINISHED":
        case "USERROLE_DELETE_ERROR":
        case "USERROLE_FETCH_START":
        case "USERROLE_FETCH_FINISH":
        case "USERROLE_ALL_FETCH_START":
        case "USERROLE_ALL_FETCH_FINISH":
        case "USERROLE_SHOULD_FETCH":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doUserRoleFetchAll:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "USERROLE_ALL_FETCH_START",
        payload: {
          shouldFetch: false,
        },
      });

      apiFetch(`/user_roles/`)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "USERROLE_ALL_FETCH_FINISH",
            payload: { allRoles: j.items },
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

  doUserRoleFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      if (!store.selectAuthIsLoggedIn()) return;
      let user = store.selectAuthTokenPayload();
      dispatch({
        type: "USERROLE_FETCH_START",
        payload: {
          shouldFetch: false,
        },
      });

      apiFetch(`/user_roles/?q={"keycloak_id":"${user.sub}"}`)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "USERROLE_FETCH_FINISH",
            payload: { data: j.items ? j.items : [] },
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

  doPostUserRole:
    (data, cb) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "USERROLE_SAVE_STARTED",
        payload: { isSaving: true },
      });

      store.doCwmsSeqNewFetch().then((res) => {
        let code = res.items[0].seq;
        store.doActionPostData(
          `/wq_user_roles_t/`,
          { role_code: code, ...data },
          () => {
            dispatch({
              type: "USERROLE_SAVE_FINISHED",
              payload: { isSaving: false },
            });
            cb && cb();
          },
          (err) => console.log(err)
        );
      });
    },

  doPutUserRole:
    (data, cb) =>
    ({ dispatch, store }) => {
      let apiRoot = store.selectApiRoot();
      let { doActionPutData } = store;
      dispatch({
        type: "USERROLE_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPutData(
        `${apiRoot}/wq_user_roles_t/${data.role_code}`,
        data,
        () => {
          dispatch({
            type: "USERROLE_SAVE_FINISHED",
            payload: { isSaving: false },
          });
          cb && cb();
        },
        (e) =>
          dispatch({
            type: "USERROLE_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },
  doDeleteUserRole:
    (code, cb) =>
    ({ dispatch, store }) => {
      let apiRoot = store.selectApiRoot();
      let { doActionDeleteData } = store;
      dispatch({
        type: "USERROLE_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      doActionDeleteData(
        `${apiRoot}/wq_user_roles_t/${code}`,
        {},
        () => {
          dispatch({
            type: "USERROLE_DELETE_FINISHED",
            payload: { isDeleting: false },
          });
          cb && cb();
        },
        (e) =>
          dispatch({
            type: "USERROLE_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },
  selectUserRoleData: (state) => {
    return state.userRole.data;
  },

  selectUserIsSysAdmin: createSelector(
    "selectUserRoleData",
    "selectUserData",
    (userRoleData, userData) =>
      // userData.name === "Tyler Siskar" ||
      !!find(userRoleData, (r) => r.role === "SYS.ADMIN")
  ),

  selectUserOfficesOptions: createSelector(
    "selectUserIsSysAdmin",
    "selectUserRoleData",
    "selectOfficeData",
    (userIsSysAdmin, userRoleData, officeData) => {
      if (userIsSysAdmin) {
        return officeData.map((r) => ({
          value: r.office_id,
          label: r.office_id,
        }));
      } else
        return userRoleData.map((r) => ({
          value: r.role.split(".")[0],
          label: r.role.split(".")[0],
        }));
    }
  ),
  selectUserRoleAll: (state) => state.userRole.allRoles,

  selectUserCurrentOrgRoles: createSelector(
    "selectProject",
    "selectUserRoleData",
    (project, userRoleData) => {
      if (!project) return [];
      return userRoleData.filter((r) => r.office_id === project.office_id);
    }
  ),
  selectUserIsOrgMemberOrAdmin: createSelector(
    "selectUserCurrentOrgRoles",
    "selectUserIsSysAdmin",
    (roles, userIsSysAdmin) => {
      return userIsSysAdmin
        ? userIsSysAdmin
        : roles.filter((r) => ["ADMIN", "MEMBER"].includes(r.role_type))
            .length > 0;
    }
  ),

  selectUserIsAnyMemberOrAdmin: createSelector(
    "selectUserRoleData",
    (userRoleData) => {
      return userRoleData.filter((r) =>
        ["ADMIN", "MEMBER"].includes(r.role_type)
      );
    }
  ),
  selectUserRoleIsFetching: (state) => state.userRole.isFetching,
  selectUserRoleIsSaving: (state) => state.userRole.isSaving,
  selectUserRoleIsDeleting: (state) => state.userRole.isDeleting,
};
