import React, { useEffect, useReducer } from "react";
import {
  ModalContent,
  ModalHeader,
  ModalFooter,
} from "../../app-components/modal";
import { connect } from "redux-bundler-react";
import ReactSelect from "react-select";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const UserPreferencesModal = (props) => {
  const {
    doModalClose,
    userData,
    userOfficesOptions,
    doPutUser,
    doUserFetch,
    userIsSaving,
    doUserFetchAll,
  } = props;
  const [state, dispatch] = useReducer(reducer, { ...userData });
  useEffect(doUserFetchAll, []);
  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSelect = (field, payload) => {
    dispatch({
      type: "UPDATE_INPUT",
      field,
      payload: payload.value,
    });
  };

  return (
    <ModalContent size="md">
      <ModalHeader title="User Preferences" />
      <section className="modal-body">
        <div className="container-fluid">
          <div className="d-flex flex-column w-100">
            <label>Name</label>
            <input
              className="form-control w-100"
              value={state.name}
              name="name"
              onChange={handleChange}
            />
          </div>
          <div className="d-flex flex-column w-100">
            <label>Email</label>
            <input
              className="form-control w-100"
              value={state.email}
              name="email"
              onChange={handleChange}
            />
          </div>
          <div className="d-flex flex-column w-100 mt-2">
            <label>Primary Office</label>
            <ReactSelect
              name="primary_office"
              onChange={(obj) => handleSelect("primary_office", obj)}
              options={userOfficesOptions}
              value={{
                label: state.primary_office,
                value: state.primary_office,
              }}
              menuPosition="fixed"
              className="w-100"
            />
          </div>
        </div>
      </section>
      <ModalFooter
        showCancelButton
        onCancel={doModalClose}
        onSave={() => doPutUser(state, doUserFetch)}
        saveText={
          userIsSaving ? <i className="mdi mdi-loading mdi-spin" /> : "Save"
        }
      />
    </ModalContent>
  );
};

export default connect(
  "doModalClose",
  "selectUserOfficesOptions",
  "selectUserData",
  "doUserFetch",
  "doPutUser",
  "selectUserIsSaving",
  "doUserFetchAll",
  UserPreferencesModal
);
