export default {
  name: "methods",
  getReducer: () => {
    const initialData = {
      selectsMethods: [],
      shouldFetch: false
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "METHODS_FETCH_STARTED":
        case "METHODS_FETCH_FINISHED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doMethodsFetch: () => ({ dispatch, store, apiFetch }) => {
    dispatch({
      type: "METHODS_FETCH_STARTED",
      payload: {
        shouldFetch: false
      }
    });
    const createData = data => {
      var list = [];
      var i = 0;
      for (i = 0; i < data.length; i++) {
        list.push({ value: data[i].test_mthd, label: data[i].test_desc });
      }
      dispatch({
        type: "METHODS_FETCH_FINISHED",
        payload: {
          selectsMethods: list
        }
      });
    };

    apiFetch("/wq_test_methods/")
      .then(response => response.json())
      .then(r => createData(r.items))
      .catch(e => {
        console.log(e);
      });
  },

  selectMethodsSelect: state => {
    return state.methods.selectsMethods;
  },

  reactMethodsShouldFetch: state => {
    if (state.methods.shouldFetch)
      return {
        actionCreator: "doMethodsFetch"
      };
  }
};
