import React from "react";
import LineChart from "./LineChart";

const TrueLineChart = props => {
    return (
        <div>
            <LineChart
                legendColor={["#64d097", "#c5c5c5", 'cyan']}
                chartData={props.data}
                height={props.height ?? 152}
                {...props}
            />
        </div>
    );
}

export default TrueLineChart;

