const aigsCsv = [
  [
    "OBSERVATION_ID",
    "OFFICE_ID",
    "LOCATION_ID",
    "OBSERVED_PROPERTY_ID",
    "OBSERVED_DATETIME",
    "ANALYZED_DATETIME",
    "DEPTH",
    "DEPTH_UNIT",
    "DATA_CLASSIFICATION",
    "RESULT_VALUE",
    "RESULT_UNIT",
    "RESULT_GRADE",
    "MEDIUM",
    "ACTIVITY_NAME",
    "COLLECTION_METHOD",
    "FIELD_DEVICE_ID",
    "FIELD_DEVICE_TYPE",
    "FIELD_COMMENT",
    "LAB_SPECIMEN_NAME",
    "LAB_ANALYSIS_METHOD",
    "LAB_DETECTION_CONDITION",
    "LAB_MDL",
    "LAB_MRL",
    "LAB_QUALITY_FLAG",
    "LAB_RECEIVED_DATETIME",
    "LAB_PREPARED_DATETIME",
    "LAB_SAMPLE_FRACTION",
    "LAB_FROM_LABORATORY",
    "LAB_SAMPLE_ID",
    "LAB_DILUTION_FACTOR",
    "LAB_COMMENT",
    "FIELD_VISIT_START_DATE",
    "VALIDATION_WARNINGS",
    "QC_TYPE",
    "QC_SOURCE_SAMPLE_ID",
    "CAS_NUMBER",
    "LATITUDE",
    "LONGITUDE",
    "ELEVATION",
    "ELEVATION_UNIT",
    "COUNTRY",
    "STATE",
    "COUNTY",
    "LOCATION_TYPE",
    "LOCATION_GROUP",
  ],
  [
    "00021a08-6d61-4766-802c-831c91135d1a",
    "LRH",
    "1JWF0004",
    "Magnesium",
    "2020-07-02 14:53:00.000-04:00",
    "2020-07-02 14:53:00.000-04:00",
    "4",
    "m",
    "LAB",
    "14.6",
    "mg/l",
    "ok",
    "Water",
    "1JWF0004_202006240914004",
    "Alpha Sampler",
    "0",
    "0",
    "0",
    "1JWF0004_202006240914004_HNO3",
    "Boo Yes",
    "0",
    "0.0217",
    "0.5",
    "0",
    "2020-06-26 14:33:00.000-04:00",
    "2020-07-02 07:42:00.000-04:00",
    "TOTAL",
    "Pace-Beaver",
    "7332958002",
    "1",
    "0",
    "0",
    "0",
    "replicate",
    "1nnnn_202006241015000",
    "7439-95-4",
    "37.233029",
    "-82.349199",
    "0",
    "0",
    "United States",
    "Virginia",
    "Dickenson",
    "Lake",
    "",
  ],
];

export default aigsCsv;
