import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";
import EditTaxaModal from "./EditTaxaModal";
import { taxaConfig } from "../../dataExport/filterConfigs";
import { TableContainer } from "../../../app-components/page-content";

const TableTaxacode = (props) => {
  const {
    taxaData,
    doTaxaTableFetch,
    taxaIsFetching,
    doModalOpen,
    userIsAnyMemberOrAdmin,
  } = props;

  useEffect(doTaxaTableFetch, []);

  return (
    <div
      style={{
        width: "100%",
        padding: "16px 64px 32px",
        height: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      <TableContainer fullWidth>
        <div className="custom-table">
          <div className="card-body">
            <div className="d-flex w-100 align-items-center justify-content-between mb-2">
              <h4 className="m-0">Taxacodes</h4>
              {userIsAnyMemberOrAdmin && (
                <button
                  className="btn btn-outline-success"
                  onClick={() => {
                    doModalOpen(EditTaxaModal);
                  }}
                >
                  Add Taxacode
                </button>
              )}
            </div>
            <MaterialTable
              title=""
              isLoading={taxaIsFetching}
              columns={[
                {
                  field: "link",
                  title: "Actions",
                  render: (rowData) => {
                    return (
                      <button
                        className="hover"
                        onClick={(e) => {
                          doModalOpen(EditTaxaModal, {
                            data: rowData,
                            edit: true,
                          });
                        }}
                      >
                        <i className="mdi mdi-pencil-outline mdi-24px"></i>
                      </button>
                    );
                  },
                },
                { title: "Class Name", field: "class_name" },
                { title: "Entry Level", field: "entry_level" },
                { title: "Sci Name", field: "sci_name" },
                { title: "Taxacode", field: "taxacode" },
              ]}
              data={taxaData}
              options={{
                loadingType: "overlay",
                exportButton: true,
                exportAllData: true,
                sorting: true,
                search: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 500, 1000, 2000],
              }}
            />
          </div>
        </div>
      </TableContainer>
    </div>
  );
};

export default connect(
  "doTaxaShouldFetch",
  "doTaxaTableFetch",
  "selectTaxaIsFetching",
  "selectTaxaData",
  "doModalOpen",
  "selectUserIsAnyMemberOrAdmin",
  ...taxaConfig.selectors,
  ...taxaConfig.fetchFns,
  TableTaxacode
);
