const resultsCsv = [
  [
    "OFFICE_ID",
    "SITE_NAME",
    "LOC_ID",
    "SAMPLE_NUM",
    "SAMPLE_DEPTH",
    "DEPTH_UOM",
    "LAB_ID",
    "LAB_SNUM",
    "PARAMETER",
    "VALUE",
    "TEXT_VALUE",
    "UNITS",
    "COLLECTION_METHOD",
    "SAMPLE_TIME",
    "DETECT",
    "PQUANT",
    "PRE_RECOV",
    "POST_RECOV",
    "DILUTION",
    "LAB_ANLZ",
    "DCL_FLAG",
    "DATE_ENTERED",
    "REVISION",
    "VAL_QUAL",
    "VAL_ID",
    "EXPORT_FLAG",
    "ACT_VALUE",
    "TEST_MTHD",
    "PREP_MTHD",
    "RESULT_GRADE",
    "DATE_LAB_RECEIVED",
    "DATE_LAB_PREPARED",
    "LAB_SAMPLE_FRACTION",
    "LAB_COMMENT",
    "VALIDATION_WARNINGS",
  ],
  [
    "LRN",
    "J. Percy Priest",
    "3JPP20004",
    "199808250900007",
    "7",
    "F",
    "FIELD",
    "199808250900007",
    "OXYGEN DISSOLVED PERCENT OF SATURATION         %",
    "49.21",
    "49.21",
    "%",
    "Hydrolab",
    "1998-08-25T04:00:00Z",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "2023-07-25T04:00:00Z",
    "",
    "",
    "",
    "",
    "",
    "*",
    "",
    "",
    "1998-08-25T04:00:00Z",
    "1998-08-25T04:00:00Z",
    "",
    "",
    "",
  ],
];

export default resultsCsv;
