import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import BarGraph from "./BarGraph";

import Time from "./Time";
import Map from "../../app-components/Maps";
import Options from "./Options";
import Spinner from "../misc/loader";
import "./index.css";

import FilterSidebar from "../dataExport/FilterSidebar";
import mapConfig from "./map.config.json";

export default connect(
  "selectProjectMapDataLoading",
  ...mapConfig.selectors,
  ...mapConfig.fetchFns,
  ({ projectMapDataLoading: loading, ...props }) => {
    const [open, setOpen] = useState(false);
    const handleDrawer = () => {
      setOpen(!open);
    };
    return (
      <div className="mapFrame">
        <div id="projectMapFrame">
          <Map
            mapKey="projectMap"
            height={"calc(100vh - 53px)"}
            position="absolute"
            width={"100%"}
            options={{ center: [-10769182.729196, 4554920.738373], zoom: 5 }}
          />
          <div id="popup" style={{ display: "none" }}>
            <a id="popup-closer" className="ol-popup-closer">
              <i className="mdi mdi-close mdi-18px" />
            </a>
            <div id="popup-content"></div>
            <div id="myDiv"></div>
          </div>
          <FilterSidebar config={mapConfig} {...props} />
          {loading ? (
            <div style={{ position: "absolute", top: "500px", left: "1000px" }}>
              <Spinner />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);
