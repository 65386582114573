import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import CardTitle from "./CardTitle";
import CardTable from "./CardTable";
import TableFieldTrip from "../fieldTrips/TableFieldTrip";
import TableIndices from "../tables/TableIndices";
import TableSiteShannon from "../tables/TableSiteShannon";
import BasicTabs from "../../app-components/tabs/Tabs";
import withError from "../../utils/catchError";
import { TrendsConclusionPlot, TrendsPlot } from "../trends";
import TableTrends from "../tables/TableTrends";
import { ContourChart } from "../contour";
import Biologics from "../biologics/Biologics";
import TableBiologics from "../tables/TableBiologics";

const ProjectHome = (props) => {
  const {
    apiRoot,
    project,
    routeParams,
    projectCount,
    doProjectShouldFetch,
    userIsOrgMemberOrAdmin,
    doHeatmapShouldFetch,
    doFieldTripShouldFetch,
    doIndicesShouldFetch,
    heatmapData,
    doHeatmapFetch,
    userCurrentOrgRoles,
  } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [contourData, setContourData] = useState();

  useEffect(() => {
    if (routeParams.project) {
      doProjectShouldFetch();
      //fetch heatmap data if there is none or if stored data is related to current site
      if (
        !heatmapData.length ||
        (heatmapData.length &&
          heatmapData[0].site_code !== parseInt(routeParams.project))
      ) {
        doHeatmapShouldFetch();
      }
      doFieldTripShouldFetch();
      doIndicesShouldFetch();
      getActiveTab();
    }
  }, [doProjectShouldFetch, doHeatmapShouldFetch, routeParams]);

  useEffect(() => {
    let contourData1 = heatmapData.map((item, i) => ({
      x: new Date(item.sample_time),
      y: item.sample_depth,
      z: item.value,
      parameter: item.storet_num,
    }));

    const contourSeries = {
      data: contourData1,
      width: 600,
      height: 300,
    };
    setContourData(contourSeries);
  }, [heatmapData]);
  let total = 0;
  projectCount.forEach((item) => {
    total = item.count_site + total;
  });

  const getActiveTab = () => {
    const { pathname } = window.location;
    [
      "Locations",
      "Field Trips",
      "Indices",
      "Trends",
      "Contour",
      "Biologics",
    ].forEach((label, i) => {
      if (pathname.includes(label.toLowerCase().replace(" ", ""))) {
        setActiveTab(i);
      }
    });
  };
  return (
    <div
      style={{
        padding: "0 64px 32px",
        height: "100%",
        width: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      <CardTitle
        addButton={activeTab}
        project={project}
        projectCount={projectCount}
        userIsOrgMemberOrAdmin={userIsOrgMemberOrAdmin}
      />
      <BasicTabs
        onChange={(val) => setActiveTab(val)}
        activeTab={activeTab}
        baseUrl={`/projects/${routeParams.project}`}
        labels={[
          "Locations",
          "Field Trips",
          "Indices",
          "Trends",
          "Contour",
          "Biologics",
        ]}
        routeParams={routeParams}
        hasLinks
      >
        <CardTable
          project={project}
          count={total}
          apiRoot={apiRoot}
          routeParams={routeParams}
        />
        {project && (
          <TableFieldTrip
            title={`Field Trips at ${project.site_name}`}
            setError={props.setError}
          />
        )}
        {project && (
          <div className="d-flex flex-column w-100">
            <TableIndices
              title={`Indices at ${project.site_name}`}
              setError={props.setError}
            />
            <TableSiteShannon
              title={`Shannon Indices at ${project.site_name}`}
            />
          </div>
        )}
        <div className="d-flex flex-column w-100">
          <TrendsConclusionPlot />
          <TrendsPlot />
          <TableTrends />
        </div>
        <div>
          <ContourChart series={contourData} doHeatmapFetch={doHeatmapFetch} />
        </div>
        <div>
          <Biologics />
          <TableBiologics />
        </div>
      </BasicTabs>
    </div>
  );
};
export default connect(
  "selectApiRoot",
  "selectProject",
  "selectProjectIsFetching",
  "selectProjectCount",
  "doProjectShouldFetch",
  "selectRouteParams",
  "selectUserIsOrgMemberOrAdmin",
  "doHeatmapShouldFetch",
  "doFieldTripShouldFetch",
  "doHeatmapFetch",
  "selectHeatmapData",
  "doIndicesShouldFetch",
  "selectUserCurrentOrgRoles",
  withError(ProjectHome)
);
