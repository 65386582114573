import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "fieldVisit",
  getReducer: () => {
    const initialData = {
      data: [],
      loc_data: [],
      isFetching: false,
      fieldVisitByFieldTrip: [],
      shouldFetchByFieldTrip: false,
      shouldFetchByLocation: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "FIELD_VISIT_FETCH_START":
        case "FIELD_VISIT_FETCH_FINISH":
        case "FIELD_VISIT_SHOULD_FETCH":
        case "FIELD_VISIT_SHOULD_FETCH_BY_LOC":
        case "FIELD_VISIT_FETCH_BY_LOC_START":
        case "FIELD_VISIT_FETCH_BY_LOC_FINISH":
        case "FIELD_VISIT_SAVE_STARTED":
        case "FIELD_VISIT_SAVE_ERROR":
        case "FIELD_VISIT_DELETE_STARTED":
        case "FIELD_VISIT_DELETE_ERROR":
        case "FIELD_VISIT_SAVE_FINISHED":
        case "FIELD_VISIT_DELETE_FINISHED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doFieldVisitShouldFetch:
    (type) =>
    ({ dispatch }) => {
      dispatch({
        type: "FIELD_VISIT_SHOULD_FETCH",
        payload: { shouldFetch: true },
      });
    },

  doFieldVisitShouldFetchByLocation:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "FIELD_VISIT_SHOULD_FETCH_BY_LOC",
        payload: { shouldFetchByLocation: true },
      });
    },

  doFieldVisitFetchByFieldTrip:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "FIELD_VISIT_FETCH_START",
        payload: { isFetching: true, shouldFetchByFieldTrip: false },
      });
      let url = `/field_visit/?q={"field_trip_code":${
        store.selectRouteParams().fieldTrip
      }}&limit=20000`;

      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "FIELD_VISIT_FETCH_FINISH",
            payload: { fieldVisitByFieldTrip: j.items, isFetching: false },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },

  doFieldVisitFetchByLocation:
    (state, limit = 20000) =>
    ({ dispatch, store, apiFetch }) => {
      let locationCode = store.selectRouteParams().id;
      let url = `/field_visit/?q={"location_code":${locationCode}}&limit=20000`;
      if (state) {
        url = ordsifyUrlBuilder(
          "/field_visit/",
          [
            {
              keyword: "location_code",
              items: [locationCode],
            },
            {
              keyword: "field_trip_code",
              items:
                state.field_trip_code &&
                state.field_trip_code[0] &&
                state.field_trip_code[0].value
                  ? [state.field_trip_code[0].value]
                  : [],
            },
            {
              keyword: "field_visit_date",
              items:
                state.field_visit_date && state.field_visit_end_date
                  ? [
                      `${state.field_visit_date}T00:00:00Z`,
                      `${state.field_visit_end_date}T00:00:00Z`,
                    ]
                  : !state.field_visit_date && state.field_visit_end_date
                  ? [
                      "1900-01-01T00:00:00Z",
                      `${state.field_visit_end_date}T00:00:00Z`,
                    ]
                  : state.field_visit_date && !state.field_visit_end_date
                  ? [
                      `${state.field_visit_date}T00:00:00Z`,
                      "2100-01-01T00:00:00Z",
                    ]
                  : [],
            },
          ],
          limit
        );
      }

      if (!locationCode) return;
      dispatch({
        type: "FIELD_VISIT_FETCH_BY_LOC_START",
        payload: {
          shouldFetchByLocation: false,
          isFetching: true,
        },
      });
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "FIELD_VISIT_FETCH_BY_LOC_FINISH",
            payload: { loc_data: j.items, isFetching: false },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },

  doFieldVisitFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      let fieldTripCode = store.selectRouteParams().fieldTrip;
      if (!fieldTripCode) return;
      dispatch({
        type: "FIELD_VISIT_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      let url = `/field_visit/?q={"field_trip_code":${fieldTripCode}}&limit=20000`;

      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "FIELD_VISIT_FETCH_FINISH",
            payload: { data: j.items, isFetching: false },
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

  doFieldVisitPost:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPostData, selectApiRoot, doModalClose, selectRouteParams } =
        store;
      let apiRoot = selectApiRoot();
      let routeParams = selectRouteParams();
      dispatch({
        type: "FIELD_VISIT_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPostData(
        `${apiRoot}/field_visit/`,
        state,
        () => {
          dispatch({
            type: "FIELD_VISIT_SAVE_FINISHED",
            payload: {
              isSaving: false,
              shouldFetch: routeParams.id ? false : true,
              shouldFetchByLocation: routeParams.id ? true : false,
              shouldFetchByFieldTrip: routeParams.fieldTrip ? true : false,
            },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "FIELD_VISIT_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doFieldVisitPut:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPutData, selectApiRoot, doModalClose, selectRouteParams } =
        store;
      let apiRoot = selectApiRoot();
      let routeParams = selectRouteParams();
      dispatch({
        type: "FIELD_VISIT_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPutData(
        `${apiRoot}/field_visit/${state.field_visit_code}`,
        state,
        () => {
          dispatch({
            type: "FIELD_VISIT_SAVE_FINISHED",
            payload: {
              isSaving: false,
              shouldFetch: routeParams.id ? false : true,
              shouldFetchByLocation: routeParams.id ? true : false,
              shouldFetchByFieldTrip: routeParams.fieldTrip ? true : false,
            },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "FIELD_VISIT_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doFieldVisitDelete:
    (state) =>
    ({ dispatch, store }) => {
      let {
        doActionDeleteData,
        selectApiRoot,
        doModalClose,
        selectRouteParams,
      } = store;
      let apiRoot = selectApiRoot();
      let routeParams = selectRouteParams();
      dispatch({
        type: "FIELD_VISIT_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      doActionDeleteData(
        `${apiRoot}/field_visit/${state.field_visit_code}`,
        {},
        () => {
          dispatch({
            type: "FIELD_VISIT_DELETE_FINISHED",
            payload: {
              isDeleting: false,
              shouldFetch: routeParams.id ? false : true,
              shouldFetchByLocation: routeParams.id ? true : false,
              shouldFetchByFieldTrip: routeParams.fieldTrip ? true : false,
            },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "FIELD_VISIT_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },

  selectFieldVisitByFieldTrip: (state) =>
    state.fieldVisit.fieldVisitByFieldTrip,

  selectFieldVisitIsSaving: (state) => state.fieldVisit.isSaving,
  selectFieldVisitIsDeleting: (state) => state.fieldVisit.isDeleting,
  selectFieldVisitError: (state) => state.fieldVisit.error,
  selectFieldVisitIsFetching: (state) => state.fieldVisit.isFetching,
  selectFieldVisitShouldFetch: (state) => state.fieldVisit.shouldFetch,
  selectFieldVisit: (state) => state.fieldVisit.data,
  selectFieldVisitByLocation: (state) => state.fieldVisit.loc_data,
  selectFieldVisitShouldFetchByLocation: (state) =>
    state.fieldVisit.shouldFetchByLocation,
  selectFieldVisitShouldFetchByFieldTrip: (state) =>
    state.fieldVisit.shouldFetchByFieldTrip,

  reactFieldVisitShouldFetch: createSelector(
    "selectFieldVisitIsFetching",
    "selectFieldVisitShouldFetch",
    (isFetching, shouldFetch) => {
      // never fetch if anoter fetch already in progress
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doFieldVisitFetch",
        };
      }
    }
  ),
  reactFieldVisitShouldFetchByLocation: createSelector(
    "selectFieldVisitIsFetching",
    "selectFieldVisitShouldFetchByLocation",
    (isFetching, shouldFetchByLocation) => {
      // never fetch if another fetch already in progress
      if (isFetching) {
        return null;
      }
      if (shouldFetchByLocation) {
        return {
          actionCreator: "doFieldVisitFetchByLocation",
        };
      }
    }
  ),
  reactFieldVisitShouldFetchByFieldTrip: createSelector(
    "selectFieldVisitIsFetching",
    "selectFieldVisitShouldFetchByFieldTrip",
    (isFetching, shouldFetchByFieldTrip) => {
      // never fetch if another fetch already in progress
      if (isFetching) {
        return null;
      }
      if (shouldFetchByFieldTrip) {
        return {
          actionCreator: "doFieldVisitFetchByFieldTrip",
        };
      }
    }
  ),
};
