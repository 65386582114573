import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";

import Parser from "./Parser";
import Upload from "./Upload";

const Index = connect("selectRouteParams", ({ routeParams }) => {
  const [locationParam, setLocation] = useState("");

  useEffect(() => {
    if (routeParams) {
      setLocation(routeParams.id);
    }
  }, [routeParams]);

  return (
    <div>
      <div
        style={{
          padding: "0 64px 32px",
          height: "100%",
          width: "100%",
          minHeight: "calc(100vh - 106px)",
        }}
      >
        <Upload location_code={locationParam} />
        <Parser />
      </div>
    </div>
  );
});

export default Index;
