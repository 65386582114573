import React, { useState } from "react";
import {
  ModalContent,
  ModalHeader,
  ModalFooter,
} from "../../app-components/modal";

const SaveConfigModal = ({ doSave, config, config_code }) => {
  const [name, setName] = useState("");
  const _onSave = () => {
    doSave({ ...config, config_code, name });
  };
  return (
    <ModalContent size="md">
      <ModalHeader title="Save Configuration" />
      <section className="modal-body">
        <div className="container-fluid">
          <label>Configuration Name</label>
          <input
            className="form-control"
            name="name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            maxLength="20"
          />
          <p className="m-0 mt-1 text-muted">
            <small>{20 - name.length} characters remaining</small>
          </p>
        </div>
      </section>
      <ModalFooter onSave={_onSave} showCancelButton />
    </ModalContent>
  );
};

export default SaveConfigModal;
