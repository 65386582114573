import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "alias",
  getReducer: () => {
    const initialData = {
      data: [],
      shouldFetch: false,
      isFetching: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "ALIAS_FETCH_START":
        case "ALIAS_FETCH_FINISH":
        case "ALIAS_SHOULD_FETCH":
        case "ALIAS_SAVE_STARTED":
        case "ALIAS_SAVE_FINISHED":
        case "ALIAS_SAVE_ERROR":
        case "ALIAS_DELETE_STARTED":
        case "ALIAS_DELETE_FINISHED":
        case "ALIAS_DELETE_ERROR":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doAliasShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({ type: "ALIAS_SHOULD_FETCH", payload: { shouldFetch: true } });
    },

  doAliasFetch:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      let location_code = store.selectRouteParams().id;
      dispatch({
        type: "ALIAS_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      let url = ordsifyUrlBuilder(
        "/location_alias/",
        [
          {
            keyword: "location_code",
            items: location_code ? [location_code] : "",
          },
        ],
        10000
      );

      if (state) {
        url = ordsifyUrlBuilder(
          "/location_alias/",
          [
            {
              keyword: "location_code",
              items: location_code ? [location_code] : "",
            },
            {
              keyword: "date_created",
              items:
                state.min_date && state.max_date
                  ? [`${state.min_date}`, `${state.max_date}`]
                  : !state.min_date && state.max_date
                  ? ["1900-01-01T00:00:00Z", `${state.max_date}`]
                  : state.min_date && !state.max_date
                  ? [`${state.min_date}`, "2100-01-01T00:00:00Z"]
                  : [],
            },
          ],
          10000
        );
      }

      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "ALIAS_FETCH_FINISH",
            payload: { data: j.items, isFetching: false },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },

  doAliasPost:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPostData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "ALIAS_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPostData(
        `${apiRoot}/location_alias/`,
        state,
        () => {
          dispatch({
            type: "ALIAS_SAVE_FINISHED",
            payload: { isSaving: false, shouldFetch: true },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "ALIAS_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doAliasPut:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPutData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "ALIAS_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPutData(
        `${apiRoot}/location_alias/${state.location_alias_code}`,
        state,
        () => {
          dispatch({
            type: "ALIAS_SAVE_FINISHED",
            payload: { isSaving: false, shouldFetch: true },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "ALIAS_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doAliasDelete:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionDeleteData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "ALIAS_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      doActionDeleteData(
        `${apiRoot}/location_alias/${state.location_alias_code}`,
        {},
        () => {
          dispatch({
            type: "ALIAS_DELETE_FINISHED",
            payload: { isDeleting: false, shouldFetch: true },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "ALIAS_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },
  // selectors
  selectAliasData: (store) => store.alias.data,
  selectAliasIsFetching: (store) => store.alias.isFetching,
  selectAliasShouldFetch: (store) => store.alias.shouldFetch,

  selectAliasIsSaving: (store) => store.alias.isSaving,
  selectAliasIsDeleting: (store) => store.alias.isDeleting,
  selectAliasError: (state) => state.alias.error,
  reactAliasShouldFetch: createSelector(
    "selectAliasIsFetching",
    "selectAliasShouldFetch",
    (isFetching, shouldFetch) => {
      if (isFetching) {
        return null;
      }
      if (shouldFetch)
        return {
          actionCreator: "doAliasFetch",
        };
    }
  ),
};
