import { Vector as VectorLayer } from "ol/layer";
import Overlay from "ol/Overlay";
import GeoJSON from "ol/format/GeoJSON";
import { Vector as VectorSource } from "ol/source";
import { Text, Circle as CircleStyle, Style, Fill, Stroke } from "ol/style";
import { getCenter } from "ol/extent";
import Plotly from "../app-components/chart/minify-plotly";
import { ordsifyUrlBuilder } from "../utils/ordsify";

const projectMapBundle = {
  name: "projectMap",

  getReducer: () => {
    const initialData = {
      data: null,
      _shouldInitialize: false,
      _shouldAddData: false,
      _shouldRemoveData: false,
      _mapLoaded: false,
      mapData: null,
      mapExtent: null,
      type: null,
      canvas: null,
      loading: false,
      shouldExport: false,
      showBar: false,
      time: null,
      shouldFetchTypes: false,
      timeData: [],
      done: false,
      dataLoading: false,
      geoJsonData: null,
    };
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "AUTH_VERIFY_TOKEN":
          return Object.assign({}, state, {
            shouldFetchTypes: true,
          });
        case "MAPS_INITIALIZED":
          if (payload.hasOwnProperty("projectMap")) {
            return Object.assign({}, state, {
              _mapLoaded: true,
            });
          } else {
            return state;
          }
        case "MAPS_SHUTDOWN":
          if (payload.hasOwnProperty("projectMap")) {
            return Object.assign({}, state, {
              _mapLoaded: false,
            });
          } else {
            return state;
          }
        case "PROJECTMAP_INITIALIZE_START":
        case "PROJECTMAP_INITIALIZE_FINISH":
        case "PROJECTMAP_INITIALIZE_CANCEL":
        case "PROJECTMAP_ADD_DATA_START":
        case "PROJECTMAP_ADD_DATA_FINISH":
        case "PROJECTMAP_REMOVE_DATA_START":
        case "PROJECTMAP_REMOVE_DATA_FINISH":
        case "PROJECTMAP_ADD_LAYER":
        case "PROJECTMAP_FETCH_START":
        case "PROJECTMAP_FETCH_FINISH":
        case "PROJECTMAP_FETCH_ABORT":
        case "PROJECTMAP_TYPE_ACTIVE":
        case "PROJECT_MAP_TYPE_FILTERED":
        case "PROJECTMAP_EXPORT_START":
        case "PROJECTMAP_EXPORT_FINISH":
        case "PROJECTMAP_BAR_GRAPH":
        case "PROJECTMAP_SET_TIME":
        case "PROJECTMAP_YEAR_START":
        case "PROJECTMAP_YEAR_FINISH":
        case "PROJECTMAP_TYPE_FETCH_START":
        case "PROJECTMAP_TYPE_FETCH_FINISH":
        case "PROJECTMAP_RESET_FILTER":
        case "PROJECTMAP_DATA_FETCH_FINISH":
        case "PROJECTMAP_GEOJSON_START":
        case "PROJECTMAP_GEOJSON_FINISH":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doProjectMapLocationFetch:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "PROJECTMAP_FETCH_START",
        payload: {
          data: null,
          mapData: null,
          done: false,
          dataLoading: true,
          timeData: null,
          _shouldInitialize: false,
        },
      });

      let projects = state.projects && state.projects.map((proj) => proj.value);
      let office = state.office_id && state.office_id.map((off) => off.value);

      let url;

      if (state) {
        url = ordsifyUrlBuilder(
          "/wq_v_mapping",
          [
            {
              keyword: "office_id",
              items: office ? office : [],
            },
            {
              keyword: "site_code",
              items: projects ? projects : [],
            },
          ],
          10000
        );
      }

      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          if (j.items && j.items.length > 0) {
            dispatch({
              type: "PROJECTMAP_FETCH_FINISH",
              payload: {
                data: j.items,
              },
            });
            store.doProjectMapToGeoJson();
          } else {
            const map = store.selectMapsObject()["projectMap"];
            store.doProjectMapRemoveData();
            const data = store.selectProjectMapDataPoints();
            map.removeLayer(data);
            dispatch({
              type: "PROJECTMAP_FETCH_FINISH",
              payload: {
                data: j.items,
                dataLoading: false,
              },
            });
          }
        });
    },

  doProjectMapToGeoJson:
    () =>
    ({ store, dispatch }) => {
      const { selectProjectMapData, selectProjectMapTimeData } = store;
      let projectMapData = selectProjectMapData();
      let projectMapTimeData = selectProjectMapTimeData();
      dispatch({
        type: "PROJECTMAP_GEOJSON_START",
      });
      let data = [];
      if (!projectMapData || !projectMapData.length) return null;

      let geoJsonObj = {
        type: "FeatureCollection",
        crs: {
          type: "name",
          properties: { name: "urn:ogc:def:crs:EPSG::4326" },
        },
        features: projectMapData.map((x) => {
          if (!projectMapTimeData || !projectMapTimeData.length) {
            data = [];
          } else {
            data = projectMapTimeData.filter(
              (y) => y.location_code === x.location_code
            );
          }
          return {
            type: "Feature",
            properties: {
              loc_desc: x.loc_desc,
              loc_id: x.loc_id,
              location_code: x.location_code,
              location_type_id: x.location_type_id,
              site_code: x.site_code,
              results_count: x.results_count ? x.results_count : 0,
              sample_count: x.sample_count ? x.sample_count : 0,
              bioresults_count: x.bioresults_count ? x.bioresults_count : 0,
              biosample_count: x.biosample_count ? x.biosample_count : 0,
              data: data,
            },
            geometry: {
              type: "Point",
              coordinates: [x.longitude, x.latitude],
            },
          };
        }),
      };
      dispatch({
        type: "PROJECTMAP_GEOJSON_FINISH",
        payload: {
          geoJsonData: geoJsonObj,
          _shouldInitialize: true,
          dataLoading: false,
        },
      });
    },
  doProjectMapInitialize:
    () =>
    ({ dispatch, store }) => {
      if (!store.selectProjectMapGeoJsonData()) {
        dispatch({
          type: "PROJECTMAP_INITIALIZE_CANCEL",
          payload: {
            _shouldInitialize: false,
          },
        });
        return;
      }
      // set up layers and stuff here
      dispatch({
        type: "PROJECTMAP_INITIALIZE_START",
        payload: {
          _shouldInitialize: false,
        },
      });
      const vectorSource = new VectorSource({
        features: new GeoJSON().readFeatures(
          store.selectProjectMapGeoJsonData(),
          { featureProjection: "EPSG:3857" }
        ),
      });
      const geojson = new VectorLayer({
        source: vectorSource,
        style: (f, r) => {
          return new Style({
            image: new CircleStyle({
              radius: 5,
              fill: new Fill({ color: "rgba(125,188,255)" }),
              stroke: new Stroke({ color: "blue", width: 1 }),
            }),
            text: new Text({
              font: "12px Calibri,sans-serif",
              fill: new Fill({ color: "#000" }),
              stroke: new Stroke({
                color: "#fff",
                width: 4,
              }),
              offsetX: 15,
              offsetY: 15,
              text: f.get("loc_id"),
            }),
          });
          // style.getText().setText()
        },
        declutter: true,
        name: "data",
      });
      const extent = vectorSource.getExtent();
      dispatch({
        type: "PROJECTMAP_INITIALIZE_FINISH",
        payload: {
          mapData: geojson,
          _shouldAddData: true,
          mapExtent: extent,
        },
      });
    },
  doProjectMapAddData:
    () =>
    ({ dispatch, store }) => {
      function timeout(delay) {
        return new Promise((res) => setTimeout(res, delay));
      }
      dispatch({
        type: "PROJECTMAP_ADD_DATA_START",
        payload: {
          _shouldAddData: false,
          dataLoading: false,
        },
      });
      const selectStyle = new Style({
        image: new CircleStyle({
          radius: 8,
          fill: new Fill({
            color: "rgba(99, 166, 239)",
          }),
          stroke: new Stroke({ color: "white", width: 2 }),
        }),
      });
      let selected = null;
      const map = store.selectMapsObject()["projectMap"];
      store.doProjectMapRemoveData();
      const data = store.selectProjectMapDataPoints();
      const extent = store.selectProjectMapExtent();
      map.addLayer(data);
      const showBar = store.selectProjectMapShowBar();
      let container = document.getElementById("popup");
      let content = document.getElementById("popup-content");
      let closer = document.getElementById("popup-closer");
      if (container) {
        container.style.display = "block";
      }
      let overlay = new Overlay({
        element: container,
        positioning: "bottom-center",
        stopEvent: false,
        offset: [20, -20],
      });
      if (closer) {
        closer.onclick = function () {
          overlay.setPosition(undefined);
          closer.blur();
          return false;
        };
      }
      const layout = {
        autosize: false,
        width: 300,
        height: 300,
        margin: {
          l: 35,
          r: 35,
          t: 35,
          b: 35,
          pad: 4,
        },
      };
      map.on("click", function (evt) {
        this.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
          if (content) {
            Plotly.purge(content);
          }
          let xData = [];
          let yData = [];
          if (feature.values_.data) {
            feature.values_.data.map((x) => {
              if (x.decade) {
                xData.push(x.decade);
              } else {
                xData.push(x.sample_year);
              }
              yData.push(x.num_results);
            });
          }
          const chartData = [
            {
              x: xData,
              y: yData,
              type: "bar",
            },
          ];
          if (selected !== null) {
            selected.setStyle(undefined);
            selected = null;
          }
          if (feature) {
            let extent = feature.getGeometry().getExtent();
            let coordinate = getCenter(extent);
            console.log(feature);
            if (content && showBar) {
              content.innerHTML = `<div>
              <h6 style="color: rgb(25, 118, 210)"> Location Info</h6>
                                <p>Location ID: <b>${feature.get(
                                  "loc_id"
                                )}</b></p>
                                <p>Location Desc: <b>${feature.get(
                                  "loc_desc"
                                )}</b></p>
                            </div>`;
              Plotly.newPlot(content, chartData, layout);
            } else if (content) {
              content.innerHTML = `<div>
              <h6 style="color: rgb(25, 118, 210)"> Location Info</h6>
              <p>Location ID: <b>${feature.get("loc_id")}</b></p>
              <p>Location Desc: <b>${feature.get("loc_desc")}</b></p>
              <p>Results Count: <b>${feature.get("results_count")}</b></p>
              <p>Samples Count: <b>${feature.get("sample_count")}</b></p>
              <p>Bioresults Count: <b>${feature.get("bioresults_count")}</b></p>
              <p>Biosamples Count: <b>${feature.get("biosample_count")}</b></p>
              <a href=/projects/${feature.get(
                "site_code"
              )}/locations/${feature.get("location_code")}>Explore More</a>
          </div>`;
            }
            overlay.setPosition(coordinate);
            map.addOverlay(overlay);
            selected = feature;
            feature.setStyle(selectStyle);
            return feature;
          } else {
            map.removeOverlay(overlay);
          }
        });
      });
      map.getView().fit(extent, map.getSize());
      map.on("moveend", function (e) {
        timeout(100);
        dispatch({
          type: "PROJECTMAP_ADD_DATA_FINISH",
          payload: {
            shouldExport: true,
          },
        });
      });

      dispatch({
        type: "PROJECTMAP_ADD_DATA_FINISH",
        payload: {
          shouldExport: true,
        },
      });
    },
  doProjectMapAddLayer:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "PROJECTMAP_ADD_LAYER",
      });
      const map = store.selectMapsObject()["projectMap"];
      const data = store.selectProjectMapData();
      map.addLayer(data);
    },
  doProjectMapRemoveData:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "PROJECTMAP_REMOVE_DATA_START",
        payload: {
          _shouldRemoveData: false,
        },
      });
      const map = store.selectMapsObject()["projectMap"];
      map
        .getLayers()
        .getArray()
        .filter((layer) => layer.get("name") === "data")
        .forEach((layer) => map.removeLayer(layer));
      dispatch({
        type: "PROJECTMAP_REMOVE_DATA_FINISH",
      });
    },
  doProjectMapTypeActive:
    (type) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "PROJECTMAP_TYPE_ACTIVE",
        payload: {
          type: type,
        },
      });
      const data = store.selectProjectMapData();
      let filtered = [];
      if (data) {
        filtered = data.filter((x) => x.location_type_id === type);
        dispatch({
          type: "PROJECT_MAP_TYPE_FILTERED",
          payload: {
            data: filtered,
          },
        });
      }
    },
  doProjectMapExport:
    () =>
    async ({ dispatch, store }) => {
      dispatch({
        type: "PROJECTMAP_EXPORT_START",
        payload: {
          loading: true,
          shouldExport: false,
        },
      });
      const map = store.selectMapsObject()["projectMap"];
      // const canvas = document.getElementsByTagName('canvas')[0];
      // let url;
      // const blob = canvas.toBlob(function (blob) {
      //     url = URL.createObjectURL(blob);
      //     window.open(url)
      // }, 'image/png');
      let url;
      let blob;
      map.once("rendercomplete", function () {
        const mapCanvas = document.createElement("canvas");
        const size = map.getSize();
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        const mapContext = mapCanvas.getContext("2d");
        Array.prototype.forEach.call(
          map
            .getViewport()
            .querySelectorAll(".ol-layer canvas, canvas.ol-layer"),
          function (canvas) {
            if (canvas.width > 0) {
              const opacity =
                canvas.parentNode.style.opacity || canvas.style.opacity;
              mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);

              const backgroundColor = canvas.parentNode.style.backgroundColor;
              if (backgroundColor) {
                mapContext.fillStyle = backgroundColor;
                mapContext.fillRect(0, 0, canvas.width, canvas.height);
              }

              let matrix;
              const transform = canvas.style.transform;
              if (transform) {
                // Get the transform parameters from the style's transform matrix
                matrix = transform
                  .match(/^matrix\(([^(]*)\)$/)[1]
                  .split(",")
                  .map(Number);
              } else {
                matrix = [
                  parseFloat(canvas.style.width) / canvas.width,
                  0,
                  0,
                  parseFloat(canvas.style.height) / canvas.height,
                  0,
                  0,
                ];
              }
              // Apply the transform to the export map context
              CanvasRenderingContext2D.prototype.setTransform.apply(
                mapContext,
                matrix
              );
              mapContext.drawImage(canvas, 0, 0);
            }
          }
        );
        if (navigator.msSaveBlob) {
          // link download attribute does not work on MS browsers
          navigator.msSaveBlob(mapCanvas.msToBlob(), "map.png");
        } else {
          blob = mapCanvas.toBlob(function (blob) {
            url = URL.createObjectURL(blob);
            // window.open(url)
            dispatch({
              type: "PROJECTMAP_EXPORT_FINISH",
              payload: {
                canvas: url,
                loading: false,
              },
            });
          }, "image/png");
        }
        return blob;
      });
      map.renderSync();
      return url;
      // dispatch({
      //     type: "PROJECTMAP_EXPORT_FINISH",
      //     payload: {
      //         // canvas: url,
      //         loading: false
      //     }
      // })
    },
  doProjectMapsBarGraph:
    (value) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "PROJECTMAP_BAR_GRAPH",
        payload: {
          showBar: value,
        },
      });
    },
  doProjectMapSetTime:
    (time) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "PROJECTMAP_SET_TIME",
        payload: {
          time: time,
        },
      });
    },
  doProjectMapFetchTypes:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "PROJECTMAP_TYPE_FETCH_START",
        payload: {
          shouldFetchTypes: false,
        },
      });
      const typeOptions = [];
      apiFetch(`/locations/projects_locations_types/`)
        .then((res) => res.json())
        .then((r) => {
          r.items.forEach((x) => {
            typeOptions.push({
              label: x.location_type_id,
              value: x.location_type_id,
            });
          });
          dispatch({
            type: "PROJECTMAP_TYPE_FETCH_FINISH",
            payload: {
              types: typeOptions,
            },
          });
        });
    },
  doProjectMapResetFilter:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "PROJECTMAP_RESET_FILTER",
        payload: {
          type: "",
          time: "",
          showBar: false,
          dataLoading: false,
          data: [],
          _shouldRemoveData: true,
          content: null,
        },
      });
      // store.doProjectActive("");
      // store.doStoretActive("");
    },

  selectProjectMapCanvas: (state) => state.projectMap.canvas,
  selectProjectMapCanvasLoading: (state) => state.projectMap.loading,
  selectProjectMapData: (state) => state.projectMap.data,
  selectProjectMapExtent: (state) => state.projectMap.mapExtent,
  selectProjectMapDataPoints: (state) => state.projectMap.mapData,
  selectProjectMapGeoJsonData: (state) => state.projectMap.geoJsonData,
  selectProjectMapTypeActive: (state) => state.projectMap.type,
  selectProjectMapShowBar: (state) => state.projectMap.showBar,
  selectProjectMapTime: (state) => state.projectMap.time,
  selectProjectMapTypes: (state) => state.projectMap.types,
  selectProjectMapTimeData: (state) => state.projectMap.timeData,
  selectProjectMapDataLoading: (state) => state.projectMap.dataLoading,

  reactProjectMapShouldInitialize: (state) => {
    if (state.projectMap._shouldInitialize) {
      return { actionCreator: "doProjectMapInitialize" };
    }
  },
  reactProjectMapShouldAddData: (state) => {
    if (state.projectMap._mapLoaded && state.projectMap._shouldAddData)
      return { actionCreator: "doProjectMapAddData" };
  },
  reactProjectMapShouldRemoveData: (state) => {
    if (state.projectMap._mapLoaded && state.projectMap._shouldRemoveData)
      return { actionCreator: "doProjectMapRemoveData" };
  },
  reactProjectMapShouldExport: (state) => {
    if (state.projectMap.shouldExport)
      return { actionCreator: "doProjectMapExport" };
  },
  reactProjectMapShouldFetchTypes: (state) => {
    if (state.projectMap.shouldFetchTypes)
      return { actionCreator: "doProjectMapFetchTypes" };
  },
};
export default projectMapBundle;
