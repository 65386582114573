import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "lakeQuality",
  getReducer: () => {
    const initialState = {
      data: [],
      isSaving: false,
      isDeleting: false,
      isFetching: false,
      error: null,
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case "LAKEQUALITY_SAVE_STARTED":
        case "LAKEQUALITY_SAVE_FINISHED":
        case "LAKEQUALITY_SAVE_ERROR":
        case "LAKEQUALITY_DELETE_STARTED":
        case "LAKEQUALITY_DELETE_FINISHED":
        case "LAKEQUALITY_DELETE_ERROR":
        case "LAKEQUALITY_FETCH_STARTED":
        case "LAKEQUALITY_FETCH_FINISHED":
        case "LAKEQUALITY_FETCH_ERROR":
          return { ...state, ...payload };
        case "MODAL_CLOSED":
          return { ...state, error: null };
        default:
          return state;
      }
    };
  },

  selectLakeQualityItems: (state) => state.lakeQuality.data,
  selectLakeQualityIsFetching: (state) => state.lakeQuality.isFetching,

  doLakeQualityFetch:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "LAKEQUALITY_FETCH_STARTED",
        payload: { isFetching: true },
      });
      let url = "/wq_lrh_lake_quality/";
      if (state)
        url = ordsifyUrlBuilder(
          "/wq_lrh_lake_quality/",
          [
            {
              keyword: "sample_time",
              items:
                state.min_sample_date && state.max_sample_date
                  ? [`${state.min_sample_date}`, `${state.max_sample_date}`]
                  : !state.min_sample_date && state.max_sample_date
                  ? ["1900-01-01T00:00:00Z", `${state.max_sample_date}`]
                  : state.min_sample_date && !state.max_sample_date
                  ? [`${state.min_sample_date}`, "2100-01-01T00:00:00Z"]
                  : [],
            },
          ],
          10000
        );
      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "LAKEQUALITY_FETCH_FINISHED",
            payload: { isFetching: false, data: j.items },
          });
        })
        .catch((err) => {
          dispatch({
            type: "LAKEQUALITY_FETCH_ERROR",
            payload: { isFetching: false, error: err },
          });
        });
    },

  doPostLakeQuality:
    (data, cb) =>
    ({ dispatch, store }) => {
      let apiRoot = store.selectApiRoot();
      let { doActionPostData } = store;
      dispatch({
        type: "LAKEQUALITY_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPostData(
        `${apiRoot}/wq_lrh_lake_quality/`,
        data,
        () => {
          dispatch({
            type: "LAKEQUALITY_SAVE_FINISHED",
            payload: { isSaving: false },
          });
          store.doLakeQualityFetch();
          cb && cb();
        },
        (e) =>
          dispatch({
            type: "LAKEQUALITY_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },
};
