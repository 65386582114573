import React, { useReducer, useEffect } from "react";
import ReactSelect from "react-select";
import { connect } from "redux-bundler-react";
import {
  ModalFooter,
  ModalContent,
  ModalHeader,
} from "../../app-components/modal";
import withError from "../../utils/catchError";
import { find } from "lodash";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const _convertToObject = (value, valueKey, labelKey, arr) => {
  if (!value || typeof value === "object") return value;
  let currentObj = arr.filter((item) => item[valueKey] == value)[0];
  if (currentObj)
    return { value: currentObj[valueKey], label: currentObj[labelKey] };
  else return null;
};

const ProjectModal = ({
  doProjectPost,
  doProjectPut,
  doProjectDelete,
  projectIsDeleting,
  projectIsSaving,
  projectError: error,
  data,
  officeData,
  doOfficeFetch,
  doModalClose,
  edit,
  site_code,
  userRoleData,
  userIsSysAdmin,
  doFetchLocationByProject,
  locationsByProject,
}) => {
  const [state, dispatch] = useReducer(reducer, {});

  let usersOffices = userIsSysAdmin
    ? officeData
    : officeData.filter(
        (o) => !!find(userRoleData, (obj) => obj.office_id === o.office_id)
      );

  useEffect(() => {
    if (edit) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: { ...data },
      });
    } else {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: { site_code },
      });
    }
  }, []);

  useEffect(() => {
    doOfficeFetch();
  }, []);

  useEffect(() => {
    if (state.site_code) doFetchLocationByProject(state.site_code);
  }, [state.site_code]);
  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSelect = (field, payload) => {
    dispatch({
      type: "UPDATE_INPUT",
      field,
      payload,
    });
  };

  const _renderProjectForm = () => {
    return (
      <>
        <div className="d-flex flex-column w-100 mb-2">
          <label htmlFor="site_id">Project ID</label>
          <input
            className="custom-input"
            type="text"
            value={state["site_id"]}
            name={"site_id"}
            id={"site_id"}
            onChange={handleChange}
          />
        </div>
        <div className="d-flex flex-column w-100 mb-2">
          <label htmlFor="site_name">Project Name</label>
          <input
            className="custom-input"
            type="text"
            value={state["site_name"]}
            name={"site_name"}
            id={"site_name"}
            onChange={handleChange}
          />
        </div>

        <div className="d-flex flex-column w-100 mb-2">
          <label htmlFor="office_id">Office</label>
          <ReactSelect
            value={_convertToObject(
              state.office_id,
              "office_id",
              "office_id",
              usersOffices
            )}
            name="office_id"
            options={usersOffices.map((item) => ({
              value: item["office_id"],
              label: item["office_id"],
            }))}
            menuPosition="fixed"
            onChange={(obj) => handleSelect("office_id", obj.value)}
          />
        </div>
        <div className="d-flex flex-column w-100 mb-2">
          <label htmlFor="url_cover_photo">Cover Photo URL</label>
          <input
            className="custom-input"
            type="text"
            value={state["url_cover_photo"]}
            name={"url_cover_photo"}
            id={"url_cover_photo"}
            onChange={handleChange}
          />
        </div>
        <div className="d-flex flex-column w-100 ">
          <label htmlFor="profile_loc">Profile Location</label>
          <ReactSelect
            value={_convertToObject(
              state.profile_loc,
              "location_code",
              "loc_id",
              locationsByProject
            )}
            name="profile_loc"
            options={locationsByProject.map((item) => ({
              value: item["location_code"],
              label: item["loc_id"],
            }))}
            menuPosition="fixed"
            onChange={(obj) => handleSelect("profile_loc", obj.value)}
          />

          <div>
            <p className="m-0 text-muted">
              <small>
                This value is used on the home page, where users can directly
                import profile data to this location.
              </small>
            </p>
          </div>
        </div>
      </>
    );
  };
  return (
    <ModalContent size="md" error={error}>
      <ModalHeader title={edit ? "Edit Project" : "Add Project"} />
      <section className="modal-body">
        <div className="container-fluid">{_renderProjectForm()}</div>
      </section>
      <ModalFooter
        showCancelButton
        onSave={() => (edit ? doProjectPut(state) : doProjectPost(state))}
        onDelete={edit ? () => doProjectDelete(state) : null}
        customClosingLogic
        onCancel={doModalClose}
        saveIsDisabled={projectIsSaving}
        deleteIsDisabled={projectIsDeleting}
        saveText={
          projectIsSaving ? <i className="mdi mdi-loading mdi-spin" /> : "Save"
        }
        deleteText={
          projectIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "selectApiRoot",
  "doProjectShouldFetch",
  "doActionPostData",
  "doOfficeFetch",
  "selectOfficeData",
  "doModalClose",
  "doProjectPut",
  "doProjectPost",
  "doProjectDelete",
  "selectProjectIsSaving",
  "selectProjectIsDeleting",
  "selectProjectError",
  "selectUserRoleData",
  "selectUserIsSysAdmin",
  "doFetchLocationByProject",
  "selectLocationsByProject",
  withError(ProjectModal)
);
