import React, { useState, useEffect } from "react";
import { connect } from "redux-bundler-react";
import "./dataexport.css";
import FilterSidebar from "./FilterSidebar";
import { TableContainer } from "../../app-components/page-content";
import { resultsConfig } from "./filterConfigs";
import MaterialTable from "material-table";

const DataExport = ({
  dataExport,
  resultIsFetching,
  storetData,
  projectAll,
  doModalOpen,
  filters,
  ...props
}) => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    let col =
      dataExport &&
      dataExport.length &&
      Object.keys(dataExport[0])
        .map((key) =>
          key === "storet_num"
            ? { field: "parameter", title: "Parameter" }
            : !key.includes("code") && key !== "links"
            ? {
                field: key,
                title: key
                  .replaceAll("_", " ")
                  .replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase()),
              }
            : null
        )
        .filter((item) => item !== null);

    let row =
      dataExport &&
      dataExport.length &&
      dataExport.map((item, i) => {
        item.parameter = item.parameter
          ? item.parameter
          : storetData.find((o) => o.storet_num === item.storet_num)
          ? storetData.find((o) => o.storet_num === item.storet_num).full_name
          : "";
        delete item.storet_num;
        return { ...item, id: i };
      });
    setColumns(col);
    setRows(row);
  }, [dataExport]);

  return (
    <div className="d-flex">
      <FilterSidebar config={resultsConfig} {...props} />
      <TableContainer>
        <h3 className="card-title border-bottom pt-4">Observations</h3>
        {resultIsFetching ? (
          <div style={{ textAlign: "center" }}>
            <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
          </div>
        ) : !dataExport.length && !resultIsFetching ? (
          <h6>
            No data at the current parameters, please apply filters to generate
            table!
          </h6>
        ) : (
          <div className="custom-table">
            <div className="card-body">
              <MaterialTable
                title={`Results at ${
                  filters && filters.projects && filters.projects.length > 0
                    ? filters.projects.map((p) => p.label).join(", ")
                    : "N/A"
                }`}
                isLoading={resultIsFetching}
                columns={columns ? columns : []}
                data={rows ? rows : []}
                options={{
                  loadingType: "overlay",
                  exportButton: true,
                  exportAllData: true,
                  sorting: true,
                  pageSize: 100,
                  pageSizeOptions: [100, 500, 1000],
                  search: false,
                }}
              />
            </div>
          </div>
        )}
      </TableContainer>
    </div>
  );
};

export default connect(
  "selectProjectAll",
  "doStoretShouldFetch",
  "selectDataExport",
  "selectResultIsFetching",
  "selectStoretData",
  "doModalOpen",
  "selectFilters",
  ...resultsConfig.selectors,
  ...resultsConfig.fetchFns,
  DataExport
);
