import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";
import TableFieldVisitModal from "./TableFieldVisitModal";
import { stringAvatar } from "./helpers";
import CustomAvatar from "./CustomAvatar";
import { fieldVisitConfig } from "../dataExport/filterConfigs";
import "../sample/modal.css";

const TableFieldVisit = (props) => {
  const {
    fieldVisitByLocation,
    fieldVisitIsFetching,
    doFieldVisitShouldFetchByLocation,
    doFieldTripShouldFetch,
    fieldTripAll,
    doModalOpen,
    cwmsSeqIsFetching,
    isPosting,
    location,
  } = props;

  const _fetchFieldTripVisit = () => {
    doFieldVisitShouldFetchByLocation();
    doFieldTripShouldFetch();
  };
  useEffect(_fetchFieldTripVisit, []);
  let locId = location && location.loc_id;
  return (
    <MaterialTable
      title={`Field Visits for ${locId}`}
      isLoading={fieldVisitIsFetching || cwmsSeqIsFetching || isPosting}
      columns={[
        {
          field: "link",
          title: "Actions",
          render: (rowData) => {
            return (
              <button
                className="hover"
                onClick={(e) => {
                  doModalOpen(TableFieldVisitModal, {
                    data: rowData,
                    edit: true,
                  });
                }}
              >
                <i className="mdi mdi-pencil-outline mdi-24px"></i>
              </button>
            );
          },
        },
        {
          title: "Field Trip",
          field: "field_trip_code",
          render: (rowData) => {
            let ft =
              rowData.field_trip_code &&
              fieldTripAll.filter(
                (f) => f.field_trip_code === rowData.field_trip_code
              );
            return ft && ft.length > 0 && ft[0].field_trip_id;
          },
        },
        { title: "Location", field: "loc_id" },
        {
          title: "Participants",
          field: "participants",
          render: (rowData) => {
            return (
              <div className="d-flex">
                {rowData.participants &&
                  JSON.parse(rowData.participants).length &&
                  JSON.parse(rowData.participants).map((name) => (
                    <div className="mr-1" key={name}>
                      <CustomAvatar
                        key={name}
                        size={30}
                        disabled
                        {...stringAvatar(name, 30)}
                      />
                    </div>
                  ))}
              </div>
            );
          },
        },
        {
          title: "Start Date",
          field: "field_visit_date",
        },
        {
          title: "End Date",
          field: "field_visit_end_date",
        },
        { title: "Notes", field: "notes" },
      ]}
      data={fieldVisitByLocation}
      options={{
        loadingType: "overlay",
        exportButton: true,
        exportAllData: true,
        search: false,
        sorting: true,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [50, 500, 1000, 2000],
      }}
      actions={[
        {
          icon: "refresh",
          tooltip: "Refresh table",
          isFreeAction: true,
          onClick: () => doFieldVisitShouldFetchByLocation(),
        },
      ]}
    />
  );
};

export default connect(
  "selectFieldVisitIsFetching",
  "doFieldVisitShouldFetchByLocation",
  "doActionPostData",
  "doActionDeleteData",
  "selectFieldVisitByLocation",
  "selectApiRoot",
  "doCwmsSeqFetch",
  "selectCwmsSeq",
  "selectCwmsSeqIsFetching",
  "selectIsPosting",
  "selectRouteParams",
  "doModalOpen",
  "doFieldTripShouldFetch",
  "selectLocation",
  ...fieldVisitConfig.selectors,
  ...fieldVisitConfig.fetchFns,
  TableFieldVisit
);
