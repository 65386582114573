import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder } from "../utils/ordsify";
import { find } from 'lodash';

export default {
  name: "biologics",
  getReducer: () => {
    const initialData = {
      data: [],
      series: [],
      shouldFetch: false,
      isFetching: false,
      isBuilding: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "BIOLOGICS_FETCH_START":
        case "BIOLOGICS_FETCH_FINISH":
        case "BIOLOGICS_SHOULD_FETCH":
        case "SET_BIOLOGICS_SERIES_START":
        case "SET_BIOLOGICS_SERIES_FINISH":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doSetBiologicsSeries: () => ({ dispatch, store }) => {
    dispatch({
      type: "SET_BIOLOGICS_SERIES_START",
      payload: { isBuilding: true }
    })

    let data = store.selectBiologicsData();
    let sampleYears = [];
    let classNames = [];
    let series = {};
    data.forEach(x => {
      if(!sampleYears.includes(x.sample_year)) sampleYears.push(x.sample_year);
      if(!classNames.includes(x.class_name)) classNames.push(x.class_name);
    })

    sampleYears.sort();
    sampleYears.forEach(year => {
      //for each year, we need to add data to an arrray for EACH classname
      classNames.forEach(cName => {
        let obj = find(data, obj => obj.sample_year === year && obj.class_name === cName);
        let biocount = !!obj ? obj.sum_biocount : 0;
        if(!!series[cName]) series[cName].push(biocount);
        else series[cName] = [biocount];
      })
    })
    let temp = Object.keys(series).map(name => {
      return { name, data: series[name]};
    })
    let tempOptions = {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Biologics Diversity'
      },
      xAxis: {
        categories: sampleYears
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Sum Biocount'
        }
      },
      legend: {
        reversed: true
      },
      plotOptions: {
          column: {
              stacking: 'percent'
          }
      },
      series: temp
    }

    dispatch({
      type: "SET_BIOLOGICS_SERIES_FINISH",
      payload: { series: tempOptions, isBuilding: false }
    })

  },

  doBiologicsShouldFetch: () => ({ dispatch }) => {
    dispatch({ type: "BIOLOGICS_SHOULD_FETCH", payload: { shouldFetch: true } })
  },

  doBiologicsFetch: () => ({ dispatch, store, apiFetch }) => {
    dispatch({
      type: "BIOLOGICS_FETCH_START",
      payload: {
        shouldFetch: false,
        isFetching: true
      }
    });
    let site_id = store.selectProject() ? store.selectProject().site_id : 'piv';
    const url = `/charting/bio/class_comm_comp/${site_id}?limit=20000`;
    apiFetch(url)
      .then(r => r.json())
      .then(j =>
        dispatch({
          type: "BIOLOGICS_FETCH_FINISH",
          payload: { data: j.items, isFetching: false }
        })
      )
      .catch(e => {
        console.log(e);
      });
  },
  // selectors
  selectBiologicsData: store => store.biologics.data,
  selectBiologicsSeries: store => store.biologics.series,
  selectBiologicsIsFetching: store => store.biologics.isFetching,
  selectBiologicsIsBuilding: store => store.biologics.isBuilding,
  selectBiologicsShouldFetch: store => store.biologics.shouldFetch,

  reactBiologicsShouldFetch: createSelector(
    "selectBiologicsIsFetching",
    "selectBiologicsShouldFetch",
    (isFetching, shouldFetch) => {
      if (isFetching) {
        return null
      }
      if (shouldFetch)
        return {
          actionCreator: "doBiologicsFetch"
        }
    })

};
