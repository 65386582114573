import { createSelector } from "redux-bundler";
import { sortBy } from 'lodash';
// import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
    name: "locationIndices",
    getReducer: () => {
        const initialData = {
            data: [],
            ctsiData: [],
            uglData: []
        };

        return (state = initialData, { type, payload }) => {
            switch (type) {
                case "LOCATION_INDICES_FETCH_START":
                case "LOCATION_INDICES_FETCH_FINISH":
                case "LOCATION_INDICES_SHOULD_FETCH":
                case "LOCATION_GRAPH_DATA_UPDATED":
                    return Object.assign({}, state, payload);
                default:
                    return state;
            }
        };
    },

    doSetGraphData: () => ({ dispatch, store }) => {
        let secchi_arr = [];
        let ctsi_arr = [];
        let chl_arr = [];
        let phosph_arr = [];
        let n_arr = [];
        let chl_ugl = [];
        let p_ugl = [];
        let locationId = store.selectRouteParams().id ? store.selectRouteParams().id : store.selectRouteParams().location;
        let sortedArr = sortBy(store.selectSortedIndices()[locationId], ['sample_year', 'sample_month']);
        // convert indicies data to array of data points (sampletime x, value y) //
        sortedArr.forEach(obj => {
            if (obj.ctsi_secchi !== null) secchi_arr.push([new Date(obj.sample_time).getTime(), obj.ctsi_secchi]);
            if (obj.ctsi !== null) ctsi_arr.push([new Date(obj.sample_time).getTime(), obj.ctsi]);
            if (obj.ctsi_chlorophyll !== null) chl_arr.push([new Date(obj.sample_time).getTime(), obj.ctsi_chlorophyll]);
            if (obj.ctsi_phosophorous !== null) phosph_arr.push([new Date(obj.sample_time).getTime(), obj.ctsi_phosophorous]);
            if (obj.nitrogen_ugl !== null) n_arr.push([new Date(obj.sample_time).getTime(), obj.nitrogen_ugl]);
            if (obj.chlorophyll_ugl !== null) chl_ugl.push([new Date(obj.sample_time).getTime(), obj.chlorophyll_ugl])
            if (obj.phosophorous_ugl !== null) p_ugl.push([new Date(obj.sample_time).getTime(), obj.phosophorous_ugl])
        })

        dispatch({
            type: "LOCATION_GRAPH_DATA_UPDATED",
            payload: { ctsiData: [secchi_arr, ctsi_arr, phosph_arr, chl_arr], uglData: [n_arr, chl_ugl, p_ugl] }
        })
    },

    doLocationIndicesShouldFetch: () => ({ dispatch }) => {
        dispatch({ type: "LOCATION_INDICES_SHOULD_FETCH", payload: { shouldFetch: true } });
    },

    doLocationIndicesFetch: () => ({ dispatch, store, apiFetch }) => {
        dispatch({
            type: "LOCATION_INDICES_FETCH_START",
            payload: {
                shouldFetch: false,
                isFetching: true
            }
        });

        let url = `/indicies/location_condition/${store.selectRouteParams().location}?limit=500`;
        apiFetch(url)
            .then(r => r.json())
            .then(j =>
                dispatch({
                    type: "LOCATION_INDICES_FETCH_FINISH",
                    payload: { data: j.items, isFetching: false }
                })
            )
            .catch(e => {
                console.log(e);
            });
    },
    selectLocationIndicesIsFetching: state => state.locationIndices.isFetching,
    selectLocationIndicesShouldFetch: state => state.locationIndices.shouldFetch,
    selectLocationIndices: state => state.locationIndices.data,
    selectCtsiData: state => state.locationIndices.ctsiData,
    selectUglData: state => state.locationIndices.uglData,
    selectCurrentIndices: createSelector(
        "selectLocationIndices",
        "selectRouteParams",
        (locationIndices, routeParams) => {
            return locationIndices.find(item => item.location_code === parseInt(routeParams.location));
        }
    ),
    reactLocationIndicesShouldFetch: createSelector(
        "selectLocationIndicesIsFetching",
        "selectLocationIndicesShouldFetch",
        (isFetching, shouldFetch) => {
            // never fetch if anoter fetch already in progress
            if (isFetching) {
                return null;
            }
            if (shouldFetch) {
                return {
                    actionCreator: "doLocationIndicesFetch"
                };
            }
        }
    ),
};
