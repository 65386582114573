import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DownloadOutlined from "@mui/icons-material/FileDownload";
import { sortBy } from "lodash";
import { DeleteOutlined, Edit } from "@material-ui/icons";
import { connect } from "redux-bundler-react";

const CODES = [
  "location_code",
  "result_code",
  "site_code",
  // "storet_num",
  "sample_code",
  "source_code",
];
const _isNotCode = (key) => !CODES.includes(key);

const Row = ({ nestedData, renderLink, actions, doModalOpen, storetData }) => {
  const [open, setOpen] = useState(false);
  const [chronData, setChronological] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [loading, setLoading] = useState(true);

  const objToArr = () => {
    let chronologicalData = sortBy(nestedData, "sample_time").reverse();
    let dataArr = [];
    /* creating structure for csv download , arr with first row being titles, and the rest being corresponding data */
    chronologicalData.forEach((year, i) => {
      if (i === 0) dataArr.push(Object.keys(year));
      dataArr.push(Object.keys(year).map((item) => year[item]));
    });
    setCSVData(dataArr);
    setChronological(chronologicalData);
  };

  useEffect(() => objToArr(), [nestedData]);
  useEffect(() => chronData.length > 0 && setLoading(false), [chronData]);

  const _downloadCSV = () => {
    let csv =
      "data:text/csv;charset=utf-8," +
      csvData.map((e) => e.join(",")).join("\n");
    var encodedUri = encodeURI(csv);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${csvData[1][0]}_sample_results.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const _renderActions = (rowData) => {
    return (
      actions &&
      actions.map((action, i) => {
        switch (action) {
          case "EDIT":
            return (
              <IconButton onClick={() => doModalOpen(rowData)} key={i}>
                <Edit />
              </IconButton>
            );
          default:
            return (
              <IconButton key={i}>
                <DeleteOutlined htmlColor="red" />
              </IconButton>
            );
        }
      })
    );
  };

  if (loading) return null;
  else
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            {chronData.length > 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TableCell>
          {renderLink && (
            <TableCell>
              {chronData.length > 0 &&
                renderLink(chronData[0].site_code, chronData[0].location_code)}
            </TableCell>
          )}
          <TableCell>{actions && _renderActions(chronData[0])}</TableCell>
          {Object.keys(chronData[0]).map((key) =>
            key === "storet_num" ? (
              <TableCell key={key}>
                {storetData.find((o) => o.storet_num === chronData[0][key])
                  ? storetData.find((o) => o.storet_num === chronData[0][key])
                      .short_name
                  : null}
              </TableCell>
            ) : _isNotCode(key) ? (
              <TableCell key={key}>{chronData[0][key]}</TableCell>
            ) : null
          )}
        </TableRow>
        {/* Rest of data is a nested row*/}
        <TableRow style={{ backgroundColor: "#f3f3f3" }}>
          <TableCell style={{ padding: 0 }} colSpan={csvData[0].length}>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              sx={{ width: "100%" }}
            >
              <Box sx={{ margin: 1, width: "100%" }}>
                <div className="d-flex  align-items-center mb-3">
                  <Typography variant="h6" component="div">
                    All Results at {csvData[1][0]}
                  </Typography>
                  <IconButton onClick={_downloadCSV}>
                    <DownloadOutlined sx={{ height: 24, width: 24 }} />
                  </IconButton>
                </div>
              </Box>
              <Table>
                {csvData.map((item, index) => {
                  if (index === 0) {
                    return (
                      <TableHead key={index}>
                        <TableRow>
                          <TableCell>Actions </TableCell>
                          {item.map(
                            (key) =>
                              _isNotCode(key) && (
                                <TableCell
                                  key={key}
                                  style={{ fontWeight: "bold" }}
                                >
                                  {key === "storet_num"
                                    ? "Parameter"
                                    : key
                                        .replaceAll("_", " ")
                                        .replace(/(\b[a-z](?!\s))/g, (x) =>
                                          x.toUpperCase()
                                        )}
                                </TableCell>
                              )
                          )}
                        </TableRow>
                      </TableHead>
                    );
                  } else {
                    return (
                      <TableBody>
                        <TableRow>
                          {item.map((cell, i) => {
                            if (_isNotCode(csvData[0][i])) {
                              if (i === 0)
                                return (
                                  <>
                                    <TableCell />
                                    <TableCell>{cell}</TableCell>
                                  </>
                                );
                              else if (csvData[0][i] === "storet_num") {
                                return (
                                  <TableCell>
                                    {storetData.find(
                                      (o) => o.storet_num === cell
                                    )
                                      ? storetData.find(
                                          (o) => o.storet_num === cell
                                        ).short_name
                                      : null}
                                  </TableCell>
                                );
                              } else return <TableCell>{cell}</TableCell>;
                            } else if (i === 0) {
                              return (
                                <TableCell>
                                  {actions &&
                                    _renderActions(chronData[index - 1])}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      </TableBody>
                    );
                  }
                })}
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
};

const TableCollapsible = ({
  isFetching,
  doSave,
  doUpdate,
  doDelete,
  routeParams,
  actions,
  doModalOpen,
  doFetch,
  doSort,
  sortedData,
  data,
  title,
  userIsOrgMemberOrAdmin,
  dashboardLink = "",
  renderCheckbox,
  renderLink,
  storetData,
  onAdd,
  addText,
  addButtonDisabled,
}) => {
  useEffect(doFetch, []);
  useEffect(doSort, [data]);

  return (
    <div>
      <div className="custom-table">
        <div className="card-body">
          <div style={{ textAlign: "center" }}>
            <div className="d-flex justify-content-between card-title border-bottom w-100 mb-2">
              <h4 className="float-left">{title}</h4>
              {onAdd && (
                <button
                  className={`btn btn-outline-${
                    addButtonDisabled ? "secondary" : "success"
                  }`}
                  onClick={!addButtonDisabled ? onAdd : undefined}
                >
                  {addText}
                </button>
              )}
            </div>
            {renderCheckbox && renderCheckbox()}
            {!data.length && !isFetching ? (
              <p>No Data at these Parameters</p>
            ) : isFetching ? (
              <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
            ) : (
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      {renderLink && <TableCell>View Dashboard </TableCell>}
                      <TableCell>Actions </TableCell>
                      {data.length &&
                        Object.keys(data[0]).map(
                          (key) =>
                            _isNotCode(key) && (
                              <TableCell
                                style={{ fontWeight: "bold" }}
                                key={key}
                              >
                                {key === "storet_num"
                                  ? "Parameter"
                                  : key
                                      .replaceAll("_", " ")
                                      .replace(/(\b[a-z](?!\s))/g, (x) =>
                                        x.toUpperCase()
                                      )}
                              </TableCell>
                            )
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(sortedData).map((key) => (
                      <Row
                        key={key}
                        nestedData={sortedData[key]}
                        renderLink={renderLink}
                        actions={actions}
                        doModalOpen={doModalOpen}
                        storetData={storetData}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect("selectStoretData", TableCollapsible);
