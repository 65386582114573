import { find, sortBy } from "lodash";
import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "project",
  getReducer: () => {
    const initialData = {
      data: [],
      locationsArray: [],
      count: [],
      shouldFetch: false,
      isFetching: false,
      isLocationFetching: false,
      shouldCountFetch: false,
      isCountFetching: false,
      projectActive: "",
      projectsActive: [],
      projectsArray: [],
      // for filter
      projects: [],
      isProjectsFetching: false,
      shouldProjectsFetch: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "URL_UPDATED":
        case "AUTH_LOGGED_IN":
          return Object.assign({}, state, {
            locationsArray: [],
          });
        case "PROJECT_FETCH_FINISH":
        case "PROJECT_FETCH_START":
        case "PROJECTS_FETCH_FINISH":
        case "PROJECTS_FETCH_START":
        case "PROJECT_COUNT_FETCH_START":
        case "PROJECT_COUNT_FETCH_FINISH":
        case "PROJECT_COUNT_SHOULD_FETCH":
        case "PROJECT_SHOULD_FETCH":
        case "PROJECTS_SHOULD_FETCH":
        case "PROJECT_ACTIVE_UPDATE":
        case "PROJECT_LOCATION_ACCUMULATE_FETCH_FINISH":
        case "PROJECT_LOCATION_ACCUMULATE_FETCH_START":
        case "PROJECT_REMOVE_LOCATION":
        case "PROJECT_CLEAR_LOCATIONS":
        case "PROJECT_SAVE_STARTED":
        case "PROJECT_SAVE_FINISHED":
        case "PROJECT_SAVE_ERROR":
        case "PROJECT_DELETE_STARTED":
        case "PROJECT_DELETE_FINISHED":
        case "PROJECT_DELETE_ERROR":
          return Object.assign({}, state, payload);
        case "PROJECT_LOCATION_FETCH_FINISH":
          return { ...state, ...payload, shouldCountFetch: true };
        default:
          return state;
      }
    };
  },

  doProjectShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "PROJECT_SHOULD_FETCH",
        payload: { shouldFetch: true },
      });
    },
  doProjectsShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "PROJECTS_SHOULD_FETCH",
        payload: { projectsShouldFetch: true },
      });
    },

  doProjectsFetch:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "PROJECTS_FETCH_START",
        payload: { isProjectsFetching: true, projectsShouldFetch: false },
      });
      let office =
        state && state.office && state.office.map((off) => off.value);
      let url = ordsifyUrlBuilder(
        "/sites2",
        [
          {
            keyword: "office_id",
            items: office ? office : [],
          },
        ],
        500
      );
      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "PROJECTS_FETCH_FINISH",
            payload: { projects: j.items, isProjectsFetching: false },
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  doProjectFetch:
    (site_code) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "PROJECT_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });
      let sCode = site_code ?? store.selectRouteParams().project;
      let url = ordsifyUrlBuilder(
        "/sites2",
        site_code
          ? [
              {
                keyword: "site_code",
                items: [sCode],
              },
            ]
          : [],
        500
      );
      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "PROJECT_FETCH_FINISH",
            payload: { data: j.items, isFetching: false },
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  doProjectLocationAccumulateFetch:
    (siteCode, isMulti) =>
    ({ dispatch, store, apiFetch }) => {
      let id = siteCode
        ? siteCode
        : store.selectRouteParams()
        ? store.selectRouteParams().project
        : "";
      let existingLocations = isMulti
        ? store.selectProjectLocationsArray()
        : [];
      dispatch({
        type: "PROJECT_LOCATION_ACCUMULATE_FETCH_START",
        payload: {
          isLocationFetching: true,
        },
      });
      if (id) {
        apiFetch(`/locations/projects/${id}?q={}&limit=10000`)
          .then((r) => r.json())
          .then((j) =>
            dispatch({
              type: "PROJECT_LOCATION_ACCUMULATE_FETCH_FINISH",
              payload: {
                locationsArray: [...existingLocations, ...j.items],
                isLocationFetching: false,
              },
            })
          )
          .catch((e) => {
            console.log(e);
          });
      }
    },
  doProjectRemoveLocations:
    (siteCode) =>
    ({ dispatch, store, apiFetch }) => {
      let updatedLocations = store
        .selectProjectLocationsArray()
        .filter((item) => item.site_code !== siteCode);
      dispatch({
        type: "PROJECT_REMOVE_LOCATION",
        payload: {
          locationsArray: updatedLocations,
          isLocationFetching: false,
        },
      });
    },
  doProjectClearLocations:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "PROJECT_CLEAR_LOCATIONS",
        payload: {
          locationsArray: [],
          isLocationFetching: false,
        },
      });
    },
  doProjectCountFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      let id = store.selectRouteParams();
      dispatch({
        type: "PROJECT_COUNT_FETCH_START",
        payload: {
          shouldCountFetch: false,
          isCountFetching: true,
        },
      });
      if (!id.project) return null;
      apiFetch(`/locations/projects/count/${id.project}`)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "PROJECT_COUNT_FETCH_FINISH",
            payload: { count: j.items, isCountFetching: false },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },

  doProjectActive:
    (project) =>
    ({ dispatch, store }) => {
      if (project && project.hasOwnProperty("value")) {
        const code = project.value;
        dispatch({
          type: "PROJECT_ACTIVE_UPDATE",
          payload: {
            projectActive: code,
          },
        });
        store.doLocationFetchByProject(code);
      } else if (project) {
        dispatch({
          type: "PROJECT_ACTIVE_UPDATE",
          payload: {
            projectActive: project,
          },
        });
        store.doLocationFetchByProject(project);
      } else {
        dispatch({
          type: "PROJECT_ACTIVE_UPDATE",
          payload: {
            projectActive: "",
          },
        });
      }
    },
  doProjectsActive:
    (projects) =>
    ({ dispatch, store }) => {
      if (projects && projects.length) {
        const projectArray = [...new Set(projects.map((item) => item.value))];
        dispatch({
          type: "PROJECT_ACTIVE_UPDATE",
          payload: {
            projectsActive: projects,
            projectsArray: projectArray,
          },
        });
        store.doSampleFetch();
        // @TODO Hacky to clear active dates when no locations needed
      } else {
        dispatch({
          type: "PROJECT_ACTIVE_UPDATE",
          payload: {
            projectsActive: [],
            projectsArray: [],
          },
        });
      }
    },

  doProjectPost:
    (state) =>
    ({ dispatch, store }) => {
      const { doActionPostData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "PROJECT_SAVE_STARTED",
        payload: { isSaving: true },
      });

      doActionPostData(
        `${apiRoot}/sites2/`,
        state,
        () => {
          dispatch({
            type: "PROJECT_SAVE_FINISHED",
            payload: { isSaving: false, projectsShouldFetch: true },
          });
          doModalClose();
        },
        (e) => {
          dispatch({
            type: "PROJECT_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          });
        }
      );
    },
  doProjectPut:
    (state) =>
    ({ dispatch, store }) => {
      const { doActionPutData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "PROJECT_SAVE_STARTED",
        payload: { isSaving: true },
      });

      doActionPutData(
        `${apiRoot}/sites2/${state.site_code}`,
        state,
        () => {
          dispatch({
            type: "PROJECT_SAVE_FINISHED",
            payload: { isSaving: false, projectsShouldFetch: true },
          });
          doModalClose();
        },
        (e) => {
          dispatch({
            type: "PROJECT_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          });
        }
      );
    },
  doProjectDelete:
    (state) =>
    ({ dispatch, store }) => {
      const { doActionDeleteData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "PROJECT_DELETE_STARTED",
        payload: { isDeleting: true },
      });

      doActionDeleteData(
        `${apiRoot}/sites2/${state.site_code}`,
        {},
        () => {
          dispatch({
            type: "PROJECT_DELETE_FINISHED",
            payload: { isDeleting: false, projectsShouldFetch: true },
          });
          doModalClose();
        },
        (e) => {
          dispatch({
            type: "PROJECT_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          });
        }
      );
    },

  selectProjectIsSaving: (state) => state.project.isSaving,
  selectProjectIsDeleting: (state) => state.project.isDeleting,
  selectProjectError: (state) => state.project.error,

  selectProjectsActive: (store) => store.project.projectsActive,
  selectProjectsActiveArray: (store) => store.project.projectsArray,
  selectProjectActive: (store) => store.project.projectActive,

  selectProjectRaw: (store) => store.project,

  selectProjectAll: (store) => store.project.data,
  selectProjects: (store) => store.project.data,
  selectProjectData: (store) => store.project.data,

  selectProjectsList: (state) => state.project.projects,
  selectProjectsIsFetching: (state) => state.project.isProjectsFetching,
  selectProjectsShouldFetch: (store) => store.project.projectsShouldFetch,

  selectProjectOptions: (store) =>
    sortBy(store.project.data, (obj) => obj.site_name.toUpperCase()).map(
      (item) => ({
        value: item.site_code,
        label: item.site_name,
        office_id: item.office_id,
      })
    ),

  selectProjectLocationsArray: (state) => state.project.locationsArray,
  selectProjectCount: (store) => store.project.count,
  selectProjectIsFetching: (store) => store.project.isFetching,
  selectProjectShouldFetch: (store) => store.project.shouldFetch,
  selectProjectIsCountFetching: (store) => store.project.isCountFetching,
  selectProjectShouldCountFetch: (store) => store.project.shouldCountFetch,

  selectProject: createSelector(
    "selectRouteParams",
    "selectProjectRaw",
    (routeParams, projectRaw) => {
      if (routeParams.project) {
        return projectRaw.data.find(
          (d) => String(d.site_code) === routeParams.project
        );
      } else {
        return null;
      }
    }
  ),

  selectProjectsUserAccess: createSelector(
    "selectUserIsSysAdmin",
    "selectUserRoleData",
    "selectProjectAll",
    (userIsSysAdmin, roles, projects) => {
      return userIsSysAdmin
        ? projects
        : projects.filter((p) => {
            // console.log(roles.map((r) => r.office_id).includes(p.office_id));
            return !!find(roles, (r) => r.office_id == p.office_id);
          });
    }
  ),

  reactProjectShouldFetch: createSelector(
    "selectProjectIsFetching",
    "selectProjectShouldFetch",
    (isFetching, shouldFetch) => {
      // never fetch if anoter fetch already in progress
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doProjectFetch",
        };
      }
    }
  ),

  reactProjectsShouldFetch: createSelector(
    "selectProjectsIsFetching",
    "selectProjectsShouldFetch",
    (isFetching, projectsShouldFetch) => {
      // never fetch if anoter fetch already in progress
      if (isFetching) {
        return null;
      }
      if (projectsShouldFetch) {
        return {
          actionCreator: "doProjectsFetch",
        };
      }
    }
  ),

  reactProjectShouldCountFetch: createSelector(
    "selectProjectIsCountFetching",
    "selectProjectShouldCountFetch",
    (isCountFetching, shouldCountFetch) => {
      // never fetch if anoter fetch already in progress
      if (isCountFetching) {
        return null;
      }
      if (shouldCountFetch) {
        return {
          actionCreator: "doProjectCountFetch",
        };
      }
    }
  ),
};
