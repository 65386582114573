import React from 'react';
import { connect } from 'redux-bundler-react'
import DocumentCard from './DocumentCard';
import Modal from "./Modal";

const iconDict = {
    'text/csv': 'equalizer',
    'image/jpeg': 'image',
    'application/pdf': 'picture_as_pdf'
}

const DocumentList = connect(
    "doDocumentDownload",
    ({
        doDocumentDownload,
        items
    }) => {
        return (
            <div style={{ height: "700px", overflowY: "auto" }}>
                <Modal />
                {items.map(x => {
                    return (
                        <DocumentCard
                            id={x.id}
                            name={x.name}
                            subject={x.subject}
                            type={iconDict[x.mime_type]}
                            date={x.date_uploaded}
                            document_type={x.document_type}
                            onClick={() => doDocumentDownload(x)}
                        />
                    )
                })}
            </div>
        )
    }
)
export default DocumentList
