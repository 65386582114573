import React from "react";
import { connect } from "redux-bundler-react";
import { TableContainer } from "../../app-components/page-content";
import TableSample from "./TableSample";
import FilterSidebar from "../dataExport/FilterSidebar";
import { samplesConfig } from "../dataExport/filterConfigs";

export default connect(
  "doModalOpen",
  ...samplesConfig.selectors,
  ...samplesConfig.fetchFns,
  ({ doModalOpen, ...props }) => {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          minHeight: "calc(100vh - 106px)",
          borderTop: "1px solid lightgray",
        }}
      >
        {" "}
        <FilterSidebar config={samplesConfig} {...props} />
        <TableContainer>
          <TableSample />
        </TableContainer>
      </div>
    );
  }
);
