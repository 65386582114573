import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "stream",
  getReducer: () => {
    const initialData = {
      data: [],
      all: [],
      shouldFetch: false,
      isFetching: false,
      lastFetch: null,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "AUTH_VERIFY_TOKEN":
          return { ...state, shouldFetch: true };
        case "STREAM_FETCH_START":
        case "STREAM_FETCH_FINISH":
        case "STREAM_TABLE_FETCH_STARTED":
        case "STREAM_SAVE_STARTED":
        case "STREAM_SAVE_FINISHED":
        case "STREAM_SAVE_ERROR":
        case "STREAM_DELETE_STARTED":
        case "STREAM_DELETE_FINISHED":
        case "STREAM_DELETE_ERROR":
        case "STREAM_SHOULD_FETCH":
          return Object.assign({}, state, payload);
        case "MODAL_CLOSED":
          return { ...state, error: null };
        default:
          return state;
      }
    };
  },
  doStreamShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({ type: "STREAM_SHOULD_FETCH", payload: { shouldFetch: true } });
    },

  doStreamFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "STREAM_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      const url = ordsifyUrlBuilder("/stream_codes/", [], 600);
      //"/stream_codes/?limit=600"
      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "STREAM_FETCH_FINISH",
            payload: {
              all: j.items, //.concat(store.selectStreamRaw().data),
              isFetching: false,
              lastFetch: new Date(),
            },
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

  doStreamTableFetch:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "STREAM_TABLE_FETCH_STARTED",
        payload: {
          shouldTableFetch: false,
          isFetching: true,
        },
      });

      let url = `/streams/?limit=10000`;

      if (state) {
        url = ordsifyUrlBuilder(
          "/streams/",
          [
            {
              keyword: "stream_code",
              items: state["stream_code"] ? state["stream_code"] : "", // if no location_codes passed, fetch all
            },
          ],
          20000
        );
      }

      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "STREAM_FETCH_FINISH",
            payload: {
              isFetching: false,
              data: j.items,
            },
          });
        });
    },

  doStreamSave:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPostData, doActionPutData, doModalClose } = store;
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "STREAM_SAVE_STARTED",
        payload: { isSaving: true },
      });
      let doSave = state.stream_code ? doActionPutData : doActionPostData;
      let url = state.stream_code
        ? `${apiRoot}/streams/${state.stream_code}`
        : `${apiRoot}/streams/`;
      doSave(
        url,
        state,
        () => {
          dispatch({
            type: "STREAM_SAVE_FINISHED",
            payload: { isSaving: false, shouldTableFetch: true, error: null },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "STREAM_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doStreamDelete:
    (state) =>
    ({ dispatch, store }) => {
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "STREAM_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      store.doActionDeleteData(
        `${apiRoot}/streams/${state.stream_code}`,
        {},
        () => {
          dispatch({
            type: "STREAM_DELETE_FINISHED",
            payload: { isDeleting: false, shouldTableFetch: true, error: null },
          });
          store.doModalClose();
        },
        (e) =>
          dispatch({
            type: "STREAM_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },

  selectStreamRaw: (state) => state.stream,

  selectStreamAll: (state) => state.stream.all,
  selectStreamData: (state) => state.stream.data,
  selectStreamIsFetching: (store) => store.stream.isFetching,
  selectStreamShouldFetch: (state) => state.stream.shouldFetch,
  selectStreamTableShouldFetch: (store) => store.stream.shouldTableFetch,
  selectStreamIsSaving: (state) => state.stream.isSaving,
  selectStreamIsDeleting: (state) => state.stream.isDeleting,
  selectStreamError: (state) => state.stream.error,

  selectStream: createSelector(
    "selectStreamAll",
    "selectLocation",
    (streamData, location) => {
      if (!streamData) {
        return null;
      } else {
        if (!location) {
          return streamData;
        } else {
          return streamData.find(
            (d) => String(d.stream_code) === String(location.stream_code)
          );
        }
      }
    }
  ),

  reactStreamShouldFetch: createSelector(
    "selectStreamIsFetching",
    "selectStreamShouldFetch",
    (isFetching, shouldFetch) => {
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doStreamFetch",
        };
      }
    }
  ),
  reactStreamTableShouldFetch: createSelector(
    "selectStreamIsFetching",
    "selectStreamTableShouldFetch",
    (isFetching, tableShouldFetch) => {
      if (isFetching) {
        return null;
      }
      if (tableShouldFetch) {
        return {
          actionCreator: "doStreamTableFetch",
        };
      }
    }
  ),
};
