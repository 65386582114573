import React, { useState } from "react";
import ChartMultiScatter from "../ChartMultiScatter";
import profileConfig from "./profile.config.json";
import { TableContainer } from "../../../app-components/page-content";
import FilterSidebar from "../../dataExport/FilterSidebar";
import { connect } from "redux-bundler-react";
import GateController from "./GateController";

const VerticalProfile = ({
  profileData,
  filters,
  profileGates: gates,
  ...rest
}) => {
  let includeDates = rest.profileDates
    .map(function (date) {
      return date.getTime();
    })
    .filter(function (date, i, array) {
      return array.indexOf(date) === i;
    })
    .map(function (time) {
      return new Date(time);
    })
    .sort((d1, d2) => d1 - d2);

  const initialState = {
    min_sample_depth: 0,
    max_sample_depth: 100,
    min_value: -5,
    max_value: 100,
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      {" "}
      <FilterSidebar
        config={profileConfig}
        includeDates={includeDates}
        initialState={initialState}
        {...rest}
      />
      <TableContainer>
        <div className="d-flex w-100">
          <ChartMultiScatter
            data={filters ? (filters.date ? profileData : []) : []}
            filters={filters}
            gates={gates}
          />
          <GateController />
        </div>
      </TableContainer>
    </div>
  );
};
export default connect(
  "selectFilters",
  "selectProfileGates",
  ...profileConfig.fetchFns,
  ...profileConfig.selectors,
  VerticalProfile
);
