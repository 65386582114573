import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import { TableContainer } from "../../app-components/page-content";
import { bioresultsConfig } from "../dataExport/filterConfigs";
import FilterSidebar from "../dataExport/FilterSidebar";

import TableBioResult from "./TableBioResult";

const PageBioResults = ({ doBioResultShouldFetch, routeParams, ...props }) => {
  useEffect(() => {
    doBioResultShouldFetch();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      {" "}
      <FilterSidebar config={bioresultsConfig} {...props} />
      <TableContainer>
        <TableBioResult />
      </TableContainer>
    </div>
  );
};
export default connect(
  "doBioResultShouldFetch",
  "selectRouteParams",
  ...bioresultsConfig.selectors,
  ...bioresultsConfig.fetchFns,
  PageBioResults
);
