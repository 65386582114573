import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import { TableContainer } from "../../../app-components/page-content";
import MaterialTable from "material-table";
import EditMajorBasinsModal from "./EditMajorBasinsModal";

const TableMajorBasins = (props) => {
  const {
    majorBasinAll,
    doMajorBasinTableFetch,
    majorBasinIsFetching,
    doModalOpen,
    doModalOpenUniqueCode,
    userIsAnyMemberOrAdmin,
  } = props;

  useEffect(doMajorBasinTableFetch, []);

  return (
    <div
      style={{
        width: "100%",
        padding: "0px 64px 32px",
        height: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      <TableContainer fullWidth>
        <div className="custom-table mt-3">
          <div className="card-body">
            <div className="d-flex w-100 align-items-center justify-content-between mb-2">
              <h4 className="m-0">Major Basins</h4>
              {userIsAnyMemberOrAdmin && (
                <button
                  className="btn btn-outline-success"
                  style={{ maxWidth: 250, maxHeight: 35 }}
                  onClick={() => {
                    doModalOpenUniqueCode(
                      EditMajorBasinsModal,
                      {},
                      "major_code"
                    );
                  }}
                >
                  Add Major Basin
                </button>
              )}
            </div>
            <MaterialTable
              title=""
              isLoading={majorBasinIsFetching}
              columns={[
                {
                  field: "link",
                  title: "Actions",
                  render: (rowData) => {
                    if (userIsAnyMemberOrAdmin) {
                      return (
                        <button
                          className="hover"
                          onClick={(e) => {
                            doModalOpen(EditMajorBasinsModal, {
                              data: rowData,
                              edit: true,
                            });
                          }}
                        >
                          <i className="mdi mdi-pencil-outline mdi-24px"></i>
                        </button>
                      );
                    }
                  },
                },
                //{ title: "Major Code", field: "major_code" },
                { title: "Major Name", field: "major_name" },
                {
                  title: "Major Abbreviation",
                  field: "major_abbr",
                },
              ]}
              data={majorBasinAll}
              options={{
                loadingType: "overlay",
                exportButton: true,
                sorting: true,
                search: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 500, 1000, 2000],
              }}
            />
          </div>
        </div>
      </TableContainer>
    </div>
  );
};

export default connect(
  "selectMajorBasinAll",
  "selectMajorBasinIsFetching",
  "doMajorBasinTableFetch",
  "doModalOpen",
  "doModalOpenUniqueCode",
  "selectUserIsAnyMemberOrAdmin",
  TableMajorBasins
);
