export default {
  name: "charts",
  getReducer: () => {
    const initialData = {
      items: [],
      samples: [],
      axes: [],
      shouldFetch: false,
      shouldSamplesFetch: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "CHARTS_FETCH_STARTED":
        case "CHARTS_FETCH_FINISHED":
        case "CHARTS_SAMPLES_FETCH_STARTED":
        case "CHARTS_SAMPLES_FETCH_FINISHED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doChartFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "CHARTS_FETCH_STARTED",
        payload: {
          shouldFetch: false,
        },
      });
      const params = store.selectRouteParams();
      if (!params.id) return null;
      const createData = (data) => {
        var list = [];
        var xaxis = [];
        var i = 0;
        for (i = 0; i < data.length; i++) {
          list.push([data[i].test_mthd, data[i].num_test_mthd]);
          xaxis.push(data[i].test_mthd);
        }
        dispatch({
          type: "CHARTS_FETCH_FINISHED",
          payload: {
            items: list,
            axes: xaxis,
          },
        });
      };

      apiFetch(
        `/results_test_count/?limit=500&q={"location_code":${params.id}}`
      )
        .then((response) => response.json())
        .then((r) => createData(r.items))
        .catch((e) => {});
    },

  doChartSamplesFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "CHARTS_SAMPLES_FETCH_STARTED",
        payload: {
          shouldSamplesFetch: false,
        },
      });
      const params = store.selectRouteParams();
      if (!params.id) return null;
      const createData = (data) => {
        var samples = [];
        var xaxis = [];
        var i = 0;
        for (i = 0; i < data.length; i++) {
          samples.push([data[i].sample_time, data[i].num_day]);
          xaxis.push(data[i].sample_time);
        }
        dispatch({
          type: "CHARTS_SAMPLES_FETCH_FINISHED",
          payload: {
            data: samples,
            axes: xaxis,
          },
        });
      };

      apiFetch(`/samples_day/?limit=500&q={"location_code":${params.id}}`)
        .then((response) => response.json())
        .then((r) => createData(r.items))
        .catch((e) => {});
    },

  selectChartData: (state) => {
    return state.charts.items;
  },

  selectChartSamplesData: (state) => {
    return state.charts.data;
  },

  reactChartShouldFetch: (state) => {
    if (state.charts.shouldFetch)
      return {
        actionCreator: "doChartFetch",
      };
  },

  reactChartSamplesShouldFetch: (state) => {
    if (state.charts.shouldSamplesFetch)
      return {
        actionCreator: "doChartSamplesFetch",
      };
  },
};
