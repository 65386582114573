import React from "react";
import MaterialTable from "material-table";
import Link from "../misc/link";
import { connect } from "redux-bundler-react";

export default connect(
  "doActionDeleteData",
  "selectRouteParams",
  "selectUserIsOrgMemberOrAdmin",
  "doProjectLocationsFetch",
  "selectIsDeleting",
  "selectProject",
  ({
    doActionDeleteData,
    routeParams,
    doProjectLocationsFetch,
    userIsOrgMemberOrAdmin,
    isDeleting,
    project,
    ...props
  }) => {
    return (
      <div>
        <MaterialTable
          title={props.title}
          isLoading={props.isLoading || isDeleting}
          editable={{
            onRowDelete: userIsOrgMemberOrAdmin
              ? (oldData) =>
                  new Promise((resolve, reject) => {
                    doActionDeleteData(
                      `/wq_locations/${oldData.location_code}`,
                      {},
                      doProjectLocationsFetch,
                      (e) => console.error(e)
                    );
                    resolve();
                  })
              : null,
          }}
          columns={[
            {
              field: "link",
              title: "View Details",
              render: (rowData) => {
                if (rowData.location_code === project.profile_loc) {
                  return (
                    <div
                      style={{
                        position: "relative",
                        display: "inline-flex",
                        padding: 8,
                      }}>
                      <Link
                        to={`/projects/${rowData.site_code}/locations/${rowData.location_code}`}>
                        <i className="mdi mdi-eye mdi-24px"></i>
                      </Link>
                      <div style={{ position: "absolute", top: 0, right: 0 }}>
                        <i
                          className="mdi mdi-upload"
                          title="Default Profile Upload Location"
                        />
                      </div>
                    </div>
                  );
                }
                return (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-flex",
                      padding: 8,
                    }}>
                    <Link
                      to={`/projects/${rowData.site_code}/locations/${rowData.location_code}`}>
                      <i className="mdi mdi-eye mdi-24px"></i>
                    </Link>
                  </div>
                );
              },
            },
            // { title: "Location Code", field: "location_code" },
            { title: "Location ID", field: "loc_id" },
            { title: "Location Description", field: "loc_desc" },
            // { title: "Project Code", field: "site_code" }
          ]}
          data={props.data}
          options={{
            loadingType: "overlay",
            exportButton: true,
            exportAllData: true,
            //filtering: true,
            sorting: true,
            search: true,
            pageSize: 200,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [100, 200, 500],
          }}
        />
      </div>
    );
  }
);
