import React from "react";

import { classArray } from "../../utils/helpers";

/**
 *
 * @param {String} size - Set the size of the modal content. One of ['sm', null, 'lg', 'xl']. Default null (medium).
 * @param {Element} children - React Element displayed in the modal dialog.
 */
const ModalContent = ({
  size,
  className,
  modalContentClassName,
  children,
  error,
  ...customProps
}) => {
  const modalCls = classArray([
    "modal-dialog",
    "modal-dialog-scrollable",
    "modal-dialog-centered",
    `modal-${size}`,
    className,
  ]);

  const modalContentCls = classArray(["modal-content", modalContentClassName]);

  const _renderErrorBody = (code) => {
    switch (code) {
      case 500:
        return "Likely character limit or input data type issue.";
      case 401:
        return "Unauthorized. The endpoint you are trying to reach is not available.";
      case 404:
        return "Unavailable. The route you are trying to access does not exist";
      case 400:
      default:
        return "Invalid request. If you receive this it is an issue with the data being sent.";
    }
  };

  return (
    <div className={modalCls}>
      <div className={modalContentCls} {...customProps}>
        {children}
        {error && (
          <div className="mr-3 ml-3">
            <div className={`alert alert-danger w-100`} role="alert">
              <h5 className="alert-heading">{error.message}</h5>
              <p>
                <b className="mr-1">Error Code {error.status}:</b>
                {_renderErrorBody(error.status)}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalContent;
