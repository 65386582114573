import React, { useEffect, useReducer } from "react";
import ReactSelect from "react-select";
import { connect } from "redux-bundler-react";
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "../../../app-components/modal";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};
const _convertToObject = (value, valueKey, labelKey, arr) => {
  if (!value || typeof value === "object") return value;
  let currentObj = arr.filter((item) => item[valueKey] == value)[0];
  if (currentObj)
    return { value: currentObj[valueKey], label: currentObj[labelKey] };
  else return null;
};
const DocumentModal = ({
  data,
  doDocumentDelete,
  documentIsDeleting,
  doDocumentEdit,
  documentIsUpdating,
  documentTypes,
  doModalClose,
  documentError: error,
}) => {
  let initialState = {};
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...data });
  useEffect(() => {
    dispatch({
      type: "INITIALIZE_MODAL",
      payload: state,
    });
  }, []);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSelect = (field, payload) => {
    dispatch({
      type: "UPDATE_INPUT",
      field,
      payload,
    });
  };

  return (
    <ModalContent size="md" error={error}>
      <ModalHeader title="Edit Document" />
      <section className="modal-body">
        <div className="container-fluid">
          <div className="d-flex pb-2">
            <div className="d-flex flex-column w-100 mr-1">
              <label>Name</label>
              <input
                className="form-control "
                onChange={handleChange}
                name={"name"}
                value={state.name}
              />
            </div>
            <div className="d-flex flex-column w-100 ml-1">
              <label>Subject</label>
              <input
                className="form-control"
                onChange={handleChange}
                name={"subject"}
                value={state.subject}
              />
            </div>
          </div>
          <div className="d-flex flex-column">
            <label>Document Type</label>
            <ReactSelect
              className="w-100"
              value={_convertToObject(
                state["document_type"],
                "document_type_id",
                "document_type_id",
                documentTypes
              )}
              inputId="document_type"
              options={documentTypes.map((item) => ({
                value: item.document_type_id,
                label: item.document_type_id,
              }))}
              onChange={(obj) => handleSelect("document_type", obj.value)}
              menuPosition="fixed"
            />
          </div>
        </div>
      </section>
      <ModalFooter
        customClosingLogic
        showCancelButton
        onCancel={doModalClose}
        onSave={() => doDocumentEdit(state)}
        onDelete={() => doDocumentDelete(state.id)}
        saveIsDisabled={documentIsUpdating}
        deleteIsDisabled={documentIsDeleting}
        saveText={
          documentIsUpdating ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Save"
          )
        }
        deleteText={
          documentIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "doModalClose",
  "doDocumentEdit",
  "doDocumentPost",
  "selectDocumentIsDeleting",
  "doDocumentDelete",
  "selectDocumentIsUpdating",
  "selectDocumentTypes",
  "selectDocumentError",
  DocumentModal
);
