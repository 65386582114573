import { sortBy } from "lodash";
import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "analysisMethods",
  getReducer: () => {
    const initialState = {
      data: [],
      isFetching: false,
      shouldFetch: false,
      isPosting: false,
      isDeleting: false,
    };

    return (state = initialState, { payload, type }) => {
      switch (type) {
        case "ANALYSIS_METHODS_FETCH_STARTED":
        case "ANALYSIS_METHODS_FETCH_FINISHED":
        case "ANALYSIS_METHODS_FETCH_ERROR":
        case "ANALYSIS_METHODS_SHOULD_FETCH":
        case "ANALYSIS_METHODS_DELETE_STARTED":
        case "ANALYSIS_METHODS_DELETE_FINISHED":
        case "ANALYSIS_METHODS_DELETE_ERROR":
        case "ANALYSIS_METHODS_SAVE_STARTED":
        case "ANALYSIS_METHODS_SAVE_FINISHED":
        case "ANALYSIS_METHODS_SAVE_ERROR":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doAnalysisMethodsFetch:
    (state) =>
    ({ dispatch, apiFetch }) => {
      dispatch({
        type: "ANALYSIS_METHODS_FETCH_STARTED",
        payload: { isFetching: true },
      });
      let url = "/wq_analysis_methods/?limit=10000";
      if (state) {
        url = ordsifyUrlBuilder(
          "/wq_analysis_methods/",
          [
            {
              keyword: "analysis_method_id",
              items: state.analysis_method_id ? [state.analysis_method_id] : [],
            },
            {
              keyword: "revision_number",
              items: state.revision_number ? [state.revision_number] : [],
            },
            {
              keyword: "method_source",
              items: state.method_source ? [state.method_source] : [],
            },
          ],
          10000
        );
      }
      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "ANALYSIS_METHODS_FETCH_FINISHED",
            payload: { isFetching: false, data: j.items },
          });
        })
        .catch((err) => {
          dispatch({
            type: "ANALYSIS_METHODS_FETCH_ERROR",
            payload: { isFetching: false, error: err },
          });
        });
    },

  doPostAnalysisMethods:
    (data, cb) =>
    ({ dispatch, store }) => {
      let {
        doActionPostData,
        doAnalysisMethodsFetch,
        selectApiRoot,
        doModalClose,
      } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "ANALYSIS_METHODS_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPostData(
        `${apiRoot}/wq_analysis_methods/`,
        data,
        () => {
          dispatch({
            type: "ANALYSIS_METHODS_SAVE_FINISHED",
            payload: { isSaving: false },
          });
          cb && cb();
          doAnalysisMethodsFetch();
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "ANALYSIS_METHODS_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },
  doPutAnalysisMethods:
    (data, cb) =>
    ({ dispatch, store }) => {
      let {
        doActionPutData,
        doAnalysisMethodsFetch,
        selectApiRoot,
        doModalClose,
      } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "ANALYSIS_METHODS_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPutData(
        `${apiRoot}/wq_analysis_methods/${data.method_code}`,
        data,
        () => {
          dispatch({
            type: "ANALYSIS_METHODS_SAVE_FINISHED",
            payload: { isSaving: false, shouldFetch: true },
          });
          cb && cb();
          doAnalysisMethodsFetch();
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "ANALYSIS_METHODS_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },
  doDeleteAnalysisMethods:
    (code, cb) =>
    ({ dispatch, store }) => {
      let {
        doActionDeleteData,
        doAnalysisMethodsFetch,
        selectApiRoot,
        doModalClose,
      } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "ANALYSIS_METHODS_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      doActionDeleteData(
        `${apiRoot}/wq_analysis_methods/${code}`,
        {},
        () => {
          dispatch({
            type: "ANALYSIS_METHODS_DELETE_FINISHED",
            payload: { isDeleting: false, shouldFetch: true },
          });
          cb && cb();
          doAnalysisMethodsFetch();
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "ANALYSIS_METHODS_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },
  selectAnalysisMethodsIsFetching: (state) => state.analysisMethods.isFetching,
  selectAnalysisMethodsIsDeleting: (state) => state.analysisMethods.isDeleting,
  selectAnalysisMethodsIsSaving: (state) => state.analysisMethods.isSaving,
  selectAnalysisMethodsError: (state) => state.analysisMethods.error,
  selectAnalysisMethodsItems: (state) => state.analysisMethods.data,
};
