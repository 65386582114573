import React, { useEffect } from "react";
import Table from "../tables/TableLocationProject";
import "../location/cards/customCard.css";
import { connect } from "redux-bundler-react";
const ProjectLocations = ({
  project,
  count,
  projectLocations,
  projectLocationsIsFetching,
  projectIsFetching,
  doProjectLocationsShouldFetch,
}) => {
  useEffect(doProjectLocationsShouldFetch, []);
  return projectLocationsIsFetching || projectIsFetching ? (
    <div className="custom-table" style={{ width: "300px" }}>
      <div className="card-body">
        <div style={{ textAlign: "center" }}>
          <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
        </div>
      </div>
    </div>
  ) : (
    <div className="custom-table">
      <div className="card-body">
        <div style={{ textAlign: "center" }}>
          <Table
            title={`${count} Locations at ${project && project.site_name}`}
            data={projectLocations}
            isLoading={projectLocationsIsFetching}
          />
        </div>
      </div>
    </div>
  );
};
export default connect(
  "selectProjectIsFetching",
  "doProjectLocationsShouldFetch",
  "selectProjectLocationsIsFetching",
  "selectProjectLocations",
  ProjectLocations
);
