import React, { useEffect, useReducer } from "react";
import { connect } from "redux-bundler-react";
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "../../../app-components/modal";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const EditTaxaModal = (props) => {
  const {
    doModalClose,
    data,
    edit,
    doTaxaPost,
    doTaxaPut,
    doTaxaDelete,
    taxaIsSaving,
    taxaIsDeleting,
    taxaError: error,
  } = props;

  let initialState = { entry_level: "" };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (edit) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: {
          ...data,
        },
      });
    }
  }, [edit, data]);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  return (
    <ModalContent size="md" error={error}>
      <ModalHeader title={edit ? "Add Taxa" : "Edit Taxa Info"} />
      <section className="modal-body">
        <div className="container-fluid">
          <form>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="entry_level">Entry Level</label>
                <input
                  type="text"
                  className="form-control"
                  name="entry_level"
                  onChange={handleChange}
                  placeholder="i.e. K"
                  value={state.entry_level}
                  maxLength="1"
                />
                <p className="m-0 mt-1 text-muted">
                  <small>
                    {1 - state.entry_level.length} characters remaining
                  </small>
                </p>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="sci_name">Sci Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="sci_name"
                  onChange={handleChange}
                  placeholder="i.e. IRONOQUIA"
                  value={state.sci_name}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="taxacode">Taxacode</label>
                <input
                  type="text"
                  className="form-control"
                  name="taxacode"
                  onChange={handleChange}
                  placeholder="i.e. 051300030006030401000100000000"
                  value={state.taxacode}
                />
              </div>
            </div>
          </form>
        </div>
      </section>
      <ModalFooter
        onSave={() => (edit ? doTaxaPut(state) : doTaxaPost(state))}
        customClosingLogic
        onDelete={edit ? () => doTaxaDelete(state) : null}
        showCancelButton
        onCancel={doModalClose}
        saveIsDisabled={taxaIsSaving}
        deleteIsDisabled={taxaIsDeleting}
        saveText={
          taxaIsSaving ? <i className="mdi mdi-loading mdi-spin" /> : "Save"
        }
        deleteText={
          taxaIsDeleting ? <i className="mdi mdi-loading mdi-spin" /> : "Delete"
        }
      />
    </ModalContent>
  );
};

export default connect(
  "doTaxaShouldFetch",
  "doTaxaTableFetch",
  "selectTaxaIsFetching",
  "selectTaxaData",
  "selectTaxaIsDeleting",
  "selectTaxaIsSaving",
  "selectTaxaError",
  "doTaxaPost",
  "doTaxaPut",
  "doTaxaDelete",
  EditTaxaModal
);
