import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_exporting from "highcharts/modules/exporting";
import { connect } from "redux-bundler-react";
HC_exporting(Highcharts);

function ChartMultiScatter({ data, filters, gates }) {
  const [chartOptions, setChartOptions] = useState({});
  const chartSymbols = [
    "circle",
    "square",
    "diamond",
    "triangle",
    "triangle-down",
  ];
  const series = [];

  let options = {
    chart: {
      type: "scatter",
      zoomType: "xy",
    },
    title: {
      // messy but have to check if there a projects, and then check if there are locations in the projects selected, then pick the location id/name
      text: ` ${
        filters && filters.projects && filters.projects.length > 0
          ? filters[filters.projects[0].value]
            ? filters[filters.projects[0].value][0]
              ? filters[filters.projects[0].value][0].label
              : ""
            : ""
          : ""
      } Profile Chart ${
        filters && filters.date
          ? typeof filters.date === "object"
            ? filters.date.toISOString().split("T")[0]
            : filters.date.split("T")[0]
          : ""
      }`,
      align: "left",
    },
    xAxis: {
      title: {
        text: "Value",
      },
      labels: {
        format: "{value}",
      },
      gridLineWidth: 1,
      gridLineDashStyle: "Dash",
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      min: filters && filters.min_value,
      max: filters && filters.max_value,
      tickInterval: 5,
    },
    yAxis: {
      title: {
        text: "Sample Depth",
      },
      labels: {
        format: "{value}",
      },
      reversed: true,
      min: filters && filters.min_sample_depth,
      max: filters && filters.max_sample_depth,
      tickInterval: 5,
      plotBands: Object.keys(gates).map((gateId) => {
        return {
          color:
            gates[gateId].type === "Bypass" ? "#B9EAC9" : "hsl(0, 0%, 80%)",
          from: gates[gateId].min,
          to: gates[gateId].max,
          label: gates[gateId].label,
        };
      }),
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      scatter: {
        marker: {
          radius: 4,
          symbol: "circle",
          states: {
            hover: {
              enabled: true,
              lineColor: "rgb(100,100,100)",
            },
          },
        },
        states: {
          hover: {
            marker: {
              enabled: false,
            },
          },
        },
      },
    },
    tooltip: {
      pointFormat: "Value: {point.x} <br/> Sample Depth: {point.y} ft",
    },
    series,
  };

  useEffect(() => {
    if (!data || data.length === 0) {
      setChartOptions({
        ...options,
        xAxis: {
          ...options.xAxis,
          min: filters && filters.min_value,
          max: filters && filters.max_value,
        },
        yAxis: {
          ...options.yAxis,
          min: filters && filters.min_sample_depth,
          max: filters && filters.max_sample_depth,
        },
        series: [],
      });
    } else {
      let newSeries = [];
      let obj = {};
      filters &&
        filters.parameters &&
        filters.parameters.forEach((param, i) => {
          if (!obj[param.value] && param) {
            newSeries.push({
              name: param.label,
              id: param.value,
              marker: { symbol: chartSymbols[i] },
              data: data
                .map((row) =>
                  row.storet_num === param.value
                    ? [row.value, row.sample_depth]
                    : null
                )
                .filter((x) => x !== null),
            });
            obj[param.value] = [];
          }
        });
      setChartOptions({
        ...options,
        xAxis: {
          ...options.xAxis,
          min: filters && filters.min_value ? filters.min_value : null,
          max: filters && filters.max_value ? filters.max_value : null,
        },
        yAxis: {
          ...options.yAxis,
          min:
            filters && filters.min_sample_depth
              ? filters.min_sample_depth
              : null,
          max:
            filters && filters.max_sample_depth
              ? filters.max_sample_depth
              : null,
        },
        series: newSeries,
      });
    }
  }, [filters, data, gates]);

  return (
    <div style={{ paddingTop: 24, height: "80vh", width: "100%" }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ style: { height: "100%", width: "100%" } }}
      />
    </div>
  );
}

export default connect(
  "selectLocationSelected",
  "selectFilters",
  ChartMultiScatter
);
