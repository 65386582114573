import { createRouteBundle } from "redux-bundler";
import FourOhFour from "../containers/404";

import PageBioResults from "../containers/bioresults/PageBioResults";
import PageBioSamples from "../containers/biosamples/PageBioSamples";

import PageAdmin from "../containers/admin/PageAdmin";
import ProjectHome from "../containers/project/projectHome";
import PageProjects from "../containers/project/PageProjects";
import LocationHome from "../containers/location/locationHome";
import LocationSamples from "../containers/sample/samplesHome";
import LocationNotes from "../containers/note/notesHome";
import LocationResults from "../containers/result/resultsHome";
import LocationResultsData from "../containers/result/resultsData";
import LocationAlias from "../containers/alias/aliasHome";
import LocationBulk from "../containers/tasks/documents/bulk/Index";
import LocationPhotos from "../containers/tasks/documents/photos/Index";
import LocationUpload from "../containers/tasks/documents/upload/Index";
import LocationDocuments from "../containers/tasks/documents/Index";

import ProjectMap from "../containers/mapping/index";
import Statistics from "../containers/report-tools/statistics/Index";
import Stations from "../containers/report-tools/stations/Index";
import Summary from "../containers/report-tools/summary/Index";
import Parameter from "../containers/report-tools/parameter/Index";
import Hitlist from "../containers/report-tools/hit/Index";
import TableParameters from "../containers/lookup-tables/parameters/TableParameters";
import TableTaxacode from "../containers/lookup-tables/taxacode/TableTaxacode";
import TableLabs from "../containers/lookup-tables/labs/TableLabs";
import TableMajorBasins from "../containers/lookup-tables/major-basins/TableMajorBasins";
import TableMinorBasins from "../containers/lookup-tables/minor-basins/TableMinorBasins";
import TableStreams from "../containers/lookup-tables/streams/TableStreams";

import FieldTripPage from "../containers/fieldTrips/index";
import IndicesPage from "../containers/indices/index";
import Home from "../containers/home";
import DataExport from "../containers/dataExport/DataExport";

import PageCharting from "../containers/charting2/Index";
import HeatmapPage from "../containers/heatmap";
import PageFieldVisit from "../containers/fieldTrips/PageFieldVisit";
import BulkImport from "../containers/bulkImport";
import VerticalProfile from "../containers/charting/profile";
import TableCollection from "../containers/lookup-tables/collection-mthd/TableCollection";
import PageCounties from "../containers/lookup-tables/counties/PageCounties";
import TableLocationType from "../containers/lookup-tables/location-types/TableLocationType";
import ApiDocs from "../containers/api-docs";
import AccessRequestForm from "../containers/home/AccessRequestForm";
import PageDataFlag from "../containers/lookup-tables/data-flag/PageDataFlag";
import PageReports from "../containers/reports/PageReports";
import PageAnalysisMethod from "../containers/lookup-tables/analysis-method/PageAnalysisMethod";

export default createRouteBundle({
  "": Home,
  "/": Home,
  "/api-documentation": ApiDocs,
  "/data-export": DataExport,
  "/bulk-import": BulkImport,
  "/admin": PageAdmin,
  "/access-request": AccessRequestForm,
  "/charting": PageCharting,
  "/charting/heatmap": HeatmapPage,
  "/charting/profile": VerticalProfile,
  "/collection": TableCollection,
  "/hitlist": Hitlist,
  "/parameter": Parameter,
  "/labs": TableLabs,
  "/majorbasins": TableMajorBasins,
  "/projects": PageProjects,
  "/mapping": ProjectMap,
  "/stations": Stations,
  "/streams": TableStreams,
  "/statistics": Statistics,
  "/summary": Summary,
  "/taxacode": TableTaxacode,
  "/minorbasins": TableMinorBasins,
  "/parameters": TableParameters,
  "/counties": PageCounties,
  "/location_types": TableLocationType,
  "/data_flags": PageDataFlag,
  "/analysis_methods": PageAnalysisMethod,
  "/projects/:project": ProjectHome,
  "/projects/:project/locations": ProjectHome,
  "/projects/:project/fieldtrips": ProjectHome,
  "/projects/:project/trends": ProjectHome,
  "/projects/:project/indices": ProjectHome,
  "/projects/:project/contour": ProjectHome,
  "/projects/:project/biologics": ProjectHome,
  "/projects/:project/locations/:id": LocationHome,

  // location specific context
  "/projects/:project/locations/:id/alias": LocationAlias,
  "/projects/:project/locations/:id/bioresults": PageBioResults,
  "/projects/:project/locations/:id/biosamples": PageBioSamples,
  "/projects/:project/locations/:id/notes": LocationNotes,
  "/projects/:project/locations/:id/reports": PageReports,
  "/projects/:project/locations/:id/results": LocationResults,
  "/projects/:project/locations/:id/results/:storet/:depth":
    LocationResultsData,
  "/projects/:project/locations/:id/samples": LocationSamples,
  "/projects/:project/locations/:id/fieldvisits": PageFieldVisit,
  "/projects/:project/locations/:id/documents": LocationDocuments,
  "/projects/:project/locations/:id/documents/upload": LocationUpload,
  "/projects/:project/locations/:id/documents/photos": LocationPhotos,
  "/projects/:project/locations/:id/documents/bulk": LocationBulk,

  "/projects/:project/fieldtrips/:fieldTrip": FieldTripPage,
  "/projects/:project/fieldtrips/add": FieldTripPage,

  "/projects/:project/indices/:location": IndicesPage,

  // Save for when adding bioresults bargraph
  // "/projects/:project/locations/:id/test": StackedBar,

  "*": FourOhFour,
});
