import React, { useEffect } from "react";
import CustomAvatar from "./CustomAvatar";
import { connect } from "redux-bundler-react";
import TableFieldVisitModal from "./TableFieldVisitModal";
import { stringAvatar } from "./helpers";
import MaterialTable from "material-table";
import FieldTripModal from "./FieldTripModal";

// Field tri ppage needs to show a quick summary of participants , date range and notes.
// that +
const FieldTripPage = ({
  fieldTripAll,
  fieldVisitByFieldTrip,
  doFieldVisitFetchByFieldTrip,
  doFieldTripFetch,
  doModalOpen,
  doModalOpenUniqueCode,
  routeParams,
  fieldVisitIsFetching,
  fieldTripIsFetching,
}) => {
  let currentFieldTrip = fieldTripAll.filter(
    (items) => items.field_trip_code == routeParams.fieldTrip
  )[0];

  useEffect(() => {
    doFieldTripFetch();
    doFieldVisitFetchByFieldTrip();
  }, []);

  if (fieldTripIsFetching || !currentFieldTrip)
    return <i className="mdi mdi-loading mdi-spin mdi-48px" />;
  return (
    <div
      style={{
        height: "100%",
        padding: "0 64px 0",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      <div
        className="custom-table d-flex flex-column p-3 mb-4"
        style={{ minHeight: `0 !important` }}
      >
        <div className="d-flex justify-content-between align-items-center border-bottom pb-1 mb-3">
          <h3 className="m-0"> Field Trip {currentFieldTrip.field_trip_id}</h3>
          <button
            className="btn btn-ghost"
            onClick={() => {
              doModalOpen(FieldTripModal, {
                data: currentFieldTrip,
                edit: true,
              });
            }}
          >
            <i className="mdi mdi-pencil-outline mdi-24px"></i>{" "}
          </button>
        </div>
        <p>
          <b className="mr-2">Date Range:</b>
          {currentFieldTrip.start_date.split("T")[0]} -{" "}
          {currentFieldTrip.end_date.split("T")[0]}
        </p>
        <div className="d-flex mb-2">
          <b className="mr-2">Participants:</b>
          {currentFieldTrip.participants &&
            JSON.parse(currentFieldTrip.participants).join(", ")}
        </div>
        <p>
          <b className="mr-2">Notes:</b>
          {currentFieldTrip.notes}
        </p>
      </div>
      <div className="custom-table p-3">
        <div className="d-flex justify-content-between">
          <h4>Field Visits</h4>
          <button
            className="btn btn-outline-success"
            onClick={(e) =>
              doModalOpenUniqueCode(
                TableFieldVisitModal,
                {
                  data: {
                    field_trip_code: parseInt(routeParams.fieldTrip),
                    participants: currentFieldTrip.participants,
                  },
                  edit: false,
                },
                "field_visit_code"
              )
            }
          >
            Add Field Visit
          </button>
        </div>
        <div className="card-body">
          <MaterialTable
            title={``}
            isLoading={fieldVisitIsFetching}
            columns={[
              {
                field: "link",
                title: "Actions",
                render: (rowData) => {
                  return (
                    <button
                      className="hover"
                      onClick={(e) => {
                        doModalOpen(TableFieldVisitModal, {
                          data: { ...rowData },
                          participants: currentFieldTrip.participants,

                          edit: true,
                        });
                      }}
                    >
                      <i className="mdi mdi-pencil-outline mdi-24px"></i>
                    </button>
                  );
                },
              },
              {
                title: "Field Trip",
                field: "field_trip_code",
                render: (rowData) => {
                  let ft =
                    rowData.field_trip_code &&
                    fieldTripAll.filter(
                      (f) => f.field_trip_code === rowData.field_trip_code
                    );
                  return ft && ft.length > 0 && ft[0].field_trip_id;
                },
              },
              { title: "Location", field: "loc_id" },
              {
                title: "Participants",
                field: "participants",
                render: (rowData) => {
                  return (
                    <div className="d-flex">
                      {rowData.participants &&
                        JSON.parse(rowData.participants).length &&
                        JSON.parse(rowData.participants).map((name) => (
                          <div className="mr-1">
                            <CustomAvatar
                              key={name}
                              size={30}
                              disabled
                              {...stringAvatar(name, 30)}
                            />
                          </div>
                        ))}
                    </div>
                  );
                },
              },
              {
                title: "Start Date",
                field: "field_visit_date",
              },
              {
                title: "End Date",
                field: "field_visit_end_date",
              },
              { title: "Notes", field: "notes" },
            ]}
            data={fieldVisitByFieldTrip}
            options={{
              loadingType: "overlay",
              exportButton: true,
              exportAllData: true,
              search: false,
              sorting: true,
              pageSize: 50,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [50, 500, 1000, 2000],
            }}
            actions={[
              {
                icon: "refresh",
                tooltip: "Refresh table",
                isFreeAction: true,
                onClick: () => doFieldVisitFetchByFieldTrip(),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(
  "doFieldVisitFetchByFieldTrip",
  "doFieldTripFetch",
  "selectFieldTripAll",
  "selectFieldVisitByFieldTrip",
  "doModalOpen",
  "selectRouteParams",
  "selectFieldVisitIsFetching",
  "doModalOpenUniqueCode",
  "selectFieldTripIsFetching",
  FieldTripPage
);
