import { createSelector } from "redux-bundler";
import olMap from "ol/Map.js";
import View from "ol/View";
import ScaleBar from "ol/control/ScaleLine";
// import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
// import { OSM, Vector as VectorSource } from "ol/source";
import BasemapPicker from "../ol-controls/basemap-picker";
import { fromLonLat } from 'ol/proj';
import { defaults } from "ol/control";
import "../ol-controls/ol.css"

const actions = {
    MAPS_INITIALIZED: `MAPS_INITIALIZED`,
    MAPS_SHUTDOWN: `MAPS_SHUTDOWN`,
};

const mapsBundle = {
    name: "maps",

    getReducer: () => {
        const initialData = {
            _geoProjection: "EPSG:4326",
            _webProjection: "EPSG:3857",
        };

        return (state = initialData, { type, payload }) => {
            switch (type) {
                case actions.MAPS_INITIALIZED:
                case actions.MAPS_SHUTDOWN:
                case "MAP_VIEW_UPDATED":
                    return Object.assign({}, state, payload);
                default:
                    return state;
            }
        };
    },

    doMapsInitialize: (key, el, options) => ({ dispatch, store }) => {
        const map = new olMap(
            Object.assign(
                {
                    controls: defaults().extend([new ScaleBar({ units: "us" }), new BasemapPicker()]),
                    target: el,
                    view: new View({
                        center: (options && options.center) || [-11000000, 4600000],
                        zoom: (options && options.zoom) || 4,
                    }),
                    layers: [],
                },
                options
            )
        );
        dispatch({
            type: actions.MAPS_INITIALIZED,
            payload: {
                [key]: map,
            },
        });
    },
    doMapsShutdown: (key) => ({ dispatch }) => {
        dispatch({
            type: actions.MAPS_SHUTDOWN,
            payload: {
                [key]: null,
            },
        });
    },
    doMapsZoomTo: (key, lonLat, z) => ({ dispatch, store }) => {
        const map = store.selectMapsObject()[key];
        const view = map.getView();
        view.animate({
            zoom: z || 10,
            center: fromLonLat(lonLat),
        });
        dispatch({ type: "MAP_VIEW_UPDATED" })
    },

    selectMapsState: (state) => {
        return state.maps;
    },
    selectMapsObject: createSelector("selectMapsState", (state) => {
        const items = {};
        Object.keys(state).forEach((key) => {
            if (key[0] !== "_") items[key] = state[key];
        });
        return items;
    }),
    selectMapsFlags: createSelector("selectMapsState", (state) => {
        const flags = {};
        Object.keys(state).forEach((key) => {
            if (key[0] === "_") flags[key] = state[key];
        });
        return flags;
    }),
    selectMapsGeoProjection: (state) => {
        return state.maps._geoProjection;
    },
    selectMapsWebProjection: (state) => {
        return state.maps._webProjection;
    },
};

export default mapsBundle;