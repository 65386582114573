import { sortBy } from "lodash";
import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "projectSummary",
  getReducer: () => {
    const initialData = {
      projectSummaryData: [],
      isSummaryFetching: false,
      projectSummaryShouldFetch: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "AUTH_VERIFY_TOKEN":
          return Object.assign({}, state, {
            projectSummaryShouldFetch: true,
          });
        case "PROJECT_SUMMARY_SHOULD_FETCH":
        case "PROJECT_SUMMARY_FETCH_START":
        case "PROJECT_SUMMARY_FETCH_FINISH":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doProjectSummaryShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "PROJECT_SUMMARY_SHOULD_FETCH",
        payload: { projectSummaryShouldFetch: true },
      });
    },

  doProjectSummaryFetch:
    (limit = 1000) =>
    ({ dispatch, store, apiFetch }) => {
      let id = store.selectRouteParams();
      dispatch({
        type: "PROJECT_SUMMARY_FETCH_START",
        payload: {
          projectSummaryShouldFetch: false,
          isSummaryFetching: true,
        },
      });

      let url = "/site_results_summary/?q={}&limit=1000";
      if (id.project) {
        url = ordsifyUrlBuilder(
          "/site_results_summary/",
          [
            {
              keyword: "site_code",
              items: [id.project],
            },
          ],
          limit
        );
      }
      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "PROJECT_SUMMARY_FETCH_FINISH",
            payload: { projectSummaryData: j.items, isSummaryFetching: false },
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  selectProjectSummaryShouldFetch: (store) =>
    store.project.projectSummaryShouldFetch,
  selectProjectSummaryIsFetching: (store) =>
    store.project.projectSummaryIsFetching,
  selectProjectSummaryData: (store) => store.project.projectSummaryData,

  reactProjectSummaryShouldFetch: createSelector(
    "selectProjectSummaryIsFetching",
    "selectProjectSummaryShouldFetch",
    (projectSummaryIsFetching, projectSummaryShouldFetch) => {
      // never fetch if anoter fetch already in progress
      if (projectSummaryIsFetching) {
        return null;
      }
      if (projectSummaryShouldFetch) {
        return {
          actionCreator: "doProjectSummaryFetch",
        };
      }
    }
  ),
};
