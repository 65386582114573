import React from "react";

import Highcharts from "highcharts/highstock";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import addHighchartsMore from "highcharts/highcharts-more";

addHighchartsMore(Highcharts);
HighchartsExporting(Highcharts);

// Thin wrapper around Highcharts component. Specific charts in the app will reference
// <Chart options={options} />. "options" passed to this component are the highcharts
// style options object.  This approach isolates all Highcharts imports into one
// component so they're not scattered across multiple charting components.
// This also technically would allow swapping-out the underlying charting library
// (Highcharts) and isolate code changes to this component.
// Note on containerProps: https://github.com/highcharts/highcharts-react/issues/72

export default function Chart(props) {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={props.options}
      containerProps={{ style: { width: "100%", height: props.height ? props.height : 800 } }}
    />
  );
}
