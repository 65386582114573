import React, { useState, useEffect } from "react";
import BasicTabs from "../../app-components/tabs/Tabs";
import { connect } from "redux-bundler-react";
import ManageUsers from "./ManageUsers";

const PageAdmin = ({
  doAccessRequestFetchAll,
  accessRequestAll,
  doGrantAccess,
  accessRequestIsDeleting,
  userRoleIsSaving,
  doDeleteAccessRequest,
  doUserFetchAll,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    doAccessRequestFetchAll();
    doUserFetchAll();
  }, []);

  return (
    <div>
      <div
        style={{
          padding: "0 64px 32px",
          height: "100%",
          width: "100%",
          minHeight: "calc(100vh - 106px)",
        }}
      >
        <BasicTabs
          onChange={(val) => setActiveTab(val)}
          activeTab={activeTab}
          labels={["Users", `Access Requests (${accessRequestAll.length})`]}
        >
          <ManageUsers />
          <div className="custom-table p-3">
            <div className="d-flex border-bottom w-100 justify-content-between align-items-center">
              <h4 className="my-2">Access Requests</h4>
            </div>
            {accessRequestAll.length > 0 ? (
              accessRequestAll.map((request, i) => {
                return (
                  <div
                    className="d-flex flex-column border rounded p-3 m-2"
                    key={`${request.name}${i}`}
                  >
                    <h6>{request.name}</h6>
                    <p>{request.office_id}</p>
                    <p>{request.create_date.split("T")[0]}</p>
                    {userRoleIsSaving || accessRequestIsDeleting ? (
                      <i className="mdi mdi-loading mdi-spin mdi-18px" />
                    ) : (
                      <div className="d-flex">
                        <button
                          className="btn btn-success mr-2"
                          id="ADMIN"
                          onClick={(e) => doGrantAccess(request, e.target.id)}
                        >
                          Accept as ADMIN
                        </button>
                        <button
                          className="btn btn-info mr-2"
                          id="MEMBER"
                          onClick={(e) => doGrantAccess(request, e.target.id)}
                        >
                          Accept as MEMBER
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() =>
                            doDeleteAccessRequest(
                              request.request_code,
                              doAccessRequestFetchAll
                            )
                          }
                        >
                          {" "}
                          Reject
                        </button>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <h4 className="m-3 text-muted">No access requests!</h4>
            )}
          </div>
        </BasicTabs>
      </div>
    </div>
  );
};

export default connect(
  "doUserFetchAll",
  "doAccessRequestFetchAll",
  "doDeleteAccessRequest",
  "selectAccessRequestAll",
  "selectUsersAll",
  "doGrantAccess",

  "selectAccessRequestIsFetching",

  "selectAccessRequestIsDeleting",
  "selectAccessRequestIsSaving",
  "selectUserRoleIsSaving",
  PageAdmin
);
