export default {
    name: "edit",

    getReducer: () => {
        const initialData = {
            editInfo: true
        };
        return (state = initialData, { type, payload }) => {
            switch (type) {
                case "EDIT_UPDATE":
                    return Object.assign({}, state, payload);
                default:
                    return state;
            }
        };
    },

    doEditInfoUpdate: () => ({ dispatch, store }) => {
        dispatch({
            type: "EDIT_UPDATE",
            payload: {
                editInfo: !store.selectEditInfo()
            }
        });
    },


    selectEditInfo: state => state.edit.editInfo,


}
