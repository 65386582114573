import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import {
  TextField,
  Snackbar,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import ModalType from "./Modal";

const DocumentUpload = connect(
  "doDocumentUpload",
  "selectDocumentStatus",
  "doDocumentClearStatus",
  "selectDocumentTypes",
  "selectRouteParams",
  "doDocumentTypeFetch",
  ({
    doDocumentUpload,
    documentStatus,
    doDocumentClearStatus,
    documentTypes,
    routeParams,
    doDocumentTypeFetch,
  }) => {
    const [file, setFile] = useState("");
    const [subject, setSubject] = useState("");
    const [open, setOpen] = useState(false);
    const [type, setType] = useState(1);
    const [location, setLocation] = useState("");
    useEffect(() => {
      if (documentStatus === 200) {
        setOpen(true);
      }
    }, [documentStatus]);

    useEffect(() => {
      setLocation(routeParams.id);
      doDocumentTypeFetch();
    }, [routeParams]);

    const handleChange = (e) => {
      setFile(e.target.files[0]);
    };
    const handleClose = () => {
      setOpen(false);
      doDocumentClearStatus(null);
    };
    const handleTypeChange = (event) => {
      setType(parseInt(event.target.value));
    };

    return (
      <div
        style={{
          padding: "0 64px 32px",
          height: "100%",
          width: "100%",
          minHeight: "calc(100vh - 106px)",
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Success!
          </Alert>
        </Snackbar>
        <div className="card" style={{ width: "100%" }}>
          <div className="card-body">
            <h4 className="card-title">Upload Documents</h4>
            <div className="row">
              <div className="col-sm">
                <input
                  type="file"
                  id="files"
                  onChange={(e) => handleChange(e)}
                ></input>
                <div style={{ height: "15px" }}></div>
                <TextField
                  label="Subject"
                  variant="outlined"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
              <div className="col-sm">
                <FormLabel>Choose Document Type</FormLabel>
                <RadioGroup
                  onChange={handleTypeChange}
                  value={type}
                  defaultValue={1}
                >
                  {documentTypes.map((x) => {
                    return (
                      <FormControlLabel
                        value={x.document_type_code}
                        control={<Radio />}
                        label={x.document_type_id}
                      />
                    );
                  })}
                </RadioGroup>
              </div>
              <div className="col-sm">
                <ModalType />
              </div>
            </div>
            <div style={{ marginTop: "30px" }}>
              <button
                className="btn btn-primary rounded-pill"
                style={{ maxWidth: 250, maxHeight: 35, marginRight: 12 }}
                onClick={() => doDocumentUpload(file, location, subject, type)}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default DocumentUpload;
