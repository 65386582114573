import React, { useReducer, useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import {
  ModalFooter,
  ModalContent,
  ModalHeader,
} from "../../app-components/modal";
import Select from "react-select";
import EditResultModal from "./EditResultModal";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const SampleModal = ({
  data,
  edit,
  hideResults,
  nested,
  location_code,
  collectionsSelects,
  doModalClose,
  routeParams,
  fieldVisitByLocation,
  sample_code,
  doNestedModalOpenUniqueCode,
  doNestedModalClose,
  doSamplePost,
  doSamplePut,
  doSampleDelete,
  sampleIsSaving,
  sampleIsDeleting,
  sampleError: error,
  doUpdateModalProps,
}) => {
  let initialState = {
    location_code: location_code ? location_code : routeParams.id,
    sample_code: sample_code ? sample_code : null,
    date_entered: new Date(),
  };
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...data });
  useEffect(() => {
    dispatch({
      type: "INITIALIZE_MODAL",
      payload: state,
    });
  }, []);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSelect = (field, payload) => {
    dispatch({
      type: "UPDATE_INPUT",
      field,
      payload,
    });
  };

  const doSave = () => {
    state.sample_time =
      state.sample_time && !state.sample_time.includes("Z")
        ? `${state.sample_time}:00Z`
        : state.sample_time;
    state.date_entered = new Date();
    state.sample_depth = parseInt(state.sample_depth);
    if (edit) {
      doSamplePut(state, () => {
        doUpdateModalProps({ data: state, edit: true });
        nested ? doNestedModalClose() : doModalClose();
      });
    } else {
      doSamplePost(state, () => {
        doUpdateModalProps({ data: state, edit: true });
      });
    }
  };

  const _renderSampleForm = () => {
    const _getFieldVisitValue = () => {
      if (!state["field_visit_code"]) return;
      let selectedFV =
        fieldVisitByLocation &&
        fieldVisitByLocation.filter(
          (obj) => obj.field_visit_code === state["field_visit_code"]
        )[0];
      if (selectedFV) {
        return {
          label: selectedFV.field_visit_code,
          value: selectedFV.field_visit_code,
        };
      } else return null;
    };
    return (
      <>
        <div className="d-flex mb-2">
          <div className="d-flex flex-column w-100 mr-1">
            <label htmlFor="sample_time">Sample Date</label>
            <input
              className="custom-input"
              type="datetime-local"
              placeholder="Sample Date"
              value={
                state["sample_time"] && state["sample_time"].includes("Z")
                  ? state["sample_time"].split("Z")[0]
                  : state["sample_time"]
              }
              name={"sample_time"}
              id={"sample_time"}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex flex-column w-100 ml-1">
            <label htmlFor="sample_num">Sample Number</label>
            <input
              className="custom-input"
              type="text"
              value={state["sample_num"]}
              name={"sample_num"}
              id={"sample_num"}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="d-flex flex-column w-100 mb-2">
          <label htmlFor="sample_depth">Sample Depth</label>
          <input
            className="custom-input"
            type="number"
            placeholder="Sample Depth"
            value={state["sample_depth"]}
            name={"sample_depth"}
            id={"sample_depth"}
            onChange={handleChange}
          />
        </div>
        <div className="mb-2">
          <label htmlFor="collect">Collection Method</label>
          <Select
            value={
              collectionsSelects && state["collect_mthd"]
                ? collectionsSelects.filter(
                    (obj) => obj.value === state["collect_mthd"]
                  )[0]
                : null
            }
            name={"collect_mthd"}
            inputId="collect"
            options={collectionsSelects.map((item) => ({
              value: item.value,
              label: item.label,
            }))}
            onChange={(obj) => handleSelect("collect_mthd", obj.value)}
            menuPosition="fixed"
          />
        </div>
        <div>
          <label htmlFor="field">Field Visit</label>
          <Select
            value={_getFieldVisitValue()}
            name={"field_visit_code"}
            inputId="field"
            options={fieldVisitByLocation.map((item) => ({
              value: item.field_visit_code,
              label: item.field_visit_code,
            }))}
            onChange={(obj) => handleSelect("field_visit_code", obj.value)}
            menuPosition="fixed"
          />
        </div>
      </>
    );
  };

  return (
    <ModalContent size="lg" error={error}>
      <ModalHeader title={edit ? "Edit Sample" : "New Sample"} />
      <section className="modal-body">
        <div className="container-fluid">{_renderSampleForm()}</div>
        {edit && !hideResults && (
          <div className="d-flex w-100 justify-content-end">
            <button
              className="btn btn-info mt-2"
              onClick={() =>
                doNestedModalOpenUniqueCode(
                  EditResultModal,
                  { sample_code: state.sample_code, editSample: true },
                  "result_code"
                )
              }>
              {"Add Results"}
            </button>
          </div>
        )}
      </section>
      <ModalFooter
        showCancelButton
        customClosingLogic
        onCancel={nested ? doNestedModalClose : doModalClose}
        onSave={doSave}
        onDelete={() => doSampleDelete(state)}
        saveIsDisabled={sampleIsSaving}
        deleteIsDisabled={sampleIsDeleting}
        saveText={
          sampleIsSaving ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : edit ? (
            "Update Sample"
          ) : (
            "Save"
          )
        }
        deleteText={
          sampleIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "doActionDeleteData",
  "doSamplesShouldFetch",
  "doActionPostData",
  "doActionPutData",
  "selectRouteParams",
  "selectApiRoot",
  "selectCollectionsSelects",
  "selectStoretOptions",
  "doCwmsSeqFetch",
  "doCwmsSeqNewFetch",
  "selectCwmsSeq",
  "selectCwmsSeqIsFetching",
  "doSampleFetchByCode",
  "selectSampleSelected",
  "selectSampleIsFetching",
  "selectIsPosting",
  "doModalClose",
  "selectLabs",
  "selectFieldVisitByLocation",
  "doSamplePost",
  "doSamplePut",
  "doSampleDelete",
  "selectSampleIsSaving",
  "selectSampleIsDeleting",
  "selectSampleError",
  "doNestedModalOpenUniqueCode",
  "doNestedModalClose",
  "doUpdateModalProps",
  SampleModal
);
