import React from "react";
import Link from "../../misc/link";
import "./customCard.css";
import { TableChart, ArrowRightAlt } from "@material-ui/icons";

function CardNotes(props) {
  return !props.location || !props.project ? (
    <div className="custom">
      <div className="card-body">
        <div style={{ textAlign: "center" }}>
          <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="custom custom-notes"
    >
      <a style={{ textDecoration: 'none', cursor: 'pointer', height: '100%' }} href={`/projects/${props.project.site_code}/locations/${props.location.location_code}/notes`}>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridTemplateRows: '1fr', height: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <TableChart color="white" htmlColor="white" fontSize='large' />

          </div>
          <h5 className="card-title m-0" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '100%', color: 'white' }}>Notes</h5>
        </div>
        <div style={{ position: 'absolute', right: 8, bottom: 0 }}>
          <ArrowRightAlt color='white' htmlColor='white' fontSize='large' />
        </div>
      </a>
    </div>
  );
}

export default CardNotes;
