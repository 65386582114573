import React from "react";
import Home from "./home";
import { connect } from "redux-bundler-react";

const AuthProvider = ({ authIsLoggedIn: isLoggedIn, children }) => {
  const _renderContent = () => {
    if (!isLoggedIn && window.location.pathname !== "/api-documentation")
      return null;
    else return children;
    // else return <AccessRequest />;
  };
  return (
    <div>
      {!isLoggedIn && window.location.pathname !== "/api-documentation" && (
        <Home />
      )}
      {_renderContent()}
    </div>
  );
};

export default connect("selectAuthIsLoggedIn", AuthProvider);
