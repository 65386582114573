import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "taxa",
  getReducer: () => {
    const initialData = {
      data: [],
      shouldFetch: false,
      isFetching: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "TAXA_FETCH_START":
        case "TAXA_FETCH_FINISH":
        case "TAXA_SHOULD_FETCH":
        case "TAXA_TABLE_FETCH_START":
        case "TAXA_SAVE_STARTED":
        case "TAXA_SAVE_FINISHED":
        case "TAXA_SAVE_ERROR":
        case "TAXA_DELETE_STARTED":
        case "TAXA_DELETE_FINISHED":
        case "TAXA_DELETE_ERROR":
          return Object.assign({}, state, payload);
        case "MODAL_CLOSED":
          return { ...state, error: null };
        case "BIOSAMPLE_TYPE_FETCH_FINISHED":
          return { ...state, shouldFetch: true };
        default:
          return state;
      }
    };
  },

  doTaxaShouldFetch:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "TAXA_SHOULD_FETCH",
        payload: {
          shouldFetch: true,
        },
      });
    },

  doTaxaFetch:
    (location_codes) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "TAXA_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      const url = ordsifyUrlBuilder(
        "/taxa/",
        [
          {
            keyword: "location_code",
            items: location_codes ? location_codes : [], // if no location_codes passed, fetch all
          },
        ],
        20000
      );

      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "TAXA_FETCH_FINISH",
            payload: {
              isFetching: false,
              data: j.items,
            },
          });
        });
    },
  doTaxaTableFetch:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "TAXA_TABLE_FETCH_START",
        payload: {
          shouldTableFetch: false,
          isFetching: true,
        },
      });

      let url = `/taxa/?limit=10000`;

      if (state) {
        url = ordsifyUrlBuilder(
          "/taxa/",
          [
            {
              keyword: "sci_name",
              items: state["sci_name"] ? state["sci_name"] : "", // if no location_codes passed, fetch all
            },
          ],
          20000
        );
      }

      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "TAXA_FETCH_FINISH",
            payload: {
              isFetching: false,
              data: j.items,
            },
          });
        });
    },

  doTaxaPost:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPostData, doModalClose } = store;
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "TAXA_SAVE_STARTED",
        payload: { isSaving: true },
      });
      let url = `${apiRoot}/wq_taxa/`;
      doActionPostData(
        url,
        state,
        () => {
          dispatch({
            type: "TAXA_SAVE_FINISHED",
            payload: { isSaving: false, shouldTableFetch: true, error: null },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "TAXA_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doTaxaPut:
    (state) =>
    ({ dispatch, store }) => {
      let { doActionPutData, doModalClose } = store;
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "TAXA_SAVE_STARTED",
        payload: { isSaving: true },
      });
      let url = `${apiRoot}/wq_taxa/${state.taxacode}`;
      doActionPutData(
        url,
        state,
        () => {
          dispatch({
            type: "TAXA_SAVE_FINISHED",
            payload: { isSaving: false, shouldTableFetch: true, error: null },
          });
          doModalClose();
        },
        (e) =>
          dispatch({
            type: "TAXA_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doTaxaDelete:
    (state) =>
    ({ dispatch, store }) => {
      let apiRoot = store.selectApiRoot();
      dispatch({
        type: "TAXA_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      store.doActionDeleteData(
        `${apiRoot}/wq_taxa/${state.taxacode}`,
        {},
        () => {
          dispatch({
            type: "TAXA_DELETE_FINISHED",
            payload: { isDeleting: false, shouldTableFetch: true, error: null },
          });
          store.doModalClose();
        },
        (e) =>
          dispatch({
            type: "TAXA_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },

  selectTaxaRaw: (store) => store.taxa,

  selectTaxaData: (state) => state.taxa.data,
  selectTaxaIsFetching: (store) => store.taxa.isFetching,
  selectTaxaShouldFetch: (store) => store.taxa.shouldFetch,

  selectTaxaTableShouldFetch: (store) => store.taxa.shouldTableFetch,
  selectTaxaIsDeleting: (state) => state.taxa.isDeleting,
  selectTaxaIsSaving: (state) => state.taxa.isSaving,
  selectTaxaError: (state) => state.taxa.error,

  selectTaxaDataByCode: createSelector(
    "selectTaxaData",
    "selectBioResultDataByCode",
    (taxaData, bioResultsByCode) => {
      return taxaData.filter((obj) => {
        return bioResultsByCode.map((pt) => pt.taxacode).includes(obj.taxacode);
      });
    }
  ),
  reactTaxaShouldFetch: createSelector(
    "selectTaxaIsFetching",
    "selectTaxaShouldFetch",
    (isFetching, shouldFetch) => {
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doTaxaFetch",
        };
      }
    }
  ),
  reactTaxaTableShouldFetch: createSelector(
    "selectTaxaIsFetching",
    "selectTaxaTableShouldFetch",
    (isFetching, shouldFetch) => {
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doTaxaTableFetch",
        };
      }
    }
  ),
};
