import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import { OutlinedInput, InputLabel, InputAdornment, IconButton, FormControl } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Search, Delete, Clear } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: theme.spacing(1),
        width: 250,
    },
}));

const Toolbar = connect(
    "doDocumentSearch",
    "selectRouteParams",
    ({
        doDocumentSearch,
        routeParams
    }) => {
        const [search, setSearch] = useState('')
        const [searchActive, setSearchActive] = useState(false)
        const handleSearch = () => {
            doDocumentSearch(search, routeParams.id)
            setSearchActive(true)
        }
        const classes = useStyles();
        const handleClear = () => {
            setSearchActive(false)
            setSearch('')
            doDocumentSearch()
        }
        return (
            <div>
                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel>Search</InputLabel>
                    <OutlinedInput
                        label={"Search"}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        endAdornment={searchActive ?
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClear}
                                >
                                    {<Clear />}
                                </IconButton>
                            </InputAdornment> :
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleSearch}
                                >
                                    {<Search />}
                                </IconButton>
                            </InputAdornment>
                        } />
                </FormControl>
            </div>
        )
    }
)

export default Toolbar