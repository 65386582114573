import React from "react";
import "./BottomNav.css";

export default (props) => {
  return (
    <nav
      className={`bottomNav ${props.className}`}
      style={{ backgroundColor: "rgb(25, 118, 210)" }}
    >
      <a
        style={{ color: "white" }}
        className="nav-item "
        href="/"
        title="The Enterprise Water Quality (WQ) Demonstration application is the database of record for the US Army Corps of Engineers (USACE) Civil Works (CW) WQ mission. This application is currently in demonstration."
      >
        About Us
      </a>
      <a
        style={{ color: "white" }}
        className="nav-item nav-link active"
        href="/"
        title="By using this application, you acknowledge that the data seen in this application should be considered provisional. Do not use this application as an absolute or accurate representation of current water levels, water quality physical or biological conditions, harmful algae blooms, or as a predictor of past, present, or future conditions. The application is intended for demonstration and display purposes only.
 
          Questions, concerns, or suggestions? Please contact us at: wq@usace.army.mil"
      >
        Legal
      </a>
      <a
        style={{ color: "white" }}
        className="nav-item nav-link active"
        href="https://www.usace.army.mil/Accessibility.aspx"
      >
        Accessibility
      </a>
      <a
        style={{ color: "white" }}
        className="nav-item nav-link active"
        href="mailto:wq@usace.army.mil"
      >
        Contact Us
      </a>
      <a
        style={{ color: "white" }}
        className="nav-item nav-link active"
        href="https://www.usace.army.mil/FOIA.aspx"
      >
        FOIA
      </a>
      <a
        style={{ color: "white" }}
        className="nav-item nav-link active"
        href="http://www.usace.army.mil/InformationQualityAct.aspx"
      >
        Information Quality Act
      </a>
      <a
        style={{ color: "white" }}
        className="nav-item nav-link active"
        href="https://www.usace.army.mil/Link-Disclaimer/"
      >
        Link Disclaimer
      </a>
      <a
        style={{ color: "white" }}
        className="nav-item nav-link active"
        href="http://prhome.defense.gov/nofear"
      >
        No Fear Act
      </a>
      <a
        style={{ color: "white" }}
        className="nav-item nav-link active"
        href="http://www.usace.army.mil/PrivacyandSecurity.aspx"
      >
        Privacy/Security
      </a>
      <a
        style={{ color: "white" }}
        className="nav-item nav-link active"
        href="mailto:Bryan.R.Armbrust@usace.army.mil@usace.army.mil"
      >
        Public Inquiries
      </a>
      <a
        style={{ color: "white" }}
        className="nav-item nav-link active"
        href="https://usa.gov"
      >
        USA.gov
      </a>
    </nav>
    /* <BottomNavigation
                  showLabels
                  className={classes.root}
              >
                  <Tooltip title="The Enterprise Water Quality (WQ) Demonstration application is the database of record for the US Army Corps of Engineers (USACE) Civil Works (CW) WQ mission. This application is currently in demonstration.">
                      <p style={{ paddingRight: "15px" }}>About Us</p>
                  </Tooltip>
                  <Divider orientation="vertical" flexItem />
                  <Tooltip title="By using this application, you acknowledge that the data seen in this application should be considered provisional. Do not use this application as an absolute or accurate representation of current water levels, water quality physical or biological conditions, harmful algae blooms, or as a predictor of past, present, or future conditions. The application is intended for demonstration and display purposes only.">
                      <p style={{ paddingLeft: "15px", paddingRight: "15px" }}>Legal</p>
                  </Tooltip>
                  <Divider orientation="vertical" flexItem />
                  <Link to="https://www.usace.army.mil/Accessibility.aspx">
                      <p style={{ paddingLeft: "15px", paddingRight: "15px" }}>Accessibility</p>
                  </Link>
                  <Divider orientation="vertical" flexItem />
                  <Link to="mailto:wq@usace.army.mil">
                      <p style={{ paddingLeft: "15px", paddingRight: "15px" }}>Contact Us</p>
                  </Link>
                  <Divider orientation="vertical" flexItem />
                  <Link to="https://www.usace.army.mil/FOIA.aspx">
                      <p style={{ paddingLeft: "15px", paddingRight: "15px" }}>FOIA</p>
                  </Link>
                  <Divider orientation="vertical" flexItem />
                  <Link to="http://www.usace.army.mil/InformationQualityAct.aspx">
                      <p style={{ paddingLeft: "15px", paddingRight: "15px" }}>Information Quality Act</p>
                  </Link>
                  <Divider orientation="vertical" flexItem />
                  <Link to="https://www.usace.army.mil/Link-Disclaimer/">
                      <p style={{ paddingLeft: "15px", paddingRight: "15px" }}>Link Disclaimer</p>
                  </Link>
                  <Divider orientation="vertical" flexItem />
                  <Link to="http://prhome.defense.gov/nofear">
                      <p style={{ paddingLeft: "15px", paddingRight: "15px" }}>No Fear Act</p>
                  </Link>
                  <Divider orientation="vertical" flexItem />
                  <Link to="http://www.usace.army.mil/PrivacyandSecurity.aspx">
                      <p style={{ paddingLeft: "15px", paddingRight: "15px" }}>Privacy/Security</p>
                  </Link>
                  <Divider orientation="vertical" flexItem />
                  <Link to="mailto:Bryan.R.Armbrust@usace.army.mil@usace.army.mil">
                      <p style={{ paddingLeft: "15px", paddingRight: "15px" }}>Public Inquiries</p>
                  </Link>
                  <Divider orientation="vertical" flexItem />
                  <Link to="https://usa.gov">
                      <p style={{ paddingLeft: "15px" }}>USA.gov</p>
                  </Link>
              </BottomNavigation> */
  );
};
