import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import ReactSelect from "react-select";
import {
  ModalContent,
  ModalHeader,
  ModalFooter,
} from "../../app-components/modal";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "../location/locationHome.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxHeight: "70vh",
  minWidth: "40vw",
};

const ExportDataModal = (props) => {
  const {
    doProjectSummaryFetch,
    doResultLocFetch,
    resultsLocData,
    routeParams,
    storetOptions,
    doModalClose,
  } = props;
  const [formValues, setFormValues] = useState({
    dateFrom: new Date(),
    dateTo: new Date(),
    locationInfo: "idOnly",
  });
  const [includeDate, setIncludeDate] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    doProjectSummaryFetch();
    let dates = createDateArray();
    let storetArr = storetValsToArr();
    storetArr = storetArr && storetArr[0] === undefined ? [] : storetArr;
    doResultLocFetch(null, storetArr, dates, 20000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _dataFetch = (dateFrom, dateTo) => {
    let dates = createDateArray(dateFrom, dateTo);
    let storetArr = storetValsToArr();
    storetArr = storetArr && storetArr[0] === undefined ? [] : storetArr;
    doResultLocFetch(null, storetArr, dates, 20000);
  };

  const dateFromOnChange = (date) => {
    _dataFetch(date, formValues.dateTo);
    setFormValues((state) => ({ ...state, dateFrom: date }));
  };
  const dateToOnChange = (date) => {
    _dataFetch(formValues.dateFrom, date);
    setFormValues((state) => ({ ...state, dateTo: date }));
  };
  const handleChange = (e) =>
    setFormValues((state) => ({ ...state, [e.target.name]: e.target.value }));
  const handleSelect = (obj) => {
    _dataFetch();
    setFormValues((state) => ({ ...state, storet: obj }));
  };

  const handleSubmit = (e) => {
    let newData = [];
    if (resultsLocData.length === 0) {
      setError(true);
      return;
    }
    let location_keys = [
      "northing",
      "easting",
      "huc",
      "quad_code",
      "loc_desc",
      "datum_ref",
      "time_zone_name",
      "state_initial",
      "stream_code",
      "stream_name",
      "major_code",
      "minor_code",
      "major_basin_name",
      "major_basin_abbr",
      "minor_basin_name",
      "latitude",
      "longitude",
      "loc_id",
    ];
    /* Push titles of each column first */
    newData.push(
      Object.keys(resultsLocData[0])
        .filter((key, i) => {
          if (location_keys.includes(key)) {
            switch (formValues.locationInfo) {
              case "idOnly":
                return key === "loc_id";
              case "brief":
                return (
                  key === "state_initial" ||
                  key === "loc_id" ||
                  key === "major_basin_name" ||
                  key === "minor_basin_name"
                );
              case "full":
                return key;
            }
          } else if (i !== 0) {
            return key;
          }
        })
        .filter((val) => val !== undefined)
        .reverse()
    );

    /* Add data to table here */
    resultsLocData.forEach((obj) => {
      newData.push(
        Object.keys(obj)
          .map((key, i) => {
            if (location_keys.includes(key)) {
              switch (formValues.locationInfo) {
                case "idOnly":
                  if (key === "loc_id") {
                    return obj[key];
                  }
                  break;
                case "brief":
                  if (
                    key === "state_initial" ||
                    key === "loc_id" ||
                    key === "major_basin_name" ||
                    key === "minor_basin_name"
                  ) {
                    return obj[key];
                  }
                  break;
                case "full":
                  return typeof obj[key] === "string"
                    ? obj[key].replaceAll(",", "")
                    : obj[key];
              }
            } else if (i === 0) {
              return undefined;
            } else {
              return typeof obj[key] === "string"
                ? obj[key].replaceAll(",", "")
                : obj[key];
            }
          })
          .filter((val) => val !== undefined)
          .reverse()
      );
    });

    /* Add todays date and custom text if selected in first two rows */
    let todaysDateRow;
    if (formValues.includeTextField) {
      let includeTextRow = [formValues.includeTextField];
      includeTextRow.push(...Array(Object.keys(newData[0]).length - 1));
      newData.unshift(includeTextRow);
    }
    if (includeDate) {
      todaysDateRow = ["Report Date", new Date().toISOString().split("T")[0]];
      todaysDateRow.push(...Array(Object.keys(newData[0]).length - 2));
      newData.unshift(todaysDateRow);
    }
    setError(false);
    let csv =
      "data:text/csv;charset=utf-8," +
      newData.map((e) => e.join(",")).join("\n");
    var encodedUri = encodeURI(csv);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `${routeParams.project.toString()}_${
        new Date().toISOString().split("T")[0]
      }_report.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    doModalClose();
  };

  const dateToString = (date) =>
    date.toISOString().split("T")[0] + "T00:00:00Z";
  const createDateArray = (dateFrom, dateTo) => [
    dateToString(dateFrom ? dateFrom : formValues.dateFrom),
    dateToString(dateTo ? dateTo : formValues.dateTo),
  ];
  const storetValsToArr = () =>
    formValues &&
    formValues.storet &&
    formValues.storet.map((item) => item.storet_num);

  const _appendDateToData = (e) => {
    setIncludeDate(e.target.checked);
  };

  return (
    <ModalContent size="md">
      <ModalHeader title="Export Project Data" />
      <section className="modal-body">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <form component="fieldset" onSubmit={handleSubmit}>
            <div style={{ paddingBottom: 16, display: "flex" }}>
              <div style={{ paddingRight: 12 }}>
                <DatePicker
                  label="From"
                  onChange={dateFromOnChange}
                  value={formValues.dateFrom}
                  format="MM/dd/yyyy"
                  disableFuture
                />
              </div>
              <DatePicker
                label="To"
                onChange={dateToOnChange}
                value={formValues.dateTo}
                format="MM/dd/yyyy"
                disableFuture
              />
            </div>
            <div style={{ paddingBottom: 16, width: "100%" }}>
              <ReactSelect
                isMulti
                name={"storet"}
                placeholder="Parameters"
                options={storetOptions}
                onChange={handleSelect}
              />
            </div>
            <div
              style={{
                paddingBottom: 16,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ paddingBottom: 16, height: "100%" }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={_appendDateToData}
                        name="includeDate"
                      />
                    }
                    label="Include report date"
                  />
                  <textarea
                    style={{ padding: 12 }}
                    placeholder="Report Notes"
                    label="Report Notes"
                    variant="outlined"
                    name="includeTextField"
                    onChange={handleChange}
                  />
                </FormGroup>
              </div>
              <RadioGroup
                defaultValue="idOnly"
                name="locationInfo"
                onChange={handleChange}
                value={formValues.locationInfo}
              >
                <FormControlLabel
                  value="idOnly"
                  control={<Radio />}
                  label="Include only station IDs"
                />
                <FormControlLabel
                  value="brief"
                  control={<Radio />}
                  label="Brief station information (ID, water body reference, state)"
                />
                <FormControlLabel
                  value="full"
                  control={<Radio />}
                  label="Full station info"
                />
              </RadioGroup>
            </div>
          </form>
        </MuiPickersUtilsProvider>
      </section>
      <ModalFooter
        saveText="Download CSV"
        onSave={handleSubmit}
        showCancelButton
        customClosingLogic
        onCancel={doModalClose}
      />
      {error && (
        <div className="text-danger pl-3 pb-3">
          There is no data available for the provided parameters.
        </div>
      )}
    </ModalContent>
  );
};

export default connect(
  "selectRouteParams",
  "selectProjectSummaryData",
  "doProjectSummaryFetch",
  "doResultLocFetch",
  "selectResultsLocData",
  "selectResultsLocIsFetching",
  "selectStoretOptions",
  "doModalClose",
  ExportDataModal
);
