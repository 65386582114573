import React, { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import Select from 'react-select';

export default connect(
    "doProjectMapTypeActive",
    "selectProjectMapTypes",
    "selectProjectMapTypeActive",
    ({
        doProjectMapTypeActive,
        projectMapTypes,
        projectMapTypeActive
    }) => {
        const [val, setVal] = useState(null)
        const [values, setValues] = useState([])
        useEffect(() => {
            if (projectMapTypes && projectMapTypes.length) {
                setValues(projectMapTypes)
            }
        }, [projectMapTypes])
        useEffect(() => {
            if (projectMapTypeActive === '') {
                setVal(null)
            }
        })
        const handleChange = (e) => {
            if (e) {
                setVal(e)
                doProjectMapTypeActive(e.value)
            } else {
                setVal(null)
            }
        }
        return (
            <div style={{ width: "200px", padding: "5px" }}>
                <Select
                    placeholder="Location Types"
                    options={values}
                    value={val}
                    onChange={(value) => handleChange(value)}
                    menuPosition="fixed"
                />
            </div>
        )
    }
)