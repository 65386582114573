import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";
import EditCollectionModal from "./EditCollectionModal";
import { collectConfig } from "../../dataExport/filterConfigs";
import { TableContainer } from "../../../app-components/page-content";

const TableCollection = (props) => {
  const {
    collections,
    doCollectionTableFetch,
    collectionIsFetching,
    doModalOpen,
    doModalOpenUniqueCode,
    userIsAnyMemberOrAdmin,
  } = props;

  const _fetchCollectionMthd = () => {
    doCollectionTableFetch();
  };

  useEffect(_fetchCollectionMthd, []);
  //let locId = location && location.loc_id;

  return (
    <div
      style={{
        width: "100%",
        padding: "16px 64px 32px",
        height: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      <TableContainer fullWidth>
        <div className="custom-table">
          <div className="card-body">
            <div className="d-flex justify-content-between w-100 mb-2">
              <h4 className="m-0">Collection Methods</h4>
              {userIsAnyMemberOrAdmin && (
                <button
                  className="btn btn-outline-success"
                  style={{ maxWidth: 250, maxHeight: 35 }}
                  onClick={() => {
                    doModalOpenUniqueCode(
                      EditCollectionModal,
                      {},
                      "collection_method_code"
                    );
                  }}
                >
                  Add Collection Method
                </button>
              )}
            </div>
            <MaterialTable
              title=""
              isLoading={collectionIsFetching}
              columns={[
                {
                  field: "link",
                  title: "Actions",
                  render: (rowData) => {
                    if (userIsAnyMemberOrAdmin)
                      return (
                        <button
                          className="hover"
                          onClick={(e) => {
                            doModalOpen(EditCollectionModal, {
                              data: rowData,
                              edit: true,
                            });
                          }}
                        >
                          <i className="mdi mdi-pencil-outline mdi-24px"></i>
                        </button>
                      );
                  },
                },
                { title: "Office ID", field: "office_id" },
                { title: "Method Type", field: "meth_type" },
                { title: "Collection Method", field: "collect_mthd" },
                // { title: "Method Code", field: "collection_method_code" },
                { title: "Method Name", field: "meth_name" },
              ]}
              data={collections}
              options={{
                loadingType: "overlay",
                exportButton: true,
                exportAllData: true,
                //filtering: true,
                sorting: true,
                search: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 500, 1000, 2000],
              }}
            />
          </div>
        </div>
      </TableContainer>
    </div>
  );
};
export default connect(
  "doCollectionTableFetch",
  "doCollectionsShouldFetch",
  "selectCollections",
  "doActionPostData",
  "doActionDeleteData",
  "selectApiRoot",
  "doCwmsSeqFetch",
  "doModalOpen",
  "selectCwmsSeqIsFetching",
  "selectUserIsAnyMemberOrAdmin",
  "doModalOpenUniqueCode",
  "selectCollectionIsFetching",
  ...collectConfig.selectors,
  ...collectConfig.fetchFns,
  TableCollection
);
