// Utilities for Oracle ORDS specific tasks
// ORDS syntax from this reference:
// https://docs.oracle.com/cd/E56351_01/doc.30/e87809/developing-REST-applications.htm#AELIG3000

// Return ORDS "equals" querystring part
const ordsifyEquals = (str) => `{"$eq":"${str}"}`;
const ordsifyDate = (str) => `{"$date":"${str}"}`;
const ordsifyFilter = (str) => `{"$instr":"${str}"}`;

//const ordsifyGreaterThan = value => `{"$gt":"${value}"}`;

const ordsifyQuerystringPartBuilder = (keyword, items) => {
  // Builds a single element in an ORDS querystring, "where ITEM in ('a', 'b', 'c')"
  // Example: {"location_code":{"or":[{"$eq": 10},{"$eq": 11},{"$eq": 12}]}}

  if (items.length === 0) {
    return "";
  } else if (
    keyword === "sample_time" ||
    keyword === "field_visit_date" ||
    keyword === "date_created"
  ) {
    return `"${keyword}": { "$between": [${items
      .map((i) => ordsifyDate(i))
      .join()}]}`;
  } else if (
    keyword === "sample_year" ||
    keyword === "value" ||
    keyword === "count" ||
    keyword === "sample_depth_range"
  ) {
    return `"${
      keyword === "sample_depth_range" ? "sample_depth" : keyword
    }": { "$between": [${items[0]}, ${items[1]}]}`;
  } else if (
    keyword === "sci_name" ||
    keyword === "full_name" ||
    keyword === "sample_num" ||
    keyword === "data_qualifier_flag" ||
    keyword === "associated_lab" ||
    keyword === "analysis_method_id" ||
    keyword === "method_source"
    // keyword === "taxacode" ||
    // keyword === "meth_name" ||
    // keyword === "full_name" ||
    // keyword === "storet_num"
  ) {
    return `"${keyword}": ${ordsifyFilter(items)}`;
  } else {
    return `"${keyword}": { "$or": [${
      items && items.map((i) => ordsifyEquals(i)).join()
    }]}`;
  }
};

const ordsifyBaseurlBuilder = (base_url) => {
  // Strip trailing slashes.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace
  // https://stackoverflow.com/questions/31273443/remove-last-slash-from-url-with-regex
  return base_url.replace(/\/+$/, "");
};

const ordsifyQuerystringBuilder = (querystring_definitions) => {
  // Assemble multiple parts of a querystring into complete querystring.
  // Multiple parts of complete querystring represent a "high level AND"
  // Returned querystring includes the ?q=
  const querystring_parts = querystring_definitions
    .map((q) => ordsifyQuerystringPartBuilder(q.keyword, q.items))
    .filter((i) => i !== ""); // Filter Empty strings
  return `?q={${querystring_parts.join()}}`;
};

const ordsifyLimitstringBuilder = (limit) => {
  return `limit=${parseInt(limit)}`;
};

const ordsifyUrlBuilder = (base_url, querystring_definitions, limit) => {
  return `${base_url}/${ordsifyQuerystringBuilder(
    querystring_definitions
  )}&${ordsifyLimitstringBuilder(limit)}`;
};
const ordsifyUrlBuilderNoSlash = (base_url, querystring_definitions, limit) => {
  return `${base_url}${ordsifyQuerystringBuilder(
    querystring_definitions
  )}&${ordsifyLimitstringBuilder(limit)}`;
};

const ordsifyFetchPaginated = (url, items, resolve, reject) => {
  // Fetches all pages of an ORDS-backed paginated endpoint, may take a long time...
  // https://itnext.io/how-to-get-resources-from-paginated-rest-api-d7c20fe2bb0b

  // @TODO apiFetch?
  fetch(url)
    .then((r) => r.json())
    .then((j) => {
      const retrieved = j.items.concat(items);
      if (j.hasMore) {
        const next_url = j.links
          .find((i) => i.rel === "next")
          .href.replace(/http:/, "https:");

        ordsifyFetchPaginated(next_url, retrieved, resolve, reject);
      } else {
        resolve(retrieved);
      }
    });
};

const abbr = {
  1: "JAN",
  2: "FEB",
  3: "MAR",
  4: "APR",
  5: "MAY",
  6: "JUN",
  7: "JUL",
  8: "AUG",
  9: "SEP",
  10: "OCT",
  11: "NOV",
  12: "DEC",
};
function parseDate(s) {
  var b = s.split(/\D/);
  return new Date(b[0], --b[1], b[2]);
}
const stringifyDate = (date) => {
  let newDate;
  if (typeof date === "string") newDate = parseDate(date);
  else newDate = new Date(date);
  const year = newDate.getFullYear();
  const strYear = year.toString().slice(2);
  const month = newDate.getMonth();
  const day = newDate.getDate();
  const string = day + "-" + abbr[month + 1] + "-" + strYear;
  return string;
};

export {
  ordsifyBaseurlBuilder,
  ordsifyFetchPaginated,
  ordsifyLimitstringBuilder,
  ordsifyQuerystringBuilder,
  ordsifyUrlBuilder,
  stringifyDate,
  ordsifyUrlBuilderNoSlash,
};
