import React, { useState } from "react";
import "./datagrid.css";

const SelectableCell = ({ children, idx, onClick, active, title }) => {
  let activeCell = active === idx;
  return (
    <td
      className={`border selectable ${activeCell ? "active-cell" : ""}`}
      onClick={onClick}>
      {activeCell && idx === `00_${title}` ? (
        <input
          defaultValue={children}
          className="custom-input"
          style={{ width: 100 }}
        />
      ) : (
        children
      )}
    </td>
  );
};

const DataGrid = ({ title = "", data, active, setActive, columns }) => {
  const [error, setError] = useState();
  return (
    <div className="d-flex flex-column w-100 mt-3">
      <div className="d-flex flex-column mb-2 align-items-flex-start justify-content-center">
        <h5>{title}</h5>
        <h6 className="text-muted">
          <small>
            To upload data, copy from excel sheet, select the top left cell in
            the table below and press Ctrl+V on your keyboard or right click to
            paste the data.
          </small>
        </h6>
      </div>
      <table className="table table-striped border w-100" id={title}>
        <tr>
          {columns.map((col) => (
            <th className="border">{col.name}</th>
          ))}
        </tr>
        <tbody>
          {data.map((row, rowIdx) => {
            return (
              <tr>
                {row.map((cell, cellIdx) => {
                  return (
                    <SelectableCell
                      title={title}
                      idx={`${rowIdx}${cellIdx}_${title}`}
                      onClick={
                        !setActive
                          ? () => {}
                          : () => setActive(`${rowIdx}${cellIdx}_${title}`)
                      }
                      active={active}>
                      {Object.values(cell).join("")}
                    </SelectableCell>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {error && (
        <div className="alert alert-danger">
          <div className=" d-flex justify-content-between align-items-center">
            <h6 className="alert-heading">{error}</h6>
            <button className="btn btn-ghost" onClick={() => setError(null)}>
              <i className="mdi mdi-close mdi-18px" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default DataGrid;
