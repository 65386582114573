import { createSelector } from "redux-bundler";

export default {
  name: "office",
  getReducer: () => {
    const initialData = {
      data: [],
      shouldFetch: false,
      isFetching: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "OFFICE_FETCH_START":
        case "OFFICE_FETCH_FINISH":
        case "OFFICE_SHOULD_FETCH":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doOfficeShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "OFFICE_SHOULD_FETCH",
        payload: {
          shouldFetch: true,
        },
      });
    },

  doOfficeFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "OFFICE_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      const url = "/office/";
      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "OFFICE_FETCH_FINISH",
            payload: {
              isFetching: false,
              data: j.items,
            },
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

  selectOfficeData: (state) => state.office.data,
  selectOfficeIsFetching: (state) => state.office.isFetching,
  selectOfficeShouldFetch: (state) => state.office.shouldFetch,

  reactOfficeShouldFetch: createSelector(
    "selectOfficeIsFetching",
    "selectOfficeShouldFetch",
    (isFetching, shouldFetch) => {
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doOfficeFetch",
        };
      }
    }
  ),
};
