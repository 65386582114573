import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";
import { TableContainer } from "../../../app-components/page-content";
import DataFlagModal from "./DataFlagModal";

const TableDataFlag = (props) => {
  const {
    dataFlagItems,
    doDataFlagFetch,
    dataFlagIsFetching,
    doModalOpen,
    doModalOpenUniqueCode,
    userIsAnyMemberOrAdmin,
  } = props;

  useEffect(doDataFlagFetch, []);

  return (
    <div className="custom-table mt-3">
      <div className="card-body">
        <div className="d-flex w-100 align-items-center justify-content-between mb-2">
          <h4 className="m-0">Data Qualifier Flags</h4>
          {userIsAnyMemberOrAdmin && (
            <button
              className="btn btn-outline-success"
              style={{ maxWidth: 250, maxHeight: 35 }}
              onClick={() => {
                doModalOpenUniqueCode(DataFlagModal, {}, "flag_code");
              }}
            >
              Add Data Qualifier Flag
            </button>
          )}
        </div>
        <MaterialTable
          title=""
          isLoading={dataFlagIsFetching}
          columns={[
            {
              field: "link",
              title: "Actions",
              render: (rowData) => {
                if (userIsAnyMemberOrAdmin)
                  return (
                    <button
                      className="hover"
                      onClick={(e) => {
                        doModalOpen(DataFlagModal, {
                          data: rowData,
                          edit: true,
                        });
                      }}
                    >
                      <i className="mdi mdi-pencil-outline mdi-24px"></i>
                    </button>
                  );
              },
            },
            { title: "Data Qualifier Flag", field: "data_qualifier_flag" },
            { title: "Associated Lab", field: "associated_lab" },
            { title: "Office ID", field: "office_id" },
            { title: "Definition", field: "definition" },
          ]}
          data={dataFlagItems}
          options={{
            loadingType: "overlay",
            exportButton: true,
            exportAllData: true,
            sorting: true,
            search: false,
            pageSize: 20,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [20, 500, 1000, 2000],
          }}
        />
      </div>
    </div>
  );
};
export default connect(
  "doDataFlagFetch",
  "selectDataFlagItems",
  "doCwmsSeqFetch",
  "doModalOpen",
  "selectDataFlagIsFetching",
  "selectUserIsAnyMemberOrAdmin",
  "doModalOpenUniqueCode",
  TableDataFlag
);
