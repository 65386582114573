import React from "react";
import { connect } from "redux-bundler-react";
import Chart from "./Chart";

// Component purpose is to create highcharts options object for boxplot
// and call "<Chart options={options} />"
// Box Plot Series reference:
// https://www.highcharts.com/docs/chart-and-series-types/box-plot-series


function StackedBar({data, loading, ...props}) {

    if(loading) return <i className="mdi mdi-loading mdi-spin mdi-48px"></i>;
    else return <Chart options={data} height={props.height} />;
}

export default connect(
    "selectLocationSelected",
    "selectStoretSelected",
    "selectResultsummaryData",
    StackedBar
);
