export default {
  name: "lrnForm",
  getReducer: () => {
    const initialData = {
      data: [],
      params: [],
      shouldFetch: false,
      isFetching: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "LRN_FORM_PARAMS_FETCH_START":
        case "LRN_FORM_PARAMS_FETCH_FINISH":
        case "LRN_FORM_PARAMS_FETCH_ERROR":
        case "LRN_FORM_SHOULD_FETCH":
        case "LRN_FORM_FETCH_STARTED":
        case "LRN_FORM_FETCH_FINISHED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doLrnFormShouldFetch:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "LRN_FORM_SHOULD_FETCH",
        payload: {
          shouldFetch: true,
        },
      });
    },

  doLrnFormParamsFetch:
    (dates, storet_num, limit = 20000) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "LRN_FORM_PARAMS_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      const url = "/bulk/celrn_form_561/param";
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "LRN_FORM_PARAMS_FETCH_FINISH",
            payload: {
              params: j.items,
              isFetching: false,
            },
          })
        )
        .catch((e) =>
          dispatch({
            type: "LRN_FORM_PARAMS_FETCH_ERROR",
            payload: {
              isFetching: false,
            },
          })
        );
    },

  doLrnFormFetch:
    (fieldVisitCode, cb) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "LRN_FORM_FETCH_STARTED",
        payload: { isFetching: true },
      });
      let url = fieldVisitCode
        ? `${store.selectApiRoot()}/form_561_t/?q={"field_visit_code":${fieldVisitCode}}`
        : `${store.selectApiRoot()}/form_561_t/`;
      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "LRN_FORM_FETCH_FINISHED",
            payload: { isFetching: false, data: j.items },
          });
          cb && cb();
        });
    },

  selectLrnForm: (state) => state.lrnForm.data,

  selectLrnFormParams: (state) => state.lrnForm.params,
  selectLrnFormIsFetching: (store) => store.lrnForm.isFetching,
  reactLrnFormShouldFetch: (state) => {
    // never fetch if another fetch is in progress
    if (state.lrnForm.isFetching) return null;

    if (state.lrnForm.shouldFetch)
      return {
        actionCreator: "doLrnFormFetch",
      };
  },
};
