import React, { useEffect, useReducer } from "react";
import { connect } from "redux-bundler-react";
import {
  ModalContent,
  ModalHeader,
  ModalFooter,
} from "../../app-components/modal";
import ReactSelect from "react-select";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    default:
      return state;
  }
};

const _convertToObject = (value, valueKey, labelKey, arr) => {
  if (!value || typeof value === "object") return value;
  let currentObj = arr.filter((item) => item[valueKey] == value)[0];
  return { value: currentObj[valueKey], label: currentObj[labelKey] };
};
const AddLocationModal = (props) => {
  const {
    location,
    majorBasinAll,
    minorBasinAll,
    streamAll,
    projectAll,
    stateAll,
    doStreamFetch,
    doStateShouldFetch,
    doMajorBasinShouldFetch,
    doMinorBasinShouldFetch,
    locationTypeItems,
    countiesItems,
    doModalClose,
    location_code,
    edit,
    doLocationPost,
    doLocationPut,
    doLocationDelete,
    locationIsSaving,
    locationIsDeleting,
    routeParams,
    locationError: error,
    doLocationTypeShouldFetch,
  } = props;
  let initialState = edit
    ? { ...location }
    : {
        location_code,
        site_code: routeParams.project,
        quad_code: "",
        datum_ref: "",
        huc: "",
        depth_uom: "",
        be_uom: "",
      };
  const [info, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    doStreamFetch();
    doStateShouldFetch();
    doMajorBasinShouldFetch();
    doMinorBasinShouldFetch();
    doLocationTypeShouldFetch();
  }, []);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.id,
      payload: e.target.value,
    });
  };

  const handleChangeSelect = (obj, name) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: name,
      payload: obj.value,
    });
  };

  let countiesInState = info.state
    ? countiesItems.filter((c) => c.storet_state == info.state)
    : countiesItems;

  return (
    <ModalContent size="lg" error={error}>
      <ModalHeader title="Location Details" />
      <section className="modal-body">
        <div className="container-fluid">
          <form>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="location_code">Location Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="location_code"
                  // onChange={handleChange}
                  placeholder="Location code..."
                  value={info.location_code}
                  disabled
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="loc_id">Location ID</label>
                <input
                  type="text"
                  className="form-control"
                  id="loc_id"
                  onChange={handleChange}
                  placeholder="i.e. 3BAR20011"
                  value={info.loc_id}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="loc_id">Location Desc</label>
                <input
                  type="text"
                  className="form-control"
                  id="loc_desc"
                  onChange={handleChange}
                  placeholder="i.e. Eddy Creek Embayment Mile 2.0"
                  value={info.loc_desc}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <div>
                  <label id="stream-selector-label" htmlFor="location_stream">
                    Stream
                  </label>
                </div>
                <ReactSelect
                  labelId="stream-selector-label"
                  id="stream_code"
                  name="stream_code"
                  placeholder="Stream..."
                  onChange={(obj) => handleChangeSelect(obj, "stream_code")}
                  value={_convertToObject(
                    info.stream_code,
                    "stream_code",
                    "stream_label",
                    streamAll
                  )}
                  options={streamAll.map((s) => ({
                    value: s.stream_code,
                    label: s.stream_label,
                  }))}
                />
              </div>
              <div className="form-group col-md-6">
                <div>
                  <label id="type-selector-label" htmlFor="location_type_code">
                    Location Type
                  </label>
                </div>
                <ReactSelect
                  labelId="type-selector-label"
                  id="location_type_code"
                  name="location_type_code"
                  placeholder="Location Type..."
                  onChange={(obj) =>
                    handleChangeSelect(obj, "location_type_code")
                  }
                  value={_convertToObject(
                    info.location_type_code,
                    "type_code",
                    "type_name",
                    locationTypeItems
                  )}
                  options={
                    locationTypeItems.length > 0 &&
                    locationTypeItems.map((s) => ({
                      value: s.type_code,
                      label: s.type_name,
                    }))
                  }
                />
              </div>
            </div>
            <h5 className="border-bottom">Coordinate Info</h5>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="quad_code">Quad Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="quad_code"
                  placeholder="i.e. 0001"
                  onChange={handleChange}
                  value={info.quad_code}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="datum_ref">Datum Ref</label>
                <input
                  type="text"
                  className="form-control"
                  id="datum_ref"
                  placeholder="i.e. NAD83"
                  onChange={handleChange}
                  value={info.datum_ref}
                  maxLength="8"
                />
                <p className="m-0 mt-1 text-muted">
                  <small>
                    {8 - (info.datum_ref ? info.datum_ref.length : 0)}{" "}
                    characters remaining
                  </small>
                </p>
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="latlong_mthd">Lat Long Method</label>
                <input
                  type="text"
                  className="form-control"
                  id="latlong_mthd"
                  placeholder="i.e. Digital"
                  onChange={handleChange}
                  value={info.latlong_mthd}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="lat_deg">Lat Degree</label>
                <input
                  type="text"
                  className="form-control"
                  id="lat_deg"
                  placeholder="i.e. 36"
                  onChange={handleChange}
                  value={info.lat_deg}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="lat_min">Lat Minute</label>
                <input
                  type="text"
                  className="form-control"
                  id="lat_min"
                  placeholder="i.e. 23"
                  onChange={handleChange}
                  value={info.lat_min}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="lat_sec">Lat Second</label>
                <input
                  type="text"
                  className="form-control"
                  id="lat_sec"
                  placeholder="i.e. 45"
                  onChange={handleChange}
                  value={info.lat_sec}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="long_deg">Long Degree</label>
                <input
                  type="text"
                  className="form-control"
                  id="long_deg"
                  placeholder="i.e. 53"
                  onChange={handleChange}
                  value={info.long_deg}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="long_min">Long Minute</label>
                <input
                  type="text"
                  className="form-control"
                  id="long_min"
                  placeholder="i.e. 32"
                  onChange={handleChange}
                  value={info.long_min}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="long_sec">Long Second</label>
                <input
                  type="text"
                  className="form-control"
                  id="long_sec"
                  placeholder="i.e. 29"
                  onChange={handleChange}
                  value={info.long_sec}
                />
              </div>
            </div>
            <h5 className="border-bottom">Location Info</h5>
            <div className="form-row">
              <div className="form-group col-md-4">
                <div>
                  <label id="site-code-selector-label" htmlFor="site_code">
                    Project
                  </label>
                </div>
                <ReactSelect
                  menuPosition="fixed"
                  labelId="site-code-selector-label"
                  id="site_code"
                  name="site_code"
                  onChange={(obj) => handleChangeSelect(obj, "site_code")}
                  value={_convertToObject(
                    info.site_code,
                    "site_code",
                    "site_name",
                    projectAll
                  )}
                  options={projectAll.map((item) => ({
                    value: item.site_code,
                    label: item.site_name,
                  }))}
                />
              </div>
              <div className="form-group col-md-3">
                <div>
                  <label id="state-selector-label" htmlFor="state">
                    State
                  </label>
                </div>
                <ReactSelect
                  menuPosition="fixed"
                  labelId="state-selctor-label"
                  id="state"
                  name="state"
                  onChange={(obj) => handleChangeSelect(obj, "state")}
                  value={_convertToObject(
                    info.state,
                    "storet_state",
                    "postal_state",
                    stateAll
                  )}
                  options={stateAll.map((s) => ({
                    value: s.storet_state,
                    label: s.postal_state,
                  }))}
                />
              </div>
              <div className="form-group col-md-3">
                <label id="county-selector-label" htmlFor="cnty_code">
                  County
                </label>
                <ReactSelect
                  menuPosition="fixed"
                  labelId="county-selctor-label"
                  id="cnty_code"
                  name="cnty_code"
                  onChange={(obj) => handleChangeSelect(obj, "cnty_code")}
                  noOptionsMessage={() => (
                    <p className="m-0 d-flex flex-column">
                      To add counties for this state, please navigate to the
                      counties lookup table and add!
                      <a href="/counties" onClick={() => doModalClose()}>
                        Go to Counties
                      </a>
                    </p>
                  )}
                  value={_convertToObject(
                    info.cnty_code,
                    "cnty_code",
                    "cnty_name",
                    countiesInState
                  )}
                  options={countiesInState.map((s) => ({
                    value: s.cnty_code,
                    label: s.cnty_name,
                  }))}
                />
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="huc">HUC</label>
                <input
                  type="text"
                  className="form-control"
                  id="huc"
                  placeholder="i.e. 05130205"
                  onChange={handleChange}
                  value={info.huc}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-3">
                <label htmlFor="major_basin">Major Basin</label>
                <ReactSelect
                  menuPosition="fixed"
                  id="major_basin"
                  name="major_basin"
                  onChange={(obj) => handleChangeSelect(obj, "major_basin")}
                  value={_convertToObject(
                    info.major_basin,
                    "major_code",
                    "major_name",
                    majorBasinAll
                  )}
                  options={majorBasinAll.map((mb) => ({
                    value: mb.major_code,
                    label: mb.major_name,
                  }))}
                />
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="minor_basin">Minor Basin</label>
                <ReactSelect
                  menuPosition="fixed"
                  id="minor_basin"
                  name="minor_basin"
                  onChange={(obj) => handleChangeSelect(obj, "minor_basin")}
                  value={_convertToObject(
                    info.minor_basin,
                    "minor_code",
                    "minor_name",
                    minorBasinAll
                  )}
                  options={minorBasinAll
                    .filter((mb) =>
                      info.major_basin
                        ? info.major_basin === mb.major_code
                        : true
                    )
                    .map((mb) => ({
                      value: mb.minor_code,
                      label: mb.minor_name,
                    }))}
                />
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="streamMile">Stream Mile</label>
                <input
                  type="text"
                  className="form-control"
                  id="stream_mile"
                  placeholder="i.e. 108"
                  onChange={handleChange}
                  value={info.stream_mile}
                />
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="substrate">Substrate</label>
                <input
                  type="text"
                  className="form-control"
                  id="substrate"
                  placeholder="i.e. sandstone"
                  onChange={handleChange}
                  value={info.substrate}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-2">
                <label htmlFor="max_depth">Max Depth</label>
                <input
                  type="text"
                  className="form-control"
                  id="max_depth"
                  placeholder="i.e. 40"
                  onChange={handleChange}
                  value={info.max_depth}
                />
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="depth_uom">Depth Units</label>
                <input
                  type="text"
                  className="form-control"
                  id="depth_uom"
                  placeholder="i.e. ft"
                  onChange={handleChange}
                  value={info.depth_uom}
                  maxLength="2"
                />
                <p className="m-0 mt-1 text-muted">
                  <small>
                    {2 - (info.depth_uom ? info.depth_uom.length : 0)} chrs
                    remaining
                  </small>
                </p>
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="bottom_elev">Bottom Elevation</label>
                <input
                  type="text"
                  className="form-control"
                  id="bottom_elev"
                  placeholder="i.e. 100"
                  onChange={handleChange}
                  value={info.bottom_elev}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="be_uom">Bottom Elevation Units</label>
                <input
                  type="text"
                  className="form-control"
                  id="be_uom"
                  placeholder="i.e. cm"
                  onChange={handleChange}
                  value={info.be_uom}
                  maxLength="2"
                />
                <p className="m-0 mt-1 text-muted">
                  <small>
                    {2 - (info.be_uom ? info.be_uom.length : 0)} chrs remaining
                  </small>
                </p>
              </div>
            </div>
          </form>
        </div>
      </section>
      <ModalFooter
        onSave={() => (edit ? doLocationPut(info) : doLocationPost(info))}
        customClosingLogic
        onDelete={edit ? () => doLocationDelete(info) : null}
        showCancelButton
        onCancel={doModalClose}
        saveIsDisabled={locationIsSaving}
        deleteIsDisabled={locationIsDeleting}
        saveText={
          locationIsSaving ? <i className="mdi mdi-loading mdi-spin" /> : "Save"
        }
        deleteText={
          locationIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "doActionPutData",
  "doActionPostData",
  "doLocationFetch",
  "doLocationTypeShouldFetch",
  "doStateShouldFetch",
  "doStreamFetch",
  "selectApiRoot",
  "selectStateAll",
  "selectStreamAll",
  "selectRouteParams",
  "selectProjectAll",
  "selectMinorBasinAll",
  "selectMajorBasinAll",
  "doMajorBasinShouldFetch",
  "doMinorBasinShouldFetch",
  "doUpdateUrl",
  "selectIsPosting",
  "selectLocation",
  "selectLocationTypeItems",
  "selectCountiesItems",
  "doModalClose",

  "doLocationPost",
  "doLocationPut",
  "doLocationDelete",
  "selectLocationIsSaving",
  "selectLocationIsDeleting",
  "selectLocationError",
  AddLocationModal
);
