import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import "../dataExport/dataexport.css";
import { TableContainer } from "../../app-components/page-content";
import BulkUpload from "../tasks/documents/bulk/Upload";
import MaterialTable from "material-table";
import AigsTempTable from "../tasks/documents/bulk/AigsTempTable";
import BasicTabs from "../../app-components/tabs/Tabs";
import { bioresultsCsv, aigsCsv, resultsCsv } from "../../assets";
import AigsParamsModal from "./AigsParamsModal";

const BulkImport = ({
  aigsObservationData,
  doBulkImport,
  doAigsTempFetch,
  aigsObservationDataIsFetching: isFetching,
  isImporting,
  bulkParsedData,
  bulkParsedDataFields,
  routeParams,
  bioResultBulkParsedData,
  bioResultBulkParsedDataFields,
  resultBulkParsedData,
  resultBulkParsedDataFields,
  doBulkParse,
  doBulkParseBioresults,
  doBulkParseClear,
  doBulkParseClearBioresults,
  doBulkImportBioresults,
  doModalOpen,
  doAigsParamsFetch,
  doBulkParseResults,
  doBulkParseClearResults,
  doBulkImportResults,
}) => {
  const [success, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    doAigsTempFetch();
    doAigsParamsFetch();
  }, []);

  const _onFetch = () => {
    setSuccess(true);
    doAigsTempFetch();
  };

  const _doBulkImport = (csv) => {
    if (csv) {
      activeTab === 0
        ? doBulkImportResults(csv, () => {})
        : activeTab === 1
        ? doBulkImport(csv, _onFetch)
        : doBulkImportBioresults(csv, _onFetch);
    } else
      setErrorMsg(
        "Please provide a file to upload, and reattempt bulk import."
      );
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      {" "}
      <div className="sidenav">
        <h3 className="card-title border-bottom">File Upload</h3>
        <BulkUpload
          onImport={_doBulkImport}
          states={{ success, setSuccess, errorMsg, setErrorMsg }}
          doParse={
            activeTab === 0
              ? doBulkParseResults
              : activeTab === 1
              ? doBulkParse
              : doBulkParseBioresults
          }
          doClear={
            activeTab === 0
              ? doBulkParseClearResults
              : activeTab === 1
              ? doBulkParseClear
              : doBulkParseClearBioresults
          }
          csvFile={
            activeTab === 0
              ? resultsCsv
              : activeTab === 1
              ? aigsCsv
              : bioresultsCsv
          }
        />
      </div>
      <TableContainer>
        <div className="custom-table mt-3">
          <div className="card-body">
            <div className="d-flex flex-column">
              <div className="m-3">
                <BasicTabs
                  onChange={(val) => setActiveTab(val)}
                  activeTab={activeTab}
                  labels={["Results", "AIGS", "BioResults"]}
                  routeParams={routeParams}
                >
                  <>
                    <MaterialTable
                      title={"Results Data Preview"}
                      columns={
                        resultBulkParsedDataFields &&
                        resultBulkParsedDataFields.length
                          ? resultBulkParsedDataFields.map((x) => {
                              return { title: x, field: x };
                            })
                          : []
                      }
                      data={
                        resultBulkParsedData && resultBulkParsedData.length
                          ? resultBulkParsedData
                          : []
                      }
                      loading={isFetching || isImporting}
                      options={{
                        loadingType: "overlay",
                        exportButton: true,
                        exportAllData: true,
                        //filtering: true,
                        sorting: true,
                        search: false,
                        pageSize: 50,
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [50, 500, 1000, 2000],
                      }}
                    />
                    <div className="mt-3">
                      <h6 className="text-muted">
                        This is in progress.. no data processing yet!{" "}
                      </h6>
                    </div>
                  </>
                  <>
                    <div className="w-100 d-flex flex-column">
                      <div className="d-flex w-100 justify-content-end">
                        <a
                          href="#"
                          className="link-primary mb-2"
                          onClick={() => doModalOpen(AigsParamsModal)}
                        >
                          View Imported Parameters
                        </a>
                      </div>
                      <MaterialTable
                        title={"AIGS Data Preview"}
                        columns={
                          bulkParsedDataFields && bulkParsedDataFields.length
                            ? bulkParsedDataFields.map((x) => {
                                return { title: x, field: x };
                              })
                            : []
                        }
                        data={
                          bulkParsedData && bulkParsedData.length
                            ? bulkParsedData
                            : []
                        }
                        loading={isFetching || isImporting}
                        options={{
                          loadingType: "overlay",
                          exportButton: true,
                          exportAllData: true,
                          //filtering: true,
                          sorting: true,
                          search: false,
                          pageSize: 50,
                          emptyRowsWhenPaging: false,
                          pageSizeOptions: [50, 500, 1000, 2000],
                        }}
                      />
                      <div className="mt-3">
                        <AigsTempTable />
                      </div>
                    </div>
                  </>

                  <>
                    <MaterialTable
                      title={"Bioresults Data Preview"}
                      columns={
                        bioResultBulkParsedDataFields &&
                        bioResultBulkParsedDataFields.length
                          ? bioResultBulkParsedDataFields.map((x) => {
                              return { title: x, field: x };
                            })
                          : []
                      }
                      data={
                        bioResultBulkParsedData &&
                        bioResultBulkParsedData.length
                          ? bioResultBulkParsedData
                          : []
                      }
                      loading={isFetching || isImporting}
                      options={{
                        loadingType: "overlay",
                        exportButton: true,
                        exportAllData: true,
                        //filtering: true,
                        sorting: true,
                        search: false,
                        pageSize: 50,
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [50, 500, 1000, 2000],
                      }}
                    />
                    <div className="mt-3">
                      {/* <AigsTempTable /> */}
                      <h6 className="text-muted">
                        This is in progress.. no data processing yet!{" "}
                      </h6>
                    </div>
                  </>
                </BasicTabs>
              </div>
            </div>
          </div>
        </div>
      </TableContainer>
    </div>
  );
};

export default connect(
  "selectBulkParsedDataFields",
  "selectBulkParsedData",
  "selectAigsObservationData",
  "doAigsTempFetch",
  "doBulkImport",
  "selectIsImporting",
  "selectAigsObservationIsFetching",
  "selectRouteParams",
  "selectBioResultBulkParsedData",
  "selectBioResultBulkParsedDataFields",
  "doBulkParse",
  "doBulkParseBioresults",
  "doBulkParseClearBioresults",
  "doBulkParseClear",
  "doBulkImportBioresults",
  "doModalOpen",
  "doAigsParamsFetch",
  "selectResultBulkParsedData",
  "selectResultBulkParsedDataFields",
  "doBulkParseResults",
  "doBulkParseClearResults",
  "doBulkImportResults",
  BulkImport
);
