import React from "react";
import { connect } from "redux-bundler-react";
import TableResult from "./TableResult";
import FilterSidebar from "../dataExport/FilterSidebar";
import { TableContainer } from "../../app-components/page-content";
import { resultsSummaryConfig } from "../dataExport/filterConfigs";

export default connect(
  "selectRouteParams",
  ...resultsSummaryConfig.selectors,
  ...resultsSummaryConfig.fetchFns,
  (props) => {
    const projectParam = props.routeParams.project;
    return (
      <div>
        <div
          style={{
            display: "flex",
            width: "100%",
            minHeight: "calc(100vh - 106px)",
            borderTop: "1px solid lightgray",
          }}
        >
          {" "}
          <FilterSidebar config={resultsSummaryConfig} {...props} />
          <TableContainer>
            <TableResult projectParam={projectParam} />
          </TableContainer>
        </div>
      </div>
    );
  }
);
