import React, { useState } from "react";
import Plotly from "../../app-components/chart/minify-plotly";
import createPlotlyComponent from "react-plotly.js/factory";
const PlotlyPlot = createPlotlyComponent(Plotly);

const Plot = (props) => {
  return <PlotlyPlot {...props} />;
};

export default Plot;
