import { ordsifyUrlBuilder } from "../utils/ordsify";
import { createSelector } from "redux-bundler";

export default {
  name: "heatmap",
  getReducer: () => {
    const initialData = {
      data: [],
      shouldFetch: false,
      isFetching: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "HEATMAP_FETCH_START":
        case "HEATMAP_FETCH_FINISH":
        case "HEATMAP_SHOULD_FETCH":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doHeatmapShouldFetch:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "HEATMAP_SHOULD_FETCH",
        payload: {
          shouldFetch: true,
        },
      });
    },

  doHeatmapFetch:
    (dates, storet_num, location_code, limit = 20000) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "HEATMAP_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      const url = ordsifyUrlBuilder(
        "/results",
        [
          {
            keyword: "site_code",
            items: store.selectRouteParams().project
              ? [store.selectRouteParams().project]
              : [],
          },
          {
            keyword: "storet_num",
            items: storet_num ? [storet_num] : ["00010"],
          },
          {
            keyword: "sample_time",
            items: !dates
              ? []
              : dates[0] && dates[1]
              ? [`${dates[0]}T00:00:00Z`, `${dates[1]}T00:00:00Z`]
              : !dates[0] && dates[1]
              ? ["1900-01-01T00:00:00Z", `${dates[1]}T00:00:00Z`]
              : dates[0] && !dates[1]
              ? [`${dates[0]}T00:00:00Z`, "2100-01-01T00:00:00Z"]
              : [],
          },
          {
            keyword: "location_code",
            items: location_code ? [location_code] : ""
          }
        ],
        limit
      );
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "HEATMAP_FETCH_FINISH",
            payload: {
              data: j.items,
              isFetching: false,
            },
          })
        )
        .catch((e) => console.log(e));
    },

  selectHeatmapData: (state) => state.heatmap.data,
  selectHeatmapIsFetching: (store) => store.heatmap.isFetching,
  reactHeatmapShouldFetch: (state) => {
    // never fetch if another fetch is in progress
    if (state.heatmap.isFetching) return null;

    if (state.heatmap.shouldFetch)
      return {
        actionCreator: "doHeatmapFetch",
      };
  },
};
