import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import { CircularProgress, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Toolbar from "./Toolbar";
import DocumentList from "./List";
import "./linkbutton.css";
import DocumentTable from "./DocumentTable";

const Index = connect(
  "selectRouteParams",
  "doDocumentFetch",
  "doDocumentTypeFetch",
  "selectDocumentIsFetching",
  "selectDocumentItems",
  "selectDocumentSearchItems",
  "selectDocumentSearchTerm",
  "selectDocumentStatus",
  "doDocumentClearStatus",
  "selectDocumentIsUpdating",
  ({
    routeParams,
    doDocumentFetch,
    documentIsFetching,
    documentItems,
    doDocumentTypeFetch,
    documentSearchItems,
    documentSearchTerm,
    documentStatus,
    doDocumentClearStatus,
    documentIsUpdating,
  }) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
      setOpen(false);
      doDocumentClearStatus(null);
    };
    useEffect(() => {
      if (routeParams) {
        doDocumentFetch(routeParams.id);
        doDocumentTypeFetch();
      }
    }, [routeParams]);
    useEffect(() => {
      if (documentStatus === 200) {
        setOpen(true);
      }
    }, [documentStatus]);
    const renderItems = () => {
      let items;
      if (documentSearchTerm && documentSearchTerm.length) {
        items = documentSearchItems;
      } else {
        items = documentItems;
      }
      return items;
    };
    return (
      <div
        style={{
          padding: "0 64px 24px",
          width: "100%",
          minHeight: "calc(100vh - 106px)",
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Success!
          </Alert>
        </Snackbar>
        <div style={{ width: "100%", marginBottom: 0 }}>
          <div className="d-flex justify-content-between border-bottom pb-1 mb-2">
            <h3 className="card-title bold m-0">Documents</h3>
            <div style={{ display: "inline-flex" }}>
              <a className="mr-4 icon-button" href="documents/upload">
                <p className="m-0 mr-2">Upload Documents</p>
                <i className="mdi mdi-upload mdi-24px" />
              </a>
              <a className="mr-4 icon-button" href="documents/photos">
                <p className="m-0 mr-2">View Gallery</p>
                <i className="mdi mdi-image-multiple mdi-24px" />
              </a>
              {/* <a className="icon-button" href="documents/bulk"><p className="m-0 mr-2">Bulk Import</p><i className="mdi mdi-import mdi-24px" /></a> */}
            </div>
          </div>
        </div>
        <DocumentTable
          items={renderItems()}
          isFetching={documentIsFetching || documentIsUpdating}
        />
      </div>
    );
  }
);
export default Index;
