import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import CollapsedTable from "../../app-components/tables/collapsedTable";
import EditResultModal from "./EditResultModal";
import SampleModal from "./SampleModal";

const TableSample = (props) => {
  const {
    samplesNewIsFetching,
    userIsOrgMemberOrAdmin,
    routeParams,
    doSamplesNewFetch,
    doLocationFetch,
    doSetSortedSamplesNewObject,
    sortedSamplesNew,
    samplesNew,
    location,
    doModalOpen,
    doModalOpenUniqueCode,
    cwmsSeqIsFetching,
    doSamplesFetchByLocation,
    samplesByLocation,
    loadedConfig,
  } = props;

  const _fetchSamples = () => {
    doSamplesNewFetch();
    doSamplesFetchByLocation();
  };
  useEffect(() => {
    if (!loadedConfig) _fetchSamples();
  }, [doSamplesNewFetch, doSamplesFetchByLocation]);

  useEffect(() => {
    if (!location) doLocationFetch();
  }, [location]);

  let sortedData = [];
  samplesByLocation.forEach((smpl) => {
    sortedData.push({
      sample: smpl,
      results: sortedSamplesNew[smpl.sample_code],
    });
  });
  return (
    <CollapsedTable
      isFetching={samplesNewIsFetching}
      userIsOrgMemberOrAdmin={userIsOrgMemberOrAdmin}
      routeParams={routeParams}
      doFetch={doSamplesNewFetch}
      doSort={doSetSortedSamplesNewObject}
      data={samplesNew}
      title={`Samples at ${location && location.loc_id}`}
      actions={["EDIT"]}
      doEditSample={(data) => {
        doModalOpen(SampleModal, {
          data,
          edit: true,
        });
      }}
      doEditResult={(data) => {
        doModalOpen(EditResultModal, {
          data,
          edit: true,
        });
      }}
      onAdd={() => {
        doModalOpenUniqueCode(SampleModal, {}, "sample_code");
      }}
      addText={
        cwmsSeqIsFetching ? (
          <i className="mdi mdi-loading mdi-spin" />
        ) : (
          "Add Sample"
        )
      }
      addButtonDisabled={cwmsSeqIsFetching}
      sortedData={sortedData}
    />
  );
};

export default connect(
  "selectSamplesNewIsFetching",
  "selectUserIsOrgMemberOrAdmin",
  "selectApiRoot",
  "selectSamplesNew",
  "selectSortedSamplesNew",
  "doSetSortedSamplesNewObject",
  "doSamplesNewFetch",
  "doSamplesFetchByLocation",
  "selectRouteParams",
  "selectLocationSelected",
  "selectLocation",
  "doModalOpen",
  "doModalOpenUniqueCode",
  "selectCwmsSeqIsFetching",
  "selectSamplesByLocation",
  "selectLoadedConfig",
  "doLocationFetch",
  TableSample
);
