import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";
import EditLabsModal from "./EditLabsModal";
import { TableContainer } from "../../../app-components/page-content";

const TableLabs = (props) => {
  const {
    labs,
    doLabsTableFetch,
    labsIsFetching,
    doModalOpenUniqueCode,
    doModalOpen,
    userIsAnyMemberOrAdmin,
  } = props;

  const _fetchLabs = () => {
    doLabsTableFetch();
  };

  useEffect(_fetchLabs, []);

  return (
    <div
      style={{
        width: "100%",
        padding: "0px 64px 32px",
        height: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      {/* <FilterSidebar config={taxaConfig} {...props} /> */}
      <TableContainer fullWidth>
        <div className="custom-table mt-3">
          <div className="card-body">
            <div className="d-flex w-100 align-items-center justify-content-between mb-2">
              <h4 className="m-0">Labs</h4>
              {userIsAnyMemberOrAdmin && (
                <button
                  className="btn btn-outline-success"
                  onClick={() => {
                    doModalOpenUniqueCode(EditLabsModal, {}, "lab_code");
                  }}
                >
                  Add Labs
                </button>
              )}
            </div>
            <MaterialTable
              title=""
              isLoading={labsIsFetching}
              columns={[
                {
                  field: "link",
                  title: "Actions",
                  render: (rowData) => {
                    if (userIsAnyMemberOrAdmin) {
                      return (
                        <button
                          className="hover"
                          onClick={(e) => {
                            doModalOpen(EditLabsModal, {
                              data: rowData,
                              edit: true,
                            });
                          }}
                        >
                          <i className="mdi mdi-pencil-outline mdi-24px"></i>
                        </button>
                      );
                    }
                  },
                },
                { title: "Lab ID", field: "lab_id" },
                { title: "Lab Name", field: "lab_name" },
                { title: "City", field: "city" },
                { title: "State", field: "state" },
              ]}
              data={labs}
              options={{
                loadingType: "overlay",
                exportButton: true,
                sorting: true,
                search: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 500, 1000, 2000],
              }}
            />
          </div>
        </div>
      </TableContainer>
    </div>
  );
};

export default connect(
  "doLabsShouldFetch",
  "doLabsTableFetch",
  "selectLabsIsFetching",
  "selectLabs",
  "doModalOpenUniqueCode",
  "doModalOpen",
  "selectUserIsAnyMemberOrAdmin",
  // ...taxaConfig.selectors,
  // ...taxaConfig.fetchFns,
  TableLabs
);
