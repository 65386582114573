import React from "react";
import Link from "../../misc/link";
import "./customCard.css";
import { PictureAsPdf, Image, Equalizer } from "@material-ui/icons";

function FileCard(props) {
  let Icon =
    props.type === "pdf"
      ? PictureAsPdf
      : props.type === "image"
      ? Image
      : Equalizer;
  let color =
    props.type === "pdf" ? "red" : props.type === "image" ? "navy" : "green";
  return (
    <div
      className={`custom custom-task custom-${props.type}`}
      onClick={props.onClick}
    >
      <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "100%",
            paddingRight: 8,
          }}
        >
          <Icon htmlColor={color} fontSize="large" />
        </div>
        <p
          className="card-subtitle m-0"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "100%",
            color: color,
          }}
        >
          {props.name}
        </p>
      </div>
    </div>
  );
}

export default FileCard;
