import React, { useState, useEffect } from "react";
import { connect } from "redux-bundler-react";
import Link from "../../misc/link";
import Chart from "../../charting/Chart";
import "./customCard.css";

function CardResults(props) {
  const [resultData, setResultData] = useState(0);
  const [options, setOptions] = useState(0);
  const {
    doResultsummaryFetchByLocation,
    resultsummarySummary,
    resultData: results,
    doResultFetch,
    resultsummaryIsFetchingSummary,
    resultsummaryIsFetching,
  } = props;

  useEffect(() => {
    if (props.location == null) return;
    setResultData(0);
    doResultsummaryFetchByLocation({
      location_code: props.location.location_code,
    });
    doResultFetch();
  }, [props.location]);

  useEffect(() => {
    if (
      !resultsummaryIsFetchingSummary &&
      resultsummarySummary.length &&
      results &&
      results.length
    ) {
      setResultData(resultsummarySummary);
      setOptions({
        chart: {
          type: "boxplot",
          inverted: true,
        },
        title: {
          text: resultsummarySummary[0].storet_name,
        },
        subtitle: {
          text: `Number of Results: ${resultsummarySummary[0].num_results}`,
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: ["1"],
          title: {
            text: "",
          },
        },
        yAxis: {
          title: {
            text: resultsummarySummary[0].units,
          },
        },
        series: [
          {
            name: "Values",
            data: [
              [
                resultsummarySummary[0].minval,
                resultsummarySummary[0].pct_25,
                resultsummarySummary[0].medianval,
                resultsummarySummary[0].pct_75,
                resultsummarySummary[0].maxval,
              ],
            ],
          },
        ],
      });
    }
  }, [resultsummaryIsFetchingSummary, results]);

  return !props.location || !props.project ? (
    <div className="custom">
      <div className="card-body">
        <div style={{ textAlign: "center" }}>
          <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
        </div>
      </div>
    </div>
  ) : (
    <div className="custom custom-results">
      <a
        style={{
          textDecoration: "none",
          cursor: "pointer",
          height: "100%",
        }}
        href={`/projects/${props.project.site_code}/locations/${props.location.location_code}/results`}
      >
        <div className="card-body h-100">
          <h4
            className="card-title border-bottom mb-0"
            style={{ color: "#3c4b64" }}
          >
            Results
          </h4>
          {resultsummaryIsFetchingSummary ? (
            <div style={{ textAlign: "center", color: "black" }}>
              <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
            </div>
          ) : !resultData ? (
            <div
              className="d-flex h-100 align-items-center justify-content-center"
              style={{ textAlign: "center" }}
            >
              <h3 className="text-muted m-0">No results at this location!</h3>
            </div>
          ) : (
            <>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Chart options={options} height={150} />
                <p className="card-text" style={{ color: "#3c4b64" }}>
                  <strong>Total Results Count: </strong>
                  {!results.length ? "0" : results.length}
                </p>
                <Link
                  style={{ fontWeight: "bold" }}
                  to={`/projects/${props.project.site_code}/locations/${props.location.location_code}/results`}
                >
                  Go to Results
                </Link>
              </div>
            </>
          )}
        </div>
      </a>
    </div>
  );
}

export default connect(
  "doResultsummaryFetchByLocation",
  "doResultFetch",
  "selectResultsummarySummary",
  "selectResultsummaryIsFetchingSummary",
  "selectResultsummaryIsFetching",
  "selectResultsummaryData",
  "selectResultData",
  CardResults
);
