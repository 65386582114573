import React from 'react';
import { connect } from 'redux-bundler-react';
import MaterialTable from "material-table";


const Parser = connect(
    "selectBulkParsedData",
    "selectBulkParsedDataFields",
    ({ bulkParsedData, bulkParsedDataFields }) => {
        return (
            <div>
                {bulkParsedData ?
                    <MaterialTable
                        title={"Results"}
                        columns={bulkParsedDataFields.map(x => {
                            return { title: x, field: x }
                        })}
                        data={bulkParsedData}
                    />
                    : null}
            </div>
        )
    }

)

export default Parser;