import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilderNoSlash } from "../utils/ordsify";

export default {
  name: "station",
  // reducers
  getReducer: () => {
    const initialData = {
      data: [],
      config: [],
      shouldFetch: false,
      done: false,
    };
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "REPORT_STATION_STARTED":
        case "REPORT_STATION_FINISHED":
        case "REPORT_STATION_CONFIG_STARTED":
        case "REPORT_STATION_CONFIG_FINISHED":
        case "REPORT_STATION_CONFIG_SHOULD_FETCH":
        case "REPORT_STATION_CONFIG_FETCH_STARTED":
        case "REPORT_STATION_CONFIG_FETCH_FINISHED":
        case "REPORT_STATION_LOAD_STARTED":
        case "REPORT_STATION_LOAD_FINISHED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },
  // action creators
  doReportStationGenerate:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      let projects = state.projects && state.projects.map((proj) => proj.value);
      let locations = Object.keys(state).map((key) => {
        if (parseInt(key) && state[key]) {
          /** key is site_code containing arrays of locations */
          return state[key].map((location) => location.value);
        }
      });
      locations = locations.flat(1).filter((item) => item !== undefined);
      dispatch({ type: "REPORT_STATION_STARTED", payload: { done: false } });
      const url = ordsifyUrlBuilderNoSlash(
        `/reports/stations`,
        [
          {
            keyword: "site_code",
            items: projects ? projects : [],
          },
          {
            keyword: "location_code",
            items: locations ? locations : "", // if no location_codes passed, fetch all
          },
        ],
        500
      );

      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "REPORT_STATION_FINISHED",
            payload: {
              data: j.items,
              done: true,
            },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },
  doReportStationPostConfig:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({ type: "REPORT_STATION_CONFIG_STARTED" });
      const id = Math.floor(Math.random() * 100000);
      const cacUid = parseInt(store.selectAuthTokenPayload().sub);
      const locations = store.selectLocationsActive();
      let locationNames = [];
      if (locations[0].hasOwnProperty("loc_id")) {
        locationNames = [
          ...new Set(locations.map((item) => item.loc_id)),
        ].toString();
      } else {
        locationNames = [
          ...new Set(locations.map((item) => item.label)),
        ].toString();
      }
      const data = {
        project_code: store.selectProjectActive(),
        location_codes: store.selectLocationsActiveArray().toString(),
        location_ids: locationNames,
        cac_uid: cacUid,
        id: id,
      };
      apiFetch("/reports/config", {
        method: "POST",
        headers: {
          Cac_uid: cacUid,
          Id: id,
          Report_type: "station",
        },
        body: JSON.stringify(data),
      }).then((r) => {
        dispatch({ type: "REPORT_STATION_CONFIG_FINISHED" });
      });
    },
  doReportStationShouldFetchConfig:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "REPORT_STATION_CONFIG_SHOULD_FETCH",
        payload: {
          shouldFetch: true,
        },
      });
    },
  doReportStationGetConfig:
    () =>
    ({ dispatch, store, apiFetch }) => {
      const cacUid = parseInt(store.selectAuthTokenPayload().sub);
      dispatch({
        type: "REPORT_STATION_CONFIG_FETCH_STARTED",
        payload: { shouldFetch: false },
      });
      apiFetch(`/reports/config/${cacUid}?q={"report_type":"station"}`)
        .then((response) => response.json())
        .then((r) =>
          dispatch({
            type: "REPORT_STATION_CONFIG_FETCH_FINISHED",
            payload: {
              config: r.items,
            },
          })
        );
      store.doReportSetFinished(false);
    },
  doReportStationLoadConfig:
    (rowData) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "REPORT_STATION_LOAD_STARTED",
      });
      store.doReportSetFinished(true);
      // get project data
      // @TODO I think I need a project lookup for project code
      // store.doLocationFetchByProject(rowData.project_code)
      // Need to wait for locations to get back to get activeLocationCode
      const locationCodes = rowData.location_codes.split(",");
      // FETCH SAMPLE
      // I dont even know if we need this anymroe
      // store.doSampleFetch(locationCodes)
      // PROJECT ACTIVE
      store.doProjectActive(rowData.project_code);
      // FETCH LOCATIONS BY LOCATION CODE
      // back to statistics bundle?
      store.doReportLocationFetch(locationCodes);
      dispatch({
        type: "REPORT_STATION_LOAD_FINISHED",
      });
    },

  selectReportStationData: (state) => state.station.data,
  selectReportStationDone: (state) => state.station.done,
  selectReportStationConfig: (state) => state.station.config,
  selectReportStationConfigShouldFetch: (state) => state.station.shouldFetch,
  selectReportStationConfigJson: createSelector(
    "selectReportStationConfig",
    "selectProjectAll",
    (reportStationConfig, projectAll) => {
      let data = [];
      if (reportStationConfig.length) {
        reportStationConfig.forEach((x) => {
          data.push(JSON.parse(x.configuration));
        });
        data.map((x) => {
          x.project_name = projectAll.find(
            (y) => y.site_code === x.project_code
          ).site_name;
        });
      }

      return data;
    }
  ),
  reactReportStationConfigFetch: createSelector(
    "selectReportStationConfigShouldFetch",
    (shouldFetch) => {
      if (shouldFetch)
        return {
          actionCreator: "doReportStationGetConfig",
        };
    }
  ),
};
