import { useEffect, useReducer, useState } from "react";
import { connect } from "redux-bundler-react";
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "../../../app-components/modal";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const EditMajorBasinsModal = (props) => {
  const {
    major_code,
    edit,
    data,
    doModalClose,
    doMajorBasinPost,
    doMajorBasinPut,
    doMajorBasinDelete,
    majorBasinIsSaving,
    majorBasinIsDeleting,
    majorBasinError: error,
  } = props;

  let initialState = { major_abbr: "" };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (edit) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: {
          ...data,
        },
      });
    } else {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: {
          major_code,
        },
      });
    }
  }, [edit, data]);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  return (
    <ModalContent size="md" error={error}>
      <ModalHeader title={edit ? "Edit Major Basin Info" : "Add Major Basin"} />
      <section className="modal-body">
        <div className="container-fluid">
          <form>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="major_name">Major Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="major_name"
                  onChange={handleChange}
                  placeholder="i.e. SWTRB"
                  value={state.major_name}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="major_abbr">Major Abbreviation</label>
                <input
                  type="text"
                  className="form-control"
                  name="major_abbr"
                  onChange={handleChange}
                  placeholder="i.e. T3"
                  value={state.major_abbr}
                  maxLength="2"
                />
                <p className="m-0 mt-1 text-muted">
                  <small>
                    {2 - state.major_abbr.length} characters remaining
                  </small>
                </p>
              </div>
            </div>
          </form>
        </div>
      </section>
      <ModalFooter
        onSave={() => (edit ? doMajorBasinPut(state) : doMajorBasinPost(state))}
        customClosingLogic
        onDelete={edit ? () => doMajorBasinDelete(state) : null}
        showCancelButton
        onCancel={doModalClose}
        saveIsDisabled={majorBasinIsSaving}
        deleteIsDisabled={majorBasinIsDeleting}
        saveText={
          majorBasinIsSaving ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Save"
          )
        }
        deleteText={
          majorBasinIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "doMajorBasinPost",
  "doMajorBasinPut",
  "doMajorBasinDelete",
  "selectMajorBasinIsSaving",
  "selectMajorBasinIsDeleting",
  "selectMajorBasinError",
  "doModalClose",
  EditMajorBasinsModal
);
