import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import Select from "react-select";
import "./hometest.css";
import classnames from "../../utils/classnames";
import Link from "../misc/link";
import { find, sortBy } from "lodash";
import pkg from "../../../package.json";
import cm from "./img/cm.png";
import NewCards from "./NewCards";
import UserPreferencesModal from "./UserPreferencesModal";
import PdfManual from "../../assets/WQ_Authentication_Manual.pdf";

const Home = ({
  doAuthLogin,
  doAuthLogout,
  authIsLoggedIn,
  projectAll,
  doUpdateUrl,
  authUsername,
  userRoleData,
  officeData,
  userIsOrgMemberOrAdmin,
  doOfficeFetch,
  doProjectFetch,
  authIsLoggingIn,
  projectIsFetching,

  doKeycloakAuthenticateCode,
  doModalOpen,
  userOfficesOptions,
  doUserRoleFetch,
  userIsSysAdmin,
  userRoleIsFetching,
  userIsFetching,
  userData,
  userIsAnyMemberOrAdmin,
}) => {
  const [projectOption, setProjectOption] = useState();
  const [projectOptions, setProjectOptions] = useState([]);
  const [officeOption, setOfficeOption] = useState({
    value: userData.primary_office,
    label: userData.primary_office,
  });
  const [show, setShow] = useState(false);
  const [img, setImg] = useState(Math.ceil(Math.random() * 7));

  useEffect(() => {
    _setOfficeOption({
      value: userData.primary_office,
      label: userData.primary_office,
    });
  }, [userData, userOfficesOptions]);

  useEffect(() => {
    if (authIsLoggedIn) {
      doProjectFetch();
      doOfficeFetch();
      doUserRoleFetch();
    }
  }, [authIsLoggedIn]);

  useEffect(() => {
    _setOfficeOption(officeOption);
  }, [projectAll]);

  const dropdownMenuClass = classnames({
    "dropdown-menu": true,
    "dropdown-menu-right": true,
    show: show,
  });

  const toggleShow = () => setShow(!show);
  const renderButton = () => {
    const name = authUsername;
    return <div className="dropdown-toggle btn btn-ghost-light">{name}</div>;
  };

  let projects = sortBy(
    projectAll,
    [(proj) => proj.site_name.toLowerCase()],
    "site_name"
  ).filter((p) => find(userOfficesOptions, (obj) => obj.value === p.office_id));

  const _setOfficeOption = (obj) => {
    let projects = sortBy(
      projectAll,
      [(proj) => proj.site_name.toLowerCase()],
      "site_name"
    ).filter((p) =>
      find(userOfficesOptions, (obj) => obj.value === p.office_id)
    );

    if (!obj) {
      setProjectOptions(projects);
    } else {
      setProjectOptions(projects.filter((p) => p.office_id === obj.value));
    }
    setOfficeOption(obj);
  };
  let today = new Date().getTime();
  let endAlertDate = new Date("12/31/2023").getTime();

  return (
    <div className="home">
      {authIsLoggedIn && (
        <div>
          <div
            onClick={toggleShow}
            className="dropdown"
            style={{
              width: "200px",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 999,
            }}>
            {renderButton()}
            <div
              onClick={toggleShow}
              style={{
                display: show ? "block" : "none",
                position: "fixed",
                zIndex: 999,
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }}></div>
            <div
              className={dropdownMenuClass}
              x-placement="bottom-start"
              style={{
                position: "absolute",
                willChange: "transform",
                top: "0px",
                right: "0px",
                transform: "translate3d(0px, 26px, 0px)",
              }}>
              {userIsSysAdmin && (
                <span className="dropdown-item">
                  <Link to={`/admin`}>Admin</Link>
                </span>
              )}
              <span
                className="dropdown-item"
                onClick={() => doModalOpen(UserPreferencesModal)}>
                User Preferences
              </span>
              <span className="dropdown-item" onClick={doAuthLogout}>
                Logout
              </span>
            </div>
          </div>
        </div>
      )}

      <div className={`background background-${img}`}>
        <div className="header-card" style={{ position: "relative" }}>
          <div className="d-flex flex-column justify-content-around h-100 align-items-center">
            <div
              className="d-flex align-items-end pr-3 pl-3"
              style={{ paddingBottom: authIsLoggedIn ? 24 : 24 /*108*/ }}>
              <div className="d-flex align-items-start">
                <img className="cm" src={cm} loading="lazy" />
                <div className="d-flex flex-column align-items-start">
                  <div className="h1 text-black m-0">USACE Civil Works</div>
                  <div
                    className="h2 d-flex flex-column align-items-start"
                    style={{ color: "#1976d2" }}>
                    Water Quality Enterprise Database
                    <h2 className="text-muted">{pkg.version}</h2>
                  </div>
                </div>
              </div>
            </div>
            {!authIsLoggedIn && today < endAlertDate && (
              <div style={{ width: 546, margin: "auto" }}>
                <div
                  className={`alert`}
                  style={{
                    color: "#0c5460",
                    backgroundColor: "#d1ecf1",
                    borderColor: "#bee5eb",
                  }}
                  role="alert">
                  <h6 className="alert-heading">New Authentication System</h6>
                  <p>
                    Please review the authentication manual in the link below.
                    We have migrated to a new system that authenticates through
                    Login.gov.
                  </p>
                  <a href={PdfManual} type="application/pdf" target="_blank">
                    Open Authentication Manual
                  </a>
                </div>
              </div>
            )}
            {!authIsLoggedIn && (
              <button
                className="btn btn-info"
                style={{ width: 250 }}
                onClick={doKeycloakAuthenticateCode}>
                {authIsLoggingIn ||
                userRoleIsFetching ||
                userIsFetching ||
                projectIsFetching ? (
                  <i className="mdi mdi-loading mdi-spin" />
                ) : (
                  "Login with CAC"
                )}
              </button>
            )}
            {authIsLoggedIn &&
              projectAll &&
              !(userRoleIsFetching || userIsFetching) && (
                <div className="d-flex flex-column" style={{ width: 330 }}>
                  <div className="w-100 ">
                    <label className="d-flex">Filter by Office</label>
                    <Select
                      value={officeOption}
                      name="office"
                      onChange={_setOfficeOption}
                      options={userOfficesOptions}
                      isClearable
                    />
                  </div>
                  <div className="w-100  mt-4">
                    <label className="d-flex">Go to Project Home</label>
                    <div
                      style={{
                        display: "grid",
                        gap: 8,
                        gridTemplateColumns: "3fr 1fr",
                      }}>
                      <Select
                        value={projectOption}
                        name="projects"
                        onChange={setProjectOption}
                        options={projectOptions?.map((proj) => ({
                          value: proj.site_code,
                          label: proj.site_name,
                        }))}
                        isClearable
                      />
                      <button
                        className="btn btn-success"
                        onClick={() =>
                          doUpdateUrl(`/projects/${projectOption.value}`)
                        }>
                        Go
                      </button>
                    </div>
                    <div className="d-flex w-100 justify-content-center align-items-center mt-2">
                      <i>Need access to more offices? </i>{" "}
                      <a
                        className="btn btn-ghost p-0  ml-2"
                        href="/access-request">
                        <b className="link">Go here.</b>
                      </a>
                    </div>
                  </div>
                  <div className="d-flex w-100 align-items-center my-2">
                    <div className="line" />
                  </div>
                  <div className="w-100 d-flex justify-content-center">
                    <div
                      className="d-flex flex-column align-items-start"
                      style={{ width: 150, margin: "auto" }}>
                      <a href="/data-export">
                        <i className="mdi mdi-export mdi-16px mr-2" /> Export
                        Results
                      </a>
                      {userIsAnyMemberOrAdmin && (
                        <a className="mt-2" href="/bulk-import">
                          <i className="mdi mdi-import mdi-16px mr-2" />
                          Bulk Import
                        </a>
                      )}
                      <a className="link mt-2" href="/api-documentation">
                        <i className="mdi mdi-file-document mdi-16px mr-2" />
                        API Documentation
                      </a>
                    </div>
                  </div>
                </div>
              )}
            {!authIsLoggedIn && (
              <div className="w-300 d-flex justify-content-center">
                <div
                  className="d-flex flex-column align-items-start"
                  style={{ width: 150, margin: "auto" }}>
                  <a className="link mt-4" href="/api-documentation">
                    <i className="mdi mdi-file-document mdi-16px mr-2" />
                    API Documentation
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        {authIsLoggedIn && (
          <div
            className="d-flex flex-column align-items-center h6 cursor-pointer"
            style={{ position: "absolute", bottom: 32, color: "white" }}
            onClick={(e) => {
              let pageHeight = window.innerHeight;
              window.scrollBy({ top: pageHeight, behavior: "smooth" });
            }}>
            {projectIsFetching ? "Loading Projects..." : "View All Projects"}
            <i
              className={`d-inline-flex mdi ${
                projectIsFetching ? "mdi-loading mdi-spin" : "mdi-arrow-down"
              } text-white mdi-24px`}></i>
          </div>
        )}
      </div>
      {authIsLoggedIn && projects.length > 0 && officeData.length > 0 && (
        <NewCards
          officeData={officeData}
          userOfficesOptions={userOfficesOptions}
          projectAll={projects}
        />
      )}
    </div>
  );
};

export default connect(
  "doAuthLogin",
  "doAuthLogout",
  "selectAuthIsLoggedIn",
  "doProjectFetch",
  "doOfficeFetch",
  "selectProjectAll",
  "selectProjectIsFetching",
  "doUpdateUrl",
  "selectAuthTokenPayload",
  "selectAuthUsername",
  "selectOfficeData",
  "selectUserIsOrgMemberOrAdmin",
  "selectAccessToken",
  "selectAuthIsLoggingIn",
  "selectUserOfficesOptions",
  "doKeycloakAuthenticateCode",
  "doModalOpen",
  "selectUserRoleData",
  "doUserRoleFetch",
  "selectUserIsSysAdmin",
  "selectUserRoleIsFetching",
  "selectUserIsFetching",
  "selectUserData",
  "selectUserIsAnyMemberOrAdmin",
  Home
);
