import React from "react";
import { connect } from "redux-bundler-react";
import lrhConfig from "./lrh.config.json";
import TableReports from "./TableReports";
import FilterSidebar from "../dataExport/FilterSidebar";
import TableContainer from "../../app-components/page-content/tableContainer";

const PageReports = ({ routeParams, ...props }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "calc(100vh - 106px)",
        border: "1px solid lightgray",
      }}>
      {" "}
      <FilterSidebar config={lrhConfig} {...props} />
      <TableContainer>
        <TableReports />
      </TableContainer>
    </div>
  );
};
export default connect(
  "selectRouteParams",
  ...lrhConfig.selectors,
  ...lrhConfig.fetchFns,
  PageReports
);
