import React from "react";
import { connect } from "redux-bundler-react";
import MaterialTable from "material-table";
import "../Report.css";

export default connect(
  "selectReportExceedanceData",
  "selectReportExceedanceDone",
  ({ reportExceedanceData, reportExceedanceDone }) => {
    return (
      <div>
        <div
          style={{
            marginTop: "10px",
            overflow: "scroll",
            height: "calc(100vh - 200px)",
          }}
        >
          {reportExceedanceData.length ? (
            <MaterialTable
              title={"Report"}
              columns={[
                {
                  title: "Location Code",
                  field: "location_code",
                  hidden: true,
                },
                { title: "Location ID", field: "loc_id" },
                { title: "Parameter", field: "short_name" },
                { title: "Sample Depth", field: "sample_depth" },
                { title: "Sample Time", field: "sample_time" },
                { title: "Units", field: "units" },
                { title: "Value", field: "value" },
                { title: "Storet Code", field: "storet_num", hidden: true },
              ]}
              data={reportExceedanceData}
            />
          ) : reportExceedanceDone ? (
            <div>
              <h3>No Data</h3>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);
