import React, { useState, useReducer, useEffect } from "react";
import ReactSelect from "react-select";
import { connect } from "redux-bundler-react";
import {
  ModalFooter,
  ModalContent,
  ModalHeader,
} from "../../app-components/modal";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const CODES = [
  "location_code",
  "result_code",
  "site_code",
  "collect_mthd",
  "sample_code",
  "sample_time",
  "source_code",
  "collection_method_name",
  "sample_depth",
  "lab_id",
  "storet_num",
  "text_value",
  "data_flag_code",
];

const inputs = [
  "lab_id",
  "lab_snum",
  "storet_num",
  "units",
  "value",
  "date_entered",
  "test_mthd",
  "sample_code",
  "text_value",
  "detect",
  "pquant",
  "pre_recov",
  "post_recov",
  "dilution",
  "result_grade",
  "date_lab_received",
  "date_lab_prepared",
  "lab_sample_fraction",
  "lab_comment",
  "validation_warnings",
  "export_flag",
  "data_flag_code",
];
const _isNotCode = (key) => !CODES.includes(key);

const _convertToObject = (value, valueKey, labelKey, arr) => {
  if (!value || typeof value === "object") return value;
  let currentObj = arr.filter((item) => item[valueKey] == value)[0];
  if (currentObj)
    return { value: currentObj[valueKey], label: currentObj[labelKey] };
  else return null;
};

const EditResultModal = ({
  data,
  edit,
  doNestedModalClose,
  doModalClose,
  doResultPut,
  doResultPost,
  doResultDelete,
  labsOptions,
  storetOptions,
  editSample,
  resultIsSaving,
  resultIsDeleting,
  resultError: error,
  result_code,
  sample_code,
  dataFlagItems,
}) => {
  const doClose = editSample ? doNestedModalClose : doModalClose;
  const initialState = {
    result_code,
    sample_code,
    ...data,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (edit) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: {
          ...data,
          date_entered: !data.date_entered
            ? null
            : data.date_entered.split("Z")[0],
          date_lab_prepared: !data.date_lab_prepared
            ? null
            : data.date_lab_prepared.split("Z")[0],
          date_lab_received: !data.date_lab_received
            ? null
            : data.date_lab_received.split("Z")[0],
        },
      });
    }
  }, [edit, data]);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSelect = (field, payload) => {
    dispatch({
      type: "UPDATE_INPUT",
      field,
      payload: payload.value,
    });
  };
  const handleCheckboxChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.checked ? "T" : "X",
    });
  };

  const doSave = () => {
    let data = { ...state, text_value: state.value };
    data.export_flag = state.export_flag;
    data.date_lab_received = !state.date_lab_received
      ? null
      : state.date_lab_received.split(":").length === 3
      ? `${state.date_lab_received}Z`
      : `${state.date_lab_received}:00Z`;
    data.date_lab_prepared = !state.date_lab_prepared
      ? null
      : state.date_lab_prepared.split(":").length === 3
      ? `${state.date_lab_prepared}Z`
      : `${state.date_lab_prepared}:00Z`;
    data.date_entered = !state.date_entered
      ? null
      : state.date_entered.split(":").length === 3
      ? `${state.date_entered}Z`
      : `${state.date_entered}:00Z`;

    if (edit) {
      doResultPut(data, doClose);
    } else {
      doResultPost(data, doClose);
    }
  };

  return (
    <ModalContent size="lg" error={error}>
      <ModalHeader title="Edit Result" onClose={doClose} />
      <section className="modal-body">
        <div className="container-fluid">
          <div className="d-flex flex-column">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: 12,
              }}
            >
              {inputs.map((value) => {
                if (value.includes("date")) {
                  return (
                    <div className="mb-2">
                      <label htmlFor={value}>
                        {value
                          .replaceAll("_", " ")
                          .replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase())}
                      </label>
                      <input
                        className="form-control"
                        id={value}
                        onChange={handleChange}
                        name={value}
                        value={state[value]}
                        type="datetime-local"
                      />
                    </div>
                  );
                } else if (value === "export_flag") {
                  return (
                    <div className="mb-2 d-flex align-items-center">
                      <input
                        id={value}
                        onChange={handleCheckboxChange}
                        name={value}
                        value={state[value] === "T" ? true : false}
                        checked={state[value] === "T" ? true : false}
                        type="checkbox"
                        className="mr-2"
                      />
                      <label htmlFor={value}>
                        {value
                          .replaceAll("_", " ")
                          .replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase())}
                      </label>
                    </div>
                  );
                } else if (_isNotCode(value)) {
                  return (
                    <div className="mb-2">
                      <label htmlFor={value}>
                        {value
                          .replaceAll("_", " ")
                          .replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase())}
                      </label>
                      <input
                        className="form-control"
                        id={value}
                        onChange={handleChange}
                        name={value}
                        value={state[value]}
                        disabled={
                          value === "site_name" ||
                          value === "office_id" ||
                          value === "loc_id"
                        }
                      />
                    </div>
                  );
                } else if (
                  value === "lab_id" ||
                  value === "storet_num" ||
                  value === "data_flag_code"
                ) {
                  return (
                    <div className="mb-2">
                      <label htmlFor={value}>
                        {value === "storet_num"
                          ? "Parameter"
                          : value === "data_flag_code"
                          ? "Data Qualifier Flag"
                          : value
                              .replaceAll("_", " ")
                              .replace(/(\b[a-z](?!\s))/g, (x) =>
                                x.toUpperCase()
                              )}
                      </label>
                      <ReactSelect
                        className="w-100"
                        menuPosition="fixed"
                        id={value}
                        onChange={(obj) => handleSelect(value, obj)}
                        name={value}
                        value={_convertToObject(
                          state[value],
                          "value",
                          "label",
                          value === "storet_num"
                            ? storetOptions
                            : value === "data_flag_code"
                            ? dataFlagItems.map((item) => ({
                                label: item.data_qualifier_flag,
                                value: item.flag_code,
                              }))
                            : labsOptions
                        )}
                        options={
                          value === "storet_num"
                            ? storetOptions
                            : value === "data_flag_code"
                            ? dataFlagItems.map((item) => ({
                                label: item.data_qualifier_flag,
                                value: item.flag_code,
                              }))
                            : labsOptions
                        }
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </section>
      <ModalFooter
        customClosingLogic
        showCancelButton
        onCancel={doClose}
        onSave={doSave}
        onDelete={() => edit && doResultDelete(state, doClose)}
        saveIsDisabled={resultIsSaving}
        deleteIsDisabled={resultIsDeleting}
        saveText={
          resultIsSaving ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : edit ? (
            "Update Result"
          ) : (
            "Save"
          )
        }
        deleteText={
          resultIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "doNestedModalClose",
  "doModalClose",
  "doSamplesNewFetch",
  "doLabsTableFetch",
  "doSamplesFetchByLocation",
  "selectLabsOptions",
  "selectStoretOptions",
  "doResultPost",
  "doResultPut",
  "doResultDelete",
  "selectResultIsSaving",
  "selectResultIsDeleting",
  "selectResultError",
  "selectDataFlagItems",
  "doDataFlagFetch",
  EditResultModal
);
