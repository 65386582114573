import React from "react";
import { connect } from "redux-bundler-react";

/**
 * @param {string} title - The title of the Modal
 */
const ModalHeader = connect(
  "doModalClose",
  ({ doModalClose, subtitle, title = "", hideClose, onClose }) => (
    <header className="modal-header">
      <div className="d-flex flex-column w-100">
        <div className="d-flex w-100 justify-content-between">
          <h5 className="modal-title">{title}</h5>
          {!hideClose && (
            <span
              className="close pointer text-primary"
              onClick={onClose ? onClose : doModalClose}
            >
              &times;
            </span>
          )}
        </div>
        {subtitle && <h6 className="text-muted mt-2">{subtitle}</h6>}
      </div>
    </header>
  )
);

export default ModalHeader;
