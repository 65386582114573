import React, { memo } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default memo(function BasicTabs(props) {
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (props.activeTab !== undefined) setValue(props.activeTab);
  }, [props.activeTab]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onChange(newValue);
  };
  return (
    <Box
      sx={{
        "& .MuiBox-root": {
          padding: "0px",
        },
        width: "100%",
        padding: 0,
      }}
    >
      <Box
        sx={
          props.borderType === "none"
            ? { borderTop: 0, borderColor: "divider" }
            : { borderTop: 1, borderColor: "divider" }
        }
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              top: "0px",
            },
          }}
        >
          {props.labels.map((item, index) => (
            <Tab
              label={item}
              style={{ padding: 12 }}
              component="a"
              onClick={(e) => e.preventDefault()}
              href={
                props.hasLinks &&
                `${props.baseUrl}/${item.toLowerCase().replace(" ", "")}`
              }
            />
          ))}
        </Tabs>
      </Box>
      {[...props.children].map((child, index) => {
        return (
          <TabPanel value={value} index={index}>
            {child}
          </TabPanel>
        );
      })}
    </Box>
  );
});
