import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import { TableContainer } from "../../app-components/page-content";
import { aliasConfig } from "../dataExport/filterConfigs";
import FilterSidebar from "../dataExport/FilterSidebar";

import TableAlias from "./TableAlias";

const LocationAlias = ({ doAliasShouldFetch, routeParams, ...props }) => {
  useEffect(() => {
    doAliasShouldFetch();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      {" "}
      <FilterSidebar config={aliasConfig} {...props} />
      <TableContainer>
        <TableAlias />
      </TableContainer>
    </div>
  );
};
export default connect(
  "doAliasShouldFetch",
  "selectRouteParams",
  ...aliasConfig.selectors,
  ...aliasConfig.fetchFns,
  LocationAlias
);
