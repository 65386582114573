import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "biosample",
  getReducer: () => {
    const initialData = {
      data: [],
      shouldFetch: false,
      isFetching: false,

      shouldTypeFetch: false,
      biosampleTypeIsFetching: false,
      sampleTypes: [],
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "BIOSAMPLE_FETCH_START":
        case "BIOSAMPLE_SHOULD_FETCH":
        case "BIOSAMPLE_TYPE_FETCH_STARTED":
        case "BIOSAMPLE_TYPE_FETCH_FINISHED":
        case "BIOSAMPLE_TYPE_FETCH_ERROR":
        case "BIOSAMPLE_DELETE_STARTED":
        case "BIOSAMPLE_DELETE_FINISHED":
        case "BIOSAMPLE_DELETE_ERROR":
        case "BIOSAMPLE_SAVE_STARTED":
        case "BIOSAMPLE_SAVE_FINISHED":
        case "BIOSAMPLE_SAVE_ERROR":
          return Object.assign({}, state, payload);
        case "MODAL_CLOSED":
          return { ...state, ...payload, error: null };
        case "BIOSAMPLE_FETCH_FINISH":
          return { ...state, ...payload, shouldTypeFetch: true };
        default:
          return state;
      }
    };
  },

  doBioSampleShouldFetch:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "BIOSAMPLE_SHOULD_FETCH",
        payload: {
          shouldFetch: true,
        },
      });
    },

  doBioSampleFetch:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      let location_code = store.selectRouteParams().id;
      dispatch({
        type: "BIOSAMPLE_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      let url = ordsifyUrlBuilder(
        "/biosamples/",
        [
          {
            keyword: "location_code",
            items: location_code ? [location_code] : "", // if no location_codes passed, fetch all
          },
        ],
        20000
      );

      if (state) {
        url = ordsifyUrlBuilder(
          "/biosamples/",
          [
            {
              keyword: "location_code",
              items: location_code ? [location_code] : "", // if no location_codes passed, fetch all
            },
            {
              keyword: "sample_depth_range",
              items:
                state.min_sample_depth && state.max_sample_depth
                  ? [
                      parseInt(state.min_sample_depth),
                      parseInt(state.max_sample_depth),
                    ]
                  : state.min_sample_depth && !state.max_sample_depth
                  ? [parseInt(state.min_sample_depth), null]
                  : !state.min_sample_depth && state.max_sample_depth
                  ? [null, parseInt(state.max_sample_depth)]
                  : [],
            },
            {
              keyword: "sample_time",
              items:
                state.min_sample_date && state.max_sample_date
                  ? [`${state.min_sample_date}`, `${state.max_sample_date}`]
                  : !state.min_sample_date && state.max_sample_date
                  ? ["1900-01-01T00:00:00Z", `${state.max_sample_date}`]
                  : state.min_sample_date && !state.max_sample_date
                  ? [`${state.min_sample_date}`, "2100-01-01T00:00:00Z"]
                  : [],
            },
          ],
          20000
        );
      }

      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "BIOSAMPLE_FETCH_FINISH",
            payload: {
              isFetching: false,
              data: j.items,
            },
          });
        });
    },

  doBioSampleTypeFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "BIOSAMPLE_TYPE_FETCH_STARTED",
        payload: { biosampleTypeIsFetching: true, shouldTypeFetch: false },
      });

      apiFetch("/biosample_types/")
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "BIOSAMPLE_TYPE_FETCH_FINISHED",
            payload: { biosampleTypeIsFetching: false, sampleTypes: j.items },
          })
        )
        .catch((e) =>
          dispatch({
            type: "BIOSAMPLE_TYPE_FETCH_ERROR",
            payload: { biosampleTypeIsFetching: false },
          })
        );
    },

  doBioSamplePost:
    (state, cb) =>
    ({ dispatch, store }) => {
      let { doActionPostData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "BIOSAMPLE_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPostData(
        `${apiRoot}/biosamples/`,
        state,
        () => {
          dispatch({
            type: "BIOSAMPLE_SAVE_FINISHED",
            payload: { isSaving: false, shouldFetch: true },
          });
          cb ? cb() : doModalClose();
        },
        (e) =>
          dispatch({
            type: "BIOSAMPLE_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doBioSamplePut:
    (state, cb) =>
    ({ dispatch, store }) => {
      let { doActionPutData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "BIOSAMPLE_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPutData(
        `${apiRoot}/biosamples/${state.biosample_code}`,
        state,
        () => {
          dispatch({
            type: "BIOSAMPLE_SAVE_FINISHED",
            payload: { isSaving: false, shouldFetch: true },
          });
          cb ? cb() : doModalClose();
        },
        (e) =>
          dispatch({
            type: "BIOSAMPLE_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doBioSampleDelete:
    (state, cb) =>
    ({ dispatch, store }) => {
      let { doActionDeleteData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "BIOSAMPLE_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      doActionDeleteData(
        `${apiRoot}/biosamples/${state.biosample_code}`,
        {},
        () => {
          dispatch({
            type: "BIOSAMPLE_DELETE_FINISHED",
            payload: { isDeleting: false, shouldFetch: true },
          });
          cb ? cb() : doModalClose();
        },
        (e) =>
          dispatch({
            type: "BIOSAMPLE_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },

  selectBioSampleIsSaving: (state) => state.biosample.isSaving,
  selectBioSampleIsDeleting: (state) => state.biosample.isDeleting,
  selectBioSampleError: (state) => state.biosample.error,

  selectBioSampleTypes: (state) => state.biosample.sampleTypes,
  selectBioSampleTypeOptions: (state) =>
    state.biosample.sampleTypes.map((obj) => ({
      label: obj.sample_type,
      value: obj.sample_type,
    })),
  selectBioSampleTypeIsFetching: (state) =>
    state.biosample.biosampleTypeIsFetching,
  selectBioSampleTypeShouldFetch: (state) => state.biosample.shouldTypeFetch,

  selectBioSampleRaw: (store) => store.biosample,

  selectBioSampleData: (state) => state.biosample.data,
  selectBioSampleIsFetching: (store) => store.biosample.isFetching,
  selectBioSampleShouldFetch: (store) => store.biosample.shouldFetch,

  selectBioSamplesByLocation: createSelector(
    "selectBioSampleData",
    "selectRouteParams",
    (bioSampleData, routeParams) => {
      return bioSampleData.filter(
        (b) => parseInt(b.location_code) === parseInt(routeParams.id)
      );
    }
  ),

  reactBioSampleShouldFetch: createSelector(
    "selectBioSampleIsFetching",
    "selectBioSampleShouldFetch",
    (isFetching, shouldFetch) => {
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doBioSampleFetch",
        };
      }
    }
  ),
  reactBioSampleTypeShouldFetch: createSelector(
    "selectBioSampleTypeIsFetching",
    "selectBioSampleTypeShouldFetch",
    (isFetching, shouldFetch) => {
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doBioSampleTypeFetch",
        };
      }
    }
  ),
};
