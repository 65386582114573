import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";
import "../../../sample/modal.css";

const keys = [
  "observation_id",
  "location_id",
  "result_code",
  "processing_comments",
  "date_uploaded",
  "date_processed",
  "observed_property_id",
  "observed_datetime",
  "analyzed_datetime",
  "depth",
  "depth_unit",
  "data_classification",
  "result_value",
  "result_unit",
  "source_of_rounded_value",
  "rounded_value",
  "rounding_specification",
  "result_status",
  "result_grade",
  "medium",
  "activity_id",
  "activity_name",
  "collection_method",
  "field_device_id",
  "field_device_type",
  "field_comment",
  "lab_specimen_name",
  "lab_analysis_method",
  "lab_detection_condition",
  "lab_limit_type",
  "lab_mdl",
  "lab_mrl",
  "lab_quality_flag",
  "lab_received_datetime",
  "lab_prepared_datetime",
  "lab_sample_fraction",
  "lab_from_laboratory",
  "lab_sample_id",
  "lab_dilution_factor",
  "lab_comment",
  "field_visit_start_date",
  "validation_warnings",
  "office_id",
  "qc_type",
  "qc_source_sample_id",
  "standards_violations",
  "specimen_taxonomy_element",
  "cas_number",
  "latitude",
  "longitude",
  "elevation",
  "elevation_unit",
  "country",
  "state",
  "county",
  "location_type",
  "location_group",
];
const AigsTempTable = (props) => {
  const { aigsTempData, aigsTempIsFetching, doAigsTempFetch } = props;
  let columns = keys.map((key) => ({
    title: key
      .replaceAll("_", " ")
      .replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase()),
    field: key,
  }));

  return (
    <MaterialTable
      title={`Observation Processing`}
      isLoading={aigsTempIsFetching}
      columns={columns}
      data={aigsTempData}
      options={{
        loadingType: "overlay",
        exportButton: true,
        exportAllData: true,
        search: false,
        sorting: true,
        pageSize: 50,
        pageSizeOptions: [50, 500, 1000, 2000],
      }}
      actions={[
        {
          icon: "refresh",
          tooltip: "Refresh table",
          isFreeAction: true,
          onClick: () => doAigsTempFetch(),
        },
      ]}
    />
  );
};

export default connect(
  "doAigsTempFetch",
  "selectAigsTempData",
  "selectAigsTempIsFetching",
  AigsTempTable
);
