import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import { TableContainer } from "../../app-components/page-content";
import { biosamplesConfig } from "../dataExport/filterConfigs";
import FilterSidebar from "../dataExport/FilterSidebar";
import TableBioSample from "./TableBiosample";

const PageBioSamples = ({ doBioSampleShouldFetch, routeParams, ...props }) => {
  useEffect(() => {
    doBioSampleShouldFetch();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      {" "}
      <FilterSidebar config={biosamplesConfig} {...props} />
      <TableContainer>
        <TableBioSample />
      </TableContainer>
    </div>
  );
};
export default connect(
  "doBioSampleShouldFetch",
  "selectRouteParams",
  ...biosamplesConfig.selectors,
  ...biosamplesConfig.fetchFns,
  PageBioSamples
);
