import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { lineChartConfig } from "../dataExport/filterConfigs";
import FilterSidebar from "../dataExport/FilterSidebar";
import { TableContainer } from "../../app-components/page-content";

const Index = connect(
  ...lineChartConfig.selectors,
  ...lineChartConfig.fetchFns,
  "doChartHydrographFetch",
  "selectChartHydrographSeries",
  "selectChartHydrographIsFetching",
  "selectStoretActiveName",
  "selectLocationActive",
  "selectSampleDepthActive",
  "selectStoretActive",
  "selectProjectActive",
  ({
    doChartHydrographFetch,
    chartHydrographSeries,
    chartHydrographIsFetching,
    storetActiveName,
    locationActive,
    sampleDepthActive,
    storetActive,
    projectActive,
    ...props
  }) => {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);

    let includeDates = props.chartHydrographDates
      .map(function (date) {
        return date.getTime();
      })
      .filter(function (date, i, array) {
        return array.indexOf(date) === i;
      })
      .map(function (time) {
        return new Date(time);
      })
      .sort((d1, d2) => d1 - d2);

    const initialState = {
      min_date: "1970-01-01",
      max_date: "2025-01-01",
    };
    // sort data here
    useEffect(() => {
      if (chartHydrographSeries !== undefined) {
        const series = Object.entries(chartHydrographSeries).map(
          ([key, value]) => {
            const values = value.sort(
              (a, b) => new Date(a.sample_time) - new Date(b.sample_time)
            );
            return {
              name: key,
              data: values.map((x) => {
                return [new Date(x.sample_time).getTime(), x.value];
              }),
            };
          }
        );

        setData(
          series.map((x) => {
            return {
              name: x.name,
              data: x.data,
            };
          })
        );
      }
    }, [chartHydrographSeries]);
    useEffect(() => {
      setCount(0);
    });

    const options = {
      chart: {
        type: "line",
      },
      title: {
        text:
          storetActiveName !== ""
            ? `Line Chart-${storetActiveName}`
            : "Line Chart",
      },
      series: data,
      xAxis: {
        type: "datetime",
        labels: {
          formatter: function () {
            return Highcharts.dateFormat("%b-%d-%Y", this.value);
          },
        },
      },
    };
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          minHeight: "calc(100vh - 106px)",
        }}
      >
        {" "}
        <FilterSidebar
          config={lineChartConfig}
          initialState={initialState}
          includeDates={includeDates}
          {...props}
        />
        <TableContainer>
          {chartHydrographSeries !== undefined && count > 0 ? (
            <h3>No Data Available</h3>
          ) : (
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              containerProps={{ style: { height: "90%" } }}
            />
          )}
        </TableContainer>
      </div>
    );
  }
);

export default Index;
