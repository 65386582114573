export default {
  name: "sources",
  getReducer: () => {
    const initialData = {
      selects: [],
      shouldFetch: false
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "SOURCES_FETCH_STARTED":
        case "SOURCES_FETCH_FINISHED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doSourcesFetch: () => ({ dispatch, store, apiFetch }) => {
    dispatch({
      type: "SOURCES_FETCH_STARTED",
      payload: {
        shouldFetch: false
      }
    });
    const createData = data => {
      var list = [];
      var i = 0;
      for (i = 0; i < data.length; i++) {
        list.push({ value: data[i].source_code, label: data[i].source_name });
      }
      dispatch({
        type: "SOURCES_FETCH_FINISHED",
        payload: {
          selects: list
        }
      });
    };

    apiFetch("/source_codes/")
      .then(response => response.json())
      .then(r => createData(r.items))
      .catch(e => {
        console.log(e);
      });
  },

  selectSourcesSelects: state => {
    return state.sources.selects;
  },

  reactSourcesShouldFetch: state => {
    if (state.sources.shouldFetch)
      return {
        actionCreator: "doSourcesFetch"
      };
  }
};
