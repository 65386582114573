import React, { useEffect, useReducer } from "react";
import { connect } from "redux-bundler-react";
import {
  ModalContent,
  ModalHeader,
  ModalFooter,
} from "../../app-components/modal";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const AliasModal = (props) => {
  const {
    data,
    doAliasPut,
    doAliasDelete,
    doAliasPost,
    aliasIsSaving,
    aliasIsDeleting,
    aliasError: error,
    routeParams,
    edit,
    doModalClose,
  } = props;

  const initialState = {
    ...data,
    location_code: routeParams.id,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    /**
     * When data is initially pulled into the modal from the table, it needs to be formatted in a digestible way for the react-select and input components.
     */
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    month = month.toString().length === 1 ? `0${month}` : month;
    let day = today.getDate();
    let initialData = { ...data };
    initialData.date_created = initialData.date_created
      ? initialData.date_created.split("T")[0]
      : `${year}-${month}-${day}`;
    dispatch({
      type: "INITIALIZE_MODAL",
      payload: initialData,
    });
  }, [data]);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const _onSave = () => {
    let putData = {
      location_code: parseInt(state.location_code),
      alias_id: state.alias_id,
      date_created: new Date(state.date_created).toISOString(),
      location_alias_code: state.location_alias_code,
    };
    if (edit) {
      doAliasPut(putData);
    } else {
      doAliasPost(putData);
    }
  };

  const _onDelete = () => {
    doAliasDelete(state);
  };

  return (
    <ModalContent size="md" error={error}>
      <ModalHeader title="Alias Details" />
      <section className="modal-body">
        <div className="container-fluid">
          <form>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label id="alias-label" htmlFor="alias_id">
                  Alias ID
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="alias_id"
                  placeholder="Alias ID"
                  name="alias_id"
                  onChange={handleChange}
                  value={state.alias_id}
                />
              </div>
            </div>
          </form>
        </div>
      </section>
      <ModalFooter
        onSave={_onSave}
        onDelete={_onDelete}
        customClosingLogic
        showCancelButton
        onCancel={doModalClose}
        saveIsDisabled={aliasIsSaving}
        deleteIsDisabled={aliasIsDeleting}
        saveText={
          aliasIsSaving ? <i className="mdi mdi-loading mdi-spin" /> : "Save"
        }
        deleteText={
          aliasIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "selectRouteParams",
  "doAliasPost",
  "doAliasPut",
  "doAliasDelete",
  "selectAliasIsSaving",
  "selectAliasIsDeleting",
  "selectAliasError",
  "doModalClose",
  AliasModal
);
