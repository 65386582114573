import React, { useReducer, useEffect, useState, useRef } from "react";
import { connect } from "redux-bundler-react";
import {
  ModalFooter,
  ModalContent,
  ModalHeader,
} from "../../../app-components/modal";
import { classnames } from "../../../utils/helpers";
import ReactDataGrid from "../../../app-components/tables/ReactDataGrid";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
    case "SET_SAMPLE_DATA":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

//need confirmation
const columns = [
  { key: "depth", name: "Depth (m)", storet: "depth" },
  { key: "temperature", name: "Temperature (C)", storet: "00010" },
  { key: "do", name: "D.O. (mg/L)", storet: "00300" },
  { key: "cond", name: "Conductivity", storet: "00094" },
];

const defaultParsePaste = (str) => {
  return str.split(/\r\n|\n|\r/).map((row) => row.split("\t"));
};

const LRHForm = ({
  data,
  doDelete,
  location,
  form_code,
  doPostLakeQuality,
  readOnly,
}) => {
  const [activeCell, setActiveCell] = useState("");

  const activeRef = useRef(activeCell);
  const _setActiveCell = (data) => {
    activeRef.current = data;
    setActiveCell(data);
  };

  const initialState = {
    form_code,
    entry: [
      ["", "", "", ""],
      ["", "", "", ""],
    ],
    outflow: [
      ["", "", "", ""],
      ["", "", "", ""],
    ],
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!readOnly) {
      document.addEventListener("paste", handlePaste);
      window.location.hash = "";
      return () => document.removeEventListener("paste", handlePaste);
    }
  }, []);

  useEffect(() => {
    if (readOnly) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: {
          ...data,
          entry: JSON.parse(data.entry_clob),
          outflow: JSON.parse(data.outflow_clob),
          sample_time: data.sample_time && data.sample_time.split(":00Z")[0],
        },
      });
    }
  }, [readOnly, data]);

  const _convertCellToObject = (cell, index) => {
    return { [columns[index].storet]: cell };
  };

  const handlePaste = (e) => {
    e.preventDefault();
    if (activeRef.current.split("_")[0] !== `00`) return;
    const pasteData = defaultParsePaste(e.clipboardData.getData("text/plain"));
    pasteData.pop(); // copying leaves a trailing row
    // convert to array of objects w storet codes.
    let newData = pasteData.map((row) => row.map(_convertCellToObject));
    _setSamples(newData, activeRef.current.split("_")[1].toLowerCase());
  };

  const doSave = () => {
    let postData = {};
    postData.entry_clob = JSON.stringify(state.entry);
    postData.outflow_clob = JSON.stringify(state.outflow);
    postData.sample_time =
      state.sample_time && !state.sample_time.includes("Z")
        ? `${state.sample_time}:00Z`
        : state.sample_time;
    postData.lake_elev = state.lake_elev;
    postData.form_code = form_code;
    //post function
    doPostLakeQuality(postData);
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const _setSamples = (payload, field) => {
    dispatch({
      type: "SET_SAMPLE_DATA",
      field,
      payload,
    });
  };

  const inputWrapperClass = classnames({
    "d-flex": true,
    "flex-column": true,
    "w-100": true,
    "mb-2": true,
  });

  return (
    <ModalContent size="lg">
      <ModalHeader
        title={`${location && location.loc_id} Lake Quality Report`}
        subtitle="Water Management"
      />
      <section className="modal-body">
        <div className="container-fluid">
          <div className="two-grid">
            <div className={inputWrapperClass}>
              <label>Datetime</label>
              <input
                className="custom-input"
                value={state.sample_time}
                onChange={handleInputChange}
                name="sample_time"
                type="datetime-local"
                disabled={readOnly}
              />
            </div>
            <div className={inputWrapperClass}>
              <label>Lake Elevation (ft)</label>
              <input
                className="custom-input"
                value={state.lake_elev}
                onChange={handleInputChange}
                name="lake_elev"
                disabled={readOnly}
              />
            </div>
          </div>
          <div>
            <ReactDataGrid
              title="Entry"
              data={state.entry}
              active={activeCell}
              setActive={!readOnly && _setActiveCell}
              columns={columns}
            />
          </div>
          <div>
            <ReactDataGrid
              title="Outflow"
              data={state.outflow}
              active={activeCell}
              setActive={!readOnly && _setActiveCell}
              columns={columns}
            />
          </div>
        </div>
      </section>
      <ModalFooter
        saveText="Submit"
        showCancelButton
        onSave={doSave}
        hideSave={readOnly}
        onDelete={doDelete}
        deleteText="Delete this item"
      />
    </ModalContent>
  );
};

export default connect("selectLocation", "doPostLakeQuality", LRHForm);
