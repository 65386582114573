import React, { useState } from "react";
import SwaggerUI from "swagger-ui-react";
import ReactSelect from "react-select";
import "swagger-ui-react/swagger-ui.css";

const ApiDocs = () => {
  const [endpoint, setEndpoint] = useState({
    value: "/public",
    label: "/public",
  });
  let options = [
    "/public",
    "/wq_results",
    "/wq_bioresults",
    "/wq_samples",
    "/biosamples",
    "/wq_locations",
    "/field_visit",
    "/field_trip",
    "/sites2",
  ].map((val) => ({ value: val, label: val }));
  return (
    <div className="tableContainer w-100">
      <h3 className=" mt-4 mb-3 ml-0">API Documentation</h3>
      <div className="d-flex flex-column w-50 mt-3">
        <label className="mb-1">Select Endpoint</label>
        <ReactSelect
          className="w-100"
          onChange={setEndpoint}
          options={options}
          value={endpoint}
          menuPosition="fixed"
        />
      </div>
      <SwaggerUI
        url={`${process.env.REACT_APP_API_ROOT}/open-api-catalog/${endpoint.value}/`}
      />
    </div>
  );
};

export default ApiDocs;
