import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "../../../app-components/modal";
import { useReducer } from "react";
import ReactSelect from "react-select";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const EditStreamsModal = (props) => {
  const {
    data,
    edit,
    stream_code,
    streamAll,
    officeData,
    doStreamSave,
    doStreamDelete,
    streamIsSaving,
    streamIsDeleting,
    streamError: error,
    doModalClose,
  } = props;

  let initialState = { stream_code };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (edit) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: {
          ...data,
        },
      });
    }
  }, [edit, data]);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSelect = (field, payload) => {
    dispatch({
      type: "UPDATE_INPUT",
      field,
      payload,
    });
  };

  return (
    <ModalContent size="md" error={error}>
      <ModalHeader title={edit ? "Edit Streams Info" : "Add Streams"} />
      <section className="modal-body">
        <div className="container-fluid">
          <form>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="stream_label">Stream Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="stream_code"
                  onChange={handleChange}
                  placeholder="i.e. 1828606099"
                  value={state.stream_name}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="stream_label">Stream Label</label>
                <input
                  type="text"
                  className="form-control"
                  name="stream_label"
                  onChange={handleChange}
                  placeholder="i.e. 0047"
                  value={state.stream_label}
                />
              </div>
              <div className="form-group col-md-6">
                <label id="office_id" htmlFor="office_id">
                  Office ID
                </label>
                <ReactSelect
                  name="office_id"
                  onChange={(obj) => handleSelect("office_id", obj.value)}
                  options={officeData.map((item) => ({
                    value: item.office_id,
                    label: item.office_id,
                  }))}
                  //placeholder="Office ID..."
                  value={{ value: state.office_id, label: state.office_id }}
                  menuPosition="fixed"
                />
              </div>
            </div>
          </form>
        </div>
      </section>
      <ModalFooter
        onSave={() => doStreamSave(state)}
        customClosingLogic
        onDelete={edit ? () => doStreamDelete(state) : null}
        showCancelButton
        onCancel={doModalClose}
        saveIsDisabled={streamIsSaving}
        deleteIsDisabled={streamIsDeleting}
        saveText={
          streamIsSaving ? <i className="mdi mdi-loading mdi-spin" /> : "Save"
        }
        deleteText={
          streamIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "selectStreamIsFetching",
  "selectStreamAll",
  "doStreamSave",
  "selectOfficeData",
  "doStreamDelete",
  "selectStreamIsSaving",
  "selectStreamIsDeleting",
  "selectStreamError",
  "doModalClose",
  EditStreamsModal
);
