import React, { useState } from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";
import AliasModal from "./AliasModal";

// import { postData, putData, deleteData } from "../../utils/fetch";

function TableAlias(props) {
  const {
    aliasData,
    aliasIsFetching,
    doAliasShouldFetch,
    doModalOpen,
    userIsOrgMemberOrAdmin,
    doCwmsSeqNewFetch,
  } = props;
  const [loading, setLoading] = useState(false);
  return (
    <div className="custom-table">
      <div className="card-body">
        <div className="d-flex flex-column w-100">
          {userIsOrgMemberOrAdmin && (
            <div className="d-flex justify-content-between align-items-center w-100">
              <h4>Aliases</h4>
              <button
                className={`btn btn-outline-${
                  loading ? "disabled" : "success"
                } m-2`}
                onClick={() => {
                  setLoading(true);
                  doCwmsSeqNewFetch().then((res) => {
                    setLoading(false);
                    doModalOpen(AliasModal, {
                      data: { location_alias_code: res.items[0].seq },
                      edit: false,
                    });
                  });
                }}
              >
                {loading ? "Loading..." : "Add Alias"}
              </button>
            </div>
          )}
          <MaterialTable
            title=""
            isLoading={aliasIsFetching}
            columns={[
              {
                field: "link",
                title: "Actions",
                render: (rowData) => {
                  if (userIsOrgMemberOrAdmin) {
                    return (
                      <button
                        className="hover"
                        onClick={(e) => {
                          doModalOpen(AliasModal, {
                            data: rowData,
                            edit: true,
                          });
                        }}
                      >
                        <i className="mdi mdi-pencil-outline mdi-24px"></i>
                      </button>
                    );
                  }
                },
              },
              // { title: "Location Code", field: "location_code" },
              { title: "Alias ID", field: "alias_id" },
              {
                title: "Date Created",
                field: "date_created",
              },
            ]}
            data={aliasData}
            options={{
              loadingType: "overlay",
              exportButton: true,
              exportAllData: true,
              sorting: true,
              pageSize: 10,
            }}
            actions={[
              {
                icon: "refresh",
                tooltip: "Refresh table",
                isFreeAction: true,
                onClick: (event) => doAliasShouldFetch(),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default connect(
  "doActionPostData",
  "doActionPutData",
  "doActionDeleteData",
  "selectAliasData",
  "selectAliasIsFetching",
  "doAliasShouldFetch",
  "selectApiRoot",
  "doModalOpen",
  "selectUserIsOrgMemberOrAdmin",
  "doCwmsSeqNewFetch",
  TableAlias
);
