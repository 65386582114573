import React, { useEffect, useReducer } from "react";
import { connect } from "redux-bundler-react";
import {
  ModalContent,
  ModalHeader,
  ModalFooter,
} from "../../app-components/modal";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const NoteModal = (props) => {
  const {
    doModalClose,
    data,
    doNotePut,
    doNoteDelete,
    doNotePost,
    noteIsSaving,
    noteIsDeleting,
    noteError: error,
    routeParams,
    edit,
    id,
  } = props;

  const initialState = {
    ...data,
    location_code: routeParams.id,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    /**
     * When data is initially pulled into the modal from the table, it needs to be formatted in a digestible way for the react-select and input components.
     */
    let initialData = { ...data };
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    month = month.toString().length === 1 ? `0${month}` : month;
    let day = today.getDate();

    initialData.date_created = initialData.date_created
      ? initialData.date_created.split("T")[0]
      : `${year}-${month}-${day}`;

    initialData.date_effective = !initialData.date_effective
      ? new Date().toISOString()
      : initialData.date_effective.split("Z")[0];

    if (edit) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: initialData,
      });
    } else {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: { ...initialData, id },
      });
    }
  }, [data]);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const _onSave = () => {
    let putData = {
      location_code: parseInt(state.location_code),
      date_effective:
        state.date_effective.split(":").length === 3
          ? `${state.date_effective}Z`
          : `${state.date_effective}:00Z`,
      note: state.note,
      date_created: new Date(state.date_created).toISOString(),

      id: state.id,
    };
    if (edit) {
      doNotePut(putData);
    } else {
      doNotePost(putData);
    }
  };
  return (
    <ModalContent size="md" error={error}>
      <ModalHeader title={edit ? "Edit Note" : "Add Note"} />
      <section className="modal-body">
        <div className="container-fluid">
          <form>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label id="notes-label" htmlFor="notes">
                  Note
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="note"
                  placeholder="Note"
                  name="note"
                  onChange={handleChange}
                  value={state.note}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label id="field-visit-end-date-label">Date Effective</label>
                <input
                  className="form-control"
                  type="datetime-local"
                  onChange={handleChange}
                  name="date_effective"
                  value={state["date_effective"]}
                />
              </div>
            </div>
          </form>
        </div>
      </section>
      <ModalFooter
        onSave={_onSave}
        onDelete={() => (edit ? doNoteDelete(state) : null)}
        showCancelButton
        customClosingLogic
        onCancel={doModalClose}
        saveIsDisabled={noteIsSaving}
        deleteIsDisabled={noteIsDeleting}
        saveText={
          noteIsSaving ? <i className="mdi mdi-loading mdi-spin" /> : "Save"
        }
        deleteText={
          noteIsDeleting ? <i className="mdi mdi-loading mdi-spin" /> : "Delete"
        }
      />
    </ModalContent>
  );
};

export default connect(
  "selectRouteParams",
  "selectApiRoot",
  "doNotePost",
  "doNotePut",
  "doNoteDelete",
  "selectCwmsSeq",
  "doCwmsSeqFetch",
  "doNoteShouldFetch",
  "selectNoteIsSaving",
  "selectNoteIsDeleting",
  "selectNoteError",
  "doModalClose",
  NoteModal
);
