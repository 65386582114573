import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const Time = connect(
  "doProjectMapSetTime",
  "selectProjectMapTime",
  "selectProjectMapShowBar",
  ({ doProjectMapSetTime, projectMapTime, projectMapShowBar }) => {
    useEffect(() => {
      if (projectMapTime === "") {
        setYear("");
      }
    }, [projectMapTime]);
    const [year, setYear] = useState("year");
    const handleChange = (e) => {
      setYear(e.target.value);
      doProjectMapSetTime(e.target.value);
    };
    return (
      <div style={{ padding: "3px 0px 0px 0px" }}>
        <FormControl error={projectMapShowBar && year === ""}>
          <FormLabel>Period</FormLabel>
          <RadioGroup value={year} onChange={(e) => handleChange(e)} row>
            <FormControlLabel value="year" control={<Radio />} label="Year" />
            <FormControlLabel
              value="decade"
              control={<Radio />}
              label="Decade"
            />
          </RadioGroup>
        </FormControl>
      </div>
    );
  }
);

export default Time;
