import MaterialTable from "material-table";
import React, { useState } from "react";
import { connect } from "redux-bundler-react";
import {
  ModalContent,
  ModalHeader,
  ModalFooter,
} from "../../app-components/modal";
import BasicTabs from "../../app-components/tabs/Tabs";
import { readString } from "react-papaparse";
import FileCard from "../location/cards/FileCard";

const iconDict = {
  "text/csv": "equalizer",
  "image/jpeg": "image",
  "application/pdf": "pdf",
};

const _getType = (type) => iconDict[type];
const PivotDataModal = ({
  doDocumentPivotUpload,
  routeParams,
  id,
  profileUploadData,
  doDocumentProfileDownload,
}) => {
  const [file, setFile] = useState();
  const [data, setData] = useState();
  const [tableData, setTableData] = useState();
  const [subject, setSubject] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event) => {
    setFile(event.target.files[0]);
    const reader = new FileReader();
    reader.onload = function (e) {
      let text = e.target.result;
      let tempData = readString(text, { header: true });
      let columns = Object.keys(tempData.data[0]);
      let newStruct = { header: {}, data: [] };
      columns.forEach((col, idx) => {
        newStruct.header[`column_${idx + 1}`] = col;
      });
      tempData.data.forEach((obj, idx) => {
        let rowObj = {};
        Object.keys(obj).forEach((key, i) => {
          rowObj[`column_${i + 1}`] = obj[key];
        });
        newStruct.data.push(rowObj);
      });
      setTableData(tempData.data);
      setData(newStruct);
    };
    reader.readAsText(event.target.files[0]);
  };
  return (
    <ModalContent size="lg">
      <ModalHeader title="Bulk Load" />
      <section className="modal-body pt-0">
        <div className="container-fluid">
          <div className="d-flex flex-column mb-2 mt-2">
            <label>Subject</label>
            <input
              className="form-control"
              value={subject}
              name="subject"
              onChange={(e) => setSubject(e.target.value)}
              maxLength="20"
            />
            <p className="m-0 mt-1 text-muted">
              <small>{20 - subject.length} characters remaining</small>
            </p>
          </div>
          <div className="d-flex flex-column">
            <label htmlFor="visitFile">File</label>
            <div className="d-flex">
              <input
                type="file"
                accept=".csv"
                onChange={handleChange}
                id="pivotFile"
                style={{ width: 200 }}
              />
              <button
                className="btn btn-outline-info btn-sm ml-2"
                onClick={() =>
                  (document.getElementById("pivotFile").value = "")
                }
              >
                Clear File
              </button>
            </div>
          </div>
          <div className="d-flex w-100 border-top mt-3 pt-2">
            <p className="text-muted">
              {`Note: Please upload CSV files that have the following first 3 columns.`}
              <br /> Date_Time | Depth (ft) | Loc_id <br />
              Any columns after these must be the FULL NAME of the parameter
              (see Lookup table for reference)
            </p>
          </div>
          {file && (
            <MaterialTable
              title="Data Preview"
              columns={
                tableData &&
                Object.keys(tableData[0]).map((key) => ({
                  title: key,
                  field: key,
                }))
              }
              data={
                tableData &&
                tableData.map((row) => ({ ...row, tableData: null }))
              }
            />
          )}
        </div>
        {/* <BasicTabs
          onChange={(val) => setActiveTab(val)}
          activeTab={activeTab}
          labels={["Upload", "Past Uploads", "Data Processing"]}
          routeParams={routeParams}
          borderType="none"
        >
          <div className="container-fluid">
            <div className="d-flex flex-column mb-2">
              <label>Subject</label>
              <input
                className="form-control"
                value={subject}
                name="subject"
                onChange={(e) => setSubject(e.target.value)}
                maxLength="20"
              />
              <p className="m-0 mt-1 text-muted">
                <small>{20 - subject.length} characters remaining</small>
              </p>
            </div>
            <div className="d-flex flex-column">
              <label htmlFor="visitFile">File</label>
              <div className="d-flex">
                <input
                  type="file"
                  accept=".csv"
                  onChange={handleChange}
                  id="pivotFile"
                  style={{ width: 200 }}
                />
                <button
                  className="btn btn-outline-info btn-sm ml-2"
                  onClick={() =>
                    (document.getElementById("pivotFile").value = "")
                  }
                >
                  Clear File
                </button>
              </div>
            </div>
            <div className="d-flex w-100 border-top mt-3 pt-2">
              <p className="text-muted">
                {`Note: Please upload CSV files that have the following first 3 columns.`}
                <br /> Date_Time | Depth (ft) | Loc_id <br />
                Any columns after these must be the FULL NAME of the parameter
                (see Lookup table for reference)
              </p>
            </div>
            {file && (
              <MaterialTable
                title="Data Preview"
                columns={
                  tableData &&
                  Object.keys(tableData[0]).map((key) => ({
                    title: key,
                    field: key,
                  }))
                }
                data={
                  tableData &&
                  tableData.map((row) => ({ ...row, tableData: null }))
                }
              />
            )}
          </div>
          <div className="container-fluid">
            {profileUploadData.map((doc) => {
              return (
                <div className="mb-2" key={doc.name}>
                  <FileCard
                    type={_getType(doc.mime_type)}
                    name={doc.name}
                    onClick={() => doDocumentProfileDownload(doc)}
                  />
                </div>
              );
            })}
          </div>
          <div className="container-fluid">Data processing</div>
        </BasicTabs> */}
      </section>
      <ModalFooter
        showCancelButton
        saveIsDisabled={!file}
        onSave={() =>
          doDocumentPivotUpload(
            file,
            routeParams.project,
            subject,
            "csv",
            data,
            id
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "doDocumentPivotUpload",
  "selectRouteParams",
  "selectProfileUploadData",
  "doDocumentProfileDownload",
  PivotDataModal
);
