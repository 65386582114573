import React, { useState, useEffect } from "react";
import { connect } from "redux-bundler-react";
import TableCollapsible from "../../app-components/tables/TableCollapsible";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import Link from "../misc/link";

const TableIndices = (props) => {
  const {
    indices,
    doIndicesFetch,
    indicesIsFetching,
    doSetSortedIndicesObject,
    sortedIndices,
    title,
  } = props;
  const [showCTSI, setShowCTSI] = useState(false);
  const [sorted, setSorted] = useState({});

  useEffect(() => setSorted(sortedIndices), [sortedIndices]);

  useEffect(() => {
    if (showCTSI) {
      let nonNull = {};
      Object.keys(sorted).forEach((loc_id) => {
        let nonNullArr = sorted[loc_id].filter((item) => item.ctsi !== null);
        if (nonNullArr.length) nonNull[loc_id] = nonNullArr;
      });
      setSorted(nonNull);
    } else {
      doSetSortedIndicesObject();
    }
  }, [showCTSI]);

  const _renderCheckbox = () => (
    <FormGroup className="w-100">
      <FormControlLabel
        control={
          <Checkbox onChange={(e) => setShowCTSI(!showCTSI)} value={showCTSI} />
        }
        label="Only show data that contains CTSI values"
      />
    </FormGroup>
  );

  const _renderLink = (project, location) => {
    return (
      <Link to={`/projects/${project}/indices/${location}`}>
        <i className="mdi mdi-eye mdi-24px"></i>
      </Link>
    );
  };

  return (
    <TableCollapsible
      isFetching={indicesIsFetching}
      doFetch={doIndicesFetch}
      doSort={doSetSortedIndicesObject}
      sortedData={sorted}
      data={indices}
      title={title}
      renderCheckbox={_renderCheckbox}
      renderLink={_renderLink}
    />
  );
};

export default connect(
  "selectIndices",
  "selectIndicesIsFetching",
  "doIndicesFetch",
  "doSetSortedIndicesObject",
  "selectSortedIndices",
  "selectRouteParams",
  TableIndices
);
