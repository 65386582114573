import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilderNoSlash } from "../utils/ordsify";

export default {
  name: "profile",
  getReducer: () => {
    const initialData = {
      isFetching: false,
      data: [],
      dataForDates: [],
      // dataForStoret: [],
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "PROFILE_FETCH_START":
        case "PROFILE_FETCH_FINISH":
        case "PROFILE_SHOULD_FETCH":
        // case "PROFILE_FETCH_STORET_START":
        // case "PROFILE_FETCH_STORET_FINISH":
        case "PROFILE_FETCH_DATE_FINISH":
        case "PROFILE_FETCH_DATE_START":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },
  doProfileShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "PROFILE_SHOULD_FETCH",
        payload: { shouldFetch: true },
      });
    },

  doProfileFetch:
    (state, limit = 100000) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "PROFILE_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      let url = `/samples_all/all`;
      if (state && state.projects) {
        let locations = Object.keys(state).map((key) => {
          if (parseInt(key) && state[key]) {
            /** key is site_code containing arrays of locations */
            return state[key].map((location) => location.value);
          }
        });
        locations = locations.flat(1).filter((item) => item !== undefined);
        let parameters =
          state.parameters && state.parameters.map((p) => p.value);
        let date;
        if (state.date) {
          date =
            typeof state.date === "string"
              ? state.date
              : state.date.toISOString();
        }
        url = ordsifyUrlBuilderNoSlash(
          `/charting/vertical_profile/${locations[0]}`,
          [
            {
              keyword: "sample_depth_range",
              items:
                state.min_sample_depth && state.max_sample_depth
                  ? [
                      parseInt(state.min_sample_depth),
                      parseInt(state.max_sample_depth),
                    ]
                  : state.min_sample_depth && !state.max_sample_depth
                  ? [parseInt(state.min_sample_depth), null]
                  : !state.min_sample_depth && state.max_sample_depth
                  ? [null, parseInt(state.max_sample_depth)]
                  : [],
            },
            {
              keyword: "sample_time",
              items: state.date
                ? [
                    `${date.split("T")[0]}T00:00:00Z`,
                    `${date.split("T")[0]}T23:59:59Z`,
                  ]
                : [],
            },
            {
              keyword: "storet_num",
              items: parameters ? parameters : [],
            },
            {
              keyword: "value",
              items:
                state.min_value && state.max_value
                  ? [parseFloat(state.min_value), parseFloat(state.max_value)]
                  : state.min_value && !state.max_value
                  ? [parseFloat(state.min_value), null]
                  : !state.min_value && state.max_value
                  ? [null, parseFloat(state.max_value)]
                  : [],
            },
          ],
          limit
        );
      }
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "PROFILE_FETCH_FINISH",
            payload: { data: j.items, isFetching: false, shouldFetch: false },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },

  doProfileFetchSampleDates:
    (state, limit = 100000) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "PROFILE_FETCH_DATE_START",
        payload: {
          shouldProfileDateFetch: false,
          isProfileDateFetching: true,
        },
      });

      let url = `/samples_all/all`;
      if (state) {
        let locations = Object.keys(state).map((key) => {
          if (parseInt(key) && state[key]) {
            /** key is site_code containing arrays of locations */
            return state[key].map((location) => location.value);
          }
        });
        locations = locations.flat(1).filter((item) => item !== undefined);
        let parameters =
          state.parameters && state.parameters.map((p) => p.value);
        url = ordsifyUrlBuilderNoSlash(
          "/samples_all/all",
          [
            {
              keyword: "location_code",
              items: locations ? locations : [],
            },
            {
              keyword: "sample_depth_range",
              items:
                state.min_sample_depth && state.max_sample_depth
                  ? [
                      parseInt(state.min_sample_depth),
                      parseInt(state.max_sample_depth),
                    ]
                  : state.min_sample_depth && !state.max_sample_depth
                  ? [parseInt(state.min_sample_depth), null]
                  : !state.min_sample_depth && state.max_sample_depth
                  ? [null, parseInt(state.max_sample_depth)]
                  : [],
            },
            {
              keyword: "storet_num",
              items: parameters ? parameters : [],
            },
            {
              keyword: "value",
              items:
                state.min_value && state.max_value
                  ? [parseFloat(state.min_value), parseFloat(state.max_value)]
                  : state.min_value && !state.max_value
                  ? [parseFloat(state.min_value), null]
                  : !state.min_value && state.max_value
                  ? [null, parseFloat(state.max_value)]
                  : [],
            },
          ],
          limit
        );
      }
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "PROFILE_FETCH_DATE_FINISH",
            payload: {
              dataForDates: j.items,
              shouldProfileDateFetch: false,
              isProfileDateFetching: true,
            },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },
  selectProfileIsFetching: (state) => state.profile.isFetching,
  selectProfileShouldFetch: (state) => state.profile.shouldFetch,
  selectProfileData: (state) => state.profile.data,
  selectProfileDates: (state) =>
    state.profile.dataForDates.map((obj) => {
      // js Date object is affected by timezone based on orientation of YYYY-MM-DD, needs to be flipped
      let sampleTime = obj.sample_time.split("T")[0]; //1986-08-12
      let sampleArr = sampleTime.split("-"); // ['1986','08','12']
      sampleTime = `${sampleArr[1]}-${sampleArr[2]}-${sampleArr[0]}`; //08-12-1986
      return new Date(sampleTime);
    }),
  selectProfileStoretOptions: createSelector(
    "selectStoretOptions",
    (storetOptions) => {
      let profileStoretNums = [
        "00011",
        "00301",
        "00299",
        "00300",
        "00295",
        "00010",
      ];
      return storetOptions.filter((obj) =>
        profileStoretNums.includes(obj.value)
      );
    }
  ),

  reactProfileShouldFetch: createSelector(
    "selectProfileIsFetching",
    "selectProfileShouldFetch",
    (isFetching, shouldFetch) => {
      // never fetch if anoter fetch already in progress
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doProfileFetch",
        };
      }
    }
  ),
};
