import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "bioresult",
  getReducer: () => {
    const initialData = {
      data: [],
      resultsByCode: [],
      shouldFetch: false,
      isFetching: false,
      shouldFetchByCode: false,
      sorted: [],
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "URL_UPDATED":
          return Object.assign({}, state, {
            shouldFetchByCode: true,
          });
        case "BIORESULT_FETCH_BY_CODE_START":
        case "BIORESULT_FETCH_BY_CODE_FINISH":
        case "BIORESULT_FETCH_BY_CODE_ABORT":
        case "BIORESULT_FETCH_START":
        case "BIORESULT_FETCH_FINISH":
        case "BIORESULT_SHOULD_FETCH":
        case "BIORESULT_SORTED":
        case "BULK_PARSED_BIORESULTS":
        case "BULK_IMPORT_BIORESULTS_STARTED":
        case "BULK_IMPORT_BIORESULTS_FINISHED":
        case "BIORESULT_DELETE_STARTED":
        case "BIORESULT_DELETE_FINISHED":
        case "BIORESULT_DELETE_ERROR":
        case "BIORESULT_SAVE_STARTED":
        case "BIORESULT_SAVE_FINISHED":
        case "BIORESULT_SAVE_ERROR":
          return Object.assign({}, state, payload);
        case "MODAL_CLOSED":
          return { ...state, error: null };
        default:
          return state;
      }
    };
  },

  doBioResultShouldFetch:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "BIORESULT_SHOULD_FETCH",
        payload: {
          shouldFetch: true,
        },
      });
    },

  doBioResultFetch:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "BIORESULT_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });
      let locationCode = store.selectRouteParams().id
        ? [store.selectRouteParams().id]
        : [];

      let taxacode = state.taxa ? state.taxa.map((p) => p.value) : [];
      let lab = state.lab ? state.lab.map((p) => p.value) : [];
      const url = ordsifyUrlBuilder(
        "/wq_bioresults/",
        [
          {
            keyword: "location_code",
            items: locationCode,
          },
          {
            keyword: "taxacode",
            items: taxacode,
          },
          {
            keyword: "lab_id",
            items: lab,
          },
          {
            keyword: "life_stage",
            items: state && state.life_stage ? [state.life_stage] : [],
          },
          {
            keyword: "count",
            items:
              state.min_count && state.max_count
                ? [parseInt(state.min_count), parseInt(state.max_count)]
                : state.min_count && !state.max_count
                ? [parseInt(state.min_count), null]
                : !state.min_count && state.max_count
                ? [null, parseInt(state.max_count)]
                : [],
          },
        ],
        100000
      );

      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "BIORESULT_FETCH_FINISH",
            payload: {
              isFetching: false,
              data: j.items,
            },
          });
        });
    },
  doBioResultFetchByCode:
    () =>
    ({ store, dispatch, apiFetch }) => {
      dispatch({
        type: "BIORESULT_FETCH_BY_CODE_START",
        payload: { isFetching: true },
      });
      let site_code = store.selectRouteParams().project;
      let loc_code = store.selectRouteParams().id;
      if (!site_code && !loc_code) {
        dispatch({
          type: "BIORESULT_FETCH_BY_CODE_ABORT",
          payload: { isFetching: false, shouldFetchByCode: false },
        });
        return;
      }
      let url = "";
      if (loc_code) {
        url = ordsifyUrlBuilder(
          "/bioresults",
          [
            {
              keyword: "location_code",
              items: loc_code ? [loc_code] : "",
            },
          ],
          50000
        );
      } else if (site_code) {
        url = ordsifyUrlBuilder(
          "/bioresults",
          [
            {
              keyword: "site_code",
              items: site_code ? [site_code] : "",
            },
          ],
          50000
        );
      }
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "BIORESULT_FETCH_BY_CODE_FINISH",
            payload: {
              resultsByCode: j.items,
              isFetching: false,
              shouldFetchByCode: false,
            },
          })
        )
        .catch((e) => console.log(e));
    },

  doSortBioresults:
    () =>
    ({ dispatch, store }) => {
      let sortedObjectArray = {};
      store.selectBioResultDataByCode().forEach((sample) => {
        const { biosample_code } = sample;
        if (sortedObjectArray[biosample_code]) {
          sortedObjectArray[biosample_code].push(sample);
        } else {
          sortedObjectArray[biosample_code] = [sample];
        }
      });
      dispatch({
        type: "BIORESULT_SORTED",
        payload: { sorted: sortedObjectArray },
      });
    },

  doUpdateResult:
    (payload, callback) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "BIORESULT_SAVE_STARTED",
        payload: { isSaving: true },
      });
      let apiRoot = store.selectApiRoot();
      store.doActionPutData(
        `${apiRoot}/wq_bioresults/${payload.bioresult_code}` /** should update this to new samples endpoint eventually, but this works */,
        payload,
        () => {
          dispatch({
            type: "BIORESULT_SAVE_FINISHED",
            payload: { isSaving: false },
          });
          store.doBioResultShouldFetch();
          store.doBioResultFetchByCode();
          callback && callback();
        },
        (e) => {
          dispatch({
            type: "BIORESULT_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          });
        }
      );
    },
  doDeleteResult:
    (payload, callback) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "BIORESULT_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      let apiRoot = store.selectApiRoot();
      store.doActionDeleteData(
        `${apiRoot}/wq_bioresults/${payload.bioresult_code}` /** should update this to new samples endpoint eventually, but this works */,
        {},
        () => {
          dispatch({
            type: "BIORESULT_DELETE_FINISHED",
            payload: { isDeleting: false },
          });
          store.doBioResultShouldFetch();
          store.doBioResultFetchByCode();
          callback && callback();
        },
        (e) => {
          dispatch({
            type: "BIORESULT_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          });
        }
      );
    },

  doAddResult:
    (payload, callback) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "BIORESULT_SAVE_STARTED",
        payload: { isSaving: true },
      });
      let apiRoot = store.selectApiRoot();
      store.doActionPostData(
        `${apiRoot}/wq_bioresults/` /** should update this to new samples endpoint eventually, but this works */,
        payload,
        () => {
          dispatch({
            type: "BIORESULT_SAVE_FINISHED",
            payload: { isSaving: false },
          });
          store.doBioResultShouldFetch();
          store.doBioResultFetchByCode();
          callback && callback();
        },
        (e) => {
          dispatch({
            type: "BIORESULT_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          });
        }
      );
    },

  doBulkParseBioresults:
    (parsed) =>
    ({ dispatch, store }) => {
      let fields = Object.keys(parsed[0]);
      dispatch({
        type: "BULK_PARSED_BIORESULTS",
        payload: {
          parsedData: parsed,
          parsedFields: fields,
        },
      });
    },

  doBulkParseClearBioresults:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "BULK_PARSED_BIORESULTS",
        payload: {
          parsedData: null,
          parsedFields: null,
        },
      });
    },
  doBulkImportBioresults:
    (csv, onSuccess, onError) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "BULK_IMPORT_BIORESULTS_STARTED",
        payload: { isImporting: true },
      });
      apiFetch("/wq_bioresults_import/batchload", {
        method: "POST",
        body: csv,
      })
        .then((r) => {
          if (r.ok) {
            dispatch({
              type: "BULK_IMPORT_BIORESULTS_FINISHED",
              payload: {
                status: r.status,
                isImporting: false,
              },
            });
          }
          onSuccess && onSuccess();
        })
        .catch((e) => {
          console.log(e);
          onError && onError(e);
          dispatch({
            type: "BULK_IMPORT_BIORESULTS_ERROR",
            payload: {
              status: 404,
              isImporting: false,
            },
          });
        });
    },

  selectBioResultRaw: (store) => store.bioresult,
  selectBioResultData: (state) => state.bioresult.data,
  selectBioResultSortedData: (state) => state.bioresult.sorted,
  selectBioResultDataByCode: (state) => state.bioresult.resultsByCode,
  selectBioResultIsFetching: (store) => store.bioresult.isFetching,
  selectBioResultShouldFetch: (store) => store.bioresult.shouldFetch,
  selectBioResultBulkParsedData: (state) => state.bioresult.parsedData,
  selectBioResultBulkParsedDataFields: (state) => state.bioresult.parsedFields,

  selectBioResultIsSaving: (state) => state.bioresult.isSaving,
  selectBioResultIsDeleting: (state) => state.bioresult.isDeleting,
  selectBioResultError: (state) => state.bioresult.error,

  reactBioResultShouldFetch: createSelector(
    "selectBioResultIsFetching",
    "selectBioResultShouldFetch",
    (isFetching, shouldFetch) => {
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doBioResultFetch",
          args: [{}],
        };
      }
    }
  ),
  reactBioResultShouldFetchByCode: (state) => {
    if (state.bioresult.isFetching) return null;
    if (state.bioresult.shouldFetchByCode)
      return { actionCreator: "doBioResultFetchByCode" };
  },
};
