import { ordsifyUrlBuilder } from "../utils/ordsify";
import { createSelector } from "redux-bundler";

export default {
  name: "result",
  getReducer: () => {
    const initialData = {
      data: [],
      dataExport: [],
      shouldFetch: false,
      isFetching: false,
      results: [],
      shouldFetchByCode: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "URL_UPDATED":
          return Object.assign({}, state, {
            shouldFetchByCode: true,
            dataExport: [],
          });
        case "RESULTS_FETCH_BY_CODE_START":
        case "RESULTS_FETCH_BY_CODE_FINISH":
        case "RESULTS_FETCH_BY_CODE_ABORT":
        case "RESULT_FETCH_START":
        case "RESULT_FETCH_FINISH":
        case "RESULT_SHOULD_FETCH":
        case "GENERATE_DATA_FINISH":
        case "GENERATE_DATA_START":
        case "CLEAR_DATA_EXPORT":
        case "RESULT_SAVE_STARTED":
        case "RESULT_SAVE_ERROR":
        case "RESULT_DELETE_STARTED":
        case "RESULT_DELETE_ERROR":
        case "BULK_PARSED_RESULTS":
        case "BULK_IMPORT_RESULTS_STARTED":
        case "BULK_IMPORT_RESULTS_FINISHED":
          return Object.assign({}, state, payload);
        case "RESULT_SAVE_FINISHED":
        case "RESULT_DELETE_FINISHED":
          return { ...state, ...payload, shouldFetch: true };
        case "MODAL_CLOSED":
          return { ...state, error: null };
        default:
          return state;
      }
    };
  },

  doResultShouldFetch:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "RESULT_SHOULD_FETCH",
        payload: {
          shouldFetch: true,
        },
      });
    },

  doResultFetchByCode:
    () =>
    ({ store, dispatch, apiFetch }) => {
      dispatch({
        type: "RESULTS_FETCH_BY_CODE_START",
        payload: { isFetching: true },
      });
      let site_code = store.selectRouteParams().project;
      let loc_code = store.selectRouteParams().id;
      if (!site_code && !loc_code) {
        dispatch({
          type: "RESULTS_FETCH_BY_CODE_ABORT",
          payload: { isFetching: false, shouldFetchByCode: false },
        });
        return;
      }
      let url = "";
      if (loc_code) {
        url = ordsifyUrlBuilder(
          "/results",
          [
            {
              keyword: "location_code",
              items: loc_code ? [loc_code] : "",
            },
          ],
          50000
        );
      } else if (site_code) {
        url = ordsifyUrlBuilder(
          "/results",
          [
            {
              keyword: "site_code",
              items: site_code ? [site_code] : "",
            },
          ],
          50000
        );
      }

      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "RESULTS_FETCH_BY_CODE_FINISH",
            payload: {
              results: j.items,
              isFetching: false,
              shouldFetchByCode: false,
            },
          })
        )
        .catch((e) => console.log(e));
    },

  doResultFetch:
    (state, limit = 20000) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "RESULT_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      let url = !state
        ? `/results/?q={"location_code":${
            store.selectRouteParams().id
          }}&limit=20000`
        : ordsifyUrlBuilder(
            "/results",
            [
              {
                keyword: "location_code",
                items: state.location_code
                  ? [state.location_code]
                  : [store.selectRouteParams().id],
              },
              {
                keyword: "sample_time",
                items:
                  state.min_sample_date && state.max_sample_date
                    ? [`${state.min_sample_date}`, `${state.max_sample_date}`]
                    : !state.min_sample_date && state.max_sample_date
                    ? ["1900-01-01T00:00:00Z", `${state.max_sample_date}`]
                    : state.min_sample_date && !state.max_sample_date
                    ? [`${state.min_sample_date}`, "2100-01-01T00:00:00Z"]
                    : [],
              },
              {
                keyword: "value",
                items:
                  state.min_value && state.max_value
                    ? [parseInt(state.min_value), parseInt(state.max_value)]
                    : state.min_value && !state.max_value
                    ? [parseInt(state.min_value), null]
                    : !state.min_value && state.max_value
                    ? [null, parseInt(state.max_value)]
                    : [],
              },
            ],
            limit
          );
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "RESULT_FETCH_FINISH",
            payload: {
              data: j.items,
              isFetching: false,
            },
          })
        )
        .catch((e) => console.log(e));
    },

  /** maybe move this elsewhere but it uses /results for now */
  doGenerateData:
    (state, limit = 20000) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "GENERATE_DATA_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });
      let projects = state.projects && state.projects.map((proj) => proj.value);
      let office = state.office && state.office.map((off) => off.value);
      let cm =
        state.collectionMethod && state.collectionMethod.map((c) => c.value);
      let parameters = state.parameters && state.parameters.map((p) => p.value);
      let locations = Object.keys(state).map((key) => {
        if (parseInt(key) && state[key]) {
          /** key is site_code containing arrays of locations */
          return state[key].map((location) => location.value);
        }
      });
      locations = locations.flat(1).filter((item) => item !== undefined);
      const url = ordsifyUrlBuilder(
        "/results",
        [
          {
            keyword: "site_code",
            items: projects ? projects : [],
          },
          {
            keyword: "location_code",
            items: locations ? locations : [],
          },
          {
            keyword: "storet_num",
            items: parameters ? parameters : [],
          },
          {
            keyword: "sample_depth_range",
            items:
              state.min_sample_depth && state.max_sample_depth
                ? [
                    parseInt(state.min_sample_depth),
                    parseInt(state.max_sample_depth),
                  ]
                : state.min_sample_depth && !state.max_sample_depth
                ? [parseInt(state.min_sample_depth), null]
                : !state.min_sample_depth && state.max_sample_depth
                ? [null, parseInt(state.max_sample_depth)]
                : [],
          },
          {
            keyword: "collect_mthd",
            items: cm ? cm : [],
          },
          {
            keyword: "office_id",
            items: office ? office : [],
          },
          {
            keyword: "sample_time",
            items:
              state.min_sample_date && state.max_sample_date
                ? [`${state.min_sample_date}`, `${state.max_sample_date}`]
                : !state.min_sample_date && state.max_sample_date
                ? ["1900-01-01T00:00:00Z", `${state.max_sample_date}`]
                : state.min_sample_date && !state.max_sample_date
                ? [`${state.min_sample_date}`, "2100-01-01T00:00:00Z"]
                : [],
          },
          {
            keyword: "value",
            items:
              state.min_value && state.max_value
                ? [parseInt(state.min_value), parseInt(state.max_value)]
                : state.min_value && !state.max_value
                ? [parseInt(state.min_value), null]
                : !state.min_value && state.max_value
                ? [null, parseInt(state.max_value)]
                : [],
          },
        ],
        limit
      );

      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "GENERATE_DATA_FINISH",
            payload: {
              dataExport: j.items,
              isFetching: false,
            },
          })
        )
        .catch((e) => console.log(e));
    },
  doClearDataExport:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "CLEAR_DATA_EXPORT",
        payload: {
          dataExport: [],
        },
      });
    },
  doResultPost:
    (state, cb) =>
    ({ dispatch, store }) => {
      let { doActionPostData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "RESULT_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPostData(
        `${apiRoot}/wq_results/`,
        state,
        () => {
          dispatch({
            type: "RESULT_SAVE_FINISHED",
            payload: { isSaving: false },
          });
          cb ? cb() : doModalClose();
        },
        (e) =>
          dispatch({
            type: "RESULT_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doResultPut:
    (state, cb) =>
    ({ dispatch, store }) => {
      let { doActionPutData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "RESULT_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPutData(
        `${apiRoot}/results2/${state.result_code}`,
        state,
        () => {
          dispatch({
            type: "RESULT_SAVE_FINISHED",
            payload: { isSaving: false },
          });
          cb ? cb() : doModalClose();
        },
        (e) =>
          dispatch({
            type: "RESULT_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doResultDelete:
    (state, cb) =>
    ({ dispatch, store }) => {
      let { doActionDeleteData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "RESULT_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      doActionDeleteData(
        `${apiRoot}/results2/${state.result_code}`,
        {},
        () => {
          dispatch({
            type: "RESULT_DELETE_FINISHED",
            payload: { isDeleting: false },
          });
          cb ? cb() : doModalClose();
        },
        (e) =>
          dispatch({
            type: "RESULT_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },

  doBulkParseResults:
    (parsed) =>
    ({ dispatch, store }) => {
      let fields = Object.keys(parsed[0]);
      dispatch({
        type: "BULK_PARSED_RESULTS",
        payload: {
          parsedData: parsed,
          parsedFields: fields,
        },
      });
    },

  doBulkParseClearResults:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "BULK_PARSED_RESULTS",
        payload: {
          parsedData: null,
          parsedFields: null,
        },
      });
    },
  doBulkImportResults:
    (csv, onSuccess, onError) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "BULK_IMPORT_RESULTS_STARTED",
        payload: { isImporting: true },
      });
      apiFetch("/wq_results_import/batchload", {
        method: "POST",
        body: csv,
      })
        .then((r) => {
          if (r.ok) {
            dispatch({
              type: "BULK_IMPORT_RESULTS_FINISHED",
              payload: {
                status: r.status,
                isImporting: false,
              },
            });
          }
          onSuccess && onSuccess();
        })
        .catch((e) => {
          console.log(e);
          onError && onError(e);
          dispatch({
            type: "BULK_IMPORT_RESULTS_ERROR",
            payload: {
              status: 404,
              isImporting: false,
            },
          });
        });
    },

  selectDataExport: (state) => state.result.dataExport,
  selectResultsByCode: (state) =>
    state.result.results.filter((result) => result.value !== null),
  selectResultRaw: (state) => state.result,
  selectResultIsFetching: (store) => store.result.isFetching,

  selectResultIsSaving: (store) => store.result.isSaving,
  selectResultIsDeleting: (store) => store.result.isDeleting,
  selectResultError: (state) => state.result.error,

  selectResultBulkParsedData: (state) => state.result.parsedData,
  selectResultBulkParsedDataFields: (state) => state.result.parsedFields,
  selectResultData: createSelector(
    "selectRouteParams",
    "selectResultRaw",
    (routeParams, result) => {
      if (routeParams.id && routeParams.storet && routeParams.depth) {
        return result.data.filter(
          (d) =>
            String(d.location_code) === routeParams.id &&
            String(d.storet_num) === routeParams.storet &&
            String(d.sample_depth) === routeParams.depth
        );
      } else if (routeParams.id) {
        return result.data.filter(
          (d) => String(d.location_code) === routeParams.id
        );
      } else {
        return result.data;
      }
    }
  ),
  reactResultShouldFetch: (state) => {
    // never fetch if another fetch is in progress
    if (state.result.isFetching) return null;
    if (state.result.shouldFetch)
      return {
        actionCreator: "doResultFetch",
      };
  },

  reactResultShouldFetchByCode: (state) => {
    if (state.result.isFetching) return null;
    if (state.result.shouldFetchByCode)
      return { actionCreator: "doResultFetchByCode" };
  },
};
