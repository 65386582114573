import { sortBy } from "lodash";
import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "accessRequest",
  getReducer: () => {
    const initialState = {
      data: [],
      allRequests: [],
      isFetching: false,
      shouldFetch: false,
      isPosting: false,
      isDeleting: false,
    };

    return (state = initialState, { payload, type }) => {
      switch (type) {
        case "ACCESS_REQUEST_FETCH_STARTED":
        case "ACCESS_REQUEST_FETCH_FINISHED":
        case "ACCESS_REQUEST_FETCH_ERROR":
        case "ACCESS_REQUEST_SHOULD_FETCH":
        case "ACCESS_REQUEST_DELETE_STARTED":
        case "ACCESS_REQUEST_DELETE_FINISHED":
        case "ACCESS_REQUEST_DELETE_ERROR":
        case "ACCESS_REQUEST_SAVE_STARTED":
        case "ACCESS_REQUEST_SAVE_FINISHED":
        case "ACCESS_REQUEST_SAVE_ERROR":
        case "ACCESS_REQUEST_ALL_FETCH_STARTED":
        case "ACCESS_REQUEST_ALL_FETCH_FINISHED":
        case "ACCESS_REQUEST_ALL_FETCH_ERROR":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doAccessRequestShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "ACCESS_REQUEST_SHOULD_FETCH",
        payload: { shouldFetch: true },
      });
    },

  doAccessRequestFetchAll:
    () =>
    ({ dispatch, apiFetch, store }) => {
      dispatch({
        type: "ACCESS_REQUEST_ALL_FETCH_STARTED",
        payload: { isFetching: true, shouldFetch: false },
      });
      let url = "/wq_access_requests/";
      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "ACCESS_REQUEST_ALL_FETCH_FINISHED",
            payload: { isFetching: false, allRequests: j.items },
          });
        })
        .catch((err) => {
          dispatch({
            type: "ACCESS_REQUEST_ALL_FETCH_ERROR",
            payload: { isFetching: false, error: err },
          });
        });
    },

  doAccessRequestFetchByUser:
    () =>
    ({ dispatch, apiFetch, store }) => {
      const keycloakId = store.selectTokenKeyCloakId();
      dispatch({
        type: "ACCESS_REQUEST_FETCH_STARTED",
        payload: { isFetching: true, shouldFetch: false },
      });
      let url = ordsifyUrlBuilder(
        "/wq_access_requests/",
        [
          {
            keyword: "keycloak_id",
            items: [keycloakId],
          },
        ],
        10000
      );
      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "ACCESS_REQUEST_FETCH_FINISHED",
            payload: { isFetching: false, data: j.items },
          });
        })
        .catch((err) => {
          dispatch({
            type: "ACCESS_REQUEST_FETCH_ERROR",
            payload: { isFetching: false, error: err },
          });
        });
    },

  doPostAccessRequest:
    (data, cb) =>
    ({ dispatch, store }) => {
      let apiRoot = store.selectApiRoot();
      let { doActionPostData, doCwmsSeqNewFetch } = store;
      dispatch({
        type: "ACCESS_REQUEST_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doCwmsSeqNewFetch().then((res) => {
        let code = res.items[0].seq;
        doActionPostData(
          `${apiRoot}/wq_access_requests_t/`,
          { ...data, request_code: code, create_date: new Date() },
          () => {
            dispatch({
              type: "ACCESS_REQUEST_SAVE_FINISHED",
              payload: { isSaving: false, shouldFetch: true },
            });
            cb && cb();
          },
          (e) =>
            dispatch({
              type: "ACCESS_REQUEST_SAVE_ERROR",
              payload: { isSaving: false, error: e },
            })
        );
      });
    },
  doPutAccessRequest:
    (data, cb) =>
    ({ dispatch, store }) => {
      let apiRoot = store.selectApiRoot();
      let { doActionPutData } = store;
      dispatch({
        type: "ACCESS_REQUEST_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPutData(
        `${apiRoot}/wq_access_requests_t/${data.request_code}`,
        data,
        () => {
          dispatch({
            type: "ACCESS_REQUEST_SAVE_FINISHED",
            payload: { isSaving: false, shouldFetch: true },
          });
          cb && cb();
        },
        (e) =>
          dispatch({
            type: "ACCESS_REQUEST_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },
  doDeleteAccessRequest:
    (code, cb) =>
    ({ dispatch, store }) => {
      let apiRoot = store.selectApiRoot();
      let { doActionDeleteData } = store;
      dispatch({
        type: "ACCESS_REQUEST_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      doActionDeleteData(
        `${apiRoot}/wq_access_requests_t/${code}`,
        {},
        () => {
          dispatch({
            type: "ACCESS_REQUEST_DELETE_FINISHED",
            payload: { isDeleting: false },
          });
          cb && cb();
        },
        (e) =>
          dispatch({
            type: "ACCESS_REQUEST_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },

  // NEED to write a cusotm sql endpoint that process multiple rows...

  // doGrantReadOnly: (user) => ({dispatch, store }) =>  {
  //   let allRoles = store.selectUserRoleAll();
  //   let usersRoles = allRoles.filter(r => r.keycloak_id === user.keycloak_id);
  //   let restOffices = store.selectOfficeData().filter(o => !usersRoles.includes(o.office_id));
  //   store.doCwmsSeqNewFetch().then((res) => {
  //     let code = res.items[0].seq;
  //     restO
  //   })
  //   // need to get all office taht user is not a role of...
  // },

  //onGrantAccess -> if user.roles == 0 THEN set doPutUser primary_office to request.office)id ALSO insert read only role for all other orgs..?
  doGrantAccess:
    (request, accessType) =>
    ({ dispatch, store }) => {
      const {
        selectUsersAll,
        doPostUserRole,
        doPutUser,
        doDeleteAccessRequest,
      } = store;
      /* Create user_role object */
      let roleObj = {
        keycloak_id: request.keycloak_id,
        office_id: request.office_id,
        role: accessType.toUpperCase(),
      };
      /** Get user object of user requesting access  */
      let currentUser = selectUsersAll().filter(
        (u) => u.keycloak_id === request.keycloak_id
      )[0];

      /** check if user has primary office */
      let userHasPrimaryOffice = !!currentUser.primary_office;
      doPostUserRole(roleObj, () => {
        /** if no primary office, assign them this one. and delete request */
        if (!userHasPrimaryOffice)
          doPutUser({
            ...currentUser,
            primary_office: request.office_id,
          });
        doDeleteAccessRequest(
          request.request_code,
          store.doAccessRequestFetchAll
        );
      });
    },

  selectAccessRequestShouldFetch: (state) => state.accessRequest.shouldFetch,
  selectAccessRequestIsFetching: (state) => state.accessRequest.isFetching,
  selectAccessRequestByUser: (state) => state.accessRequest.data,
  selectAccessRequestAll: (state) => state.accessRequest.allRequests,
  selectAccessRequestIsDeleting: (state) => state.accessRequest.isDeleting,
  selectAccessRequestIsSaving: (state) => state.accessRequest.isSaving,
  selectAccessRequestError: (state) => state.accessRequest.error,

  reactAccessRequestShouldFetch: createSelector(
    "selectAccessRequestShouldFetch",
    "selectAccessRequestIsFetching",
    (shouldFetch, isFetching) => {
      if (isFetching) return null;
      if (shouldFetch) {
        return {
          actionCreator: "doAccessRequestFetchByUser",
        };
      }
    }
  ),
};
