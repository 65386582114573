import { createSelector } from "redux-bundler";
import { stringifyDate, ordsifyUrlBuilderNoSlash } from "../utils/ordsify";

export default {
  name: "hitlist",
  // reducers
  getReducer: () => {
    const initialData = {
      data: [],
      config: [],
      shouldFetch: false,
      done: false,
    };
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "REPORT_HITLIST_STARTED":
        case "REPORT_HITLIST_FINISHED":
        case "REPORT_HITLIST_CONFIG_STARTED":
        case "REPORT_HITLIST_CONFIG_FINISHED":
        case "REPORT_HITLIST_CONFIG_SHOULD_FETCH":
        case "REPORT_HITLIST_CONFIG_FETCH_STARTED":
        case "REPORT_HITLIST_CONFIG_FETCH_FINISHED":
        case "REPORT_HITLIST_LOAD_STARTED":
        case "REPORT_HITLIST_LOAD_FINISHED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },
  // action creators
  doReportHitlistGenerate:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      const { min_sample_date: min_date, max_sample_date: max_date } = state;
      let parameter = state.parameters && state.parameters.value;
      let projectArray = state.projects.map((proj) => proj.value);
      dispatch({ type: "REPORT_HITLIST_STARTED" });
      const url = ordsifyUrlBuilderNoSlash(
        `/reports/hitlist/${parameter}/${stringifyDate(
          min_date
        )}/${stringifyDate(max_date)}`,
        [
          {
            keyword: "site_code",
            items: projectArray ? projectArray : "", // if no location_codes passed, fetch all
          },
        ],
        500
      );

      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "REPORT_HITLIST_FINISHED",
            payload: {
              data: j.items,
              done: true,
            },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },
  doReportHitlistPostConfig:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({ type: "REPORT_HITLIST_CONFIG_STARTED" });
      const id = Math.floor(Math.random() * 100000);
      const cacUid = parseInt(store.selectAuthTokenPayload().sub);
      const projects = store.selectProjectsActive();
      let projectNames = [];
      if (projects[0].hasOwnProperty("site_name")) {
        projectNames = [
          ...new Set(projects.map((item) => item.site_name)),
        ].toString();
      } else {
        projectNames = [
          ...new Set(projects.map((item) => item.label)),
        ].toString();
      }
      const data = {
        project_codes: store.selectProjectsActiveArray().toString(),
        project_names: projectNames,
        min_date: stringifyDate(store.selectSampleDateActiveMin()),
        max_date: stringifyDate(store.selectSampleDateActiveMax()),
        storet_num: store.selectStoretActive(),
        cac_uid: cacUid,
        id: id,
      };
      apiFetch("/reports/config", {
        method: "POST",
        headers: {
          Cac_uid: cacUid,
          Id: id,
          Report_type: "hitlist",
        },
        body: JSON.stringify(data),
      }).then((r) => {
        dispatch({ type: "REPORT_HITLIST_CONFIG_FINISHED" });
      });
    },
  doReportHitlistShouldFetchConfig:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "REPORT_HITLIST_CONFIG_SHOULD_FETCH",
        payload: {
          shouldFetch: true,
        },
      });
    },
  doReportHitlistGetConfig:
    () =>
    ({ dispatch, store, apiFetch }) => {
      const cacUid = parseInt(store.selectAuthTokenPayload().sub);
      dispatch({
        type: "REPORT_HITLIST_CONFIG_FETCH_STARTED",
        payload: { shouldFetch: false },
      });
      apiFetch(`/reports/config/${cacUid}?q={"report_type":"hitlist"}`)
        .then((response) => response.json())
        .then((r) =>
          dispatch({
            type: "REPORT_HITLIST_CONFIG_FETCH_FINISHED",
            payload: {
              config: r.items,
            },
          })
        );
      store.doReportSetFinished(false);
    },
  doReportHitlistLoadConfig:
    (rowData) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "REPORT_HITLIST_LOAD_STARTED",
      });
      store.doReportSetFinished(true);
      // get project data
      // Need to wait for locations to get back to get activeLocationCode
      const projectData = store.selectProjectAll();
      const projectCodes = rowData.project_codes.split(",");
      const storetCode = rowData.storet_num;
      let activeProject = [];
      projectCodes.forEach((x) => {
        activeProject.push(
          projectData.find((y) => parseInt(x) === y.site_code)
        );
      });
      // FETCH SAMPLE
      // I dont even know if we need this anymroe
      // store.doSampleFetch(locationCodes)
      // PROJECT ACTIVE
      store.doProjectsActive(activeProject);
      // STORET ACTIVE
      store.doStoretActive(storetCode);
      // SAMPLES ACTIVE
      store.doSampleDateActiveMin(rowData.min_date);
      store.doSampleDateActiveMax(rowData.max_date);
      dispatch({
        type: "REPORT_HITLIST_LOAD_FINISHED",
      });
    },

  selectReportHitlistData: (state) => state.hitlist.data,
  selectReportHitlistDone: (state) => state.hitlist.done,
  selectReportHitlistConfig: (state) => state.hitlist.config,
  selectReportHitlistConfigShouldFetch: (state) => state.hitlist.shouldFetch,
  selectReportHitlistConfigJson: createSelector(
    "selectReportHitlistConfig",
    "selectStoretData",
    (reportHitlistConfig, storetData) => {
      let data = [];
      if (reportHitlistConfig.length) {
        reportHitlistConfig.forEach((x) => {
          data.push(JSON.parse(x.configuration));
        });
        data.map((x) => {
          x.storet_name = storetData.find(
            (y) => y.storet_num === x.storet_num
          ).short_name;
        });
      }

      return data;
    }
  ),
  reactReportHitlistConfigFetch: createSelector(
    "selectReportHitlistConfigShouldFetch",
    (shouldFetch) => {
      if (shouldFetch)
        return {
          actionCreator: "doReportHitlistGetConfig",
        };
    }
  ),
};
