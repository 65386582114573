import { ordsifyUrlBuilder } from "../utils/ordsify";
import { createSelector } from "redux-bundler";

export default {
  name: "resultswloc",
  getReducer: () => {
    const initialData = {
      data: [],
      shouldFetch: false,
      isFetching: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "RESULTSWLOC_FETCH_START":
        case "RESULTSWLOC_FETCH_FINISH":
        case "RESULTSWLOC_SHOULD_FETCH":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doResultLocShouldFetch:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "RESULTSWLOC_SHOULD_FETCH",
        payload: {
          shouldFetch: true,
        },
      });
    },

  doResultLocFetch:
    (location_code, storet_nums, dates, limit = 500) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "RESULTSWLOC_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      //const url = "/results/?limit=500";
      const url = ordsifyUrlBuilder(
        "/results_w_loc",
        [
          {
            keyword: "site_code",
            items: store.selectRouteParams().project
              ? [store.selectRouteParams().project]
              : [],
          },
          // {
          //     keyword: "location_code",
          //     items: store.selectLocationSelected().length
          //         ? [store.selectLocationSelected()[0].location_code]
          //         : [],
          // },
          {
            keyword: "storet_num",
            items: storet_nums
              ? storet_nums
              : store.selectRouteParams().storet
              ? [store.selectRouteParams().storet]
              : [],
          },
          {
            keyword: "sample_time",
            items: dates ? dates : [],
          },
        ],
        limit
      );
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "RESULTSWLOC_FETCH_FINISH",
            payload: {
              data: j.items,
              isFetching: false,
            },
          })
        )
        .catch((e) => console.log(e));
    },

  selectResultsLocRaw: (state) => state.resultswloc,
  selectResultsLocIsFetching: (store) => store.resultswloc.isFetching,

  selectResultsLocData: createSelector(
    "selectRouteParams",
    "selectResultsLocRaw",
    (routeParams, resultswloc) => {
      if (routeParams.id && routeParams.storet && routeParams.depth) {
        return resultswloc.data.filter(
          (d) =>
            String(d.location_code) === routeParams.id &&
            String(d.storet_num) === routeParams.storet &&
            String(d.sample_depth) === routeParams.depth
        );
      } else if (routeParams.id) {
        return resultswloc.data.filter(
          (d) => String(d.location_code) === routeParams.id
        );
      } else {
        return resultswloc.data;
      }
    }
  ),
};
