import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilderNoSlash, ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "sample",
  getReducer: () => {
    const initialData = {
      data: [],
      shouldFetch: false,
      isFetching: false,
      samplesData: [],
      sampleDepthActiveMin: null,
      sampleDepthActiveMax: null,
      sampleDateActiveMin: new Date("January 1, 1970"),
      sampleDateActiveMax: new Date(),
      sampleDepthActive: [],
      samplesByLocation: [],
      sampleByFieldVisit: [],
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "SAMPLE_FETCH_START":
        case "SAMPLE_FETCH_FINISH":
        case "SAMPLES_FETCH_BY_FIELD_VISIT_START":
        case "SAMPLES_FETCH_BY_FIELD_VISIT_FINISH":
        case "SAMPLE_SHOULD_FETCH":
        case "SAMPLE_DEPTH_ACTIVE_UPDATE":
        case "SAMPLE_DATE_ACTIVE_UPDATE":
        case "SAMPLE_FETCH_BY_FIELD_VISIT_ERROR":
        case "SAMPLE_SAVE_STARTED":
        case "SAMPLE_SAVE_ERROR":
        case "SAMPLE_DELETE_STARTED":
        case "SAMPLE_DELETE_ERROR":
        case "SAMPLE_BY_LOCATION_FETCH_FINISH":
        case "SAMPLE_BY_LOCATION_FETCH_START":
          return Object.assign({}, state, payload);
        case "RESULT_SAVE_FINISHED":
        case "RESULT_DELETE_FINISHED":
        case "SAMPLE_DELETE_FINISHED":
        case "SAMPLE_SAVE_FINISHED":
          return { ...state, ...payload, shouldFetchByLocation: true };
        case "MODAL_CLOSED":
          return { ...state, error: null };
        default:
          return state;
      }
    };
  },

  doSampleShouldFetch:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "SAMPLE_SHOULD_FETCH",
        payload: {
          shouldFetch: true,
        },
      });
    },

  doSampleFetch:
    (location_codes) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "SAMPLE_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      const url = ordsifyUrlBuilderNoSlash(
        "/samples_all/all",
        [
          {
            keyword: "location_code",
            items: location_codes ? location_codes : "", // if no location_codes passed, fetch all
          },
        ],
        1000
      );

      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "SAMPLE_FETCH_FINISH",
            payload: {
              isFetching: false,
              data: j.items.concat(store.selectSampleRaw().data),
              samplesData: j.items,
            },
          });
        });
    },
  doSamplesFetchByLocation:
    (state, limit = 20000) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "SAMPLE_BY_LOCATION_FETCH_START",
        payload: {
          shouldFetchByLocation: false,
          isFetching: true,
        },
      });
      let url = `/samples/?q={"location_code":${
        store.selectRouteParams().id
      }}&limit=20000`;
      if (state) {
        let cm =
          state.collectionMethod && state.collectionMethod.map((c) => c.value);
        let parameters =
          state.parameters && state.parameters.map((p) => p.value);
        let field_visit_code =
          state.field_visit_code && state.field_visit_code.map((f) => f.value);
        url = ordsifyUrlBuilderNoSlash(
          "/samples/",
          [
            {
              keyword: "location_code",
              items: [store.selectRouteParams().id],
            },
            {
              keyword: "field_visit_code",
              items: field_visit_code ? field_visit_code : [],
            },
            {
              keyword: "sample_num",
              items: state.sample_num ? [state.sample_num] : [],
            },
            {
              keyword: "sample_depth_range",
              items:
                state.min_sample_depth && state.max_sample_depth
                  ? [
                      parseInt(state.min_sample_depth),
                      parseInt(state.max_sample_depth),
                    ]
                  : state.min_sample_depth && !state.max_sample_depth
                  ? [parseInt(state.min_sample_depth), null]
                  : !state.min_sample_depth && state.max_sample_depth
                  ? [null, parseInt(state.max_sample_depth)]
                  : [],
            },
            {
              keyword: "collect_mthd",
              items: cm ? cm : [],
            },
            {
              keyword: "sample_time",
              items:
                state.min_sample_date && state.max_sample_date
                  ? [`${state.min_sample_date}`, `${state.max_sample_date}`]
                  : !state.min_sample_date && state.max_sample_date
                  ? ["1900-01-01T00:00:00Z", `${state.max_sample_date}`]
                  : state.min_sample_date && !state.max_sample_date
                  ? [`${state.min_sample_date}`, "2100-01-01T00:00:00Z"]
                  : [],
            },
            {
              keyword: "storet_num",
              items: parameters ? parameters : [],
            },
            {
              keyword: "value",
              items:
                state.min_value && state.max_value
                  ? [parseFloat(state.min_value), parseFloat(state.max_value)]
                  : state.min_value && !state.max_value
                  ? [parseFloat(state.min_value), null]
                  : !state.min_value && state.max_value
                  ? [null, parseFloat(state.max_value)]
                  : [],
            },
          ],
          limit
        );
      }
      apiFetch(url)
        .then((res) => res.json())
        .then((j) => {
          dispatch({
            type: "SAMPLE_BY_LOCATION_FETCH_FINISH",
            payload: {
              isFetching: false,
              samplesByLocation: j.items,
            },
          });
        });
    },
  doSampleFetchByCode:
    (sample_code) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "SAMPLE_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      const url = ordsifyUrlBuilder(
        "/wq_samples/",
        [
          {
            keyword: "sample_code",
            items: sample_code ? [sample_code] : "",
          },
        ],
        1000
      );

      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "SAMPLE_FETCH_FINISH",
            payload: {
              isFetching: false,
              sampleSelected: j.items ? j.items[0] : [],
            },
          });
        });
    },
  doSampleDepthActiveMin:
    (depth) =>
    ({ dispatch, store }) => {
      if (depth && depth.hasOwnProperty("value")) {
        dispatch({
          type: "SAMPLE_DEPTH_ACTIVE_UPDATE",
          payload: {
            sampleDepthActiveMin: depth.value,
          },
        });
      } else {
        dispatch({
          type: "SAMPLE_DEPTH_ACTIVE_UPDATE",
          payload: {
            sampleDepthActiveMin: depth,
          },
        });
      }
    },
  doSampleDepthActiveMax:
    (depth) =>
    ({ dispatch, store }) => {
      if (depth && depth.hasOwnProperty("value")) {
        dispatch({
          type: "SAMPLE_DEPTH_ACTIVE_UPDATE",
          payload: {
            sampleDepthActiveMax: depth.value,
          },
        });
      } else {
        dispatch({
          type: "SAMPLE_DEPTH_ACTIVE_UPDATE",
          payload: {
            sampleDepthActiveMax: depth,
          },
        });
      }
    },
  doSampleDepthActive:
    (depth) =>
    ({ dispatch, store }) => {
      if (depth && depth.hasOwnProperty("value")) {
        dispatch({
          type: "SAMPLE_DEPTH_ACTIVE_UPDATE",
          payload: {
            sampleDepthActive: depth.value,
          },
        });
      } else {
        dispatch({
          type: "SAMPLE_DEPTH_ACTIVE_UPDATE",
          payload: {
            sampleDepthActive: depth,
          },
        });
      }
    },
  doSampleDateActiveMin:
    (date) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "SAMPLE_DATE_ACTIVE_UPDATE",
        payload: {
          sampleDateActiveMin: date,
        },
      });
    },
  doSampleDateActiveMax:
    (date) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "SAMPLE_DATE_ACTIVE_UPDATE",
        payload: {
          sampleDateActiveMax: date,
        },
      });
    },

  doSamplesFetchByFieldVisit:
    (fieldVisit) =>
    ({ dispatch, apiFetch, store }) => {
      if (!fieldVisit) {
        dispatch({
          type: "SAMPLE_FETCH_BY_FIELD_VISIT_ERROR",
          payload: { isFetching: false, sampleByFieldVisit: [] },
        });
      } else {
        dispatch({
          type: "SAMPLES_FETCH_BY_FIELD_VISIT_START",
          payload: {
            isFetching: true,
          },
        });

        const url = ordsifyUrlBuilder(
          "/wq_samples/",
          [
            {
              keyword: "field_visit_code",
              items: fieldVisit ? [fieldVisit] : "",
            },
          ],
          1000
        );
        apiFetch(url)
          .then((r) => r.json())
          .then((j) => {
            dispatch({
              type: "SAMPLES_FETCH_BY_FIELD_VISIT_FINISH",
              payload: {
                isFetching: false,
                sampleByFieldVisit: j.items ? j.items : [],
              },
            });
          });
      }
    },

  doSamplePost:
    (state, cb) =>
    ({ dispatch, store }) => {
      let { doActionPostData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "SAMPLE_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPostData(
        `${apiRoot}/wq_samples/`,
        state,
        () => {
          dispatch({
            type: "SAMPLE_SAVE_FINISHED",
            payload: { isSaving: false },
          });
          cb ? cb() : doModalClose();
        },
        (e) =>
          dispatch({
            type: "SAMPLE_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doSamplePut:
    (state, cb) =>
    ({ dispatch, store }) => {
      let { doActionPutData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "SAMPLE_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPutData(
        `${apiRoot}/wq_samples/${state.sample_code}`,
        state,
        () => {
          dispatch({
            type: "SAMPLE_SAVE_FINISHED",
            payload: { isSaving: false },
          });
          cb ? cb() : doModalClose();
        },
        (e) =>
          dispatch({
            type: "SAMPLE_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doSampleDelete:
    (state, cb) =>
    ({ dispatch, store }) => {
      let { doActionDeleteData, selectApiRoot, doModalClose } = store;
      let apiRoot = selectApiRoot();
      dispatch({
        type: "SAMPLE_DELETE_STARTED",
        payload: { isDeleting: true },
      });
      doActionDeleteData(
        `${apiRoot}/wq_samples/${state.sample_code}`,
        {},
        () => {
          dispatch({
            type: "SAMPLE_DELETE_FINISHED",
            payload: { isDeleting: false },
          });
          cb ? cb() : doModalClose();
        },
        (e) =>
          dispatch({
            type: "SAMPLE_DELETE_ERROR",
            payload: { isDeleting: false, error: e },
          })
      );
    },

  selectSamplesByFieldVisit: (state) => state.sample.sampleByFieldVisit,
  selectSampleByProject: (store) => store.sample.samplesData,
  selectSampleSelected: (store) => store.sample.sampleSelected,
  selectSampleDepthActiveMin: (store) => store.sample.sampleDepthActiveMin,
  selectSampleDepthActiveMax: (store) => store.sample.sampleDepthActiveMax,
  selectSampleDepthActive: (store) => store.sample.sampleDepthActive,
  selectSampleDateActiveMin: (store) => store.sample.sampleDateActiveMin,
  selectSampleDateActiveMax: (store) => store.sample.sampleDateActiveMax,
  selectSampleRaw: (store) => store.sample,
  selectSampleIsSaving: (store) => store.sample.isSaving,
  selectSampleIsDeleting: (store) => store.sample.isDeleting,
  selectSampleError: (state) => state.sample.error,

  selectSampleData: createSelector(
    "selectRouteParams",
    "selectSampleRaw",
    (routeParams, sample) => {
      if (routeParams.id) {
        return sample.data.filter(
          (d) => String(d.location_code) === routeParams.id
        );
      } else {
        return sample.data;
      }
    }
  ),
  selectSampleIsFetching: (store) => store.sample.isFetching,
  selectSampleShouldFetch: (store) => store.sample.shouldFetch,
  selectSampleShouldFetchByLocation: (store) =>
    store.sample.shouldFetchByLocation,
  selectSampleByProjectUnique: createSelector(
    "selectSampleByProject",
    (sampleByProject) => {
      let unique;
      if (sampleByProject.length) {
        unique = [
          ...new Set(sampleByProject.map((item) => item.sample_depth)),
        ].sort((a, b) => {
          return a - b;
        });
      }
      return unique && unique.length > 0
        ? unique.map((item) => ({ label: item, value: item }))
        : unique;
    }
  ),

  selectSamplesByLocation: (state) => state.sample.samplesByLocation,
  reactSampleShouldFetch: createSelector(
    "selectSampleIsFetching",
    "selectSampleShouldFetch",
    (isFetching, shouldFetch) => {
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doSampleFetch",
        };
      }
    }
  ),
  reactSampleShouldFetchByLocation: createSelector(
    "selectSampleIsFetching",
    "selectSampleShouldFetchByLocation",
    (isFetching, shouldFetchByLocation) => {
      if (isFetching) {
        return null;
      }
      if (shouldFetchByLocation) {
        return {
          actionCreator: "doSamplesFetchByLocation",
        };
      }
    }
  ),
};
