import React from "react";
import { connect } from "redux-bundler-react";
import { TableContainer } from "../../../app-components/page-content";
import FilterSidebar from "../../dataExport/FilterSidebar";
import Report from "./Report";
import stationsConfig from "./stations.config.json";

const Stations = ({ reportStationData, ...props }) => {
  const generateCSV = () => {
    const csvString = [
      [
        "Location ID",
        "Location Type",
        "County",
        "State",
        "Stream Name",
        "Stream Mile",
        "Latitude",
        "Longitude",
        "USGS Quadrangle",
      ],
      ...reportStationData.map((item) => [
        item.loc_id,
        item.location_type_id,
        item.cnty_code,
        item.state_name,
        item.stream_name,
        item.stream_mile,
        item.latitude,
        item.longitude,
        item.quad_code,
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");
    const csvFile = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvFile);
    window.open(url);
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      <FilterSidebar
        config={stationsConfig}
        generateText="Generate Report"
        {...props}
      />
      <TableContainer>
        <h3
          className="card-title border-bottom w-100 d-flex justify-content-between align-items-center"
          style={{ paddingTop: 24 }}
        >
          Station Descriptions Report
          <button
            onClick={generateCSV}
            disabled={!reportStationData.length}
            className="btn btn-info"
          >
            Export to CSV
          </button>
        </h3>
        <Report />
      </TableContainer>
    </div>
  );
};
export default connect(
  ...stationsConfig.selectors,
  ...stationsConfig.fetchFns,
  "selectReportStationData",
  Stations
);
