import React, { useState } from "react";
import Link from "../misc/link";
import "./dropdown.css";

const LinkItem = ({ icon, name, url, onClick }) => {
  return (
    <Link style={{ color: "black" }} to={url} onClick={onClick}>
      <div className="graph-item d-flex justify-content-between">
        <i className={`mdi mdi-${icon} text-primary`} />
        <p className="m-0 pl-2">{name}</p>
      </div>
    </Link>
  );
};

const GraphLinkDropdown = ({ rowData, charts }) => {
  return (
    <div className="dropdown-wrapper d-flex align-items-center">
      {rowData.sample_depth}{" "}
      <i className="mdi mdi-message-draw text-primary mdi-24px pl-2" />
      <div className="graph-dropdown" style={{ right: -30 }}>
        {charts.map((c) => {
          return (
            <div className="p-1 border-bottom w-100">
              <LinkItem {...c} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GraphLinkDropdown;
