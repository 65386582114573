import React from "react";
import { connect } from "redux-bundler-react";
import CustomAvatar from "../fieldTrips/CustomAvatar";
import { stringAvatar } from "../fieldTrips/helpers";
import ReactSelect from "react-select";
import { useState } from "react";
import { useEffect } from "react";
import { find } from "lodash";

const ManageUsers = ({
  userRoleAll,
  officeData,
  doOfficeFetch,
  doUserRoleFetchAll,
  doPutUserRole,
  doDeleteUserRole,
  userRoleData,
  usersAll: users,
  userRoleIsFetching,
  userRoleIsSaving,
  userRoleIsDeleting,
  doPostUserRole,
  userIsSysAdmin,
  doUserFetchAll,
}) => {
  const [office, setOffice] = useState({
    value: "SYS",
    label: "System Admins",
  });
  const [newUserRole, setNewUserRole] = useState();

  useEffect(doUserFetchAll, [newUserRole]);

  useEffect(() => {
    doOfficeFetch();
    doUserRoleFetchAll();
  }, []);

  let usersAll = !office
    ? userRoleAll
    : userRoleAll.filter((u) => u.office_id === office.value);

  let officeOptions = userIsSysAdmin
    ? [
        ...officeData.map((o) => ({
          value: o.office_id,
          label: o.office_id,
        })),
        { value: "SYS", label: "System Admins" },
      ]
    : officeData.map((o) => ({
        value: o.office_id,
        label: o.office_id,
      }));

  let usersNotInOffice = users.filter(
    (usr) => !find(usersAll, (obj) => usr.keycloak_id === obj.keycloak_id)
  );

  return (
    <div className="custom-table p-3">
      <div className="d-flex border-bottom w-100 justify-content-between align-items-center">
        <div>
          <h4>Manage Users</h4>
          <h6 className="text-muted ">
            View and manage users and their roles.
          </h6>
        </div>
        <div style={{ width: 300 }}>
          <ReactSelect
            name="office_id"
            options={officeOptions}
            onChange={setOffice}
            className="w-auto"
            value={office}
            placeholder="Select an office"
          />
        </div>
        <div className="d-flex" style={{ width: 300 }}>
          <ReactSelect
            name="office_id"
            options={usersNotInOffice.map((u) => ({
              value: u.keycloak_id,
              label: u.name,
            }))}
            onChange={setNewUserRole}
            className="w-100"
            value={newUserRole}
            placeholder="Add User to Office"
          />
          <button
            className="btn btn-sm btn-outline-success w-50"
            onClick={() =>
              doPostUserRole(
                {
                  keycloak_id: newUserRole.value,
                  office_id: office.value,
                  role: office.value === "SYS" ? "ADMIN" : "MEMBER",
                },
                doUserRoleFetchAll
              )
            }
          >
            {userRoleIsSaving ? (
              <i className="mdi mdi-loading mdi-18px mdi-spin" />
            ) : (
              <>
                <i className="mdi mdi-plus-circle mdi-14px mr-2" />
                Add User
              </>
            )}
          </button>
        </div>
      </div>
      {userRoleIsFetching ? (
        <i className="mdi mdi-loading mdi-48px mdi-spin" />
      ) : usersAll.length > 0 ? (
        usersAll.map((user) => (
          <div
            className="border-bottom w-100 p-2 mt-2"
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
          >
            <div className="d-flex">
              <CustomAvatar
                key={user.name}
                size={48}
                disabled
                {...stringAvatar(user.name, 48)}
              />
              <div className="d-flex flex-column ml-2">
                <p style={{ fontSize: 14 }} className="mb-1">
                  <b>{user.name}</b> <small>{user.role.split(".")[0]}</small>
                </p>
                <p className="text-muted">{user.email}</p>
              </div>
            </div>
            <div className="d-flex flex-column w-50 justify-content-center align-items-end">
              {user.role !== "SYS.ADMIN" && (
                <ReactSelect
                  className="w-100"
                  name="role"
                  options={[
                    { value: "ADMIN", label: "ADMIN" },
                    { value: "MEMBER", label: "MEMBER" },
                    { value: "READ_ONLY", label: "READ ONLY" },
                  ]}
                  placeholder="Select Role"
                  value={{
                    value: user.role_type,
                    label: user.role_type,
                  }}
                  isLoading={userRoleIsSaving}
                  onChange={(obj) =>
                    doPutUserRole(
                      {
                        keycloak_id: user.keycloak_id,
                        role_code: user.role_code,
                        office_id: user.office_id,
                        role: obj.value,
                      },
                      doUserRoleFetchAll
                    )
                  }
                />
              )}
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <button
                className="btn btn-sm btn-outline-danger"
                style={{ height: 36 }}
                onClick={() =>
                  doDeleteUserRole(user.role_code, doUserRoleFetchAll)
                }
              >
                {userRoleIsDeleting ? (
                  <i className="mdi mdi-loading mdi-18px mdi-spin" />
                ) : (
                  <>
                    <i className="mdi mdi-trash-can mdi-14px" /> Remove User
                    Role
                  </>
                )}
              </button>
            </div>
          </div>
        ))
      ) : (
        <h4 className="m-3 text-muted">No users in this office!</h4>
      )}
    </div>
  );
};

export default connect(
  "selectUserRoleAll",
  "selectOfficeData",
  "doUserRoleFetchAll",
  "doOfficeFetch",
  "doPutUserRole",
  "doDeleteUserRole",
  "selectUserRoleData",
  "selectUsersAll",
  "doPostUserRole",
  "selectUserRoleIsFetching",
  "selectUserRoleIsSaving",
  "selectUserRoleIsDeleting",
  "selectUserIsSysAdmin",
  "doUserFetchAll",
  ManageUsers
);
