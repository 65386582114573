import React, { useState, useEffect } from "react";
import { connect } from "redux-bundler-react";
import Link from "../../misc/link";
import "./customCard.css";

function CardSamples(props) {
  const [sampleData, setSampleData] = useState(null);
  const {
    doLocationsummaryFetchByLocation,
    locationsummarySummary,
    locationsummaryIsFetchingSummary,
  } = props;

  useEffect(() => {
    if (props.location == null) return;
    setSampleData(0);
    doLocationsummaryFetchByLocation(props.location.location_code);
  }, [props.location]);

  useEffect(() => {
    if (!locationsummaryIsFetchingSummary) {
      setSampleData(locationsummarySummary[0]);
    }
  }, [locationsummaryIsFetchingSummary]);

  return !props.location ||
    !props.project ||
    locationsummaryIsFetchingSummary ? (
    <div className="custom">
      <div className="card-body">
        <div style={{ textAlign: "center" }}>
          <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
        </div>
      </div>
    </div>
  ) : (
    <div className="custom">
      <a
        style={{
          textDecoration: "none",
          cursor: "pointer",
          height: "100%",
        }}
        href={`/projects/${props.project.site_code}/locations/${props.location.location_code}/samples`}
      >
        <div className="card-body h-100">
          <div className="custom-body">
            <div>
              <h4
                className="card-title border-bottom"
                style={{ color: "#3c4b64" }}
              >
                Samples
              </h4>
              <h6 className="card-subtitle mb-2 text-muted">
                {`Min Date: `}
                {sampleData && sampleData.min_date
                  ? sampleData.min_date.split("T")[0]
                  : "n/a"}
              </h6>
              <h6 className="card-subtitle mb-2 text-muted">
                {`Max Date: `}
                {sampleData && sampleData.max_date
                  ? sampleData.max_date.split("T")[0]
                  : "n/a"}
              </h6>
              <p className="card-text" style={{ color: "#3c4b64" }}>
                <strong>Sample Count: </strong>
                {!sampleData ? "0" : sampleData.num_samples}
              </p>
            </div>
            <Link
              style={{ fontWeight: "bold" }}
              to={`/projects/${props.project.site_code}/locations/${props.location.location_code}/samples`}
            >
              Go to Samples
            </Link>
          </div>
        </div>
      </a>
    </div>
  );
}

export default connect(
  "doLocationsummaryFetchByLocation",
  "selectLocationsummarySummary",
  "selectLocationsummaryIsFetchingSummary",
  CardSamples
);
