import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { connect } from "redux-bundler-react";
import {
  ModalContent,
  ModalHeader,
  ModalFooter,
} from "../../app-components/modal";

const LoadConfigModal = ({
  doFetch,
  doLoad,
  doDelete,
  page,
  profileGateConfigsByUser: gateConfigs,
  profileGateIsFetching,
  filterConfigs,
  configIsFetching,
}) => {
  const [config, setConfig] = useState();
  const [configOptions, setConfigOptions] = useState([]);

  useEffect(doFetch, []);

  useEffect(() => {
    let configsByPage = [];
    if (page === "gate") configsByPage = gateConfigs;
    else configsByPage = filterConfigs?.filter((conf) => conf.page === page);
    setConfigOptions(configsByPage);
  }, [gateConfigs, filterConfigs, page]);

  const _onLoad = () => {
    let loadedConfig = configOptions.filter(
      (item) => item.config_code === config.value
    )[0];
    doLoad(loadedConfig);
  };

  const _onDelete = () => {
    doDelete(config);
  };

  return (
    <ModalContent size="md">
      <ModalHeader title="Load / Delete Configurations" />
      <section className="modal-body">
        <div className="container-fluid">
          <label>Configurations</label>
          {configIsFetching || profileGateIsFetching ? (
            <p>Loading...</p>
          ) : (
            <ReactSelect
              options={configOptions?.map((item) => ({
                label: `${item.name} Create Date: ${
                  item.created_date.split("T")[0]
                }`,
                value: item.config_code,
              }))}
              onChange={setConfig}
              menuPosition="fixed"
            />
          )}
        </div>
      </section>
      <ModalFooter
        onSave={_onLoad}
        saveText="Load"
        deleteText="Delete selection"
        onDelete={config && doDelete ? _onDelete : null}
        showCancelButton
      />
    </ModalContent>
  );
};

export default connect(
  "selectFilterConfigs",
  "selectConfigIsFetching",
  "selectProfileGateConfigsByUser",
  "selectProfileGateIsFetching",
  LoadConfigModal
);
