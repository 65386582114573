import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import "../location/locationHome.css";
import "./trends.css";

HighchartsMore(Highcharts);

const TrendsConclusionPlot = ({
  doTrendsConclusionSelectedFetch,
  trendsConclusionSelected,
  doTrendsConclusionFetch,
  doProjectShouldFetch,
  doStoretShouldFetch,
  storetData,
  trendsConclusion,
  projectAll,
  routeParams,
  trendsConclusionIsFetching,
  storetIsFetching,
}) => {
  const [projectOptions, setProjectOptions] = useState();
  const [storetOptions, setStoretOptions] = useState();
  const [activeStoret, setActiveStoret] = useState();
  const [activeProjectOption, setActiveProjectOption] = useState();
  const [boxplotData, setBoxplotData] = useState();
  const [series, setSeries] = useState();
  const [newOption, setNewOption] = useState();

  let activeProject = projectAll.find(
    (obj) => routeParams.project == obj.site_code
  );
  const _generateOptions = (data, value, label) => {
    let options = [];
    options = data.map((item) => ({ value: item[value], label: item[label] }));
    /**removes duplicates */
    options = options.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => t.value === value.value && t.label === value.label
        )
    );
    return options;
  };

  const _generateBoxplotData = (array, name) => {
    let boxplotData = [];
    array.forEach((obj) => {
      /** earliest, then latest */
      boxplotData.push([
        name,
        obj["max_lowest_minimum"],
        obj["max_lowest_25th"],
        obj["max_lowest_median"],
        obj["max_lowest_75th"],
        obj["max_lowest_maximum"],
      ]);
      boxplotData.push([
        name,
        obj["min_lowest_minimum"],
        obj["min_lowest_25th"],
        obj["min_lowest_median"],
        obj["min_lowest_75th"],
        obj["min_lowest_maximum"],
      ]);
    });
    return boxplotData;
  };

  const _generateCurrentSeries = (data, name) => {
    let activeProject = projectAll.find(
      (obj) => routeParams.project == obj.site_code
    );
    if (data.length) {
      let activeName = name ? name : activeProject.site_name;
      return _generateBoxplotData(data, activeName);
    }
  };
  useEffect(() => {
    /* fetch all data initially */
    doTrendsConclusionFetch();
    doProjectShouldFetch();
    doStoretShouldFetch();
  }, [doTrendsConclusionFetch, doProjectShouldFetch, doStoretShouldFetch]);

  /* Convert all project objs to proper form for select component */
  useEffect(() => {
    setProjectOptions(_generateOptions(projectAll, "site_code", "site_name"));
  }, [projectAll]);

  /* Convert available storetnums to proper obj for select component */
  useEffect(() => {
    setStoretOptions(
      _generateOptions(trendsConclusion, "storet_num", "storet_num")
    );
  }, [trendsConclusion]);

  /* Once you know your storet options, generate chart data based on first storet num */
  useEffect(() => {
    if (storetOptions && storetOptions.length) {
      setActiveStoret({
        value: storetOptions[0].value,
        label: storetData.find((o) => o.storet_num === storetOptions[0].value)
          .short_name,
      });
    }
  }, [storetOptions]);

  useEffect(() => {
    let currentData =
      trendsConclusion && trendsConclusion.length && activeStoret
        ? trendsConclusion.filter(
            (item) => item.storet_num == activeStoret.value
          )
        : [];
    setBoxplotData(_generateCurrentSeries(currentData));
  }, [activeStoret]);

  useEffect(() => {
    /* only fetch new trends if new project selected */
    if (newOption) {
      doTrendsConclusionSelectedFetch(newOption.value, activeStoret.value);
    }
  }, [newOption]);

  useEffect(() => {
    if (trendsConclusionSelected.length && newOption) {
      let temp = _generateCurrentSeries(
        trendsConclusionSelected,
        newOption.label
      );
      setBoxplotData([...boxplotData, ...temp]);
    }
  }, [trendsConclusionSelected]);

  useEffect(() => {
    let conclusion_data = [];
    let names = boxplotData && boxplotData.map((arr) => arr[0]);
    let setnames = [...new Set(names)];
    // boxplotData && boxplotData.forEach((arr, num) => {
    //   let color = num === 0 ? 'orange' : num === 1 ? 'blue' : num === 2 ? 'red' : "navy";
    let color = "red";
    boxplotData &&
      boxplotData.forEach((singleData, index) => {
        if ((boxplotData.length - index) % 4 === 0) {
          color = "orange";
        } else if ((boxplotData.length - index) % 4 === 1) {
          color = "blue";
        } else if ((boxplotData.length - index) % 4 === 2) {
          color = "red";
        } else {
          color = "lightblue";
        }
        conclusion_data.push({
          type: "boxplot",
          name: names[index],
          data: [singleData],
          lineColor: color,
          lineWidth: 2,
          whiskerColor: color,
          whiskerWidth: 2,
          stemColor: color,
          stemWidth: 2,
          medianColor: color,
          medianWidth: 2,
          pointWidth: 20,
        });
      });
    // })
    if (boxplotData && boxplotData.length) {
      let options = {
        chart: {
          type: "boxplot",
        },

        title: {
          text: `Compare ${activeProject.site_name} Trends to Other Projects`,
        },

        legend: {
          enabled: false,
        },
        xAxis: {
          type: "category",
          title: {
            text: "Project Name",
          },
        },

        // yAxis: {
        //   title: {
        //     text: currentLabel
        //   }
        // },

        series: conclusion_data,
      };
      setSeries(options);
    }
  }, [boxplotData]);

  const handleStoretChange = (obj) => {
    setActiveProjectOption(null);
    setNewOption(null);
    setActiveStoret(obj);
  };

  const handleProjectChange = (arr, type) => {
    let activeProject = projectAll.find(
      (obj) => routeParams.project == obj.site_code
    );
    switch (type.action) {
      case "select-option":
        setNewOption(type.option);
        break;
      case "remove-value":
        setNewOption(null);
        setBoxplotData(
          boxplotData.filter((array) => array[0] !== type.removedValue.label)
        );
        break;
      case "clear":
        setNewOption(null);
        setBoxplotData(
          boxplotData.filter((array) => array[0] === activeProject.site_name)
        );
        break;
    }
    setActiveProjectOption(arr);
  };

  return (
    <div className="custom-table mb-4">
      <div className="card-body" style={{ minHeight: 500 }}>
        {trendsConclusionIsFetching ||
        storetIsFetching ||
        !storetData.length ? (
          <div className="text-center">
            <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <FormControl className="width-200px m-2">
                  <label>Parameters</label>
                  <Select
                    value={activeStoret}
                    name="storet_code"
                    onChange={handleStoretChange}
                    options={
                      storetOptions &&
                      storetOptions.map((item) => ({
                        value: item.value,
                        label: storetData.find(
                          (o) => o.storet_num === item.value
                        ).short_name,
                      }))
                    }
                  />
                </FormControl>
                <FormControl className="width-200px m-2">
                  <label>Compare to Projects</label>
                  <Select
                    value={activeProjectOption}
                    name="projects"
                    onChange={handleProjectChange}
                    options={
                      projectOptions &&
                      projectOptions.filter(
                        (p) => p.value !== parseInt(routeParams.project)
                      )
                    }
                    isMulti
                  />
                  {!trendsConclusionSelected.length && newOption && (
                    <p className="text-danger m-0">
                      No data for this project at current parameter
                    </p>
                  )}
                </FormControl>
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <div className="legend legend-red" />
                  <p className="m-0"> Highest Value Last 6 Years</p>
                </div>
                <div className="d-flex align-items-center">
                  <div className="legend legend-orange" />
                  <p className="m-0">Highest Value Earliest 6 Years</p>
                </div>
                <div className="d-flex align-items-center">
                  <div className="legend legend-blue" />
                  <p className="m-0">Lowest Value Last 6 Years</p>
                </div>
                <div className="d-flex align-items-center">
                  <div className="legend legend-lightblue" />
                  <p className="m-0"> Lowest Value Earliest 6 Years</p>
                </div>
              </div>
            </div>
            <HighchartsReact highcharts={Highcharts} options={series} />
          </>
        )}
      </div>
    </div>
  );
};

export default connect(
  "selectTrendsConclusion",
  "selectTrendsConclusionSelected",
  "doTrendsConclusionSelectedFetch",
  "doTrendsConclusionFetch",
  "selectTrendsConclusionIsFetching",
  "selectStoretIsFetching",
  "doProjectShouldFetch",
  "selectProjectAll",
  "doStoretShouldFetch",
  "selectStoretData",
  "selectRouteParams",
  TrendsConclusionPlot
);
