import React, { useState } from "react";
import { connect } from "redux-bundler-react";

import { Modal, TextField, Box } from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  maxWidth: "767px",
  height: "35vh",
  borderRadius: "16px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};
const ModalType = connect("doDocumentTypePost", ({ doDocumentTypePost }) => {
  const [open, setOpen] = useState(false);
  const [newType, setNewType] = useState("");
  const openModal = () => {
    setOpen(!open);
  };
  const handleChange = (e) => {
    setNewType(e.target.value);
  };
  return (
    <div>
      <button
        className="btn btn-secondary rounded-pill"
        style={{ maxWidth: 250, maxHeight: 35, marginRight: 12 }}
        onClick={openModal}
      >
        Add Type
      </button>
      <Modal open={open} onClose={openModal}>
        <Box sx={style}>
          <div>
            <h4 className="card-title border-bottom">Add Document Types</h4>
            <div style={{ display: "flex", width: "100%", paddingBottom: 16 }}>
              <TextField
                label="Document Type"
                style={{ width: "50%", marginRight: 8 }}
                onChange={handleChange}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <button
              className="btn btn-secondary rounded-pill"
              style={{ maxWidth: 250, maxHeight: 35, marginRight: 12 }}
              onClick={openModal}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary rounded-pill"
              style={{ maxWidth: 250, maxHeight: 35, marginRight: 12 }}
              onClick={() => doDocumentTypePost(newType, openModal)}
            >
              Save Type
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
});

export default ModalType;
