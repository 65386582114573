import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";
import EditResultModal from "../sample/EditResultModal";

function TableResult(props) {
  const {
    resultData,
    resultIsFetching,
    doResultShouldFetch,
    userIsOrgMemberOrAdmin,
    doModalOpen,
    doDataFlagFetch,
    dataFlagItems,
  } = props;
  useEffect(doDataFlagFetch, []);

  return (
    <MaterialTable
      title="Result Data Points"
      isLoading={resultIsFetching}
      columns={[
        {
          field: "link",
          title: "Actions",
          render: (rowData) => {
            if (userIsOrgMemberOrAdmin) {
              return (
                <button
                  className="hover"
                  onClick={(e) => {
                    doModalOpen(EditResultModal, {
                      data: rowData,
                      edit: true,
                    });
                  }}
                >
                  <i className="mdi mdi-pencil-outline mdi-24px"></i>
                </button>
              );
            }
          },
        },
        { title: "Sample Num", field: "sample_num" },
        { title: "Sample Depth", field: "sample_depth" },
        { title: "Depth Unit", field: "depth_uom" },
        { title: "Lab Id", field: "lab_id" },
        { title: "Lab Snum", field: "lab_snum" },
        { title: "Storet Units", field: "units" },
        { title: "Value", field: "value" },
        { title: "Date Entered", field: "date_entered" },
        { title: "Sample Time", field: "sample_time" },
        { title: "Test Method", field: "test_mthd" },
        { title: "Text Value", field: "text_value" },
        { title: "Detect", field: "detect" },
        { title: "pQuant", field: "pquant" },
        { title: "Pre Recovery", field: "pre_recov" },
        { title: "Post Recovery", field: "post_recov" },
        { title: "Dilution", field: "dilution" },
        { title: "Export Flag", field: "export_flag" },
        { title: "Result Grade", field: "result_grade" },
        { title: "Date Lab Received", field: "date_lab_received" },
        { title: "Date Lab Prepared", field: "date_lab_prepared" },
        { title: "Lab Sample Fraction", field: "lab_sample_fraction" },
        { title: "Lab Comment", field: "lab_comment" },
        { title: "Validation Warnings", field: "validation_warnings" },
        {
          title: "Data Qualifier Flag",
          field: "data_qualifier_flag",
        },
      ]}
      data={resultData}
      options={{
        loadingType: "overlay",
        exportButton: true,
        exportAllData: true,
        //filtering: true,
        sorting: true,
        search: true,
        pageSize: 500,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [500, 1000, 2000],
      }}
      actions={[
        {
          icon: "refresh",
          tooltip: "Refresh table",
          isFreeAction: true,
          onClick: doResultShouldFetch,
        },
      ]}
    />
  );
}

export default connect(
  "doModalOpen",
  "selectResultData",
  "selectResultIsFetching",
  "doResultShouldFetch",
  "selectUserIsOrgMemberOrAdmin",
  "selectApiRoot",
  "doDataFlagFetch",
  "selectDataFlagItems",
  TableResult
);
