import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import LoadConfigModal from "../../dataExport/LoadConfigModal";
import SaveConfigModal from "../../dataExport/SaveConfigModal";

const GateController = ({
  doUpdateProfileGates,
  profileGates: gates,
  doProfileGateSave,
  doProfileGateLoad,
  doProfileGateFetch,
  doModalOpenUniqueCode,
  doModalOpen,
  selectCwmsSeqIsFetching: isSaving,
  profileGateConfigsByUser,
  profileGateIsFetching,
}) => {
  const _deleteGate = (gateId) => {
    let tempGates = gates;
    delete tempGates[gateId];
    doUpdateProfileGates({ ...tempGates });
  };
  return (
    <div className="d-flex flex-column border-top pt-3 w-50">
      <h4>Add Gates</h4>
      <div className="d-flex">
        <button
          className="btn btn-outline-dark mr-2"
          onClick={() => {
            let id = "id" + Math.random().toString(16).slice(2);
            doUpdateProfileGates({
              ...gates,
              [id]: {
                type: "Main",
                min: 0,
                max: 100,
                label: {
                  text: "Main",
                  style: {
                    color: "gray",
                    fontSize: 20,
                    opacity: 0.8,
                  },
                  align: "center",
                },
              },
            });
          }}
        >
          Add Main Gate
        </button>
        <button
          className="btn btn-outline-success mr-2"
          onClick={() => {
            let id = "id" + Math.random().toString(16).slice(2);
            doUpdateProfileGates({
              ...gates,
              [id]: {
                type: "Bypass",
                min: 0,
                max: 100,
                label: {
                  text: "Bypass",
                  style: {
                    color: "gray",
                    fontSize: 20,
                    opacity: 0.8,
                  },
                  align: "center",
                },
              },
            });
          }}
        >
          Add Bypass Gate
        </button>
        <button
          className="btn btn-outline-info mr-2"
          disabled={isSaving}
          onClick={() => {
            doModalOpenUniqueCode(
              SaveConfigModal,
              {
                doSave: doProfileGateSave,
                config: { state: gates },
              },
              "config_code"
            );
          }}
        >
          <i className="mdi mdi-content-save mdi-18px" />
        </button>
        <button
          className="btn btn-outline-primary"
          onClick={() => {
            doModalOpen(LoadConfigModal, {
              doLoad: doProfileGateLoad,
              doFetch: doProfileGateFetch,
              page: "gate",
            });
          }}
        >
          <i className="mdi mdi-upload mdi-18px" />
        </button>
      </div>
      <div className="d-flex flex-column">
        {gates &&
          Object.keys(gates).length > 0 &&
          Object.keys(gates).map((gateId) => {
            return (
              <div className="d-flex pt-2 pb-2 align-items-center" key={gateId}>
                <input
                  className="form-control mr-2 w-50"
                  placeholder={`Label (${gates[gateId].type})`}
                  name={gateId}
                  value={gates[gateId].label && gates[gateId].label.text}
                  onChange={(e) =>
                    doUpdateProfileGates({
                      ...gates,
                      [gateId]: {
                        ...gates[gateId],
                        label: {
                          text: e.target.value,
                          style: {
                            color: "gray",
                            fontSize: 20,
                            opacity: 0.8,
                          },
                          align: "center",
                        },
                      },
                    })
                  }
                />
                <div className="d-flex align-items-center w-100">
                  <input
                    className="form-control mr-2"
                    placeholder="Min"
                    name={gateId}
                    value={gates[gateId].min}
                    onChange={(e) =>
                      doUpdateProfileGates({
                        ...gates,
                        [gateId]: {
                          ...gates[gateId],
                          min: e.target.value,
                        },
                      })
                    }
                  />
                  <input
                    className="form-control"
                    placeholder="Max"
                    name={gateId}
                    value={gates[gateId].max}
                    onChange={(e) =>
                      doUpdateProfileGates({
                        ...gates,
                        [gateId]: {
                          ...gates[gateId],
                          max: e.target.value,
                        },
                      })
                    }
                  />
                  <button
                    className="btn btn-ghost-light text-danger ml-2"
                    onClick={() => _deleteGate(gateId)}
                  >
                    <i className="mdi mdi-delete mdi-16px" />
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default connect(
  "doUpdateProfileGates",
  "selectProfileGates",
  "doProfileGateSave",
  "doProfileGateLoad",
  "doProfileGateFetch",
  "doModalOpenUniqueCode",
  "doModalOpen",
  "selectCwmsSeqIsFetching",
  GateController
);
