import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder } from "../utils/ordsify";

export default {
  name: "profileGate",
  getReducer: () => {
    const initialData = {
      gateConfig: [],
      shouldFetch: false,
      isFetching: false,
      isSaving: false,
      loadedConfig: {},
      error: null,
      gates: {},
    };
    return (state = initialData, action) => {
      switch (action.type) {
        case "PROFILEGATE_SAVE_STARTED":
        case "PROFILEGATE_SAVE_ERROR":
        case "PROFILEGATE_FETCH_STARTED":
        case "PROFILEGATE_FETCH_FINISHED":
        case "PROFILEGATE_FETCH_ERROR":
        case "PROFILEGATE_LOAD_STARTED":
        case "PROFILEGATE_LOAD_FINISHED":
        case "PROFILEGATE_LOAD_ERROR":
        case "UPDATE_PROFILE_GATES":
          return Object.assign({}, state, action.payload);
        case "PROFILEGATE_SAVE_FINISHED":
          return { ...state, shouldFetch: true };
        default:
          return state;
      }
    };
  },
  doProfileGateFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "PROFILEGATE_FETCH_STARTED",
        payload: { isFetching: true, shouldFetch: false },
      });
      let user_id = parseInt(store.selectAuthTokenPayload().sub);
      let url = ordsifyUrlBuilder(
        "/profile_gates",
        [
          {
            keyword: "user_id",
            items: [user_id],
          },
        ],
        500
      );
      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "PROFILEGATE_FETCH_FINISHED",
            payload: { isFetching: false, gateConfig: j.items },
          });
        })
        .catch((err) => {
          dispatch({
            type: "PROFILEGATE_FETCH_ERROR",
            payload: { isFetching: false, error: err },
          });
        });
    },
  doProfileGateSave:
    (configObj) =>
    ({ dispatch, store }) => {
      let { config_code, name, state } = configObj;
      let apiRoot = store.selectApiRoot();
      let user_id = parseInt(store.selectAuthTokenPayload().sub);
      let config = JSON.stringify(state);
      dispatch({
        type: "PROFILEGATE_SAVE_STARTED",
        payload: { isSaving: true },
      });

      let configCode = parseInt(config_code);

      store.doActionPostData(
        `${apiRoot}/profile_gates/`,
        {
          user_id,
          config,
          config_code: configCode,
          created_date: new Date(),
          name,
          page: "gate",
        },
        () => {
          dispatch({
            type: "PROFILEGATE_SAVE_FINISHED",
            payload: { isSaving: false, shouldFetch: true },
          });
        },
        (e) =>
          dispatch({
            type: "PROFILEGATE_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },

  doProfileGateLoad:
    (selectedConfig) =>
    ({ dispatch, store }) => {
      let configCode = parseInt(selectedConfig.config_code);
      dispatch({
        type: "PROFILEGATE_LOAD_STARTED",
        payload: { isFetching: true },
      });
      let configs = store.selectProfileGateConfigsByUser();
      if (configs && configs.length > 0) {
        let loadedConfig = configs.filter(
          (c) => parseInt(c.config_code) === configCode
        );
        dispatch({
          type: "PROFILEGATE_LOAD_FINISHED",
          payload: {
            gates: JSON.parse(loadedConfig[0].config),
          },
        });
      } else {
        dispatch({
          type: "PROFILEGATE_LOAD_ERROR",
          payload: { error: "No configurations exist" },
        });
      }
    },

  doUpdateProfileGates:
    (gates) =>
    ({ dispatch }) => {
      dispatch({
        type: "UPDATE_PROFILE_GATES",
        payload: { gates },
      });
    },
  selectProfileGates: (state) => state.profileGate.gates,
  selectProfileGateConfigsByUser: (state) => state.profileGate.gateConfig,
  selectProfileGateIsFetching: (state) => state.profileGate.isFetching,
  selectProfileGateShouldFetch: (state) => state.profileGate.shouldFetch,

  reactProfileGateShouldFetch: createSelector(
    "selectProfileGateIsFetching",
    "selectProfileGateShouldFetch",
    (isFetching, shouldFetch) => {
      if (isFetching) {
        return null;
      }
      if (shouldFetch)
        return {
          actionCreator: "doProfileGateFetch",
        };
    }
  ),
};
