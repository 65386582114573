import React from "react";
import Report from "./Report";
import FilterSidebar from "../../dataExport/FilterSidebar";
import { TableContainer } from "../../../app-components/page-content";
import { connect } from "redux-bundler-react";
import summaryConfig from "./summary.config.json";

const Summary = ({ reportSummaryData, ...props }) => {
  const generateCSV = () => {
    const csvString = [
      [
        "Location ID",
        "Sample Depth",
        "Sample Time",
        "Parameter",
        "Units",
        "Value",
      ],
      ...reportSummaryData.map((item) => [
        item.loc_id,
        item.sample_depth,
        item.sample_time,
        item.short_name,
        item.units,
        item.value,
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");
    const csvFile = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvFile);
    window.open(url);
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      <FilterSidebar
        config={summaryConfig}
        generateText="Generate Report"
        {...props}
      />
      <TableContainer>
        <h3
          className="card-title border-bottom w-100 d-flex justify-content-between align-items-center"
          style={{ paddingTop: 24 }}
        >
          Summary Report
          <button
            onClick={generateCSV}
            disabled={!reportSummaryData.length}
            className="btn btn-info"
          >
            Export to CSV
          </button>
        </h3>
        <Report />
      </TableContainer>
    </div>
  );
};
// const Summary = () => {
//     return (
//         <div>

//             <div style={{ padding: "0 64px 32px", height: "100%", width: "100%", minHeight: 'calc(100vh - 106px)' }}>
//                 <h1 style={{ marginTop: "20px" }}>Summary Report</h1>
//                 <div className="row" style={{ padding: "0px 0px 15px 5px" }}>
//                     <Projects />
//                     <Locations />
//                     <StoretMultiple />
//                 </div>
//                 <div className='row'>
//                     <DateSingle />  {/* @TODO only show dates with results */}
//                 </div>
//                 <div className="row">
//                     <Report />
//                 </div>
//             </div>

//         </div>
//     )
// }
export default connect(
  ...summaryConfig.selectors,
  ...summaryConfig.fetchFns,
  "selectReportSummaryData",
  Summary
);
