import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilder, ordsifyUrlBuilderNoSlash } from "../utils/ordsify";

export default {
  name: "samplesnew",
  getReducer: () => {
    const initialData = {
      data: [],
      sorted: {},
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "SAMPLES_NEW_FETCH_START":
        case "SAMPLES_NEW_FETCH_FINISH":
        case "SAMPLES_NEW_SHOULD_FETCH":
        case "UPDATE_SORTED_OBJECT":
          return Object.assign({}, state, payload);
        case "RESULT_SAVE_FINISHED":
        case "RESULT_DELETE_FINISHED":
        case "SAMPLE_DELETE_FINISHED":
        case "SAMPLE_SAVE_FINISHED":
          return { ...state, ...payload, shouldFetch: true };
        default:
          return state;
      }
    };
  },
  doSetSortedSamplesNewObject:
    () =>
    ({ dispatch, store }) => {
      let sortedObjectArray = {};
      store.selectSamplesNew().forEach((sample) => {
        const { sample_code } = sample;
        if (sortedObjectArray[sample_code]) {
          sortedObjectArray[sample_code].push(sample);
        } else {
          sortedObjectArray[sample_code] = [sample];
        }
      });
      dispatch({
        type: "UPDATE_SORTED_OBJECT",
        payload: { sorted: sortedObjectArray },
      });
    },
  doSamplesNewShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "SAMPLES_NEW_SHOULD_FETCH",
        payload: { shouldFetch: true },
      });
    },

  doSamplesNewFetch:
    (state, limit = 100000) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "SAMPLES_NEW_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      let url = `/samples_all/all?q={"location_code":${
        store.selectRouteParams().id
      }}`;
      if (state) {
        let cm =
          state.collectionMethod && state.collectionMethod.map((c) => c.value);
        let parameters =
          state.parameters && state.parameters.map((p) => p.value);
        url = ordsifyUrlBuilderNoSlash(
          "/samples_all/all",
          [
            {
              keyword: "location_code",
              items: [store.selectRouteParams().id],
            },
            {
              keyword: "sample_depth_range",
              items:
                state.min_sample_depth && state.max_sample_depth
                  ? [
                      parseInt(state.min_sample_depth),
                      parseInt(state.max_sample_depth),
                    ]
                  : state.min_sample_depth && !state.max_sample_depth
                  ? [parseInt(state.min_sample_depth), null]
                  : !state.min_sample_depth && state.max_sample_depth
                  ? [null, parseInt(state.max_sample_depth)]
                  : [],
            },
            {
              keyword: "collect_mthd",
              items: cm ? cm : [],
            },
            {
              keyword: "sample_time",
              items:
                state.min_sample_date && state.max_sample_date
                  ? [
                      `${state.min_sample_date}T00:00:00Z`,
                      `${state.max_sample_date}T00:00:00Z`,
                    ]
                  : !state.min_sample_date && state.max_sample_date
                  ? [
                      "1900-01-01T00:00:00Z",
                      `${state.max_sample_date}T00:00:00Z`,
                    ]
                  : state.min_sample_date && !state.max_sample_date
                  ? [
                      `${state.min_sample_date}T00:00:00Z`,
                      "2100-01-01T00:00:00Z",
                    ]
                  : [],
            },
            {
              keyword: "storet_num",
              items: parameters ? parameters : [],
            },
            {
              keyword: "value",
              items:
                state.min_value && state.max_value
                  ? [parseFloat(state.min_value), parseFloat(state.max_value)]
                  : state.min_value && !state.max_value
                  ? [parseFloat(state.min_value), null]
                  : !state.min_value && state.max_value
                  ? [null, parseFloat(state.max_value)]
                  : [],
            },
          ],
          limit
        );
      }
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "SAMPLES_NEW_FETCH_FINISH",
            payload: { data: j.items, isFetching: false, shouldFetch: false },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },
  selectSamplesNewIsFetching: (state) => state.samplesnew.isFetching,
  selectSamplesNewShouldFetch: (state) => state.samplesnew.shouldFetch,
  selectSamplesNew: (state) => state.samplesnew.data,
  selectSortedSamplesNew: (state) => state.samplesnew.sorted,

  reactSamplesNewShouldFetch: createSelector(
    "selectSamplesNewIsFetching",
    "selectSamplesNewShouldFetch",
    (isFetching, shouldFetch) => {
      // never fetch if anoter fetch already in progress
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doSamplesNewFetch",
        };
      }
    }
  ),
};
