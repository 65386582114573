import { createSelector } from "redux-bundler";
import { ordsifyUrlBuilderNoSlash } from "../utils/ordsify";
import { groupBy } from "lodash";

export default {
  name: "charts2",
  getReducer: () => {
    const initialData = {
      items: [],
      shouldFetch: false,
      isFetching: false,
      dates: [],
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "CHARTS_HYDROGRAPH_FETCH_STARTED":
        case "CHARTS_HYDROGRAPH_FETCH_FINISHED":
        case "CHARTS_HYDROGRAPH_FETCH_DATE_STARTED":
        case "CHARTS_HYDROGRAPH_FETCH_DATE_FINISHED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doChartHydrographFetchDates:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      if (state && Object.keys(state).length > 0) {
        dispatch({
          type: "CHARTS_HYDROGRAPH_FETCH_DATE_STARTED",
          payload: {
            isFetching: true,
          },
        });
        let parameters =
          state.parameters && state.parameters.map((p) => p.value);
        let depths =
          state.sample_depth && state.sample_depth.map((dep) => dep.value);
        let locations = Object.keys(state).map((key) => {
          if (parseInt(key) && state[key]) {
            /** key is site_code containing arrays of locations */
            /**if key is an int that means its a site_code */
            return state[key].map((location) => location.value);
          }
        });
        locations =
          locations &&
          locations.length > 0 &&
          locations.flat(1).filter((item) => item !== undefined);
        depths =
          depths && depths.length > 0
            ? depths.flat(1).filter((item) => item !== undefined)
            : [];
        if (!locations || !parameters || !depths.length) return;
        let url = ordsifyUrlBuilderNoSlash(
          `/charting/hydro/${locations}/${parameters}`,
          [
            {
              keyword: "sample_depth",
              items: depths,
            },
          ],
          500
        );
        apiFetch(url)
          .then((r) => r.json())
          .then((j) =>
            dispatch({
              type: "CHARTS_HYDROGRAPH_FETCH_DATE_FINISHED",
              payload: {
                dates: j.items,
                isFetching: false,
              },
            })
          )
          .catch((e) => {});
      }
    },

  doChartHydrographFetch:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      if (state) {
        dispatch({
          type: "CHARTS_HYDROGRAPH_FETCH_STARTED",
          payload: {
            isFetching: true,
          },
        });
        let parameters =
          state.parameters && state.parameters.map((p) => p.value);
        let depths =
          state.sample_depth && state.sample_depth.map((dep) => dep.value);
        let locations = Object.keys(state).map((key) => {
          if (parseInt(key) && state[key]) {
            /** key is site_code containing arrays of locations */
            /**if key is an int that means its a site_code */
            return state[key].map((location) => location.value);
          }
        });
        locations =
          locations &&
          locations.length > 0 &&
          locations.flat(1).filter((item) => item !== undefined);
        depths =
          depths && depths.length > 0
            ? depths.flat(1).filter((item) => item !== undefined)
            : [];
        if (!locations || !parameters) return;
        let minDate, maxDate;
        if (state.min_date) {
          minDate =
            typeof state.min_date === "string"
              ? state.min_date.includes("T")
                ? state.min_date
                : `${state.min_date}T00:00:00Z`
              : state.min_date.toISOString();
        }
        if (state.max_date) {
          maxDate =
            typeof state.max_date === "string"
              ? state.max_date.includes("T")
                ? state.max_date
                : `${state.max_date}T00:00:00Z`
              : state.max_date.toISOString();
        }
        let url = ordsifyUrlBuilderNoSlash(
          `/charting/hydro/${locations}/${parameters}`,
          [
            {
              keyword: "sample_depth",
              items: depths,
            },
            {
              keyword: "sample_time",
              items:
                minDate && maxDate
                  ? [`${minDate}`, `${maxDate}`]
                  : !minDate && maxDate
                  ? ["1900-01-01T00:00:00Z", `${maxDate}`]
                  : minDate && !maxDate
                  ? [`${minDate}`, "2100-01-01T00:00:00Z"]
                  : [],
            },
          ],
          500
        );
        apiFetch(url)
          .then((r) => r.json())
          .then((j) =>
            dispatch({
              type: "CHARTS_HYDROGRAPH_FETCH_FINISHED",
              payload: {
                items: j.items,
                isFetching: false,
              },
            })
          )
          .catch((e) => {});
      }
    },

  selectChartHydrographItems: (state) => state.charts2.items,
  selectChartHydrographDates: (state) => {
    let dates = state.charts2.dates.map((obj) => {
      // js Date object is affected by timezone based on orientation of YYYY-MM-DD, needs to be flipped
      let sampleTime = obj.sample_time.split("T")[0]; //1986-08-12
      let sampleArr = sampleTime.split("-"); // ['1986','08','12']
      sampleTime = `${sampleArr[1]}-${sampleArr[2]}-${sampleArr[0]}`; //08-12-1986
      return new Date(sampleTime);
    });
    return dates;
  },
  selectChartHydrographIsFetching: (state) => state.charts2.isFetching,
  selectChartHydrographSeries: createSelector(
    "selectChartHydrographItems",
    (chartHydrographItems) => {
      let result;
      if (chartHydrographItems && chartHydrographItems.length) {
        result = groupBy(chartHydrographItems, "sample_depth");
      }
      return result;
    }
  ),
};
