import React, { useEffect, useReducer } from "react";
import ReactSelect from "react-select";
import { connect } from "redux-bundler-react";
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "../../../app-components/modal";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const DataFlagModal = (props) => {
  const {
    doModalClose,
    data,
    edit,
    officeData,
    doPostDataFlag,
    doPutDataFlag,
    doDeleteDataFlag,
    dataFlagIsSaving,
    dataFlagIsDeleting,
    dataFlagError: error,
    flag_code,
  } = props;

  let initialState = {};

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (edit) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: data,
      });
    } else {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: { flag_code },
      });
    }
  }, [edit, data]);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSelect = (field, payload) => {
    dispatch({
      type: "UPDATE_INPUT",
      field,
      payload: payload.value,
    });
  };

  return (
    <ModalContent size="md" error={error}>
      <ModalHeader
        title={
          !edit ? "Add Data Qualifier Flag" : "Edit Data Qualifier Flag Info"
        }
      />
      <section className="modal-body">
        <div className="container-fluid">
          <div className="d-flex flex-column w-100">
            <label htmlFor="data_qualifier_flag">Data Qualifier Flag</label>
            <input
              type="text"
              className="form-control"
              name="data_qualifier_flag"
              onChange={handleChange}
              value={state.data_qualifier_flag}
            />
          </div>
          <div className="d-flex flex-column w-100 mt-2">
            <label htmlFor="associated_lab">Associated Lab</label>
            <input
              type="text"
              className="form-control"
              name="associated_lab"
              onChange={handleChange}
              value={state.associated_lab}
            />
          </div>
          <div className="d-flex flex-column w-100 mt-2">
            <label htmlFor="definition">Definition</label>
            <textarea
              type="text"
              className="form-control"
              name="definition"
              onChange={handleChange}
              value={state.definition}
            />
          </div>
          <div className="d-flex flex-column w-100 mt-2">
            <label htmlFor="office_id">Office ID</label>
            <ReactSelect
              id="office_id"
              onChange={(obj) => handleSelect("office_id", obj)}
              value={{ label: state.office_id, value: state.office_id }}
              menuPosition="fixed"
              options={officeData.map((s) => ({
                label: s.office_id,
                value: s.office_id,
              }))}
              className="w-100"
            />
          </div>
        </div>
      </section>
      <ModalFooter
        onSave={() => (edit ? doPutDataFlag(state) : doPostDataFlag(state))}
        customClosingLogic
        onDelete={edit ? () => doDeleteDataFlag(state.flag_code) : null}
        showCancelButton
        onCancel={doModalClose}
        saveIsDisabled={dataFlagIsSaving}
        deleteIsDisabled={dataFlagIsDeleting}
        saveText={
          dataFlagIsSaving ? <i className="mdi mdi-loading mdi-spin" /> : "Save"
        }
        deleteText={
          dataFlagIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "doDataFlagTableFetch",
  "selectDataFlagItems",
  "selectOfficeData",
  "doPostDataFlag",
  "doPutDataFlag",
  "doDeleteDataFlag",
  "selectDataFlagIsSaving",
  "selectDataFlagIsDeleting",
  "selectDataFlagError",
  "doModalClose",
  DataFlagModal
);
