import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import StackedBar from "../charting/StackedBar";

const Biologics = ({
  doBiologicsFetch,
  biologicsData,
  biologicsIsFetching,
  doSetBiologicsSeries,
  biologicsSeries,
  biologicsIsBuilding,
}) => {
  useEffect(() => {
    doBiologicsFetch();
  }, []);

  useEffect(() => {
    doSetBiologicsSeries();
  }, [biologicsData]);

  return (
    <div>
      <StackedBar
        data={biologicsSeries}
        loading={biologicsIsFetching || biologicsIsBuilding}
      />
    </div>
  );
};

export default connect(
  "doBiologicsFetch",
  "selectBiologicsData",
  "doSetBiologicsSeries",
  "selectBiologicsSeries",
  "selectBiologicsIsFetching",
  "selectBiologicsIsBuilding",
  Biologics
);
