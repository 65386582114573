import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import TrueLineChart from "../../charting/TrueLineChart";
// import TrueLineChart from "../../charting/TrueLineChart";
import Link from "../../misc/link";
import "./customCard.css";

function CardIndices(props) {
  const {
    ctsiData,
    doSetGraphData,
    doIndicesShouldFetch,
    doSetSortedIndicesObject,
    indices,
    sortedIndices,
  } = props;
  useEffect(() => {
    doIndicesShouldFetch();
  }, []);

  useEffect(() => {
    doSetSortedIndicesObject();
  }, [indices]);

  useEffect(() => {
    doSetGraphData();
  }, [sortedIndices]);

  return !props.location || !props.project ? (
    <div className="custom">
      <div className="card-body">
        <div style={{ textAlign: "center" }}>
          <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
        </div>
      </div>
    </div>
  ) : (
    <div className="custom">
      <a
        style={{
          textDecoration: "none",
          cursor: "pointer",
          height: "100%",
        }}
        href={
          indices.length > 0 &&
          `/projects/${props.project.site_code}/indices/${props.location.location_code}`
        }
      >
        <div className="card-body">
          <div className="custom-body">
            <div>
              <h4
                className="card-title border-bottom"
                style={{ color: "#3c4b64" }}
              >
                Indices
              </h4>
              <h6 className="card-subtitle mb-2 text-muted">{`Indices for ${props.location.loc_id}`}</h6>
              <TrueLineChart data={ctsiData} title="Carlson TSI" />
            </div>
            {indices.length === 0 ? (
              <h6 className="text-muted">No indices at this location!</h6>
            ) : (
              <Link
                style={{ fontWeight: "bold" }}
                to={`/projects/${props.project.site_code}/indices/${props.location.location_code}`}
              >
                Go to Indices
              </Link>
            )}
          </div>
        </div>
      </a>
    </div>
  );
}

export default connect(
  "selectCtsiData",
  "doSetGraphData",
  "doIndicesShouldFetch",
  "doSetSortedIndicesObject",
  "selectIndices",
  "selectSortedIndices",
  CardIndices
);
