const ModalBundle = {
  name: "modal",
  getReducer: () => {
    const initialData = {
      content: null,
      props: null,

      nestedContent: null,
      nestedProps: null,

      prevContent: null,
      prevProps: null,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "MODAL_OPENED":
        case "NESTED_MODAL_OPENED":
        case "NESTED_MODAL_CLOSED":
        case "MODAL_CLOSED":
        case "UPDATE_PROPS":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },
  doModalOpen:
    (content, props) =>
    ({ dispatch }) => {
      dispatch({
        type: "MODAL_OPENED",
        payload: {
          content: content,
          props: props,
        },
      });
    },

  doNestedModalOpen:
    (content, props) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "NESTED_MODAL_OPENED",
        payload: {
          nestedContent: content,
          nestedProps: props,
          prevContent: store.selectModalContent(),
          prevProps: store.selectModalProps(),
          content: content,
          props: props,
        },
      });
    },

  doNestedModalOpenUniqueCode:
    (content, props, idKey) =>
    ({ dispatch, store }) => {
      store.doCwmsSeqNewFetch().then((res) => {
        let code = res.items[0].seq;
        store.doNestedModalOpen(content, { ...props, [idKey]: code });
      });
    },
  doNestedModalClose:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "NESTED_MODAL_CLOSED",
        payload: {
          nestedContent: null,
          nestedProps: null,
          prevContent: null,
          prevProps: null,
          content: store.selectModalPrevContent(),
          props: store.selectModalPrevProps(),
        },
      });
    },

  doModalOpenUniqueCode:
    (content, props, idKey) =>
    ({ dispatch, store }) => {
      store.doCwmsSeqNewFetch().then((res) => {
        let code = res.items[0].seq;
        store.doModalOpen(content, { ...props, [idKey]: code });
      });
    },
  doModalClose:
    (cb) =>
    ({ dispatch }) => {
      dispatch({
        type: "MODAL_CLOSED",
        payload: {
          content: null,
          props: null,
          nestedContent: null,
          nestedProps: null,
          prevContent: null,
          prevProps: null,
        },
      });
      cb && typeof cb === "function" && cb();
      if (window.location.hash) window.location.hash = "";
    },

  doUpdateModalProps:
    (props, cb) =>
    ({ dispatch }) => {
      dispatch({
        type: "UPDATE_PROPS",
        payload: { props },
      });
      cb && cb();
    },
  selectModalContent: (state) => state.modal.content,
  selectModalProps: (state) => state.modal.props,
  selectModalPrevContent: (state) => state.modal.prevContent,
  selectModalPrevProps: (state) => state.modal.prevProps,
  selectModalNestedContent: (state) => state.modal.nestedContent,
  selectModalNestedProps: (state) => state.modal.nestedProps,
};

export default ModalBundle;
