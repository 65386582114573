import { Avatar, IconButton } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import React, { useState } from "react";

const CustomAvatar = ({ disabled, onDelete, size = 40, ...props }) => {
  const [hover, setHover] = useState(false);
  return (
    <IconButton
      disabled={disabled}
      style={{ padding: 0, height: size, width: size }}
      onMouseOver={(e) => !disabled && setHover(true)}
      onMouseLeave={(e) => !disabled && setHover(false)}
      onClick={!disabled ? onDelete : undefined}
    >
      {hover ? (
        <CloseOutlined sx={{ height: size, width: size }} />
      ) : (
        <Avatar {...props} />
      )}
    </IconButton>
  );
};

export default CustomAvatar;
