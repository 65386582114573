import React, { useReducer, useEffect } from "react";
import ReactSelect from "react-select";
import { connect } from "redux-bundler-react";
import {
  ModalFooter,
  ModalContent,
  ModalHeader,
} from "../../app-components/modal";
import { classnames } from "../../utils/helpers";
import Table from "../tables/Table";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };

    case "ADD_SAMPLE":
      return {
        ...state,
        samples: [...state.samples, { ...action.payload }],
      };
    case "DELETE_SAMPLE":
      let updated = state.samples.filter(
        (smpl) => smpl.tableData.id !== action.payload.tableData.id
      );
      return {
        ...state,
        samples: updated,
      };
    case "UPDATE_SAMPLE":
      let edited = state.samples.map((smpl) =>
        smpl.tableData.id === action.payload.tableData.id
          ? { ...action.payload }
          : smpl
      );
      return {
        ...state,
        samples: edited,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const SimpleFieldDataModal = ({
  doNestedModalClose,
  projectLocations,
  project,
  participantsOptions,
  field_visit_code,
  location,
  participants,
  doActionPostData,
  apiRoot,
  doLrnFormFetch,
  lrnFormIsFetching,
  lrnFormData,
  doActionPutData,
  lrnFormParams,
  doLrnFormParamsFetch,
}) => {
  let initialState = {
    project: project.site_name,
    participants,
    field_visit_code,
    location,

    samples: [],
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (lrnFormData && lrnFormData.clob_surface_sample) {
      let parsedArr = JSON.parse(lrnFormData.clob_surface_sample);
      let surfaceData = {};
      let samples = [];
      parsedArr.forEach((obj) =>
        Object.keys(obj).forEach((key) => (surfaceData[key] = obj[key]))
      );
      if (lrnFormData.clob_sample)
        samples = JSON.parse(lrnFormData.clob_sample);
      let initData = {
        ...lrnFormData,
        ...initialState,
        ...surfaceData,
        samples,
      };
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: initData,
      });
    }
  }, []);

  useEffect(() => doLrnFormParamsFetch(), [doLrnFormParamsFetch]);

  let sampleColumns =
    lrnFormParams && lrnFormParams.length > 0
      ? lrnFormParams
          .map((field) => {
            if (field.form_item.includes("18")) {
              return {
                field: field.storet_num,
                title: field.param_label,
              };
            } else return;
          })
          .filter((item) => item !== undefined)
      : [];

  sampleColumns.unshift({
    field: "sample_depth",
    title: "Sample Depth",
  });

  const doSave = () => {
    let obj = {};
    obj.location_code = state.location.value;
    obj.clob_sample =
      state.samples && state.samples.length > 0
        ? JSON.stringify(state.samples)
        : "";
    obj.date_added = new Date();
    obj.samples_clob = "";
    obj.remarks = state.remarks;
    obj.serial_no = state.serial_no;
    obj.project_code = project.site_code;
    obj.participants = JSON.stringify(
      state.participants.map((r) => r.value).join(", ")
    );
    obj.sample_date =
      state.sample_date && !state.sample_date.includes("Z")
        ? `${state.sample_date}:00Z`
        : state.sample_date;
    let surface_sample_clob = [];
    Object.keys(state).forEach((key) => {
      if (
        [
          "sample_date",
          "date_added",
          "project",
          "participants",
          "remarks",
          "field_visit_code",
          "location",
          "samples",
          "serial_no",
          // "collect_mthd",
        ].indexOf(key) === -1
      ) {
        surface_sample_clob.push({ [key]: state[key] });
      }
    });
    obj.surface_sample_clob = JSON.stringify(surface_sample_clob);
    obj.field_visit_code = state.field_visit_code;
    if (Object.keys(lrnFormData).length > 0) {
      obj.clob_surface_sample = obj.surface_sample_clob;
      delete obj.surface_sample_clob;
      doActionPutData(
        `${apiRoot}/form_561_t/${obj.field_visit_code}`,
        obj,
        () => {
          doLrnFormFetch(obj.field_visit_code, doNestedModalClose);
        },
        (e) => console.error(e)
      );
    } else {
      doActionPostData(
        `${apiRoot}/bulk/celrn_form_561`,
        obj,
        () => {
          doLrnFormFetch(obj.field_visit_code, doNestedModalClose);
        },
        (e) => console.error(e)
      );
    }
  };
  const handleInputChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };
  const handleSelect = (field, payload) => {
    dispatch({
      type: "UPDATE_INPUT",
      field,
      payload,
    });
  };

  const inputWrapperClass = classnames({
    "d-flex": true,
    "flex-column": true,
    "w-100": true,
    "mb-2": true,
  });

  return (
    <ModalContent size="lg">
      <ModalHeader
        title="Water Quality Field Data"
        subtitle="The proponent agency is CELRN-EC-H"
        onClose={doNestedModalClose}
      />
      <section className="modal-body">
        <div className="container-fluid">
          <div className="two-grid">
            <div className={inputWrapperClass}>
              <label className="d-flex">Location</label>
              <ReactSelect
                value={state.location}
                name="location"
                options={projectLocations.map((loc) => ({
                  value: loc.location_code,
                  label: loc.loc_id,
                }))}
                onChange={(obj) => handleSelect("location", obj)}
                menuPosition="fixed"
              />
            </div>
            {/*Eventually make this a select that has all the members of WQ, with ability to write in your own react-select/creatable https://react-select.com/creatable */}
            <div className={inputWrapperClass}>
              <label>Participants</label>
              <ReactSelect
                isMulti
                labelId="participants"
                id="participants"
                name="participants"
                placeholder="Participants"
                onChange={(obj) => handleSelect("participants", obj)}
                value={state["participants"]}
                noOptionsMessage={() => (
                  <p className="m-0">
                    To add more participants, please update the corresponding
                    Field Trip
                  </p>
                )}
                options={participantsOptions}
                menuPlacement="top"
                menuPosition="fixed"
              />
            </div>
          </div>
          <div className="two-grid">
            <div className={inputWrapperClass}>
              <label className="d-flex">Instrument Serial No.</label>
              <input
                className="custom-input"
                value={state.serial_no}
                onChange={handleInputChange}
                name="serial_no"
              />
            </div>
            <div className={inputWrapperClass}>
              <label className="d-flex">Date</label>
              <input
                className="custom-input"
                value={state.sample_date && state.sample_date.split("Z")[0]}
                onChange={handleInputChange}
                name="sample_date"
                type="datetime-local"
              />
            </div>
          </div>
          <div className="four-grid">
            <div className={inputWrapperClass}>
              <label className="d-flex">Weather</label>
              <input
                className="custom-input"
                value={state.weather}
                onChange={handleInputChange}
                name="weather"
              />
            </div>
            <div className={inputWrapperClass}>
              <label className="d-flex">Color / Appearance</label>
              <input
                value={state["00079"]}
                name="00079"
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
            <div className={inputWrapperClass}>
              <label className="d-flex">Wind Velocity (mph)</label>
              <input
                className="custom-input"
                value={state["00035"]}
                onChange={handleInputChange}
                name="00035"
              />
            </div>
            <div className={inputWrapperClass}>
              <label className="d-flex">Air Temperature</label>
              <input
                className="custom-input"
                value={state["00021"]}
                onChange={handleInputChange}
                name="00021"
              />
            </div>
          </div>
          {/* <div className="two-grid">
            <div className={inputWrapperClass}>
              <label htmlFor="collect">Collection Method</label>
              <ReactSelect
                value={state["collect_mthd"]}
                name={"collect_mthd"}
                inputId="collect"
                options={collectionsSelects.map((item) => ({
                  value: item.value,
                  label: item.label,
                }))}
                onChange={(obj) => handleSelect("collect_mthd", obj)}
                menuPosition="fixed"
              />
            </div>
          </div> */}
          <div className={inputWrapperClass}>
            <label className="d-flex">Remarks</label>
            <textarea
              value={state.remarks}
              name="remarks"
              onChange={handleInputChange}
              className="custom-input pt-1 pb-1"
            />
          </div>
          <div className="six-grid">
            <div className={inputWrapperClass}>
              <label className="d-flex">Maximum Depth (ft)</label>
              <input
                value={state["04195"]}
                name="04195"
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
            <div className={inputWrapperClass}>
              <label className="d-flex">Distance between banks (ft)</label>
              <input
                value={state["00004"]}
                name="00004"
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
            <div className={inputWrapperClass}>
              <label className="d-flex">Distance from left bank (ft)</label>
              <input
                value={state["00009"]}
                name="00009"
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
            <div className={inputWrapperClass}>
              <label className="d-flex">Secchi Disk (m)</label>
              <input
                value={state["00078"]}
                name="00078"
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
            <div className={inputWrapperClass}>
              <label className="d-flex">Water Surface Elevation (ft)</label>
              <input
                value={state["00062"]}
                name="00062"
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
            <div className={inputWrapperClass}>
              <label className="d-flex">Inst. Discharge (CFS)</label>
              <input
                value={state["00061"]}
                name="00061"
                onChange={handleInputChange}
                className="custom-input"
              />
            </div>
          </div>

          <Table
            title="Measurements"
            isLoading={lrnFormIsFetching}
            columns={sampleColumns}
            data={state.samples}
            options={{
              loadingType: "overlay",
              exportButton: true,
              exportAllData: true,
              sorting: true,
              pageSize: 5,
            }}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  dispatch({
                    type: "ADD_SAMPLE",
                    payload: newData,
                  });
                  resolve();
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  dispatch({
                    type: "DELETE_SAMPLE",
                    payload: oldData,
                  });
                  resolve();
                }),
              onRowUpdate: (oldData, newData) =>
                new Promise((resolve, reject) => {
                  dispatch({
                    type: "UPDATE_SAMPLE",
                    payload: {
                      ...oldData,
                      tableData: { ...newData.tableData },
                    },
                  });
                  resolve();
                }),
            }}
          />
        </div>
      </section>

      <ModalFooter
        saveText={
          lrnFormIsFetching ? (
            <i className="mdi mdi-loading mdi-spin mdi-18px" />
          ) : (
            "Save"
          )
        }
        showCancelButton
        onSave={doSave}
        customClosingLogic
        onCancel={doNestedModalClose}
      />
    </ModalContent>
  );
};

export default connect(
  "selectCollectionsSelects",
  "doCollectionsFetch",
  "doNestedModalClose",
  "selectProjectLocations",
  "selectProject",
  "doActionPostData",
  "doActionPutData",
  "selectApiRoot",
  "doLrnFormFetch",
  "selectLrnFormIsFetching",
  "doLrnFormParamsFetch",
  "selectLrnFormParams",
  SimpleFieldDataModal
);
