import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";
// import { postData, putData } from "../../utils/fetch";

function TableSiteShannon(props) {
  const {
    shannonSiteData,
    shannonIsFetching,
    doShannonSiteFetch,
    apiRoot,
    userIsOrgMemberOrAdmin,
    title,
  } = props;

  useEffect(doShannonSiteFetch, []);

  return (
    <div className="custom-table mt-4">
      <div className="card-body">
        <div style={{ textAlign: "center" }}>
          <MaterialTable
            title={title}
            isLoading={shannonIsFetching}
            columns={[
              { title: "Sample Number", field: "sample_num" },
              { title: "Location ID", field: "loc_id" },
              { title: "Count", field: "count" },
              { title: "Species", field: "species" },
              { title: "Count Row", field: "count_row" },
              { title: "Count Row Check", field: "count_row_check" },
              { title: "Total Count", field: "total_count" },
              { title: "Pi Prop", field: "pi_prop" },
              { title: "Ln Pi", field: "ln_pi" },
              { title: "Pixln", field: "pixln" },
              { title: "Richness", field: "richness" },
              { title: "H", field: "h" },
              { title: "H Max", field: "h_max" },
              { title: "J", field: "j" },
            ]}
            data={shannonSiteData}
            options={{
              loadingType: "overlay",
              exportButton: true,
              exportAllData: true,
              sorting: true,
              pageSize: 50,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [50, 500, 1000, 2000],
            }}
            actions={[
              {
                icon: "refresh",
                tooltip: "Refresh table",
                isFreeAction: true,
                onClick: (event) => doShannonSiteFetch(),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default connect(
  "doActionPostData",
  "doActionPutData",
  "doActionDeleteData",
  "selectShannonSiteData",
  "selectShannonIsFetching",
  "doShannonSiteFetch",
  "selectApiRoot",
  "selectUserIsOrgMemberOrAdmin",
  TableSiteShannon
);
