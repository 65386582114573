import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import CalendarHeatmap from "../charting/CalendarHeatmap";
import { TableContainer } from "../../app-components/page-content";
import { heatmapConfig } from "../dataExport/filterConfigs";
import FilterSidebar from "../dataExport/FilterSidebar";

const HeatmapPage = (props) => {
  const { dataExport, resultIsFetching, filters } = props;
  const [heatmapData, setHeatmapData] = useState([]);
  useEffect(() => {
    let data = [];
    if (dataExport && dataExport.length) {
      data = dataExport.map((item) => ({
        date: new Date(item.sample_time),
        count: item.value,
      }));
      setHeatmapData(data);
    } else {
      setHeatmapData([]);
    }
  }, [dataExport]);

  let units = dataExport && dataExport[0] && dataExport[0].units;
  let params = filters ? (filters.parameters ? filters.parameters : []) : [];
  return (
    <div>
      <div
        className="d-flex"
        style={{
          width: "100%",
          minHeight: "calc(100vh - 106px)",
          borderTop: "1px solid lightgray",
        }}
      >
        <FilterSidebar config={heatmapConfig} {...props} />
        <TableContainer>
          <div className="card mt-4" style={{ paddingBottom: "30px" }}>
            <CalendarHeatmap
              data={heatmapData}
              isLoading={resultIsFetching}
              units={units}
              parameter={params}
            />
          </div>
        </TableContainer>
      </div>
    </div>
  );
};

export default connect(
  "selectDataExport",
  "selectFilters",
  "selectResultIsFetching",
  ...heatmapConfig.selectors,
  ...heatmapConfig.fetchFns,
  HeatmapPage
);
