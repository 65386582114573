import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { connect } from "redux-bundler-react";
import EditParameterModal from "./EditParameterModal";
import { paramConfig } from "../../dataExport/filterConfigs";
import { TableContainer } from "../../../app-components/page-content";
import FilterSidebar from "../../dataExport/FilterSidebar";

const TableParameters = (props) => {
  const { storetData, doStoretFetchTable, storetIsFetching, doModalOpen } =
    props;

  const _fetchParameters = () => {
    doStoretFetchTable();
  };

  useEffect(_fetchParameters, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          minHeight: "calc(100vh - 106px)",
          borderTop: "white",
        }}
      >
        <FilterSidebar config={paramConfig} {...props} />
        <TableContainer>
          <div
            style={{
              width: "100%",
              padding: "0px 64px 32px",
              height: "100%",
              minHeight: "calc(100vh - 106px)",
            }}
          >
            <div className="custom-table">
              <div className="card-body">
                <div className="d-flex justify-content-between w-100 mb-2">
                  <div>
                    <h4 className="m-0">Parameters</h4>
                    <h6 className="text-muted m-0">
                      Note: Please use the filter to the left if looking for
                      specific parameters.
                    </h6>
                  </div>
                  <button
                    className="btn btn-outline-success"
                    style={{ maxWidth: 250, maxHeight: 35 }}
                    onClick={() => {
                      doModalOpen(EditParameterModal);
                    }}
                  >
                    Add Parameter
                  </button>
                </div>
                <MaterialTable
                  title={""}
                  isLoading={storetIsFetching}
                  columns={[
                    {
                      field: "link",
                      title: "Actions",
                      render: (rowData) => {
                        return (
                          <button
                            className="hover"
                            onClick={(e) => {
                              doModalOpen(EditParameterModal, {
                                data: rowData,
                                edit: true,
                              });
                            }}
                          >
                            <i className="mdi mdi-pencil-outline mdi-24px"></i>
                          </button>
                        );
                      },
                    },
                    { title: "Full Name", field: "full_name" },
                    { title: "Short Name", field: "short_name" },
                    { title: "Storet Num", field: "storet_num" },
                  ]}
                  data={storetData}
                  options={{
                    loadingType: "overlay",
                    exportButton: true,
                    exportAllData: true,
                    //filtering: true,
                    sorting: true,
                    search: false,
                    pageSize: 20,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [20, 500, 1000, 2000],
                  }}
                />
              </div>
            </div>
          </div>
        </TableContainer>
      </div>
    </div>
  );
};

export default connect(
  "doStoretShouldFetch",
  "doStoretFetchTable",
  "selectStoretIsFetching",
  "selectStoretData",
  "doModalOpen",
  ...paramConfig.selectors,
  ...paramConfig.fetchFns,
  TableParameters
);
