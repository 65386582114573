import React from "react";
import { connect } from "redux-bundler-react";
import ProjectModal from "./ProjectModal";
import TableProject from "./TableProject";
import "../location/cards/customCard.css";
import { TableContainer } from "../../app-components/page-content";
import FilterSidebar from "../dataExport/FilterSidebar";
import projectsConfig from "./project.config.json";

export default connect(
  "doModalOpenUniqueCode",
  "selectUserIsAnyMemberOrAdmin",
  ...projectsConfig.selectors,
  ...projectsConfig.fetchFns,
  ({ doModalOpenUniqueCode, userIsAnyMemberOrAdmin, ...props }) => {
    return (
      <div
        className="d-flex w-100"
        style={{
          minHeight: "calc(100vh - 106px)",
        }}
      >
        {" "}
        <FilterSidebar config={projectsConfig} {...props} />
        <TableContainer>
          <div className="custom-table mt-3">
            <div className="card-body">
              <div className="d-flex w-100 justify-content-between">
                <h4 className="m-0">Projects</h4>
                {userIsAnyMemberOrAdmin && (
                  <button
                    className={`btn btn-outline-success m-2`}
                    onClick={() =>
                      doModalOpenUniqueCode(ProjectModal, {}, "site_code")
                    }
                  >
                    {"Add Project"}
                  </button>
                )}
              </div>
              <TableProject />
            </div>
          </div>
        </TableContainer>
      </div>
    );
  }
);
