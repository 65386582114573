import { ordsifyUrlBuilder } from "../utils/ordsify";
import { createSelector } from "redux-bundler";

export default {
  name: "resultsummary",

  // reducers (function that takes an action, modifies the store, returns a function)
  getReducer: () => {
    const initialData = {
      data: [],
      isFetching: false,
      shouldFetch: false,
      isFetchingAll: false,
      shouldFetchAll: false,
      summary: [],
      isFetchingSummary: false,
      shouldFetchSummary: false,
    };
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "RESULTSUMMARY_FETCH_START":
        case "RESULTSUMMARY_FETCH_FINISH":
        case "RESULTSUMMARY_REMOVE_BY_LOCATION":
        case "RESULTSUMMARY_REMOVE_BY_STORET":
        case "RESULTSUMMARY_REMOVE_ALL":
        case "RESULTSUMMARY_SHOULD_FETCH":
        case "RESULTSUMMARY_FETCH_ALL_START":
        case "RESULTSUMMARY_FETCH_ALL_FINISH":
        case "RESULTSUMMARY_SHOULD_FETCH_ALL":
        case "RESULTSUMMARY_FETCH_LOCATION_START":
        case "RESULTSUMMARY_FETCH_LOCATION_FINISH":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },
  doResultsummaryShouldFetch:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "RESULTSUMMARY_SHOULD_FETCH",
        payload: { shouldFetch: true },
      });
    },
  doResultsummaryShouldFetchAll:
    () =>
    ({ dispatch }) => {
      dispatch({
        type: "RESULTSUMMARY_SHOULD_FETCH_ALL",
        payload: { shouldFetchAll: true },
      });
    },
  // action creators
  doResultsummaryFetch:
    (state, limit = 20000) =>
    ({ dispatch, store, apiFetch }) => {
      // set false to avoid infinite fetching attempts
      dispatch({
        type: "RESULTSUMMARY_FETCH_START",
        payload: { shouldFetch: false, isFetching: true },
      });
      let parameters =
        state && state.parameters && state.parameters.map((p) => p.value);
      let location_code = store.selectRouteParams().id;
      const url = ordsifyUrlBuilder(
        "/loc_results_summary",
        [
          {
            keyword: "location_code",
            items: [location_code],
          },
          {
            keyword: "storet_num",
            items: parameters ? parameters : [],
          },
          {
            keyword: "sample_depth",
            items: state && state.sample_depth ? [state.sample_depth] : [],
          },
        ],
        500
      );
      // Note: Filter resultsummary query to locationSelectedId.
      // If selectedLocations = [], will fetch for all locations
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "RESULTSUMMARY_FETCH_FINISH",
            payload: { data: j.items, isFetching: false },
          })
        );
    },
  doResultsummaryFetchAllData:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "RESULTSUMMARY_FETCH_ALL_START",
        payload: {
          shouldFetchAll: false,
          isFetchingAll: true,
        },
      });
      const url = ordsifyUrlBuilder("/loc_results_summary", [], 500);
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "RESULTSUMMARY_FETCH_ALL_FINISH",
            payload: { data: j.items, isFetchingAll: false },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },

  /** @todo change this to fetch by (state) and ordsify the same way doGenerateData does */
  doResultsummaryFetchByLocation:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "RESULTSUMMARY_FETCH_LOCATION_START",
        payload: { shouldFetchSummary: false, isFetchingSummary: true },
      });
      const location_code = store.selectRouteParams().id;
      const url = `/loc_results_summary/?q={"location_code":${location_code}}&limit=500`;
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "RESULTSUMMARY_FETCH_LOCATION_FINISH",
            payload: { summary: j.items, isFetchingSummary: false },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },
  // Remove results by location_code
  doResultsummaryRemoveByLocation:
    (location_codes) =>
    ({ dispatch, store }) => {
      const data = store.selectResultsummaryData();
      const new_data = data.filter(
        (d) => !location_codes.includes(d.location_code)
      );

      dispatch({
        type: "RESULTSUMMARY_REMOVE_BY_LOCATION",
        payload: { data: new_data },
      });
    },
  // Remove results by storet_num
  doResultsummaryRemoveByStoret:
    (storet_codes) =>
    ({ dispatch, store }) => {
      const data = store.selectResultsummaryData();
      const new_data = data.filter((d) => !storet_codes.includes(d.storet_num));

      dispatch({
        type: "RESULTSUMMARY_REMOVE_BY_STORET",
        payload: { data: new_data },
      });
    },

  // Remove all results (reset data to [])
  doResultsummaryRemoveAll:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "RESULTSUMMARY_REMOVE_ALL",
        payload: { data: [] },
      });
    },
  // selectors
  selectResultsummarySummary: (store) => store.resultsummary.summary,
  selectResultsummaryIsFetchingSummary: (store) =>
    store.resultsummary.isFetchingSummary,
  selectResultsummaryShouldFetchSummary: (store) =>
    store.resultsummary.shouldFetchSummary,
  selectResultsummaryRaw: (store) => store.resultsummary,
  selectResultsummaryData: (store) => store.resultsummary.data,
  selectResultsummaryIsFetching: (store) => store.resultsummary.isFetching,
  selectResultsummaryShouldFetch: (store) => store.resultsummary.shouldFetch,
  selectResultsummaryIsFetchingAll: (store) =>
    store.resultsummary.isFetchingAll,
  selectResultsummaryShouldFetchAll: (store) =>
    store.resultsummary.shouldFetchAll,

  // reactors
  reactResultsummaryShouldFetch: createSelector(
    "selectResultsummaryIsFetching",
    "selectResultsummaryShouldFetch",
    (isFetching, shouldFetch) => {
      if (isFetching) {
        return null;
      }
      if (shouldFetch) {
        return {
          actionCreator: "doResultsummaryFetch",
        };
      }
    }
  ),
  reactResultsummaryShouldFetchAll: createSelector(
    "selectResultsummaryIsFetchingAll",
    "selectResultsummaryShouldFetchAll",
    (isFetchingAll, shouldFetchAll) => {
      if (isFetchingAll) {
        return null;
      }
      if (shouldFetchAll) {
        return {
          actionCreator: "doResultsummaryFetchAllData",
        };
      }
    }
  ),
};
