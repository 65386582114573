import React, { useReducer, useEffect } from "react";
import ReactSelect from "react-select";
import { connect } from "redux-bundler-react";
import {
  ModalFooter,
  ModalContent,
  ModalHeader,
} from "../../../app-components/modal";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const CountiesModal = ({
  data,
  edit,
  doModalClose,
  county_code,
  doPutCounty,
  doPostCounty,
  doDeleteCounty,
  stateAll,
  countiesIsDeleting,
  countiesIsSaving,
  countiesError: error,
}) => {
  const initialState = {
    ...data,
    cnty_code: "",
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (edit) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: {
          ...data,
        },
      });
    } else {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: {
          county_code,
        },
      });
    }
  }, [edit, data]);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleSelect = (field, payload) => {
    dispatch({
      type: "UPDATE_INPUT",
      field,
      payload: payload.value,
    });
  };

  const _doSave = () => {
    if (edit) {
      doPutCounty(state, doModalClose);
    } else {
      doPostCounty(state, doModalClose);
    }
  };

  const _doDelete = () => {
    doDeleteCounty(state.county_code, doModalClose);
  };

  return (
    <ModalContent size="md" error={error}>
      <ModalHeader
        title={edit ? "Edit County" : "Add County"}
        onClose={doModalClose}
      />
      <section className="modal-body">
        <div className="container-fluid">
          <div className="d-flex flex-column mb-2">
            <label htmlFor="cnty_name">County Name</label>
            <input
              type="text"
              className="form-control"
              id="cnty_name"
              name="cnty_name"
              placeholder="i.e. Hendry"
              onChange={handleChange}
              value={state.cnty_name}
            />
          </div>
          <div className="d-flex flex-column mb-2">
            <label htmlFor="cnty_code">County Code</label>
            <input
              type="text"
              className="form-control"
              id="cnty_code"
              name="cnty_code"
              placeholder="i.e. 051"
              onChange={handleChange}
              value={state.cnty_code}
              maxLength="6"
            />
            <p className="m-0 mt-1 text-muted">
              <small>{6 - state.cnty_code.length} characters remaining</small>
            </p>
          </div>
          <div className="d-flex flex-column">
            <label htmlFor="storet_state">State</label>
            <ReactSelect
              id="storet_state"
              onChange={(obj) => handleSelect("storet_state", obj)}
              value={
                stateAll.filter((s) => s.storet_state == state.storet_state)
                  .length > 0 && {
                  value: stateAll.filter(
                    (s) => s.storet_state == state.storet_state
                  )[0].storet_state,
                  label: stateAll.filter(
                    (s) => s.storet_state == state.storet_state
                  )[0].state_name,
                }
              }
              menuPosition="fixed"
              options={stateAll.map((s) => ({
                label: s.state_name,
                value: s.storet_state,
              }))}
              className="w-100"
            />
          </div>
        </div>
      </section>
      <ModalFooter
        onSave={_doSave}
        customClosingLogic
        onDelete={edit ? _doDelete : null}
        showCancelButton
        onCancel={doModalClose}
        saveIsDisabled={countiesIsSaving}
        deleteIsDisabled={countiesIsDeleting}
        saveText={
          countiesIsSaving ? <i className="mdi mdi-loading mdi-spin" /> : "Save"
        }
        deleteText={
          countiesIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "selectTaxaData",
  "doNestedModalClose",
  "doModalClose",
  "selectBioSamplesByLocation",
  "selectRouteParams",
  "selectBioSampleTypeOptions",
  "doPutCounty",
  "doPostCounty",
  "doDeleteCounty",
  "selectStateAll",
  "selectCountiesIsDeleting",
  "selectCountiesIsSaving",
  "selectCountiesError",
  CountiesModal
);
