import React, { useEffect } from "react";
import { connect } from "redux-bundler-react";
import { TableContainer } from "../../app-components/page-content";
import FilterSidebar from "../dataExport/FilterSidebar";
import { fieldVisitConfig } from "../dataExport/filterConfigs";
import "../sample/modal.css";
import TableFieldVisit from "./TableFieldVisit";

const PageFieldVisit = (props) => {
  const { doFieldVisitShouldFetchByLocation, doFieldTripShouldFetch } = props;

  const _fetchFieldTripVisit = () => {
    doFieldVisitShouldFetchByLocation();
    doFieldTripShouldFetch();
  };
  useEffect(_fetchFieldTripVisit, []);
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
      className="border-top"
    >
      <FilterSidebar config={fieldVisitConfig} {...props} />
      <TableContainer>
        <div
          style={{
            height: "100%",
            minHeight: "calc(100vh - 106px)",
          }}
        >
          <div className="custom-table">
            <div className="card-body">
              <TableFieldVisit />
            </div>
          </div>
        </div>
      </TableContainer>
    </div>
  );
};

export default connect(
  "doFieldVisitShouldFetchByLocation",
  "doFieldTripShouldFetch",
  ...fieldVisitConfig.selectors,
  ...fieldVisitConfig.fetchFns,
  PageFieldVisit
);
