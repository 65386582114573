import React from "react";
import { connect } from "redux-bundler-react";
import { TableContainer } from "../../../app-components/page-content";
import FilterSidebar from "../../dataExport/FilterSidebar";
import Report from "./Report";
import hitConfig from "./hit.config.json";

const Hitlist = ({ reportHitlistData, ...props }) => {
  const generateCSV = () => {
    const csvString = [
      [
        "Project",
        "Location ID",
        "Parameter",
        "Storet Code",
        "Sample Depth",
        "Sample Time",
        "Units",
        "Value",
      ],
      ...reportHitlistData.map((item) => [
        item.site_name,
        item.loc_id,
        item.short_name,
        item.storet_num,
        item.sample_depth,
        item.sample_time,
        item.units,
        item.value,
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");
    const csvFile = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvFile);
    window.open(url);
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "calc(100vh - 106px)",
      }}
    >
      <FilterSidebar
        config={hitConfig}
        generateText="Generate Report"
        {...props}
      />
      <TableContainer>
        <h3
          className="card-title border-bottom w-100 d-flex justify-content-between align-items-center"
          style={{ paddingTop: 24 }}
        >
          Hitlist Report
          <button
            onClick={generateCSV}
            disabled={!reportHitlistData.length}
            className="btn btn-info"
          >
            Export to CSV
          </button>
        </h3>
        <Report />
      </TableContainer>
    </div>
  );
};
export default connect(
  ...hitConfig.selectors,
  ...hitConfig.fetchFns,
  "selectReportHitlistData",
  Hitlist
);
