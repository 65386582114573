import xhr from "xhr";
import { createSelector } from "redux-bundler";
import { formatDistanceToNow } from "date-fns";
import Keycloak from "../utils/Keycloak";

const keycloakHost = process.env.REACT_APP_KEYCLOAK_HOST;
const keycloakCHost = process.env.REACT_APP_KEYCLOAK_HOST_CAC;
const keycloakRealm = process.env.REACT_APP_KEYCLOAK_REALM;
const keycloakClient = process.env.REACT_APP_KEYCLOAK_CLIENT;
let keycloak = null;

const getTokenPart = function (token, part) {
  const splitToken = token.split(".");
  return splitToken[part];
};

export default {
  name: "auth",

  getReducer() {
    const initialData = {
      token: "",
      isloggingIn: false,
      err: null,
      _shouldCheckToken: true,
      _lastChecked: 0,
      devToken: {
        exp: 1692205434,
        iat: 1692204834,
        auth_time: 1692198795,
        jti: "a6bf81c0-d06f-4839-aadd-0942d2756b87",
        iss: "https://identity-test.cwbi.us/auth/realms/cwbi",
        aud: ["realm-management", "lpms-test", "warptest", "account"],
        sub: "7e9083e5-9020-403e-ad8f-5347759cb517",
        typ: "Bearer",
        azp: "wq",
        session_state: "f22ffb22-7259-4943-846d-a1f97ef95fb0",
        "allowed-origins": [
          "http://localhost:3000",
          "https://wq-test.cwbi.us/st",
          "https://wq.sec.usace.army.mil",
        ],
        realm_access: {
          roles: ["default-roles-cwbi", "offline_access", "uma_authorization"],
        },
        resource_access: {
          "realm-management": {
            roles: [
              "view-users",
              "query-clients",
              "query-groups",
              "query-users",
            ],
          },
          "lpms-test": {
            roles: ["user"],
          },
          wq: {
            roles: ["ORG.ADMIN", "SYS.ADMIN"],
          },
          warptest: {
            roles: ["all-users"],
          },
          account: {
            roles: ["manage-account", "manage-account-links", "view-profile"],
          },
        },
        scope: "openid email profile",
        sid: "f22ffb22-7259-4943-846d-a1f97ef95fb0",
        email_verified: true,
        name: "Tyler Siskar",
        preferred_username: "tyler.j.siskar",
        given_name: "Tyler",
        family_name: "Siskar",
        email: "tyler.j.siskar@usace.army.mil",
      },
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "AUTH_LOGGED_IN":
        case "AUTH_LOGGING_IN":
        case "AUTH_LOGGED_ERROR":
        case "AUTH_REFRESH":
        case "AUTH_CHECK_TOKEN":
          return Object.assign({}, state, payload);
        case "AUTH_LOGGED_OUT":
          return Object.assign({}, state, {
            token: "",
            err: null,
            isLoggingIn: false,
          });
        case "APP_IDLE":
          return Object.assign({}, state, {
            _shouldCheckToken: true,
          });
        default:
          return state;
      }
    };
  },
  init: (store) => {
    keycloak = new Keycloak({
      keycloakUrl: keycloakHost,
      keycloakCUrl: keycloakCHost,
      realm: keycloakRealm,
      client: keycloakClient,
      redirectUrl: `${document.location.origin}${document.location.pathname}`,
      refreshInterval: 600,
      sessionEndWarning: 1800,
      onAuthenticate: (token) => {
        if (process.env.NODE_ENV !== "development")
          store.doFetchAccessToken(token, store.doUserFetch);
        else {
          store.doLogin(token, store.doUserFetch);
        }
      },
      onError: (err) => {
        store.doLoginError(null);
      },
      onSessionEnding: (remainingTime) => {
        if (remainingTime > 0) {
          //   store.doFireNotification("warning", `Your session is expiring in ${Math.round(remainingTime / 60)} minutes.`)
        }
      },
    });
    keycloak.checkForSession();
  },

  doLoginError:
    (e) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "AUTH_LOGGED_ERROR",
        payload: { token: null, roles: null, err: e },
      });
    },

  doLogin:
    (token, callback) =>
    ({ dispatch, store }) => {
      const isLoggedIn = store.selectAuthIsLoggedIn();

      if (!isLoggedIn) {
        dispatch({
          type: "AUTH_LOGGED_IN",
          payload: { token: token, err: null },
        });
        callback && callback();
      } else {
        dispatch({
          type: "AUTH_REFRESH",
          payload: { token: token, err: null },
        });
        callback && callback();
      }

      const roles = store.selectAuthRoles();
      const root = store.selectApiRoot();
      const keycloakId = store.selectTokenKeyCloakId();
      if (!roles || (roles && roles.length < 1)) {
        const url = `${root}/roles/${keycloakId}`;
        xhr.get(
          {
            url: url,
            headers: {
              Authorization: "Bearer " + token,
            },
          },
          (err, res, body) => {
            if (err) {
              dispatch({
                type: "AUTH_LOGGED_ERROR",
                payload: { roles: [], err: err },
              });
            } else {
              try {
                const tokenParsed = JSON.parse(body);

                // if we're still alive we should be ok.
                dispatch({
                  type: "AUTH_LOGGED_IN",
                  payload: {
                    token: token,
                    isLoggingIn: false,
                    roles: JSON.parse(body),
                    err: null,
                  },
                });
              } catch (e) {
                dispatch({
                  type: "AUTH_LOGGED_ERROR",
                  payload: { token: null, roles: null, err: e },
                });
              }
            }
          }
        );
      }
    },

  doAuthLogout:
    () =>
    ({ dispatch, store }) => {
      store.doUpdateUrl("/");
      if (process.env.NODE_ENV !== "development") keycloak.logout();
      dispatch({ type: "AUTH_LOGGED_OUT" });
    },

  doAuthCheckToken:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "AUTH_CHECK_TOKEN",
        payload: {
          _shouldCheckToken: false,
          _lastChecked: new Date(),
        },
      });
      const isExpired = store.selectIsTokenExpired();
      if (isExpired) store.doAuthLogout();
    },

  doFetchAccessToken:
    (token, callback) =>
    ({ dispatch, store }) => {
      fetch(`${process.env.REACT_APP_NODE_API}/login`, {
        method: "GET",
        headers: { Authorization: token },
      })
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "AUTH_LOGGED_IN",
            payload: {
              accessToken: { ...j },
              shouldVerifyToken: true,
              token: token,
              error: null,
              isLoggingIn: false,
            },
          });
          callback && callback();
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.error(err);
          dispatch({
            type: "AUTH_ERROR",
            payload: {
              msg: "Error Logging In",
              error: err,
              isLoggingIn: false,
            },
          });
        });
    },
  selectAuthIsLoggedIn: (state) => {
    return !!state.auth.token;
  },

  selectTokenRaw: (state) => {
    return state.auth.token;
  },
  selectAuthIsLoggingIn: (state) => {
    return state.auth.isLoggingIn;
  },
  selectAuthRoles: createSelector("selectAuthTokenPayload", (payload) => {
    if (!payload.hasOwnProperty("resource_access")) return [];
    return payload && payload.resource_access.wq
      ? payload.resource_access.wq.roles
      : [];
  }),
  // selectAuthRolesJoined: state => {
  //   return state.auth.roles;
  // },
  selectAuthLastChecked: (state) => {
    return state.auth._lastChecked;
  },

  selectIsTokenExpired: createSelector(
    "selectAuthIsLoggedIn",
    "selectAuthTokenPayload",
    (isLoggedIn, payload) => {
      if (!isLoggedIn) return false;
      return payload.exp < Math.floor(Date.now() / 1000);
    }
  ),

  selectTokenExpiresIn: createSelector(
    "selectAuthIsLoggedIn",
    "selectAuthTokenPayload",
    (isLoggedIn, payload) => {
      if (!isLoggedIn) return false;
      return formatDistanceToNow(payload.exp * 1000);
    }
  ),

  // selectTokenHeader: createSelector("selectTokenRaw", (token) => {
  //   if (!token) return {};
  //   return JSON.parse(window.atob(getTokenPart(token, 0)));
  // }),

  selectDevToken: (state) => state.auth.devToken,
  selectAuthTokenPayload: createSelector(
    "selectTokenRaw",
    "selectDevToken",
    (token, devToken) => {
      if (process.env.NODE_ENV === "development") return devToken;
      if (!token) return {};
      return JSON.parse(window.atob(getTokenPart(token, 1)));
    }
  ),

  selectAuthUsername: createSelector("selectAuthTokenPayload", (payload) => {
    if (!payload.hasOwnProperty("preferred_username")) return null;
    return payload.preferred_username.toUpperCase();
  }),

  selectTokenEdipi: createSelector("selectAuthTokenPayload", (payload) => {
    if (!payload.hasOwnProperty("edipi")) return null;
    return payload.edipi;
  }),
  selectTokenKeyCloakId: createSelector("selectAuthTokenPayload", (payload) => {
    if (!payload.hasOwnProperty("sub")) return null;
    return payload.sub;
  }),

  selectAuthRolesJoined: createSelector("selectAuthRoles", (roles) => {
    if (!roles) return null;
    return roles;
  }),

  selectTokenGroups: createSelector("selectAuthRoles", (roles) => {
    if (!roles) return null;
    return roles.map((role) => {
      const groupRole = role.split(".");
      return groupRole[0];
    });
  }),

  selectTokenGroupRoles: createSelector("selectAuthRoles", (roles) => {
    if (!roles) return null;
    const groupRoles = {};
    roles.forEach((role) => {
      const groupRole = role.split(".");
      if (!groupRoles.hasOwnProperty(groupRole[0]))
        groupRoles[groupRole[0]] = [];
      groupRoles[groupRole[0]].push(groupRole[1]);
    });
    return groupRoles;
  }),

  selectAccessToken: (state) => state.auth.accessToken,

  reactAuthShouldCheckToken: (state) => {
    if (state.auth._shouldCheckToken) {
      if (new Date() - state.auth._lastChecked > 60000)
        return { actionCreator: "doAuthCheckToken" };
    }
  },

  doKeycloakAuthenticate:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "AUTH_LOGGING_IN",
        payload: { isLoggingIn: true },
      });
      if (process.env.NODE_ENV === "development")
        store.doLogin(store.selectAuthTokenPayload(), store.doUserFetch);
      else keycloak.directGrantX509Authenticate();
    },
  doKeycloakAuthenticateCode:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "AUTH_LOGGING_IN",
        payload: { isLoggingIn: true },
      });
      if (process.env.NODE_ENV === "development")
        store.doLogin(store.selectAuthTokenPayload(), store.doUserFetch);
      else keycloak.authenticate();
    },
  // persistActions: ["AUTH_LOGGED_IN", "AUTH_LOGGED_OUT"]
};
