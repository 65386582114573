import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "../../containers/sample/modal.css";
import { find } from "lodash";

const CODES = [
  "location_code",
  "result_code",
  "site_code",
  "storet_num",
  "sample_code",
  "source_code",
  "links",
  // "collect_mthd",
  "loc_desc",
  "taxacode",
  "bioresult_code",
  "biosample_code",
  "sample_type_code",
];
const _isNotCode = (key) => !CODES.includes(key);

/** This is created just to give a local open state to each row, if open was at the TableCollapsible level then every row would open onClick */
const Body = ({
  row: obj,
  doEditSample,
  doEditResult,
  userIsOrgMemberOrAdmin,
  sampleCodeKey,
  collectionsSelects,
}) => {
  const [open, setOpen] = useState(false);

  const _getCmLabel = (key, smpl) => {
    let cm = find(collectionsSelects, (obj) => obj.value === smpl[key]);
    return cm ? cm.label : smpl[key];
  };
  const _downloadCSV = () => {
    /* creating structure for csv download , arr with first row being titles, and the rest being corresponding data */
    let csvData = [];
    obj.results.forEach((result, i) => {
      if (i === 0) csvData.push(Object.keys(result));
      csvData.push(Object.keys(result).map((item) => result[item]));
    });
    let csv =
      "data:text/csv;charset=utf-8," +
      csvData.map((e) => e.join(",")).join("\n");
    var encodedUri = encodeURI(csv);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${csvData[1][0]}_sample_results.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          {obj.results && (
            <button className="hover" onClick={() => setOpen(!open)}>
              <i
                className={`mdi mdi-chevron-${open ? "up" : "down"} mdi-24px`}
              />
            </button>
          )}

          {userIsOrgMemberOrAdmin && (
            <button className="hover" onClick={() => doEditSample(obj.sample)}>
              <i className="mdi mdi-pencil-outline mdi-24px" />
            </button>
          )}
        </TableCell>
        {obj.sample &&
          Object.keys(obj.sample).map((key) => {
            if (key.includes("collect_mthd")) {
              return (
                <TableCell>
                  <p>{_getCmLabel(key, obj.sample)}</p>
                </TableCell>
              );
            } else if (_isNotCode(key))
              return (
                <TableCell>
                  <p>{obj.sample[key]}</p>
                </TableCell>
              );
          })}
      </TableRow>
      {/** Here is the nested Table with all Results corresponding to the above Sample */}
      {obj.results && obj.results.length && (
        <TableRow style={{ backgroundColor: "#f3f3f3" }}>
          <TableCell
            colSpan={Object.keys(obj.sample).length}
            style={{ padding: open ? 16 : 0 }}
          >
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              sx={{ width: "100%" }}
            >
              <div className="d-flex w-100 align-items-center">
                <h4 className="m-0">
                  Results in Sample {obj.sample[sampleCodeKey]}
                </h4>
                <button onClick={_downloadCSV} className="hover ml-4">
                  <i className="mdi mdi-download mdi-24px" />
                </button>
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Actions</TableCell>
                    {obj.results &&
                      obj.results.length > 0 &&
                      Object.keys(obj.results[0]).map((key, index) => {
                        if (_isNotCode(key))
                          return (
                            <TableCell key={key} style={{ fontWeight: "bold" }}>
                              {key
                                .replaceAll("_", " ")
                                .replace(/(\b[a-z](?!\s))/g, (x) =>
                                  x.toUpperCase()
                                )}
                            </TableCell>
                          );
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obj.results.map((row) => {
                    return (
                      <TableRow>
                        <TableCell>
                          <button
                            className="hover"
                            onClick={() => doEditResult(row)}
                          >
                            <i className="mdi mdi-pencil-outline mdi-24px" />
                          </button>
                        </TableCell>
                        {Object.keys(row).map((cellKey, i) => {
                          if (_isNotCode(cellKey))
                            return <TableCell>{row[cellKey]}</TableCell>;
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

const TableCollapsible = ({
  doFetch,
  doSort,
  sortedData,
  data,
  isFetching,
  title,
  addButtonDisabled,
  onAdd,
  addText,
  doEditSample,
  doEditResult,
  userIsOrgMemberOrAdmin,
  sampleCodeKey,
  collectionsSelects,
}) => {
  useEffect(doFetch, []);
  useEffect(doSort, [data]);
  if (isFetching)
    return (
      <div className="text-align-center">
        {" "}
        <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
      </div>
    );
  return (
    <div className="custom-table">
      <div className="card-body">
        <div className="d-flex justify-content-between card-title border-bottom w-100 mb-2">
          <h4 className="float-left">{title}</h4>
          {onAdd && userIsOrgMemberOrAdmin && (
            <button
              className={`btn btn-outline-${
                addButtonDisabled ? "secondary" : "success"
              }`}
              onClick={!addButtonDisabled ? onAdd : undefined}
            >
              {addText}
            </button>
          )}
        </div>
        <TableContainer component={Paper}>
          {!sortedData || sortedData.length === 0 ? (
            <h3 className="text-muted m-3">
              <small>No data available at these parameters!</small>
            </h3>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Actions</TableCell>
                  {sortedData &&
                    sortedData.length > 0 &&
                    Object.keys(sortedData[0].sample).map((key) => {
                      if (_isNotCode(key)) {
                        return (
                          <TableCell style={{ fontWeight: "bold" }} key={key}>
                            {key
                              .replaceAll("_", " ")
                              .replace(/(\b[a-z](?!\s))/g, (x) =>
                                x.toUpperCase()
                              )}
                          </TableCell>
                        );
                      }
                    })}
                </TableRow>
              </TableHead>
              {/** Samples data here */}
              <TableBody>
                {sortedData &&
                  sortedData.length &&
                  sortedData.map((row, idx) => (
                    <Body
                      row={row}
                      doEditSample={doEditSample}
                      doEditResult={doEditResult}
                      key={`row_${idx}`}
                      userIsOrgMemberOrAdmin={userIsOrgMemberOrAdmin}
                      sampleCodeKey={
                        sampleCodeKey ? sampleCodeKey : "sample_code"
                      }
                      collectionsSelects={collectionsSelects}
                    />
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </div>
    </div>
  );
};

export default connect(
  "selectStoretData",
  "selectUserIsOrgMemberOrAdmin",
  "selectCollectionsSelects",
  TableCollapsible
);
