import React, { useEffect, useReducer } from "react";
import { connect } from "redux-bundler-react";
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "../../../app-components/modal";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_INPUT":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "INITIALIZE_MODAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const EditParameterModal = (props) => {
  const {
    data,
    edit,
    doModalClose,
    doStoretPost,
    doStoretPut,
    doStoretDelete,
    storetIsSaving,
    storetIsDeleting,
    storetError: error,
  } = props;

  let initialState = { storet_num: "" };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (edit) {
      dispatch({
        type: "INITIALIZE_MODAL",
        payload: {
          ...data,
        },
      });
    }
  }, [edit, data]);

  const handleChange = (e) => {
    dispatch({
      type: "UPDATE_INPUT",
      field: e.target.id,
      payload: e.target.value,
    });
  };

  return (
    <ModalContent size="md" error={error}>
      <ModalHeader title={edit ? "Edit Parameters Info" : "Add Parameter"} />
      <section className="modal-body">
        <div className="container-fluid">
          <form>
            <div className="form-row">
              <div className="form-group col-lg">
                <label htmlFor="full_name">Full Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="full_name"
                  onChange={handleChange}
                  placeholder="i.e. COAGULANTS ADDED, LB/DAY"
                  value={state.full_name}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-lg">
                <label htmlFor="short_name">Short Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="short_name"
                  onChange={handleChange}
                  placeholder="i.e. RADIOACTIVITY PC/L"
                  value={state.short_name}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-lg">
                <label htmlFor="storet_num">Storet Num</label>
                <input
                  type="text"
                  className="form-control"
                  id="storet_num"
                  onChange={handleChange}
                  placeholder="i.e. 00191"
                  value={state.storet_num}
                  disabled={edit}
                  maxLength="5"
                />
                <p className="m-0 mt-1 text-muted">
                  <small>
                    {5 - state.storet_num.length} characters remaining
                  </small>
                </p>
              </div>
            </div>
          </form>
        </div>
      </section>
      <ModalFooter
        onSave={() => (edit ? doStoretPut(state) : doStoretPost(state))}
        customClosingLogic
        onDelete={edit ? () => doStoretDelete(state) : null}
        showCancelButton
        onCancel={doModalClose}
        saveIsDisabled={storetIsSaving}
        deleteIsDisabled={storetIsDeleting}
        saveText={
          storetIsSaving ? <i className="mdi mdi-loading mdi-spin" /> : "Save"
        }
        deleteText={
          storetIsDeleting ? (
            <i className="mdi mdi-loading mdi-spin" />
          ) : (
            "Delete"
          )
        }
      />
    </ModalContent>
  );
};

export default connect(
  "doStoretShouldFetch",
  "doStoretFetch",
  "doStoretPost",
  "doStoretPut",
  "doStoretDelete",
  "selectStoretIsFetching",
  "selectStoretData",
  "selectApiRoot",
  "selectStoretIsSaving",
  "selectStoretIsDeleting",
  "selectStoretError",
  EditParameterModal
);
