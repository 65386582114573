import { createSelector } from "redux-bundler";

export default {
  name: "user",
  getReducer: () => {
    const initialData = {
      data: {},
      allUsers: [],
      shouldFetch: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "USER_SAVE_STARTED":
        case "USER_SAVE_FINISHED":
        case "USER_SAVE_ERROR":
        case "USER_FETCH_START":
        case "USER_FETCH_FINISH":
        case "USERS_ALL_FETCH_START":
        case "USERS_ALL_FETCH_FINISH":
        case "USER_SHOULD_FETCH":
        case "USER_LINK_START":
        case "USER_LINK_FINISH":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },

  doUserFetchAll:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "USERS_ALL_FETCH_START",
        payload: {
          shouldFetch: false,
        },
      });

      apiFetch(`/wq_keycloak_users_t/`)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "USERS_ALL_FETCH_FINISH",
            payload: { allUsers: j.items },
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
  doUserFetch:
    () =>
    ({ dispatch, store, apiFetch }) => {
      if (!store.selectAuthIsLoggedIn()) return;
      let user = store.selectAuthTokenPayload();
      dispatch({
        type: "USER_FETCH_START",
        payload: {
          shouldFetch: false,
          isFetching: true,
        },
      });

      apiFetch(`/wq_keycloak_users_t/?q={"keycloak_id":"${user.sub}"}`)
        .then((r) => r.json())
        .then((j) => {
          if (j.items.length > 0) {
            dispatch({
              type: "USER_FETCH_FINISH",
              payload: { data: j.items[0], isFetching: false },
            });
            store.doUserRoleFetch();
          } else if (j.items.length === 0) store.doLinkUser();
        })
        .catch((e) => {
          console.log(e);
        });
    },

  doLinkUser:
    () =>
    ({ dispatch, store, apiFetch }) => {
      const { doActionPostData, selectAuthTokenPayload } = store;
      let currentUser = selectAuthTokenPayload();
      dispatch({
        type: "USER_LINK_START",
        payload: {},
      });
      let userObj = {
        keycloak_id: currentUser.sub,
        name: currentUser.name,
        email: currentUser.email,
        create_date: new Date(),
        last_login_date: new Date(),
      };
      doActionPostData(
        "/wq_keycloak_users_t/",
        userObj,
        () => {
          dispatch({
            type: "USER_LINK_FINISH",
            payload: { data: userObj, isFetching: false },
          });
        },
        (err) => console.log(err)
      );
    },

  doPutUser:
    (data, cb) =>
    ({ dispatch, store }) => {
      let apiRoot = store.selectApiRoot();
      let { doActionPutData } = store;
      dispatch({
        type: "USER_SAVE_STARTED",
        payload: { isSaving: true },
      });
      doActionPutData(
        `${apiRoot}/wq_keycloak_users_t/${data.keycloak_id}`,
        data,
        () => {
          dispatch({
            type: "USER_SAVE_FINISHED",
            payload: { isSaving: false },
          });
          cb && cb();
        },
        (e) =>
          dispatch({
            type: "USER_SAVE_ERROR",
            payload: { isSaving: false, error: e },
          })
      );
    },
  selectUserData: (state) => {
    return state.user.data;
  },

  selectUsersAll: (state) => {
    return state.user.allUsers;
  },

  selectUserPrimaryOffice: (state) => state.user.data.primary_office,

  selectUserIsSaving: (state) => state.user.isSaving,
  selectUserIsFetching: (state) => state.user.isFetching,
};
