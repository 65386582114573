import { createSelector } from "redux-bundler";
import {
  stringifyDate,
  ordsifyUrlBuilder,
  ordsifyUrlBuilderNoSlash,
} from "../utils/ordsify";

export default {
  name: "report",
  // reducers
  getReducer: () => {
    const initialData = {
      data: [],
      config: [],
      shouldFetch: false,
      locationData: [],
      shouldLocationActive: false,
      done: false,
      reportFetched: false,
    };
    return (state = initialData, { type, payload }) => {
      switch (type) {
        case "REPORT_STARTED":
        case "REPORT_FINISHED":
        case "REPORT_CONFIG_STARTED":
        case "REPORT_CONFIG_FINISHED":
        case "REPORT_CONFIG_FETCH_STARTED":
        case "REPORT_CONFIG_FETCH_FINISHED":
        case "REPORT_CONFIG_SHOULD_FETCH":
        case "REPORT_LOAD_STARTED":
        case "REPORT_LOAD_FINISHED":
        case "LOCATION_ACTIVE_FETCH_START":
        case "LOCATION_ACTIVE_FETCH_FINISH":
        case "REPORT_LOCATION_ACTIVE_FINISH":
        case "REPORT_FINISHED":
          return Object.assign({}, state, payload);
        default:
          return state;
      }
    };
  },
  // action creators
  doReportGenerate:
    (state) =>
    ({ dispatch, store, apiFetch }) => {
      const {
        min_sample_date,
        max_sample_date,
        min_sample_depth,
        max_sample_depth,
      } = state;
      let parameters = state.parameters && state.parameters.map((p) => p.value);
      let locations = Object.keys(state).map((key) => {
        if (parseInt(key) && state[key]) {
          /** key is site_code containing arrays of locations */
          return state[key].map((location) => location.value);
        }
      });
      locations = locations.flat(1).filter((item) => item !== undefined);
      dispatch({ type: "REPORT_STARTED", payload: { done: false } });
      const url = ordsifyUrlBuilderNoSlash(
        `/reports/statistics/${stringifyDate(min_sample_date)}/${stringifyDate(
          max_sample_date
        )}/${min_sample_depth}/${max_sample_depth}`,
        [
          {
            keyword: "location_code",
            items: locations ? locations : [],
          },
          {
            keyword: "storet_num",
            items: parameters ? parameters : [],
          },
        ],
        500
      );
      apiFetch(url)
        .then((r) => r.json())
        .then((j) =>
          dispatch({
            type: "REPORT_FINISHED",
            payload: {
              data: j.items,
              done: true,
            },
          })
        )
        .catch((e) => {
          console.log(e);
        });
    },
  doReportPostConfig:
    () =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({ type: "REPORT_CONFIG_STARTED" });
      const id = Math.floor(Math.random() * 100000);
      const cacUid = parseInt(store.selectAuthTokenPayload().sub);
      const locations = store.selectLocationsActive();
      const storets = store.selectStoretsActive();
      let storetNames = [];
      let locationNames = [];
      if (storets[0].hasOwnProperty("short_name")) {
        storetNames = [
          ...new Set(storets.map((item) => item.short_name)),
        ].toString();
      } else {
        storetNames = [
          ...new Set(storets.map((item) => item.label)),
        ].toString();
      }
      if (locations[0].hasOwnProperty("loc_id")) {
        locationNames = [
          ...new Set(locations.map((item) => item.loc_id)),
        ].toString();
      } else {
        locationNames = [
          ...new Set(locations.map((item) => item.label)),
        ].toString();
      }
      const data = {
        project_code: store.selectProjectActive(),
        location_codes: store.selectLocationsActiveArray().toString(),
        location_ids: locationNames,
        min_depth: store.selectSampleDepthActiveMin(),
        max_depth: store.selectSampleDepthActiveMax(),
        min_date: stringifyDate(store.selectSampleDateActiveMin()),
        max_date: stringifyDate(store.selectSampleDateActiveMax()),
        storet_nums: store.selectStoretActiveArray().toString(),
        storet_names: storetNames,
        cac_uid: cacUid,
        id: id,
      };
      apiFetch("/reports/config", {
        method: "POST",
        headers: {
          Cac_uid: cacUid,
          Id: id,
          Report_type: "statistics",
        },
        body: JSON.stringify(data),
      }).then((r) => {
        dispatch({ type: "REPORT_CONFIG_FINISHED" });
      });
    },
  doReportShouldFetchConfig:
    () =>
    ({ dispatch, store }) => {
      dispatch({
        type: "REPORT_CONFIG_SHOULD_FETCH",
        payload: {
          shouldFetch: true,
        },
      });
    },
  doReportGetConfig:
    () =>
    ({ dispatch, store, apiFetch }) => {
      const cacUid = parseInt(store.selectAuthTokenPayload().sub);
      dispatch({
        type: "REPORT_CONFIG_FETCH_STARTED",
        payload: { shouldFetch: false },
      });
      apiFetch(`/reports/config/${cacUid}?q={"report_type":"statistics"}`)
        .then((response) => response.json())
        .then((r) =>
          dispatch({
            type: "REPORT_CONFIG_FETCH_FINISHED",
            payload: {
              config: r.items,
              reportFetched: false,
            },
          })
        );
    },
  doReportLoadConfig:
    (rowData) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "REPORT_LOAD_STARTED",
      });
      // get project data
      // store.doLocationFetchByProject(rowData.project_code)
      // Need to wait for locations to get back to get activeLocationCode
      const locationCodes = rowData.location_codes.split(",");
      const storetData = store.selectStoretData();
      const storetCodes = rowData.storet_nums.split(",");
      let activeStoret = [];
      storetCodes.forEach((x) => {
        activeStoret.push(storetData.find((y) => x === y.storet_num));
      });
      store.doReportLocationFetch(locationCodes);
      // FETCH SAMPLE
      // store.doSampleFetch(locationCodes)
      // PROJECT ACTIVE
      store.doProjectActive(rowData.project_code);
      // STORET ACTIVE
      store.doStoretsActive(activeStoret);
      // SAMPLES ACTIVE
      store.doSampleDepthActiveMin(rowData.min_depth);
      store.doSampleDepthActiveMax(rowData.max_depth);
      store.doSampleDateActiveMin(rowData.min_date);
      store.doSampleDateActiveMax(rowData.max_date);
      // FETCH LOCATIONS BY LOCATION CODE

      dispatch({
        type: "REPORT_LOAD_FINISHED",
        payload: {
          reportFetched: true,
        },
      });
    },

  doReportLocationFetch:
    (location_codes) =>
    ({ dispatch, store, apiFetch }) => {
      dispatch({
        type: "LOCATION_ACTIVE_FETCH_START",
        payload: {
          shouldFetch: false,
        },
      });

      const url = ordsifyUrlBuilder(
        "/wq_locations/",
        [
          {
            keyword: "location_code",
            items: location_codes ? location_codes : "", // if no location_codes passed, fetch all
          },
        ],
        1000
      );
      apiFetch(url)
        .then((r) => r.json())
        .then((j) => {
          dispatch({
            type: "LOCATION_ACTIVE_FETCH_FINISH",
            payload: {
              //   data: j.items.concat(store.selectSampleRaw().data),
              locationData: j.items,
              shouldLocationActive: true,
            },
          });
        });
    },

  doReportUpdateLocation:
    () =>
    ({ dispatch, store }) => {
      const locationCodes = store.selectReportLocations();
      store.doLocationsActive(locationCodes);
      dispatch({
        type: "REPORT_LOCATION_ACTIVE_FINISH",
        payload: {
          shouldLocationActive: false,
        },
      });
    },
  doReportSetFinished:
    (way) =>
    ({ dispatch, store }) => {
      dispatch({
        type: "REPORT_FINISHED",
        payload: { reportFetched: way },
      });
    },
  //

  selectReportDone: (state) => state.report.done,
  selectReportFinished: (state) => state.report.reportFetched,
  selectReportConfig: (state) => state.report.config,
  selectReportData: (state) => state.report.data,
  selectReportConfigShouldFetch: (state) => state.report.shouldFetch,
  selectReportShouldLocationActive: (state) =>
    state.report.shouldLocationActive,
  selectReportConfigJson: createSelector(
    "selectReportConfig",
    "selectProjectAll",
    (reportConfig, projectAll) => {
      let data = [];
      if (reportConfig.length) {
        reportConfig.forEach((x) => {
          data.push(JSON.parse(x.configuration));
        });
        data.map((x) => {
          x.project_name = projectAll.find(
            (y) => y.site_code === x.project_code
          ).site_name;
        });
      }
      return data;
    }
  ),
  selectReportLocations: (state) => state.report.locationData,

  reactReportConfigFetch: createSelector(
    "selectReportConfigShouldFetch",
    (shouldFetch) => {
      if (shouldFetch)
        return {
          actionCreator: "doReportGetConfig",
        };
    }
  ),
  reactReportLocationActive: createSelector(
    "selectReportShouldLocationActive",
    "selectReportLocations",
    (reportShouldLocationActive) => {
      if (reportShouldLocationActive) {
        return {
          actionCreator: "doReportUpdateLocation",
        };
      }
    }
  ),
};
