import React, { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import Select from "react-select"

export default connect(
    "selectProjectAll",
    "doProjectActive",
    "selectProjectActive",
    "selectProjectIsFetching",
    ({ projectAll, doProjectActive, projectActive, projectIsFetching }) => {
        useEffect(() => {
            if (projectActive === '') {
                setVal(null)
            } else {
                const found = projectAll.find((project) => project.site_code === projectActive)

                setVal({
                    value: found.site_code,
                    label: found.site_name
                })
            }
        }, [projectActive])
        const [val, setVal] = useState(null)
        const handleChange = (e) => {
            if (e) {
                setVal(e)
                doProjectActive(e)
            } else {
                setVal(null)
            }
        }
        return (
            <div style={{ width: "200px", padding: "5px" }}>
                <Select
                    placeholder="Projects"
                    options={projectAll.map((item) => ({
                        value: item['site_code'],
                        label: item['site_name']
                    }))}
                    value={val}
                    onChange={(value) => handleChange(value)}
                    menuPosition="fixed"
                    isLoading={projectIsFetching}
                />
            </div>
        )
    }
)