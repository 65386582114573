import React, { useEffect, useState } from "react";
import { connect } from "redux-bundler-react";
import { classnames } from "../../utils/helpers";
import "../location/cards/customCard.css";
import AddLocationModal from "./AddLocationModal";
import ExportDataModal from "./ExportDataModal";
import PivotDataModal from "./PivotDataModal";

function CardTitle(props) {
  const {
    doProjectCountFetch,
    project,
    userIsOrgMemberOrAdmin,
    doModalOpen,
    doModalOpenUniqueCode,
  } = props;
  const [expand, setExpand] = useState(false);
  useEffect(() => {
    doProjectCountFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);
  let total = 0;
  props.projectCount.forEach((item) => {
    total = item.count_site + total;
  });

  const dropdownMenuClass = classnames({
    "dropdown-menu": true,
    show: expand,
    rounded: true,
  });

  return !props.project ? (
    <div style={{ textAlign: "center" }}>
      <i className="mdi mdi-loading mdi-spin mdi-48px"></i>
    </div>
  ) : (
    <div className="w-100">
      <div className="d-flex justify-content-between pb-2">
        <div style={{ display: "inline-flex" }}>
          <h3 className="card-title bold m-0">
            {`${props.project.site_name}`}
            <small className="text-muted ml-2">{props.project.site_id}</small>
          </h3>
        </div>
        <div style={{ display: "inline-flex" }}>
          <div className="dropdown">
            <button
              className="btn btn-outline-primary dropdown-toggle mr-2"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded={expand}
              onClick={() => setExpand(!expand)}
            >
              Import / Export Data
            </button>
            <div
              className={dropdownMenuClass}
              aria-labelledby="dropdownMenuButton"
            >
              <a
                className="dropdown-item cursor-pointer"
                style={{ maxWidth: 250, maxHeight: 35, marginRight: 12 }}
                onClick={() => {
                  setExpand(false);
                  doModalOpen(ExportDataModal);
                }}
              >
                Download Project Data
              </a>
              <a
                className="dropdown-item cursor-pointer"
                style={{ maxWidth: 250, maxHeight: 35, marginRight: 12 }}
                onClick={() => {
                  setExpand(false);
                  doModalOpenUniqueCode(PivotDataModal, {}, "id");
                }}
              >
                Import Profile Data
              </a>
            </div>
          </div>
          {userIsOrgMemberOrAdmin && (
            <button
              className="btn btn-outline-success"
              style={{ maxWidth: 250, maxHeight: 35 }}
              onClick={() => {
                doModalOpenUniqueCode(AddLocationModal, {}, "location_code");
              }}
            >
              Add Location
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
export default connect(
  "doProjectCountFetch",
  "selectProject",
  "selectProjectIsCountFetching",
  "doUpdateUrl",
  "doModalOpen",
  "doModalOpenUniqueCode",
  CardTitle
);
